import React from "react";
import AccordionSidemenu from "../../../Components/AccordionMenu/AccordionSidemenu";
import "../../../Components/Sidebar/Sidebar.css";
import TextWebLink from "../../../Components/Links/TextWebLink";
import HoursContact from "../../../Components/Text/HoursContact";
import PhoneLink from "../../../Components/Links/PhoneLink";

function RecorderSideBar() {
  const navItems = [
    {
      title: "About",
      to: "/departments/recorders",
      isLink: false,
    },
    {
      title: "Change of Address Request",
      to: "/departments/treasurer/change-of-address",
      isLink: false,
    },
    {
      title: "Fee Schedule",
      to: "/departments/recorders/fees",
      isLink: false,
    },
    {
      title: "Plot Survey's",
      to: "",
      sub: [
        {
          title: "File Plot Survey",
          to: "/departments/recorders/file-survey",
          isLink: false,
        },
        {
          title: "Search Filed Survey's",
          to: "https://ironcountyut-my.sharepoint.com/:f:/g/personal/ironits_ironcounty_net/EgpXCKmZiiVKtmfTi38QsNwB4-xxelEfLEsASJ1khRZLFg",
          isLink: true,
        },
      ],
    },
    {
      title: "Recorded Plats",
      to: "https://ironcountyut-my.sharepoint.com/:f:/g/personal/ironits_ironcounty_net/EomcfDiMF6NHthb2ybxsjo0BhjZ3Ozmogg72JP3iBvGG8g?e=QZmsEu",
      isLink: true,
    },
    {
      title: "Search Property Accounts",
      to: "https://eagleweb.ironcounty.net/eaglesoftware/web/",
      isLink: true,
    },
  ];

  return (
    <>
      <div className="nav-menu-side">
        <HoursContact>
          <p>
            Carri Jeffries, County Recorder
            <br />
            PO Box 506
            <br />
            <TextWebLink link="https://goo.gl/maps/dWeYhAwocsaNHEyT9">
              68 S 100 E Parowan, UT 84761
            </TextWebLink>
            <br />
            Monday - Friday 8:00 A.M. - 5:00 P.M.
            <br />
            <PhoneLink>435-477-8350</PhoneLink>
          </p>
        </HoursContact>
        <br />
        <AccordionSidemenu nav={navItems} />
      </div>
    </>
  );
}

export default RecorderSideBar;

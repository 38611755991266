import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import JusticeSideBar from "./JusticeSidebar";
import Textbox from "../../../Components/Text/Textbox";
import Aidaform from "../../../Components/Aidaform/Aidaform";
import Title from "../../../Components/Text/Title";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

function TrafficSchoolContact() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Left = () => (
    <>
      <Title>Traffic School Contact</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <h3>
          It is important that you provide a valid, working email address. We
          will send a confirmation email once you submit the agenda request.
          Please enter your primary telephone number so that we can contact you
          with any clarification questions.
        </h3>
      </Textbox>
      <Aidaform
        form="https://ironcounty.aidaform.com/traffic-school-contact-form"
        title=""
      />
    </>
  );

  return (
    <>
      <Helmet>
        <title>Justice Court: Traffic School Contact</title>
        <meta
          name="description"
          content="Iron County Justice Court traffic school contact Page"
        />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/justice-court/traffic-school-contact"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<JusticeSideBar />}
        id="justice-background"
        click={click}
      />
    </>
  );
}

export default TrafficSchoolContact;

import React from "react";
import ButtonIcons from "../../../../Components/Buttons/Button_Icons";
import { IoIosBusiness } from "react-icons/io";
import { TbBuildingCottage } from "react-icons/tb";
import { BsFillPersonVcardFill } from "react-icons/bs";
import "../../Home.css";

function Apply() {
  const applyButtons = [
    {
      title: "Building Permit",
      to: "https://ironcounty.mycityinspector.com/register?registration_id=15ccc64ec1d01c",
      isLink: true,
    },
    {
      title: "Business License",
      to: "/departments/clerk/business-license",
    },

    {
      title: "Burn Permit",
      to: "/departments/fire/burn-permit",
    },
    {
      title: "County Jobs",
      to: "https://ironcounty.applicantpro.com/jobs/",
      isLink: true,
    },
    {
      title: "Tax notice by email",
      to: "https://ironcounty.billtrax.com/home",
      isLink: true,
    },
    {
      title: "Traffic School",
      to: "/departments/justice-court/traffic-school",
    },
  ];

  return (
    <div id="apply-container">
      <ButtonIcons
        buttons={applyButtons}
        id="home-buttons"
        className="buttons-container-home"
      />
    </div>
  );
}

export default Apply;

import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import EngineeringSidebar from "./EngineeringSidebar";
import Textbox from "../../../Components/Text/Textbox";
import Title from "../../../Components/Text/Title";
import Half from "../../../Components/Sizes/Half";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import ButtonSingle from "../../../Components/Buttons/Button_Single";
import PhoneLink from "../../../Components/Links/PhoneLink";
import EmailLink from "../../../Components/Links/EmailLink";
import { Helmet } from "react-helmet-async";

function RequestsAndForms() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Left = () => (
    <>
      <Title>Requests and Forms</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <Half>
          <h1>Addressing</h1>
          <p>
            Contact the Engineer's office at <PhoneLink>435-865-5370</PhoneLink>{" "}
            or <EmailLink>mwilson@ironcounty.net</EmailLink>. Please provide a
            property account # or parcel #.
          </p>
          <p>
            <li>
              Homes: If you are working on a building permit for a home, please
              provide a site map or details about the placement of the home on
              the property.
            </li>
            <li>
              Land: Land only properties wil be given temporary or approximate
              addresses. If at a later date a building permit is submitted, the
              plans will be reviewed to determine if the address needs to be
              adjusted based on placement of the home.
            </li>
          </p>
        </Half>
        <Half>
          <h1>Public or Private Road Review</h1>
          <p>
            If you have a concern about whether a road is public or private,
            whether it is county maintained, or if roads are blocked, please
            contact the County Engineer's office at{" "}
            <PhoneLink>435-865-5370</PhoneLink> or{" "}
            <EmailLink>mwilson@ironcounty.net</EmailLink>. The concern will be
            reviewed by the county review committee, which meets 1 - 2 times per
            month.
          </p>
        </Half>
        <Half>
          <h1>Maintained Roads</h1>
          <ButtonSingle
            href="https://irongis.maps.arcgis.com/apps/Solutions/s2.html?appid=70e6083b3313464aabe2ec56f80040fe"
            isLink={true}
          >
            GIS Map
          </ButtonSingle>
        </Half>
        <Half>
          <h1>Road Assessment Process and Forms</h1>
          <h4>What is a Road Assessment?</h4>
          <p>
            If you live on a street that is not maintained by the county and you
            would like to get it maintained, you and your neighbors must agree
            to bring the road up to county standards before the county will
            maintain it. The packet below explains the process of review and the
            estimated costs the property owners will incur associated with it.
            The process may take up to 1 year form start to finish.
          </p>
        </Half>
        <Half>
          <h1>Land Surveys</h1>
          <p>
            The Engineering Department does not do surveying for private
            property. Please contact a private engineering/surveying firm for
            this.
          </p>
          <p>
            Filed Surveys are kept by the Iron County Recorder's Office and are
            available online using the link below
          </p>
          <ButtonSingle
            href="https://ironcountyut-my.sharepoint.com/personal/ironits_ironcounty_net/_layouts/15/onedrive.aspx?id=%2Fpersonal%2Fironits%5Fironcounty%5Fnet%2FDocuments%2FRecorders%2FFiled%20Survey%20Plats&ga=1"
            isLink={true}
          >
            Filed Surveys
          </ButtonSingle>
        </Half>
        <Half>
          <h1>Road Standards</h1>
          <li>Cross Sections</li>
          <li>Testing</li>
        </Half>
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Engineering: Requests and Forms</title>
        <meta
          name="description"
          content="Iron County engineering requests and forms Page"
        />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/engineering/requests-and-forms"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<EngineeringSidebar />}
        id="engineering-background"
        click={click}
      />
    </>
  );
}

export default RequestsAndForms;

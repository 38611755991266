import React from "react";
import { Link } from "react-router-dom";
import "./Buttons.css";

function ButtonSingle(props) {
  if (props.isLink === true) {
    return (
      <a href={props.href} target="_blank" rel="noopener noreferrer">
        <button width={props.width} className="icon-button">
          {props.children}
        </button>
      </a>
    );
  } else {
    return (
      <Link to={props.href}>
        <button width={props.width} className="icon-button">
          {props.children}
        </button>
      </Link>
    );
  }
}

export default ButtonSingle;

import React from "react";
import "./Text.css";

function Subtitle(props) {
  return (
    <div className="hero-container">
      <div className="subtitle">
        <h2>{props.children}</h2>
      </div>
    </div>
  );
}

export default Subtitle;

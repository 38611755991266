import React from "react";
import Subtitle from "../Text/Subtitle";

function Aidaform(props) {
  return (
    <>
      {props.title ? <Subtitle>{props.title}</Subtitle> : null}
      <iframe
        src={props.form}
        width="100%"
        height="1100px"
        frameborder="0"
        allowfullscreen=""
        title={props.title}
      ></iframe>
    </>
  );
}

export default Aidaform;

import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import SeniorSidebar from "./SeniorSidebar";
import Half from "../../../Components/Sizes/Half";
import Textbox from "../../../Components/Text/Textbox";
import Title from "../../../Components/Text/Title";
import TextWebLink from "../../../Components/Links/TextWebLink";
import ButtonSingle from "../../../Components/Buttons/Button_Single";
import Image from "../../../Components/Images/Image";
import ParowanMenu from "../../../Images/Departments/SeniorCenter/Menu-Parowan.png";
import ParowanActivity from "../../../Images/Departments/SeniorCenter/Activity-Parowan.png";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

function ParowanCenter() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Left = () => (
    <>
      <Title>Parowan Senior Center</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <h1>Get in Touch</h1>
        <p>
          The Parowan Senior Center is open Mondays, Wednesdays and Thursdays
          from 9:00 a.m. to 3:00 p.m. Please visit us during those times or give
          us a call at 435-477-8925
        </p>

        <Half>
          <section>
            <Image
              width="100%"
              height="auto"
              src={ParowanMenu}
              alt="picture of the parowan food menu"
            ></Image>
            <h1>Center Meals</h1>
            <p>
              The Parowan Senior Center provides a delicious “home cooked” lunch
              meal by a professional staff of cooks. Their goal is to provide
              the most nutritious and delicious meal as possible. The meals meet
              all the governmental regulations for nutrition, dietary and
              sanitation conditions.
            </p>
            <p>
              The Parowan Senior Center lunch meal is served on Monday,
              Wednesday, and Thursday. Refer to the Parowan flyer for the daily
              menu listing. The center begins serving promptly at 12:15 P.M.
              Music, entertainment, or presentations are often performed during
              lunch.
            </p>
            <p>
              Reservations are required by calling the center before 9:30 A.M.
              This allows the cooks to know how many meals they need to prepare.
              There is a suggested donation of $4.00 per meal for those 60 years
              of age and older. Those under 60 years of age must pay a charge of
              $7.00 per meal.
            </p>
          </section>
        </Half>
        <Half>
          <section>
            <Image
              width="100%"
              height="auto"
              src={ParowanActivity}
              alt="picture of the parowan activity list"
            ></Image>
            <p>
              The Parowan Senior Center has a variety of activities. No matter
              where you live you are welcome to attend. Some of these activities
              include:
              <li>Relax with a massage from Zion’s Way Hospice</li>
              <li>
                See what your blood pressure is by Horizon Home Health Care
              </li>
              <li>Play bingo with prizes awarded to the winners!</li>
              <li>The pool table is always available for a fun game of pool</li>
              <li>
                Relax on the couch in front of the T.V., read a book or do
                trivia and word puzzles provided at the center.
              </li>
              <li>
                Shopping day in Cedar City. Refer to senior bus section for
                details.
              </li>
              <li>
                During lunch enjoy entertainment and presentations. (Refer to
                flyer for details.)
              </li>
            </p>
          </section>
        </Half>
      </Textbox>
      <Textbox title="Senior Shuttle Bus">
        <h1>Lunch and shopping day in cedar at the Parowan Senior Center</h1>
        <p>
          On Monday, Wednesday and Thursday the bus can pick you up at your home
          around 10-30 – 11:30 A.M. and bring you to the center for lunch at
          12:15 P.M. Every other Tuesday is " shopping day." The bus will take
          you to walmart in Cedar. Leaving at 9:30 AM. You need to sign up at
          the center. Seating is limited. Suggested ride downtown is $4.00 round
          trip. The bus can come to Paragonah and Summit as well to pick you up
          and take you home. During those days, the bus can take you to the
          Parowan Market and other destinations in Parowan. There is a suggested
          donation of $3.00 round trip per person 60 years and older and cost of
          $7.00 for those that are under 60 years old.
        </p>
        <em>
          Please note: There are some restrictions associated with the Senior
          Bus, such as where you live, health ability to ride on the bus, etc.
        </em>

        <h1>Meals on Wheels</h1>
        <p>
          <TextWebLink link="https://www.mealsonwheelsamerica.org/">
            Meals on Wheels
          </TextWebLink>{" "}
          is available for senior citizens 60 years of age or older. Customers
          must meet the qualification to be “home bound”, meaning they are
          unable to transport themselves, or unable to have someone else
          transport them (senior bus, friends, relatives, etc.) to the Senior
          Center where lunch is available.
        </p>
        <p>
          Lunch meals are delivered in the Parowan area on Monday, Wednesday and
          Thursday. Delivery time is anytime between 11:00 A.M and 1:00 P.M.
          There is a suggested donation of $4.00 per meal.
        </p>
        <p>
          The meals are delivered in 3 sections: a sealed food tray with cold
          items, such as salads, desserts, etc. and a hot food tray with the
          main course, vegetables, etc. and drink, which is choice of milk or
          water. Refer to the monthly menu calendar. The meals are “home cooked”
          by a staff of cooks in the Senior Center kitchens. Meals are inspected
          regularly for nutrition qualifications, dietary specifications,
          sanitation and other local and governmental regulations. They are
          fresh and delicious.
        </p>
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Senior Center: Parowan</title>
        <meta
          name="description"
          content="Iron County Senior Center Parowan Page"
        />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/senior-center/parowan"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<SeniorSidebar />}
        id="Senior-background"
        click={click}
      />
    </>
  );
}

export default ParowanCenter;

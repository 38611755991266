import React, { useState } from "react";
import "../Departments.css";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import AuditorSideBar from "./AuditorSideBar";
import Title from "../../../Components/Text/Title";
import Textbox from "../../../Components/Text/Textbox";
import Map from "../../../Components/Maps/Map";
import ButtonIcons from "../../../Components/Buttons/Button_Icons";
import CardAccordion from "../../../Components/Card/CardAccordion";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import TextWebLinkList from "../../../Components/Links/TextWebLinkList";
import { Helmet } from "react-helmet-async";
import TextWebLink from "../../../Components/Links/TextWebLink";

function TaxSale() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  const taxButtons = [
    {
      title: "Bidder Registration",
      to: "https://www.publicsurplus.com/sms/browse/home",
      isLink: true,
    },
    {
      title: "Claim for Surplus Funds",
      to: "/files/departments/auditor/claim-surplus-funds-form.pdf",
      isLink: true,
    },
    {
      title: "How to Pay",
      to: "/files/departments/auditor/payment-procedures.pdf",
      isLink: true,
    },
    {
      title: "Map of Properties",
      to: "https://irongis.ironcounty.net/portal/apps/webappviewer/index.html?id=6cb6c1eec0c74bdcade53c26ca7b6274",
      isLink: true,
    },

    {
      title: "Ordinance 2020-2",
      to: "/files/departments/auditor/ordinance-2020-2.pdf",
      isLink: true,
    },
    {
      title: "Property List",
      to: "https://ironcountyut-my.sharepoint.com/:w:/g/personal/afranklin_ironcounty_net/ESe18u7PtMBBtrmKeoeIGbsBpioPhN4Wl0FzkwnulJzv5Q?e=B5RhDo",
      isLink: true,
    },
    {
      title: "Tax Sale Disclaimer",
      to: "/files/departments/auditor/tax-sale-disclaimer.pdf",
      isLink: true,
    },
    {
      title: "Terms of Sale",
      to: "/files/departments/auditor/2024-Tax-Sale-Terms-and-Conditions.pdf",
      isLink: true,
    },
  ];

  const TaxSaleResults = [
    {
      name: "2024 Tax Sale Results",
      link: "https://ironcountyut-my.sharepoint.com/:x:/g/personal/afranklin_ironcounty_net/ES4wdP8ox1NOoxYo1BXVRaMBOsEg6YsuzZdG1Eul6OCVuQ?e=8j44EP",
    },
    {
      name: "2023 Tax Sale Results",
      link: "https://ironcountyut-my.sharepoint.com/:x:/g/personal/afranklin_ironcounty_net/EUfrnWuGjxlCn2SClNokH3YBKcd7Xyj7r9mHK0OUnKW7OA?e=cpSPf6",
    },
    {
      name: "2022 Tax Sale Results",
      link: "https://ironcountyut-my.sharepoint.com/:x:/g/personal/jmcdonald_ironcounty_net/EY01BdPZUPpDjJEdDq_4kW8BGADzFBS10_nHOtdQw39MxQ?e=BPEe6o",
    },
    {
      name: "2021 Tax Sale Results",
      link: "https://ironcountyut-my.sharepoint.com/:x:/g/personal/jmcdonald_ironcounty_net/EWniTzMdLU5FkCGL841WulcB_h33AzsSL_-VwJOxDfGwew?e=8eQR1o",
    },
    {
      name: "2020 Tax Sale Results",
      link: "/files/departments/auditor/tax-sale/2020-tax-sale-results.xlsx",
    },
    {
      name: "2019 Tax Sale Results",
      link: "/files/departments/auditor/tax-sale/2019-tax-sale-results.pdf",
    },
    {
      name: "2018 Tax Sale Results",
      link: "/files/departments/auditor/tax-sale/2018-tax-sale-results.pdf",
    },
    {
      name: "2017 Tax Sale Results",
      link: "/files/departments/auditor/tax-sale/2017-tax-sale-results.pdf",
    },
    {
      name: "2016 Tax Sale Results",
      link: "/files/departments/auditor/tax-sale/2016-tax-sale-results.pdf",
    },
    {
      name: "2015 Tax Sale Results",
      link: "/files/departments/auditor/tax-sale/2015-tax-sale-results.pdf",
    },
    {
      name: "2014 Tax Sale Results",
      link: "/files/departments/auditor/tax-sale/2014-tax-sale-results.pdf",
    },
    {
      name: "2013 Tax Sale Results",
      link: "/files/departments/auditor/tax-sale/2013-tax-sale-results.pdf",
    },
    {
      name: "2012 Tax Sale Results",
      link: "/files/departments/auditor/tax-sale/2012-tax-sale-results.pdf",
    },
    {
      name: "2011 Tax Sale Results",
      link: "/files/departments/auditor/tax-sale/2011-tax-sale-results.pdf",
    },
  ];

  const PropertyList = [
    {
      name: "2024 Property List",
      link: "/files/departments/auditor/tax-sale/property-list-2024.pdf",
    },
    {
      name: "2023 Property List",
      link: "/files/departments/auditor/tax-sale/property-list-2023.pdf",
    },
    {
      name: "2022 Property List",
      link: "/files/departments/auditor/tax-sale/property-list-2022.pdf",
    },
    {
      name: "2021 Property List",
      link: "/files/departments/auditor/tax-sale/2021-property-list.docx",
    },
    {
      name: "2020 Property List",
      link: "/files/departments/auditor/tax-sale/2020-notice-of-final-tax-sale.pdf",
    },
    {
      name: "2019 Property List",
      link: "/files/departments/auditor/tax-sale/2019-property-list.docx",
    },
    {
      name: "2018 Property List",
      link: "/files/departments/auditor/tax-sale/2018-property-list.pdf",
    },
    {
      name: "2017 Property List",
      link: "/files/departments/auditor/tax-sale/2017-property-list.pdf",
    },
    {
      name: "2016 Property List",
      link: "/files/departments/auditor/tax-sale/2016-property-list.pdf",
    },
    {
      name: "2015 Property List",
      link: "/files/departments/auditor/tax-sale/2015-property-list.pdf",
    },
    {
      name: "2014 Property List",
      link: "/files/departments/auditor/tax-sale/2014-property-list.pdf",
    },
    {
      name: "2013 Property List",
      link: "/files/departments/auditor/tax-sale/2013-property-list.pdf",
    },
    {
      name: "2012 Property List",
      link: "/files/departments/auditor/tax-sale/2012-property-list.pdf",
    },
    {
      name: "2011 Property List",
      link: "/files/departments/auditor/tax-sale/2011-property-list.pdf",
    },
  ];

  const Left = () => (
    <>
      <Title>Delinquent Tax Sale</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <h1>NOTICE</h1>
        <p>
          IRON COUNTY MAKES NO WARRANTY OR GUARANTEE, EITHER EXPRESSED OR
          IMPLIED RELATIVE TO USABILITY, THE EXACT LOCATION, DESIRABILITY OR
          USEFULNESS, OR BOUNDARY LINES OF THE PROPERTIES. THE BIDDER IS SOLELY
          RESPONSIBLE FOR DETERMINING THE EXTENT, IF ANY, TO WHICH THE PROPERTY
          WILL OR MAY BE SUBJECT TO LIENS AND ENCUMBRANCES, EASEMENTS RECORDED
          OR NOT RECORDED, DETERMINING THE CONDITION OF THE PROPERTY, THE
          PHYSICAL ASPECTS OF THE LAND, ITS GEOGRAPHICAL LOCATION, ACCESSIBILITY
          AND CORRECT ACREAGE. PROSPECTIVE PURCHASERS ARE URGED TO EXAMINE THE
          TITLE, LOCATION AND DESIRABILITY OF THE AVAILABLE PROPERTIES TO THEIR
          OWN SATISFACTION <b>PRIOR</b> TO THE SALE.
        </p>
        <hr />
        <p>
          Notice is hereby given, on Thursday, May 23, 2024 at 10:00 AM MDT, the
          Iron County Utah Tax Sale will begin. The sale will close the same day
          at times shown on each auction item. All sales will be made through
          the auction by the highest bidder.
          <br />
          <br />
          The Auction will be conducted via the{" "}
          <TextWebLink link="https://www.publicsurplus.com/sms/browse/home">
            www.publicsurplus.com
          </TextWebLink>{" "}
          website ONLY.
          <br />
          <br />
          Iron County is not liable for the failure of any electronic devise
          which prevents a person from participating in the auction. “Device”
          includes, but is not limited to, cellphone, computer hardware,
          network, internet connection, software application and/or website.
          <br />
          <br />
          <ul>
            <li>
              Delinquent property may be redeemed up until the start of the tax
              sale on Thursday, May 23, 2024
            </li>
            <li>
              The “2024 Property List” will be updated daily. See below for
              link.
            </li>
          </ul>
        </p>
      </Textbox>
      <Textbox title="Steps to Qualify and Participate in the Tax Sale Auction">
        <p>
          <ul>
            <li>
              Auction registration and deposits are submitted through the
              <TextWebLink link="https://www.publicsurplus.com/sms/browse/home">
                www.publicsurplus.com
              </TextWebLink>{" "}
              . <b>Deposits will be required for each property you bid on</b>{" "}
              and will need to be paid via credit card when you make your first
              bid on each property. Properties will be separated into two tiers.
              Tier 1 requires a $50 deposit; this tier will include any
              properties with a tax value of $10,000 and under. Tier 2 requires
              a $100 deposit; this tier will include all properties with a tax
              value over $10,000. Deposits are not applied to the purchase of
              properties. If you are not the winning bidder of a property your
              deposit will be released once the tax sale is complete. If you are
              the winning bidder your deposit will be held until your full
              payment for the property is made and completed via wire transfer,
              then it will be released.
              <b>
                Iron county will be accepting only credit card deposits this
                year.
              </b>
            </li>
            <br />
            <li>
              Once the auction is completed, you will receive instructions via
              email about submitting payment if you were the highest bidder on
              any property. Payment will be submitted through the public surplus
              website and is required to be sent via wire transfer within 5
              business days (May 31, 2024) after the sale. Tax deeds will be
              recorded and mailed to the winning bidders within 4-6 weeks. They
              will be mailed to the address that was used when registering for
              the auction.
            </li>
            <br />
            <li>
              Wire Transfer Instructions: Recipient Business Name: PayMac 2520
              Saint Rose Parkway Ste 214 Henderson, NV 89053, Bank Information:
              Chase Bank 1115 S 800 E Orem, UT 84097 ABA (Routing Number):
              124001545 Accounting Number: 102908156
            </li>
          </ul>
        </p>
      </Textbox>
      <Map
        title="Iron County May Tax Sale"
        src="https://irongis.ironcounty.net/portal/apps/webappviewer/index.html?id=6cb6c1eec0c74bdcade53c26ca7b6274"
      />

      <ButtonIcons
        buttons={taxButtons}
        id="department-button"
        className="buttons-container"
      />
      <CardAccordion title="Prior Tax Sale Information">
        <TextWebLinkList
          title="Tax Sale Results by Year"
          links={TaxSaleResults}
        ></TextWebLinkList>
        <TextWebLinkList
          title="Property List by Year (PDF Format)"
          links={PropertyList}
        ></TextWebLinkList>
      </CardAccordion>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Auditor: Tax Sale</title>
        <meta name="description" content="Iron County Auditor tax sale Page" />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/auditor/tax-sale"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<AuditorSideBar />}
        id="auditor-background"
        click={click}
      />
    </>
  );
}

export default TaxSale;

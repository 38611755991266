import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import AuditorSideBar from "./AuditorSideBar";
import Title from "../../../Components/Text/Title";
import Textbox from "../../../Components/Text/Textbox";
import TextWebLink from "../../../Components/Links/TextWebLink";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

function PropertyExemptions() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Left = () => (
    <>
      <Title>Property Exemptions </Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <br />
      <Textbox>
        <section>
          <h3>For Religious, Charitable & Educational Entities</h3>
          <p>
            When a nonprofit entity acquires property on or after January 1 that
            qualifies for an exclusive use exemption, that entity may apply for
            the exclusive use exemption on or before the later of March 1st or
            30 days after the property is acquired.
            <br />
            Only land in active use (i.e. buildings, parking lots, playgrounds,
            maintained sports fields) will be considered for possible exemption.
          </p>
        </section>

        <section>
          <h3>
            <u>NEW Property Tax Exemption</u>
          </h3>
          <p>
            <b>
              DUE BY MARCH 1ST OR WITHIN 30 DAYS AFTER THE PROPERTY IS ACQUIRED.
            </b>
            <br />
            Use the applications below to file for a NEW property exemption.
            <br />
            <ul>
              <li>
                <TextWebLink link="/files/departments/auditor/pt-020-new-app-for-exmptn-fillable.pdf">
                  New Property Exemption Application & Letter
                </TextWebLink>{" "}
                - A separate application must be filed for each property account
                number
              </li>
              <li>
                <TextWebLink link="/files/departments/auditor/pt-020a-schedule-a-fillable.pdf">
                  Schedule A
                </TextWebLink>
                - Real Property - Property that has land, buildings and other
                improvements
              </li>
              <li>
                <TextWebLink link="/files/departments/auditor/pt-020b-schedule-b-fillable.pdf">
                  Schedule B
                </TextWebLink>
                - Everything not treated as <u>real</u> property - Everything
                not treated as personal property (i.e. furniture, machinery,
                equipment)
              </li>
              <li>
                <TextWebLink link="/files/departments/auditor/pt-020c-schedule-c-fillable.pdf">
                  Schedule C
                </TextWebLink>
                - Benefactors - Any gains created from the use of the property
              </li>
            </ul>
          </p>
        </section>

        <section>
          <h3>
            <u>RENEWAL of Property Tax Exemption</u>
          </h3>
          <p>
            This exemption must be filed on a yearly basis for the exemption to
            continue. The application is <b>DUE BY MARCH 1ST EVERY YEAR. </b>
            <br />
            Use the form below to file a renewal on your property tax exemption.
            <br />
            <TextWebLink link="/files/departments/auditor/pt-021-exmptn-renewal-app-fillable.pdf">
              Continued Exemption Application & Letter{" "}
            </TextWebLink>
            - A separate application must be filed for each property account
            number.
          </p>
        </section>

        <section>
          <p>
            <b>
              <u>
                All forms and documentation must be completely filled out,
                signed and sent to the Iron County Auditor’s office via mail,
                email or in person by March 1st or 30 days after the property is
                acquired to be considered for tax adjustments for that year.{" "}
              </u>
            </b>
          </p>
          <p
            style={{
              border: "solid",
              textAlign: "center",
              width: "300px",
              margin: "auto",
            }}
          >
            Iron County's Auditor's Office
            <br />
            PO Box 457
            <br />
            68 South 100 East
            <br />
            Parowan, UT, 84760
            <br />
            {/* <EmailLink>clister@ironcounty.net</EmailLink> */}
          </p>
          <h3>
            <u>Appeal Rights</u>
          </h3>
          <p>
            If you feel you have been wrongfully denied exemption or abatement
            you may appeal to the Utah State Tax Commission. You must file the
            appeal within 30 day of the denial notification. See BOE Tab
          </p>
        </section>
      </Textbox>
    </>
  );
  return (
    <>
      <Helmet>
        <title>Auditor: Property Exemptions</title>
        <meta
          name="description"
          content="Iron County Auditor property exemptions Page"
        />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/auditor/property-exemptions"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<AuditorSideBar />}
        id="auditor-background"
      />
    </>
  );
}

export default PropertyExemptions;

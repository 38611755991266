import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import CardAccordion from "../../../Components/Card/CardAccordion";
import TextWebLink from "../../../Components/Links/TextWebLink";
import Textbox from "../../../Components/Text/Textbox";
import Title from "../../../Components/Text/Title";
import ClerkSidebar from "./ClerkSidebar";
import Subtitle from "../../../Components/Text/Subtitle";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

function Passport() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Left = () => (
    <>
      <Title>Passport</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <p>
          Passports are required for all U.S. Citizens to regain entry back into
          the United States after traveling outside of the country. As of June
          1, 2009 any travel outside of a United States or it’s territories
          citizens must have a passport to re-enter the country. This also now
          includes Canada, Mexico, Bermuda, and the Caribbean Islands.
        </p>

        <p>
          <Subtitle>Hours and Fees</Subtitle>
          Passport fees must be paid by check or money order to the "U.S.
          Department of State."
          <ul>
            <li>Passport book (age 16 years and older) $130.00</li>
            <li>Passport book (age 15 years and younger) $100.00</li>
            <li>Passport card (age 16 years and older) $30.00</li>
            <li>Passport card (age 15 years and younger) $15.00</li>
            <li>
              Fee of $60.00 to expedite passport application + postage fees
            </li>
            <li>
              A fee of $35.00 is charged for the execution of each application
              made payable by credit/debit card, check, money order or cash to
              "Iron County Clerk" Passport application hours are Monday through
              Friday, 8:00 A.M. to 4:00 P.M. at the Iron County Clerk’s Office,
              68 S 100 E, Parowan, UT 84761
            </li>
          </ul>
        </p>
        <CardAccordion title="Requirements for Minors">
          <li>
            <TextWebLink link="https://travel.state.gov/content/travel/en/passports/need-passport/under-16.html">
              Special Requirements for All Minors Under Age 16
            </TextWebLink>
          </li>
          <li>
            <TextWebLink link="https://travel.state.gov/content/travel/en/passports/need-passport/16-17.html">
              Special Requirements for All Minors Ages 16 & 17
            </TextWebLink>
          </li>
        </CardAccordion>
        <CardAccordion title="How to Renew">
          It is recommended to renew your passport several weeks in advance of
          your upcoming trip. The application process is comprised of several
          steps, and a small application mistake may delay the issuance of your
          new passport. If your passport has been lost, stolen, altered,
          mutilated, damaged, or your name has changed, you should request a new
          U.S. passport as soon as possible. Note that individuals with altered
          or mutilated passports may risk prosecution under the law: Section
          1543 of Title 22 of the U.S. Code. For more information on renewing
          your passport, visit{" "}
          <TextWebLink link="https://travel.state.gov/content/travel/en/passports/have-passport/renew.html">
            travel.state.gov.
          </TextWebLink>
        </CardAccordion>
        <CardAccordion title="How to Apply">
          <p>
            <ul>
              <li>
                Fill Out{" "}
                <TextWebLink link="/files/departments/clerk/ds-11-version-c.pdf">
                  Form DS-11: Application
                </TextWebLink>{" "}
                For A U.S. Passport
              </li>
              <li>Submit Completed Form DS-11 In Person</li>
              <li>Submit Evidence of U.S. Citizenship</li>
              <li>Present Identification</li>
              <li>Submit a Photocopy of the Identification Document(s)</li>
              <li>Pay the Applicable Fee</li>
              <li>Provide One Passport Photo</li>
            </ul>
          </p>
        </CardAccordion>
        <CardAccordion title="Submit an Application for an Adult in Person">
          <p>
            You must apply in person if you meet any of the following criteria:
            <ul>
              <li>You are applying for your first U.S. passport</li>
              <li>You are under age 16</li>
              <li>
                Your previous U.S. passport was issued when you were under age
                16
              </li>
              <li>Your previous U.S. passport was lost, stolen, or damaged</li>
              <li>
                Your previous U.S. passport was issued more than 15 years ago
              </li>
              <li>
                Your name has changed since your U.S. passport was issued and
                you are unable to legally document your name change
              </li>
            </ul>
          </p>
        </CardAccordion>
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Clerk: Passport</title>
        <meta name="description" content="Iron County Clerk passport Page" />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/clerk/passport"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<ClerkSidebar />}
        id="clerk-background"
        click={click}
      />
    </>
  );
}

export default Passport;

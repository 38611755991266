import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import ParksSidebar from "./ParksSidebar";
import Title from "../../../Components/Text/Title";
import Textbox from "../../../Components/Text/Textbox";
import Third from "../../../Components/Sizes/Third";
import Map from "../../../Components/Maps/Map";
import ButtonSingle from "../../../Components/Buttons/Button_Single";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

function Reservations() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Left = () => (
    <>
      <Title>Pavilion Reservations</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <ButtonSingle
          href="/files/departments/parks/parks-recreation-ordinance.pdf"
          isLink={true}
        >
          Parks and Recreation Ordinance
        </ButtonSingle>
        <p>
          <li>Reservations are only accepted for the current year.</li>
          <li>
            Reservations will be accepted beginning on the first working day of
            the current year. You may review the reservation calendars below.
            Park ordinances are accessible at the link above for your review
            prior to making your reservation.
          </li>
          <li>
            As of January 1, 2024, Iron County Outdoor Recreation charges a
            non-refundable fee for group use of the pavilions at Three Peaks and
            Wood Ranch. The fees are as follows:
          </li>
          <ol>
            <li>
              Group up to 100 people will be $35.00 per day, per reservation
            </li>
            <li>
              Groups over 101 people will be $60.00 per day, per reservation
            </li>
            <li>Groups over 200 people Upon Approval</li>
            <li>
              Three Peaks overnight camping fee $15 per night. Sites are first
              come, first served.
            </li>
          </ol>
          <li>
            Special Events are required to obtain a permit and supply additional
            documentation such as liability insurance, medical and law
            enforcement notifications, and are also subject to a $500 fee.
            Please contact the Outdoor Recreation Director well in advance to
            start your permit process.
          </li>
          <li>
            A reservation request form must be filled out online and presented
            with applicable fees to Iron County Outdoor Recreation Office, 581 N
            Main St, Cedar City, UT, between the hours of 8:30 AM. to 5:00 PM,
            Monday through Friday. Reservations will only be confirmed by the
            director and upon receipt of payment.
          </li>
          <li>
            Consumption of alcohol in county parks requires a permit from Iron
            County Outdoor Recreation Dept. The form can be found below.
          </li>
          <ButtonSingle
            href="/files/departments/parks/alcohol-use-permit.pdf"
            isLink={true}
          >
            Alcohol Consumption Permit
          </ButtonSingle>
          <li>
            The reservation season for Wood Ranch is May 1st (weather
            permitting) through September 30th. Should you have an activity
            other than a picnic or small gathering prior to or after the season,
            please notify the director so she can advise you on the status of
            the park.
          </li>
          <li>Reservations for Three Peaks are on a year-round basis.</li>
          <li>
            Reservation of the Small Pavilion at Three Peaks includes that Group
            Camp Spot.
          </li>
          <li>
            Please submit completed application and payment in the form of cash,
            check or credit card to Iron County Outdoor Recreation Office, 581 N
            Main St, Cedar City, UT 84721
          </li>
        </p>
      </Textbox>
      <Textbox title="Make a Reservation">
        <Third>
          <ButtonSingle
            href="/files/departments/parks/shooting-range-reservation.pdf"
            isLink={true}
          >
            Shooting Range
          </ButtonSingle>
        </Third>
        <Third>
          <ButtonSingle
            href="/files/departments/parks/three-peaks-reservation.pdf"
            isLink={true}
          >
            Three Peaks
          </ButtonSingle>
        </Third>
        <Third>
          <ButtonSingle
            href="/files/departments/parks/wood-ranch-reservation.pdf"
            isLink={true}
          >
            Wood Ranch
          </ButtonSingle>
        </Third>
        <Third>
          <ButtonSingle
            href="/files/departments/parks/five-mile-reservation-applicaton.pdf"
            isLink={true}
          >
            5 Mile
          </ButtonSingle>
        </Third>
      </Textbox>
      <Textbox title="Check Availabilty">
        <h1>Wood Ranch Calendar</h1>
        <Map src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=America%2FDenver&title=Wood%20Ranch%20Calendar&src=bDZxOTA3bTNyZmlwM2F2OWxtc2dvdWs3NmtAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&color=%237CB342"></Map>
        <h1>Three Peaks Calendar</h1>
        <Map src="https://www.google.com/calendar/embed?src=1mb0stob2pv430gcldlffq48bk%40group.calendar.google.com&amp;ctz=America/Denver"></Map>
        <h1>Shooting Range Calendar</h1>
        <Map src="https://www.google.com/calendar/embed?src=scd2qsm19al7hee6lcthhuj4h4%40group.calendar.google.com&amp;ctz=America/Denver"></Map>
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Parks and Recreation: Reservations</title>
        <meta
          name="description"
          content="Iron County  Parks and Recreation reservations Page"
        />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/parks/reservations"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<ParksSidebar />}
        id="parks-background"
        click={click}
      />
    </>
  );
}

export default Reservations;

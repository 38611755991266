import React from "react";
import "../../../Components/Sidebar/Sidebar.css";
import AccordionSidemenu from "../../../Components/AccordionMenu/AccordionSidemenu";
import TextWebLink from "../../../Components/Links/TextWebLink";
import HoursContact from "../../../Components/Text/HoursContact";
import PhoneLink from "../../../Components/Links/PhoneLink";
import { FaLessThanEqual } from "react-icons/fa";

function AuditorSideBar() {
  const navItems = [
    {
      title: "About",
      to: "/departments/auditor",
      sub: null,
    },

    {
      title: "Board of Equalization-Valuation Appeals",
      to: "/departments/auditor/boa",
      sub: null,
    },

    {
      title: "Budget and Financials",
      to: "/departments/auditor/budget",
      isLink: false,
    },
    {
      title: "Fraud Hotline",
      to: "/departments/auditor/fraud",
      sub: null,
    },
    {
      title: "Tax Relief",
      sub: [
        {
          title: "Individual Abatements",
          to: "/departments/auditor/individual-abatements",
        },
        {
          title: "Property Exemptions",
          to: "/departments/auditor/property-exemptions",
        },
      ],
    },
    {
      title: "Tax Sale Information",
      to: "/departments/auditor/tax-sale",
      sub: null,
    },
  ];

  return (
    <>
      <div className="nav-menu-side">
        <HoursContact>
          <p>
            Lucas Little Iron County Auditor
            <br />
            PO Box 457
            <br />
            <TextWebLink link="https://goo.gl/maps/dWeYhAwocsaNHEyT9">
              68 S 100 E Parowan, UT 84761
            </TextWebLink>
            <br />
            Monday - Friday 8:00 A.M. - 5:00 P.M.
            <br />
            <PhoneLink>435-477-8330</PhoneLink>
          </p>
        </HoursContact>
        <br />
        <AccordionSidemenu nav={navItems} />
      </div>
    </>
  );
}

export default AuditorSideBar;

import React from "react";
import "./images.css";

function Image(props) {
  return (
    <div className="image-container">
      <div className="image-div blur-load">
        <img
          alt={props.alt}
          width={props.width}
          height={props.height}
          src={props.src}
        />
      </div>
    </div>
  );
}

export default Image;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../Images/Navbar/small-logo.png";
import "./Footer.css";
import ButtonSingle from "../Buttons/Button_Single";

function Footer() {
  return (
    <div id="footer-container">
      <div className="container-footer-logo">
        <Link to="/" className="navbar-logo">
          <img src={logo} alt="logo" id="logo" />
        </Link>

        <ButtonSingle
          href="/files/departments/it/privacy-policy.pdf"
          isLink={true}
        >
          Privacy Policy
        </ButtonSingle>
      </div>
    </div>
  );
}

export default Footer;

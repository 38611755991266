import React, { useState } from "react";
import "../Departments.css";
import AssessorSidebar from "./AssessorSidebar";
import Textbox from "../../../Components/Text/Textbox";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import TextWebLink from "../../../Components/Links/TextWebLink";
import Title from "../../../Components/Text/Title";
import CardAccordion from "../../../Components/Card/CardAccordion";
import TextWebLinkList from "../../../Components/Links/TextWebLinkList";
import Half from "../../../Components/Sizes/Half";
import ButtonSingle from "../../../Components/Buttons/Button_Single";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

function PersonalProperty() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  const ppForms = [
    {
      name: "Personal Property Valuation Schedule - 2024",
      link: "/files/departments/assessor/personal-property-schedule.pdf",
    },
    {
      name: "Personal Property Schedule A",
      link: "/files/departments/assessor/schedule-a.pdf",
    },
    {
      name: "Business Change Form – Online",
      link: "/departments/assessor/change-business-form",
    },
    {
      name: "Business Change Form – PDF",
      link: "/files/departments/assessor/business-change-form.pdf",
    },
    {
      name: "Tax Commission Business Personal Property Info Sheet - 2024",
      link: "/files/departments/assessor/business-personal-property-taxes.pdf",
    },
    {
      name: " Business Personal Property Audits - 2024",
      link: "/files/departments/assessor/business-personal-property-audits.pdf",
    },
  ];

  const Left = () => (
    <>
      <Title>Personal Property</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <ButtonSingle
          href="https://secureinstantpayments.com/sip/reports/account_selector.php?FromPopup=1&Merchant=25470&AddrReq=x&Account="
          isLink={true}
        >
          Pay Mobile / Business Tax
        </ButtonSingle>
        <p>
          1 in 3 Utah homes have high levels of radon. Protect your family’s
          health and test your home today. Visit{" "}
          <TextWebLink link="https://deq.utah.gov/waste-management-and-radiation-control/radon-program">
            Radon.utah.gov
          </TextWebLink>
        </p>
        <p>
          2023 Business Personal Property tax filing is now available online.
          You will need your account number (P + six digits) and a pin number
          (BU+eight digits), if you do not have these numbers please call our
          office M-F 8:00 a.m. – 5:00 p.m. Access your summarized account
          information at{" "}
          <TextWebLink link="https://ppaffidavits.ironcounty.net/">
            ppaffidavits.ironcounty.net{" "}
          </TextWebLink>{" "}
          . Business assets such as: computers, telephones, copiers, trade
          fixtures, furniture, signage, equipment, machinery and other like
          personal property owned and/or used in connection with your business
          as January 1, 2023 is subject to taxation.
        </p>

        <Half>
          <p>
            Please refer to your parcel number and account number located above
            to utilize our online filing system. Filing online is quick, safe,
            and efficient. For complete instructions, forms and payment options,
            visit our website at:{" "}
            <TextWebLink link="https://ppaffidavits.ironcounty.net/">
              ppaffidavits.ironcounty.net{" "}
            </TextWebLink>{" "}
            <b>
              {" "}
              Please note: If you are unable to file online, please call our
              office at (435) 477-8310 for assistance.
            </b>
          </p>
          <p>
            <b>
              Your signed Statement and tax payment are due on or before May 15,
              2024. Past due accounts will be subject to penalty and interest at
              the rate of 7.25% annually*.
            </b>
          </p>
          <p>
            <em>
              *Utah Code: 59-2-307 (1) (a) states each person who fails to file
              the signed statement "shall pay a penalty equal to 10% of the
              estimated tax due, but not less than $25 for each failure a signed
              and completed affidavit." and (2) (a) states: "The penalty imposed
              by Subsection (1)(a) may not be waived or reduced by the assessor,
              county, county Board of Equalization, or commission…"
            </em>
          </p>
        </Half>

        <Half>
          <p>
            <b> Tax Exempt:</b> A business with an aggregate taxable value of
            $28,200 or less, may be exempt from Personal Property Tax. This
            exemption applies to the total value of all Personal Property owned
            and/or used by your business in Iron County. If you have more than
            one business location in Iron County under the same business
            ownership, the exemption is applicable only if the total value of
            all property owned by such entity is less than $28,200.{" "}
            <b>
              {" "}
              Your application for exemption may only be considered with a
              completed Signed Statement of Personal Property. Failure to file
              timely and submit a signed statement may result in a forfeiture of
              your application for exemption.
            </b>
          </p>
          <p>
            If your business has closed or you no longer own this business,
            please contact our office or complete the Business Change Form
            linked below. If you need assistance or need your account or pin
            number, please call (435)477-8310 or visit our office at 68 So. 100
            East in Parowan.{" "}
            <b>
              {" "}
              Our Personal Property specialist is available Monday - Thursday
              8:00 AM to 5:00 PM or by appointment.
            </b>
          </p>

          <TextWebLinkList
            title="Personal Property Forms"
            links={ppForms}
          ></TextWebLinkList>
        </Half>
      </Textbox>
      <Textbox title="Accepted Payment Types">
        <p>
          Personal Property Taxes can be paid by cash, check, credit card or
          money order.{" "}
          <b>
            {" "}
            NOTICE: Regardless of payment method, the signed statement must be
            received by the Assessor’s Office no later than May 15, 2024.
          </b>
        </p>
        <ul>
          <li>
            <b>CREDIT CARD -</b>We accept credit cards online and over the
            phone. If you would like to pay over the phone, please call (435)
            477-8310. To pay online visit Secure Instant Payments. You must know
            your 7-digit account number (it can be found on your statement) to
            pay online.
          </li>
          <li>
            <b>CASH -</b> To pay by cash you must go in person to the Iron
            County Assessor’s Office during regular business hours to make
            payment. Please do not send cash through the mail.
          </li>
          <li>
            <b>CHECK/MONEY ORDER - </b> You can pay by check, in person, or
            mail. If paying by mail you must include the Business Name and
            Account Number. This is to make sure we apply the payment to the
            correct account. Please make the check/money order payable to Iron
            County Assessor and mail it to the following address: IRON COUNTY
            ASSESSOR, P.O. BOX 537, 68 S 100 E, PAROWAN, UT 84761
          </li>
        </ul>
      </Textbox>
      <CardAccordion title="What is Personal Property">
        <p>
          Utah Code in Title 59 requires the taxation of property for the
          funding of local government and Utah schools. Property tax is assessed
          on both real property and personal property. Generally, personal
          property used in business is subject to property taxes. Utah law
          requires business personal property to be reported to the county
          assessor where the property is located (has situs) on a tax form
          identified as a Personal Property Signed Statement. Personal Property
          is taxed based on its taxable value as of January 1 of each year. In
          order to value personal property, the Utah State Tax Commission
          provides personal property classification schedules which are used by
          all county assessors in Utah.
        </p>
      </CardAccordion>
      <CardAccordion title="What is Exempt from Taxation">
        <h2>Merchandise Inventory</h2>
        <p>
          Wares and merchandise, held for sale in the ordinary course of
          business, which constitute the stock-in-trade of any retailer,
          wholesaler, manufacturer, farmer, or livestock owner, are exempted
          from ad valorem taxes
        </p>
        <h2>Farm Machinery and Equipment</h2>
        <p>
          Tractors, milking equipment, feed handling equipment, harvesters,
          storage and cooling facilities, choppers, grain drills and planters,
          tillage tools, scales, combines, seeders, sprayers, haying equipment
          and other machinery or equipment used primarily for agricultural
          purposes are exempt from ad valorem property tax. (UCA Section
          59-2-1101). However, the exemption does not include motor vehicles
          required to be registered with the Tax Commission, as well as
          machinery and equipment used in processing of agricultural products.
        </p>
        <h2>Livestock</h2>
        <p>
          Livestock is exempt from ad valorem property taxation. (UCA Section
          59-2-1112) Property Used for Irrigation Purposes Property used for
          irrigation purposes is exempt from property taxation. (UCA Section
          59-2-1111) The exemption is limited to the property listed in Article
          XIII, Section 2 of the Utah Constitution.
        </p>
        <h2>Household Furnishings</h2>
        <p>
          Household Furnishings, furniture, and equipment used exclusively to
          maintain a primary or secondary residence are exempt form property
          taxation (UCA Section 59-2-1113 and Administrative Rule 884-24P-29).
        </p>
        <h2>Certain Tangible Personal Property</h2>
        <p>
          Tangible personal property of a taxpayer is exempt if the property has
          a total aggregate fair market value of $15,000 or less in 2020. The
          exemption does not apply to registered motor and recreational vehicles
          or mobile homes. To apply for this exemption, contact the Iron County
          Assessor. An item of taxable tangible personal property is exempt from
          taxation if the acquisition cost of that item was $1,000 or less and
          is valued at a percent good of 25% or less in 2019. The exemption only
          applies to personal property valued by schedules having a percent good
          residual of 25% or less. Note the definition of an item of taxable
          tangible personal property in Section I. (UCA Section 59-2-1115)
        </p>
        <h2>Registered Motor Vehicles, Recreational Vehicles and Aircraft</h2>
        <p>
          Registered motor vehicles, recreational vehicles and aircraft are
          exempt from ad valorem taxation and are taxed by uniform fees.
        </p>
      </CardAccordion>
      <CardAccordion title="What if I Recevied a Tax Notice?">
        <p>
          If you are a new business or have never filed: On Schedule A, list at
          100% acquisition cost, by year of acquisition, all furniture &
          fixtures, machinery & equipment, and computer equipment including
          software, owned as of January 1. The class descriptions are located on
          a separate sheet. List all leased items and the lessor on a separate
          sheet. (Do not include any motor vehicles.)
        </p>
        <p>
          If you have previously filed: On Schedule A, list only those items
          acquired or disposed of during 2019.
        </p>
        <p>
          If you are no longer in business: Indicate the circumstances on the
          Business Change Form and return it by May 15, 2020.
        </p>
        <p>
          Using the "Percent Good Table", depreciate each item to arrive at the
          current market value. Transfer this amount to line 4 of the Tax
          Notice, multiply the market value by the rate on line 5 to determine a
          tax amount for line 6.
        </p>
        <p>
          If the total market value is $15,000 or less do not calculate the tax,
          go to the exempt portion of the signed statement. Sign in the box
          verifying you qualify for the exemption. Sign the bottom of the Tax
          Notice and return it with your "Schedule A" and a check for the amount
          due by May 15, 2020.
        </p>
        <p>
          If no tax is due you must still sign the Tax Notice and return it by
          May 15, 2020. Failure to file a return will result in a penalty of 10%
          of the estimated tax due, but no less than $25.00. Interest will be
          charged on any outstanding balance. Penalty and interest charges, by
          law cannot be removed. (UC 59-2-307) The exemption cannot be applied
          after May 15, 2020 so the entire tax plus penalty & interest will be
          due.
        </p>
      </CardAccordion>
      <CardAccordion title="Acquisition Costs">
        <p>
          Cost of Acquisition must include all costs required to put an item
          into service. In addition to the cost of the item, include the
          following costs:
          <br />
          <br />
          Freight in, includes shipping costs, loading at origin, unloading at
          destination, crating, skidding, and other applicable costs of shipping
          <br />
          <br />
          Installation, engineering, rigging, erection or assembly to include
          foundations, pilings, utility connections and any other costs related
          to installation
          <br />
          <br />
          Excise and sales taxes
          <br />
          <br />
          Any other costs related to putting personal property into service are
          to be included in acquisition cost
          <br />
          <br />
          Indirect costs such as debugging, licensing fees, permits, insurance
          or security are not included in acquisition cost.
        </p>
      </CardAccordion>
      <CardAccordion title="What is Tangible Personal Property?">
        <p>
          An item of taxable tangible personal property means equipment,
          machinery, furniture or other tangible personal property that is
          functioning at its highest and best use for the purpose it was
          designed and constructed and is generally capable of performing that
          function without being combined with other items of personal property.
          An item of taxable tangible personal property is not an individual
          component part of a piece of machinery or equipment but the item of
          machinery or equipment as a whole. For example, a fully functioning
          computer is an item of taxable tangible personal property but the
          motherboard, hard drive, tower or sound card are not.
        </p>
      </CardAccordion>
      <CardAccordion title="What is Non-Capitalized Personal Property?">
        <p>
          Class 28 Non-Capitalized Personal Property is an item of machinery,
          equipment, furniture, computers, or any other tangible personal
          property that the acquisition cost has been totally expensed or
          written off in the year of acquisition. Any item of personal property
          whose acquisition cost is or has been depreciated using MACRS,
          Straight Line, or ACRS over several years for federal tax purpose does
          not qualify as being treated as Non-Capitalized Personal Property
          (Class 28)
        </p>
        <h2>
          "Non-Capitalized Personal Property" is an item of personal property
          that meets the following criteria:
        </h2>
        <p>Has an acquisition cost of $1,000 or less;</p>
        <h2>Please note:</h2>
        <p>
          Is claimed as allowed on a federal tax return as a deductible expense
          under Section 162 or Section 179, Internal Revenue Code, in the year
          of acquisition.
          <br />
          <br />
          All Classes of Property qualify for the election to Class 28
          (Non-Capitalized Personal Property)
          <br />
          <br />
          Any person who elects to designate personal property as
          "Non-Capitalized Personal Property" under Class 28 would need to
          provide proof of the acquisition cost of all non-capitalized personal
          property if selected for State Audit in accordance to Utah Code Ann.
          59-2-306(3).
          <br /> Any person who sells or disposes an item of taxable tangible
          personal property which has been designated as Non-Capitalized
          Personal Property or Class 28 must continue to pay taxes on such
          property until it reaches the 4th year on the Class 28 schedule.
          <br />
          <br />
          Any person who elects to designate personal property as
          Non-Capitalized Personal Property or Class 28 property may not appeal
          the value of the property determined by the Class 28 Schedule. An
          election to use Non-Capitalized Personal Property or Class 28 may not
          be revoked or transferred to any other Recommended Personal Property
          Valuation Schedule.
        </p>
      </CardAccordion>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Assessor: Personal Property</title>
        <meta
          name="description"
          content="Iron County Assessors personal property page"
        />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/assessor/personal-property"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<AssessorSidebar />}
        id="assessor-background"
        click={click}
      />
    </>
  );
}

export default PersonalProperty;

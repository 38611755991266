import React from "react";
import AccordionSidemenu from "../../../Components/AccordionMenu/AccordionSidemenu";
import "../../../Components/Sidebar/Sidebar.css";
import TextWebLink from "../../../Components/Links/TextWebLink";
import HoursContact from "../../../Components/Text/HoursContact";
import PhoneLink from "../../../Components/Links/PhoneLink";

function FleetSidebar() {
  const navItems = [
    {
      title: "About",
      to: "/departments/fleet",
      isLink: false,
    },
    {
      title: "Public Surplus",
      to: "https://www.publicsurplus.com/sms/all,ut/browse/search?posting=y&slth=&page=0&sortBy=timeLeft&keyWord=IRON+COUNTY+FLEET&catId=-1&endHours=-1&startHours=-1&lowerPrice=0&higherPrice=0&milesLocation=20&zipCode=&region=all%2Cut&search=Search",
      isLink: true,
    },
    {
      title: "State Fuel Network",
      to: "https://fleet.utah.gov/fuel__trashed/fueling-locations/",
      isLink: true,
    },
  ];

  return (
    <>
      <div className="nav-menu-side">
        {/* <HoursContact>
          <p>
            Ryan Riddle, Iron County Fire Warden
            <br />
            <TextWebLink link="https://goo.gl/maps/VR1N594suRuVHhk26">
              646 N Main, Cedar City, UT 84721
            </TextWebLink>
            <br />
            <PhoneLink>435-590-4714</PhoneLink>
          </p>
          <p>
            Lucas Twitchell, Assistant Iron County Fire Warden
            <br />
            <TextWebLink link="https://goo.gl/maps/p6cxGVmZT8PSUZzb9">
              1302 W Kittyhawk Dr., Cedar City Utah, UT 84721
            </TextWebLink>
            <br />
            <PhoneLink>435-704-4117</PhoneLink>
          </p>
        </HoursContact>
        <br /> */}
        <AccordionSidemenu nav={navItems} id="department-sidebar" />
      </div>
    </>
  );
}

export default FleetSidebar;

import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import EngineeringSidebar from "./EngineeringSidebar";
import Textbox from "../../../Components/Text/Textbox";
import Title from "../../../Components/Text/Title";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import PhoneLink from "../../../Components/Links/PhoneLink";
import EmailLink from "../../../Components/Links/EmailLink";
import { Helmet } from "react-helmet-async";

function Addressing() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Left = () => (
    <>
      <Title>Addressing</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <p>
          Contact the Engineer's office at <PhoneLink>435-865-5370</PhoneLink>{" "}
          or <EmailLink>mwilson@ironcounty.net</EmailLink>. Please provide a
          property account # or parcel #.
        </p>
        <p>
          <li>
            Homes: If you are working on a building permit for a home, please
            provide a site map or details about the placement of the home on the
            property.
          </li>
          <li>
            Land: Land only properties wil be given temporary or approximate
            addresses. If at a later date a building permit is submitted, the
            plans will be reviewed to determine if the address needs to be
            adjusted based on placement of the home.
          </li>
        </p>
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Engineering: Addressing</title>
        <meta
          name="description"
          content="Iron County engineering addressing Page"
        />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/engineering/addressing"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<EngineeringSidebar />}
        id="engineering-background"
        click={click}
      />
    </>
  );
}

export default Addressing;

import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import CJCSidebar from "./CJCSidebar";
import Title from "../../../Components/Text/Title";
import Textbox from "../../../Components/Text/Textbox";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

function MedicalExams() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Left = () => (
    <>
      <Title>Medical Exams</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <h1>
          Medical exams performed by Primary Children’s Safe and Healthy
          Families
        </h1>
        <p>
          Each child who comes to the CJC is offered a free medical exam
          performed by medical professional specially trained in child physical
          and sexual abuse. These are provided through Primary Children’s Safe
          and Healthy Families. Medical exams may also be requested for the
          child by law enforcement or child protective services. These exams are
          completed onsite at the Children’s Justice Center in a more
          child-centered environment.
        </p>
        <h1>Purpose of Exam</h1>
        <p>
          A forensic medical exam is performed to evaluate and treat children
          who have been physically or sexually abused. Examinations are
          comprehensive and carefully conducted in a child-friendly setting and
          include medical history and a non-invasive head-to-toe physical exam.
          When there are concerns of sexual abuse, the exam may include
          treatment for sexually transmitted infections and other testing.
          Following a report of acute sexual assault, forensic evidence may be
          collected by the medical practitioners. Medical staff may order x-rays
          or laboratory tests to aid in the evaluation. All forensic medical
          evaluations take into account physical and emotional effects of child
          maltreatment. Equally important is the reassurance children receive in
          knowing their bodies are “okay” after abuse has occurred.
        </p>
        <h1>What to Expect</h1>
        <p>
          The medical examination will take place at the Children’s Justice
          Center, in our special medical room. The exam takes approximately 2
          hours. Medical history is collected from the parent or caregiver
          including the child’s primary care doctor to facilitate coordination
          of care. Exam findings are photographed, with the use of a DSLR camera
          or special instrument, called a colposcope. Photos allow medical
          professionals to interpret findings in greater detail and allows for
          expert reviews without having a repeat examination. Our medical team
          makes every effort to deliver evidence-based medical care in a
          trauma-informed manner, seeking permission from the child with every
          step.
        </p>
        <h1>What you can tell your child about the medical examination:</h1>
        <p>
          <li>This is to make sure that his or her body is ok and healthy.</li>
          <li>The exam will not hurt.</li>
          <li>You can ask the nurse any questions you might have.</li>
          <li>The nurse will tell you everything she will do.</li>
          <li>You can stop it at any time.</li>
        </p>
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Children's Justice Center: Medical Examinations</title>
        <meta
          name="description"
          content="Iron County Children's Justice Center Medical Examinations Page"
        />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/cjc/medical-exams"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<CJCSidebar />}
        id="cjc-background"
        click={click}
      />
    </>
  );
}

export default MedicalExams;

import React from "react";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import "../../../Components/Sidebar/Sidebar.css";
import HoursContact from "../../../Components/Text/HoursContact";
import PhoneLink from "../../../Components/Links/PhoneLink";
import TextWebLink from "../../../Components/Links/TextWebLink";
import AccordionSidemenu from "../../../Components/AccordionMenu/AccordionSidemenu";

function HRSideBar() {
  const navItems = [
    {
      title: "Employee Benefits",
      to: "/files/departments/hr/2023-iron-county-beg.pdf",
      isLink: true,
    },
    {
      title: "Job Descriptions",
      to: "https://ironcountyut-my.sharepoint.com/personal/jbradbury_ironcounty_net/_layouts/15/onedrive.aspx?id=%2Fpersonal%2Fjbradbury%5Fironcounty%5Fnet%2FDocuments%2FAttachments%2FPositions%2Ezip&parent=%2Fpersonal%2Fjbradbury%5Fironcounty%5Fnet%2FDocuments%2FAttachments&ga=1",
      isLink: true,
    },
    {
      title: "Open Jobs/Apply",
      to: "https://www.applicantpro.com/openings/ironcounty/jobs",
      isLink: true,
    },

    //Future update
    // {
    //
    //   title: "Employee Resources",
    //   to: "",
    //   isLink: false,
    // },
  ];

  return (
    <>
      <div className="nav-menu-side">
        <HoursContact>
          <p>
            <b>Human Resources Office</b>
            <br />
            <TextWebLink link="https://goo.gl/maps/n4UaZjbAxr5HBvz78">
              82 N 100 E #204
            </TextWebLink>
            <br />
            Cedar City, UT 84720
            <br />
            Monday - Friday 8:00 AM - 5:00 PM
            <br />
            <PhoneLink>435-477-8335</PhoneLink>
            <br />
            <br />
            <b>Parowan Office</b>
            <br />
            <TextWebLink link="https://goo.gl/maps/dWeYhAwocsaNHEyT9">
              68 S 100 E #204 Parowan, UT 84761
            </TextWebLink>
            <br />
            Commission: Mondays 9:00 AM - 12:00 PM
          </p>
        </HoursContact>
        <br />
        <AccordionSidemenu nav={navItems} id="department-sidebar" />
      </div>
    </>
  );
}

export default HRSideBar;

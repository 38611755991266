import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import CJCSidebar from "./CJCSidebar";
import Title from "../../../Components/Text/Title";
import Aidaform from "../../../Components/Aidaform/Aidaform";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import ButtonSingle from "../../../Components/Buttons/Button_Single";
import { Helmet } from "react-helmet-async";

function ContactUsCJC() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Left = () => (
    <>
      <Title>Contact Us</Title>
      <ButtonSingle
        isLink={true}
        href="https://www.facebook.com/people/Iron-County-Childrens-Justice-Center/100068952378259/"
      >
        Facebook
      </ButtonSingle>
      <ButtonSingle
        isLink={true}
        href="https://www.instagram.com/iron_county_cjc/"
      >
        Instagram
      </ButtonSingle>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Aidaform form="https://ironcounty.aidaform.com/contact-us-cjc"></Aidaform>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Children's Justice Center: Contact Us</title>
        <meta
          name="description"
          content="Iron County Children's Justice Center Contact Us Page"
        />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/cjc/contact-us"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<CJCSidebar />}
        id="cjc-background"
        click={click}
      />
    </>
  );
}

export default ContactUsCJC;

import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import AttorneySidebar from "./AttorneySidebar";
import Title from "../../../Components/Text/Title";
import CardFAQ from "../../../Components/Card/CardFAQ";
import Subtitle from "../../../Components/Text/Subtitle";
import Textbox from "../../../Components/Text/Textbox";
import PhoneLink from "../../../Components/Links/PhoneLink";
import EmailLink from "../../../Components/Links/EmailLink";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

function ProsecutionPolicy() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  const Left = () => (
    <>
      <Title>Prosecution Policy</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <h1>
          <b>1. Screening and Filing Criminal Charges</b>
        </h1>
        <p>
          An Iron County prosecutor will seek or file criminal charges only if
          the prosecutor reasonably believes that the charges are supported by
          probable cause, that admissible evidence will be sufficient to support
          conviction beyond a reasonable doubt, and that the decision to charge
          is in the interests of justice.
        </p>
        <p>
          After criminal charges are filed, Iron County prosecutors will
          maintain them only if the prosecutor continues to reasonably believe
          that probable cause exists and that admissible evidence will be
          sufficient to support conviction beyond a reasonable doubt.
        </p>
        <p>
          If a prosecutor has significant doubt about the guilt of the accused
          or the quality, truthfulness, or sufficiency of the evidence in any
          criminal case assigned to the prosecutor, the prosecutor should
          disclose those doubts to supervisory staff. The prosecutor’s office
          should then determine whether it is appropriate to proceed with the
          case.
        </p>
        <p>
          The Iron County Attorney’s Office will never file or maintain charges
          if it believes the defendant is innocent.
        </p>
        <h1>
          <b>2. Plea Bargain</b>
        </h1>
        <p>
          Iron County prosecutors have discretion to negotiate a plea bargain or
          not. In every instance, the purpose of plea negotiations is to ensure
          justice is done in a fair and efficient manner. Prosecutors must
          consider a variety of factors when entering plea negotiations. These
          include: victim input, community safety, defendant’s criminal history
          or lack thereof, and strength of the evidence.
        </p>
        <h1>
          <b>3. Sentencing Recommendations</b>
        </h1>
        <p>
          Prosecutors at the Iron County Attorney’s Office will recommend
          sentences that they believe hold defendant’s accountable and that are
          in the interest of justice. Iron County Prosecutors consider a variety
          of factors when making a sentencing recommendation. These include:
          victim input and restitution, community safety, a defendant’s criminal
          history or lack thereof, and any aggravating or mitigating facts or
          circumstances that exist.
        </p>
        <h1>
          <b>4. Discovery Practices</b>
        </h1>
        <p>
          The Iron County Attorney’s Office has an open file policy. Providing
          broad and early discovery promotes the truth-seeking mission of the
          prosecutor and furthers the speedy trial and due process rights of
          both the accused and victims. The Iron County Attorney’s Office will
          provide to the accused copies of or access to all relevant,
          unprivileged information known to the prosecutor. A prosecutor may
          redact information prior to providing discovery as necessary for the
          protection of victims and witnesses.
        </p>
        <p>
          The Iron County Attorney’s Office will comply with the obligations
          outlined in Rule 16 of the Utah Rule of Criminal Procedure and will,
          at all times, carry out discovery obligations in good faith and in a
          manner that furthers the goals of discovery, namely, to minimize
          surprise, afford the opportunity for effective cross-examination,
          expedite trials, and meet the requirements of due process.
        </p>
        <p>
          In the event defense counsel makes discovery demands that are abusive,
          frivolous or made solely for the purpose of delay, unless otherwise
          required by law or rule, an Iron County prosecutor need not cooperate
          with such demands and should seek court guidance on what must be
          provided. Iron County prosecutors shall make timely disclosure of
          exculpatory and mitigating evidence pursuant to Brady v. Maryland, 373
          U.S. 83, 87 (1963) and its progeny. If at any point in the pretrial or
          trial proceedings a prosecutor discovers additional witnesses,
          information, or other material previously requested or ordered which
          is subject to disclosure and was not provided, the prosecutor should
          promptly notify defense counsel and provide the required information.
        </p>
        <h1>
          <b>5. Prosecution of Juveniles</b>
        </h1>
        <p>
          Similar to the prosecution of adult criminal cases, an Iron County
          prosecutor will seek or file charges against a juvenile only if the
          prosecutor believes that the allegations are supported by probable
          cause, that admissible evidence will be sufficient to support the
          allegations beyond a reasonable doubt, and that the decision to charge
          the juvenile is in the interests of justice. Charges brought against a
          juvenile are patterned after the criminal code, but are considered
          civil proceedings.
        </p>
        <p>
          The Iron County Attorney’s Office works closely with Juvenile
          Probation and other agencies to determine the most appropriate course
          of action within the parameters of juvenile law, including whether the
          matter should be handled non-judicially, or if the juvenile should
          come before a juvenile court judge.
        </p>
        <p>
          When making a determination on whether to prosecute juveniles as
          adults, Iron County prosecutors will evaluate the facts and evidence,
          and will consult with and follow current Utah law.
        </p>
        <h1>
          <b>6. Collection of Fines and Fees</b>
        </h1>
        <p>
          The Iron County Attorney’s Office does not collect fines or fees. The
          Court collects fines and fees. Some cases require a mandatory fine be
          imposed (DUI for example). In these cases Iron County prosecutors will
          follow Utah law. While we may recommend a fine or fee as a term of
          probation in other cases, we do not recommend them for the purpose of
          generating revenue. In each case, the prosecutors aim is to do
          justice.
        </p>
        <h1>
          <b>7. Criminal and Civil Asset Forfeiture Practice</b>
        </h1>
        <p>
          The Iron County Attorney’s Office will only seek the forfeiture of
          property in criminal cases where the prosecutor can prove beyond a
          reasonable doubt that the property has been used, or was intended to
          be used to commit a crime, or the property is proceeds of a crime.
        </p>
        <h1>
          <b>8. Victim Services</b>
        </h1>
        <p>
          The Iron County Attorney’s Office has a full time Victim Service
          Coordinator who is dedicated to helping victims understand and
          navigate the criminal justice process. In addition to communicating
          with victims of crime, the Victim Service Coordinator helps set up
          meetings with prosecutors as needed, connects victims with outside
          resources, notifies them of court hearings, and coordinates
          restitution amounts that will be recommended to the court by the
          prosecutor.
        </p>
        <p>
          The Iron County Attorney’s Office also works closely with community
          based victim advocates to help serve victims of crime in our
          community.
        </p>
        <h1>
          <b>9. Diversion and Restorative Justice Programs</b>
        </h1>
        <p>
          The Iron County Attorney’s Office has helped create and supports the
          Iron County Drug Court, and Iron County Mental Health Court programs.
          These problem solving courts have dedicated teams who work with
          qualifying defendants that struggle with addiction and mental illness.
          These programs allow defendants to get treatment while being
          supervised by the court and treatment team with the goal of having
          their charges dismissed or reduced upon successful completion of the
          program.
        </p>
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Attorney: Prosecution Policy</title>
        <meta
          name="description"
          content="Iron County Attorney prosecution policy Page"
        />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/attorney/prosecution-policy"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<AttorneySidebar />}
        id="attorney-background"
        click={click}
      />
    </>
  );
}

export default ProsecutionPolicy;

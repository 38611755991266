import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import BuildingSidebar from "./BuildingSidebar";
import Textbox from "../../../Components/Text/Textbox";
import Title from "../../../Components/Text/Title";
import Half from "../../../Components/Sizes/Half";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

function Building() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  const Left = () => (
    <>
      <Title>Building and Zoning</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>

      <Textbox>
        <Half>
          <h1>What We Do</h1>
          <li>Review all plans for compliance</li>
          <li>
            Issue building permits for construction projects within Iron County
            (New residential, commercial, additions, remodels, accessory
            structures, utility upgrades etc.)
          </li>
          <li>
            We inspect all phases of construction (Building, Electrical,
            Plumbing, Mechanical, Fuel Gas, etc.){" "}
          </li>
          <li>Look into zoning violations</li>
          <li>Enforce zoning ordinances </li>
        </Half>
        <Half>
          <h1>What We Don't Do</h1>
          <li>Print plat maps</li>
          <li>Lot dimensions</li>
          <li>Survey Parcel(s) of land</li>
        </Half>
      </Textbox>
      <Textbox title="Office Staff">
        <p>
          <h3>Terry Palmer – Building Official/Zoning Administrator</h3>
          <h3>Chelsea Boxwell – Office Manager</h3>
          <h3>Nate Dennett – Assistant Building Official/Zoning Officer</h3>
          <h3>Corey Ercanbrack – Lead Building Inspector</h3>
          <h3>Joe Pulica – Code Enforcement Officer</h3>
          <h3>Thayne Hainsworth – Building Maintenance Supervisor </h3>
          <h3>Reed Erickson – Planner / Special Service Coordinator</h3>
        </p>
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Building</title>
        <meta name="description" content="Iron County Building Page" />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/building"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<BuildingSidebar />}
        id="building-background"
        click={click}
      />
    </>
  );
}

export default Building;

import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import ResourcesSideBar from "./ResourcesSidebar";
import Title from "../../../Components/Text/Title";
import { Helmet } from "react-helmet-async";
import Textbox from "../../../Components/Text/Textbox";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import ButtonSingle from "../../../Components/Buttons/Button_Single";

function NaturalResources() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Left = () => (
    <>
      <Title>Natural Resources</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <h2>What We Do</h2>
        <ul>
          <li>
            Develop and maintain the Iron County Resource Management Plan
            (CRMP), specific resource plans, and the natural resources section
            of the Iron County General Plan.
          </li>
          <li>
            Develop monitoring system for all goals and objectives of the Iron
            CRMP.
          </li>
          <li>Responsible for implementing the Iron CRMP.</li>
          <li>
            Monitor, review, and report to County Board of Commissioners and
            other necessary county officials regarding all federal and state
            land and natural resource management agency proposals and
            environmental analyses that impact Iron County or Iron County
            interests.
          </li>
          <li>
            Submit formal comment to agencies, as warranted, under direction of
            the Board of Commission and Natural Resource Advisory Council.
          </li>
          <li>
            Serve as a representative for Iron County in formal Cooperating
            Agency agreements with federal agency projects of interest.
          </li>
          <li>
            Meet all other participation expectations of and requisite reporting
            to the Board of Commissioners.
          </li>
          <li>
            Responsible for building and maintaining positive and productive
            working relationships with all state and federal land and natural
            resource management agencies and personnel as well as with the
            general public and interest or advocacy groups working on or
            interested in natural resource issues in Iron County.
          </li>
          <li>
            Assist other County Departments in planning and implementing
            recreational plans.
          </li>
        </ul>
      </Textbox>
      <Textbox title="Resource Management Plan (CRMP)">
        <p>
          The Iron County Resource Management Plan (CRMP) was developed to
          facilitate cooperation and collaboration between the County and
          federal & state land management agencies in planning for natural
          resources in Iron County. The Iron CRMP was developed jointly with
          land management agencies and stakeholders and serves as the basis for
          future natural resources planning. The plan is included in Natural
          Resource Section of the Iron County General Plan and will be
          periodically updated.
        </p>
        <ButtonSingle
          width="500px"
          isLink={true}
          href="/files/departments/nr/nr-plan.pdf"
        >
          Download Iron CRMP
        </ButtonSingle>
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Natural Resources</title>
        <meta
          name="description"
          content="Iron County Justice Court Natural Resources Page"
        />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/natural-resources"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<ResourcesSideBar />}
        id="resources-background"
        click={click}
      />
    </>
  );
}

export default NaturalResources;

import React, { useState } from "react";
import "./Card.css";

function CardAccordion(props) {
  const [clicked, setClicked] = useState(false);

  const toggle = () => {
    if (clicked) {
      return setClicked(null);
    }

    setClicked(true);
  };
  return (
    <>
      <div className="card-accordion" onClick={() => toggle()}>
        <h1 className="factsh1">
          {props.title} {clicked === true ? "-" : "+"}
        </h1>

        <p>{props.desc}</p>
      </div>

      {clicked === true ? (
        <div className="facts-dropdown-a">
          <p>{props.children}</p>
        </div>
      ) : null}
    </>
  );
}

export default CardAccordion;

import React from "react";
import AccordionSidemenu from "../../../Components/AccordionMenu/AccordionSidemenu";
import "../../../Components/Sidebar/Sidebar.css";
import HoursContact from "../../../Components/Text/HoursContact";
import Subtitle from "../../../Components/Text/Subtitle";

function JailSideBar() {
  const navItems = [
    {
      title: "Jail Home Page",
      to: "/departments/commission/jail",
      isLink: false,
    },
    {
      title: "Argument For Jail",
      to: "/files/commission/jail/argument-for-jail.pdf",
      isLink: true,
    },
    {
      title: "Jail Pamphlet",
      to: "/files/commission/jail/jail-vip-final.pdf",
      isLink: true,
    },
    {
      title: "Jail Old Information",
      to: "/departments/commission/jail-old",
      isLink: false,
    },

    {
      title: "Letter From the Sheriff",
      to: "/files/commission/jail/letter-from-sheriff.pdf",
      isLink: true,
    },
    {
      title: "3D Images of Jail",
      to: "/files/commission/jail/architect-jail-images.pdf",
      isLink: true,
    },
    {
      title: "Jail Information Videos",
      to: "https://www.youtube.com/watch?v=-3hhI5z9y9A&list=PLXd72c-bFFxlLspeYvEwssKDoYSWLQOD_",
      isLink: true,
    },
    {
      title: "Election Notice",
      to: "/files/commission/jail/election-notice.pdf",
      isLink: true,
    },
  ];

  return (
    <>
      <div className="nav-menu-side">
        <AccordionSidemenu nav={navItems} id="department-sidebar" />
        <br />
        <div className="hoursContact">
          <Subtitle>Key Election Dates</Subtitle>

          <li className="no-dots">
            Oct 26 – Logic and Accuracy test to be held at 68 S 100 E, Parowan
            UT at 2:00 p.m.
          </li>
          <li className="no-dots">
            Oct 31- Ballots mailed to all eligible registered voters
          </li>
          <li className="no-dots">
            Nov 13 - Last day to register to vote by mail, in person, or
            electronic by 5:00 p.m.
          </li>
          <li className="no-dots">
            Nov 14-17 - Early voting; see early voting locations, dates and
            times below
          </li>
          <li className="no-dots">
            Nov 14- Last day to request an absentee ballot
          </li>
          <li className="no-dots">
            Nov 20 – Mailed ballots must be POSTMARKED
          </li>
          <li className="no-dots">
            Nov 21 - Election Day; see Election Day locations, date, and time
            below
          </li>
          <li className="no-dots">
            Nov 21 - Unofficial Election results will be posted after 9:00 p.m.
          </li>
          <li className="no-dots">
            Nov 28 - Post Election Audit at 2:00 pm in Clerks office
          </li>
          <li className="no-dots">
            Dec 5 - Canvass of the Primary Election at 10:00 am
          </li>
        </div>
      </div>
    </>
  );
}

export default JailSideBar;

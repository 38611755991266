import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import ITSidebar from "./ITSidebar";
import Title from "../../../Components/Text/Title";
import Textbox from "../../../Components/Text/Textbox";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

function IT() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Left = () => (
    <>
      <Title>Information Technology</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <p>
          The Information Technology Service (ITS) department plans, builds, and
          runs computer and communications systems for Iron County Departments.
        </p>
        <p>
          Supporting a work force of over 250 in 25 departments, the IT office
          provides diverse services for Law Enforcement, Tax Collection, Tax
          Payment, Public information, Prosecution, and County Fair as well as
          others. ITS provides departments with essential information technology
          and communication services that are critical to the county mission of
          serving the public.
        </p>
        <p>
          With a highly skilled staff, ITS is prepared to meet all information
          technology needs of the County.
        </p>
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>IT</title>
        <meta name="description" content="Iron County IT Page" />
        <link rel="canonical" href="https://ironcounty.net/departments/it" />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<ITSidebar />}
        id="it-background"
        click={click}
      />
    </>
  );
}

export default IT;

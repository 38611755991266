import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Accordion.css";

const AccordionSidemenu = (props) => {
  const [clicked, setClicked] = useState(false);

  const toggle = (index) => {
    if (clicked === index) {
      return setClicked(null);
    }

    setClicked(index);
  };
  return (
    <div className="accordion-div">
      {props.nav.map((item, index) => {
        if (item.isLink === true) {
          return (
            <>
              <a rel="noopener noreferrer" target="_blank" href={item.to}>
                <div className="wrap" onClick={() => toggle(index)} key={index}>
                  {" "}
                  <button className="acc-button">{item.title}</button>
                </div>
              </a>
            </>
          );
        } else {
          if (item.sub != null) {
            return (
              <>
                <div className="wrap" onClick={() => toggle(index)} key={index}>
                  {/* THIS IS WHAT TURNS IT FROM A CLICKABLE LINK TO DROPDOWN */}
                  {/* <Link to={item.to}> */}{" "}
                  <button className="acc-button">{item.title}</button>
                  {/* </Link> */}
                  <span>{clicked === index ? "-" : "+"}</span>
                </div>
                {clicked === index ? (
                  <div className="dropdown-a">
                    {item.sub.map((sub) => {
                      if (sub.isLink === true) {
                        return (
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={sub.to}
                          >
                            <div className="wrap">
                              {" "}
                              <button className="acc-button">
                                {sub.title}
                              </button>
                            </div>
                          </a>
                        );
                      } else {
                        return (
                          <Link to={sub.to}>
                            <div className="wrap">
                              {" "}
                              <button className="acc-button">
                                {sub.title}
                              </button>
                            </div>
                          </Link>
                        );
                      }
                    })}
                  </div>
                ) : null}
              </>
            );
          } else {
            return (
              <>
                <Link to={item.to}>
                  <div
                    className="wrap"
                    onClick={() => toggle(index)}
                    key={index}
                  >
                    {" "}
                    <button className="acc-button">{item.title}</button>
                  </div>
                </Link>
              </>
            );
          }
        }
      })}
    </div>
  );
};

export default AccordionSidemenu;

import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import JusticeSideBar from "./JusticeSidebar";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import Aidaform from "../../../Components/Aidaform/Aidaform";
import Title from "../../../Components/Text/Title";
import { Helmet } from "react-helmet-async";

function JailCommitment() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Left = () => (
    <>
      <Title>Jail Commitment Form</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>

      <Aidaform
        form="https://ironcounty.aidaform.com/jail-commitment-form"
        title=""
      />
    </>
  );

  return (
    <>
      <Helmet>
        <title>Justice Court: Jail Commitment</title>
        <meta
          name="description"
          content="Iron County Justice Court jail commitment Page"
        />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/justice-court/jail-commitment"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<JusticeSideBar />}
        id="justice-background"
        click={click}
      />
    </>
  );
}

export default JailCommitment;

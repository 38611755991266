import React from "react";
import AccordionSidemenu from "../../../Components/AccordionMenu/AccordionSidemenu";
import TextWebLink from "../../../Components/Links/TextWebLink";
import HoursContact from "../../../Components/Text/HoursContact";
import PhoneLink from "../../../Components/Links/PhoneLink";
import "../../../Components/Sidebar/Sidebar.css";
import EmailLink from "../../../Components/Links/EmailLink";

function AttorneySideBar() {
  const navItems = [
    {
      title: "About",
      to: "/departments/attorney",
      isLink: false,
    },
    {
      title: "County Ordinances",
      to: "https://library.municode.com/ut/iron_county/codes/code_of_ordinances",
      isLink: true,
    },
    {
      title: "Evidence Property Disposition Form",
      to: "/files/departments/attorney/evidence-property-disposition-form-new.pdf",
      isLink: true,
    },
    {
      title: "Prosecution Policy",
      to: "/departments/attorney/prosecution-policy",
      isLink: false,
    },
    {
      title: "Records Request Form",
      to: "/files/departments/attorney/grama-form.pdf",
      isLink: true,
    },

    {
      title: "Use of Force Investigation Protocol",
      to: "/files/departments/attorney/citf-protocol.pdf",
      isLink: true,
    },
  ];

  return (
    <>
      <div className="nav-menu-side">
        <HoursContact>
          <p>
            Chad E. Dotson, Iron County Attorney
            <br />
            <TextWebLink link="https://goo.gl/maps/BB6AXaXyk4Vpjzui6">
              82 N 100 E #201, Cedar City, UT 84720
            </TextWebLink>
            <br />
            Monday - Friday 8:00 A.M. - 5:00 P.M.
            <br />
            Phone: <PhoneLink>435-865-5310</PhoneLink>
            <br />
            Email: <EmailLink>icao@ironcounty.net</EmailLink>
          </p>
        </HoursContact>
        <br />
        <AccordionSidemenu nav={navItems} />
      </div>
    </>
  );
}

export default AttorneySideBar;

import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import CJCSidebar from "./CJCSidebar";
import Title from "../../../Components/Text/Title";
import Textbox from "../../../Components/Text/Textbox";
import Youtube from "../../../Components/Links/Youtube";
import CardAccordion from "../../../Components/Card/CardAccordion";
import TextWebLink from "../../../Components/Links/TextWebLink";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

function MentalHealthServices() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Left = () => (
    <>
      <Title>Mental Health Services</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <h1>
          Therapy provided by qualified practitioners through Victims of Crime
          Act (VOCA) funding to child victims of abuse
        </h1>
        <p>
          The CJC offers mental health services with contracted therapists for
          child victims of sexual or physical abuse. These sessions are meant as
          short-term, trauma-focused therapy which reduces the trauma symptoms
          in children. The modalities used are approved through the National
          Children’s Alliance and align with VOCA requirements as well as the
          scope of the CJC.
        </p>
        <p>
          Approved therapy modalities include, but are not limited to,
          Trauma-Focused Cognitive Behavioral Therapy (TF-CBT), Parent-Child
          Interaction Therapy (PCIT), Alternatives for Families: Cognitive
          Behavioral Therapy (AF-CBT), Eye Movement Desensitization and
          Reprocessing (EMDR), Child and Family Traumatic Stress Intervention
          (CFTSI), and Child Parent Psychotherapy (CPP).
        </p>
        <p>
          Our contracted therapists work with insurances as well as Crime Victim
          Reparations as needed.{" "}
        </p>
      </Textbox>
      <Textbox>
        <h1>Relax through Visualization</h1>
        <CardAccordion title="Introduction">
          <Youtube src="https://www.youtube.com/embed/GhHa2sV2mQw?si=flsaIKdZp4MI6mW9"></Youtube>
        </CardAccordion>
        <CardAccordion title="The Forest">
          <Youtube src="https://www.youtube.com/embed/4UD8pwjQ-nc?si=mBXuJwiwDMkaQ6W3"></Youtube>
        </CardAccordion>
        <CardAccordion title="The Country Road">
          <Youtube src="https://www.youtube.com/embed/pAOCXZnkhG4?si=37nd8w9nzcH4RXb8"></Youtube>
        </CardAccordion>
        <CardAccordion title="The Beach">
          <Youtube src="https://www.youtube.com/embed/6j6Xyj1iNZU?si=HxEeJvgLO7BP6c7W"></Youtube>
        </CardAccordion>
      </Textbox>
      <Textbox title="Additional Resources">
        <p>
          <b>PTSD Coach Online:</b> Free tools for helping older kids and adults
          manage symptoms of stress common after a trauma{" "}
          <TextWebLink link="https://www.ptsd.va.gov/apps/ptsdcoachonline/default.htm">
            Click here
          </TextWebLink>
        </p>
        <p>
          <b>Virtual Hope Box app:</b> A smartphone application designed for use
          by patients and their behavioral health providers as an accessory to
          treatment{" "}
          <TextWebLink link="https://apps.apple.com/us/app/virtual-hope-box/id825099621">
            Click here
          </TextWebLink>
        </p>
        <p>
          <b>National Child Traumatic Stress Network (NCTSN):</b> Information on
          trauma-informed care and services for traumatized children and their
          families
          <TextWebLink link="https://www.nctsn.org/">Click here</TextWebLink>
        </p>

        <p>
          <b>Elmo Belly Breathe:</b> Clever song teaches a belly breathing
          calming technique for young children.
          <TextWebLink link="https://www.youtube.com/watch?v=_mZbzDOpylA">
            Click here
          </TextWebLink>
        </p>
        <p>
          <b>Helping Survivors: </b> Our mission is to assist anyone who has
          been victimized by sexual assault or abuse. This guide is on the long
          term effects of child sex abuse.
          <TextWebLink link="https://helpingsurvivors.org/long-term-effects-child-sexual-abuse-molestation/">
            Click here
          </TextWebLink>
        </p>
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Children's Justice Center: Mental Health Services</title>
        <meta
          name="description"
          content="Iron County Children's Justice Center Mental Health Services Page"
        />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/cjc/mental-health-services"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<CJCSidebar />}
        id="cjc-background"
        click={click}
      />
    </>
  );
}

export default MentalHealthServices;

import React from "react";
import "./Card.css";
import ButtonIcons from "../Buttons/Button_Icons";

const CardLarge = ({ image, title, content, imageRight, to }) => {
  const test = [
    {
      title: "Learn More",
      to: to,
    },
  ];

  return (
    <>
      <div
        className={
          imageRight === true
            ? " hero-container cardLarge center"
            : " hero-container cardLarge opposite center"
        }
      >
        <div className="cardContent">
          <h1>{title}</h1>
          <p>{content}</p>
          <ButtonIcons buttons={test} id="large-card-button" className="" />
        </div>
        <div
          className={
            imageRight === true
              ? "cardImage-container"
              : "cardImage-container imgLeft"
          }
        >
          <img className="cardImage" src={image} alt="trex" />
        </div>
      </div>
    </>
  );
};

export default CardLarge;

import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import Title from "../../../Components/Text/Title";
import Textbox from "../../../Components/Text/Textbox";
import EMSidebar from "./EMSidebar";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import Aidaform from "../../../Components/Aidaform/Aidaform";
import { Helmet } from "react-helmet-async";

function VolunteerHours() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Left = () => (
    <>
      <Title>Volunteer Hours</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <p>
          Iron County officials are working with Cedar City Fire Department, and
          the Washington County Damage Assessment Team to apply for FEMA
          funding. Your reporting will help us reach threshholds for possible
          assistance to property owners.
        </p>
      </Textbox>
      <Aidaform form="https://ironcounty.aidaform.com/volunteer-hours-reporting" />
    </>
  );

  return (
    <>
      <Helmet>
        <title>Emergency Management: Volunteer Hours</title>
        <meta
          name="description"
          content="Iron County emergency Management Page"
        />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/emergency-management/volunteer-hours"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<EMSidebar />}
        id="em-background"
        click={click}
      />
    </>
  );
}

export default VolunteerHours;

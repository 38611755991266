import React, { useState } from "react";
import "../Departments.css";
import AssessorSidebar from "./AssessorSidebar";
import Textbox from "../../../Components/Text/Textbox";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import TextWebLink from "../../../Components/Links/TextWebLink";
import Title from "../../../Components/Text/Title";
import Subtitle from "../../../Components/Text/Subtitle";
import Half from "../../../Components/Sizes/Half";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

function GreenbeltAct() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Left = () => (
    <>
      <Title>Utah Farmland Assessment Act (Greenbelt Act)</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <section>
          <p>
            The Utah Farmland Assessment Act (FAA, also called the Greenbelt
            Act) allows qualifying agricultural property to be assessed and
            taxed based upon its productive capability instead of the prevailing
            market value. This unique method of assessment is vital to
            agriculture operations in close proximity to expanding urban areas,
            where taxing agricultural property at market value could make
            farming operations economically prohibitive.
          </p>
        </section>
        <Half>
          <Subtitle>How is Productive Value Determined</Subtitle>
          <p>
            Productive values are established by the Utah State Tax Commission
            with the assistance of a five-member Farmland Assessment Advisory
            Committee and Utah State University. Productive values apply
            statewide and are based upon income and expense factors associated
            with agriculture activities. These factors are expressed in terms of
            value per acre for specific land classifications.
          </p>
        </Half>

        <Half>
          <Subtitle>What Does it Take to Qualify?</Subtitle>
          <p>
            Private farmland can qualify for assessment and taxation under the
            Farmland Assessment Act if the land:
            <li>
              Is at least five contiguous acres in area. Land less than five
              acres may qualify where devoted to agricultural use in conjunction
              with other eligible acreage under identical legal ownership. Land
              used in connection with the farmhouse, such as landscaping, etc.
              cannot be included in the acreage for FAA eligibility.
            </li>
            <li>
              Is actively devoted to agricultural use, and the operation is
              managed in such a way that there is a reasonable expectation of
              profit;
            </li>
            <li>
              Has been devoted to agricultural use for at least two successive
              years immediately preceding the tax year in which application is
              made; and
            </li>
            <li>Meets average annual (per acre) production requirements.</li>
          </p>
        </Half>

        <Half>
          <section>
            <Subtitle>How is Land Classified?</Subtitle>
            <p>
              Land is classified according to its capability of producing crops
              or forage. Capability is dependent upon soil type, topography, and
              availability of irrigation water, growing season, and other
              factors. The County Assessor classifies all agricultural land in
              the county based on Natural Resource Conservation Service Soil
              Surveys and guidelines provided by the Tax Commission. The general
              classifications of agricultural land are irrigated, dryland,
              grazing land, orchard and meadow. If you disagree with your land
              classification, you can appeal to your county board of
              equalization for re-classification.
            </p>
          </section>
        </Half>
        <Half>
          <Subtitle>Defined Production Requirement</Subtitle>
          <p>
            To qualify for the Farmland Assessment Act land must produce in
            excess of 50 percent of the average agricultural production per acre
            for the given type of land and the given county or area. To
            determine production levels the County Assessor will use the
            following sources:
          </p>
          <p>
            The most recent publication of Utah Agricultural Statistics; crop
            and enterprise budgets published by Utah State University; or
            standards established by the Tax Commission. Examples:
            <ol>
              <li>
                A farmer grows alfalfa. The average annual production of alfalfa
                in his area is four tons per acre per year. To qualify he must
                produce more than two tons per acre per year.
              </li>
              <li>
                A rancher has 10 acres of irrigated pasture, which would
                reasonably carry 10 cows or 50 sheep through the grazing season.
                To qualify he will need to graze more than five head of cattle
                or 25 sheep.
              </li>
            </ol>
          </p>
        </Half>
        <Half>
          <Subtitle>What are the Expectations?</Subtitle>
          <p>
            The acreage requirement may be waived if the owner can show that 80
            percent or more of the owner’s, purchaser’s, or lessee’s income is
            derived from agricultural products produced on the land or failure
            to meet the 5 acre requirement arose solely out of an eminent domain
            proceeding.
          </p>
          <p>
            The production requirement may be waived if the owner shows that the
            property has been in agricultural use for the previous two years and
            that failure to meet the production requirement in a particular year
            was due to no fault or act of the owner, purchaser, or lessee.
          </p>
          <p>
            The production requirement will be waived if the land is involved in
            a bonafide range improvement program, crop rotation program, or
            other similarly accepted agricultural practice, which does not give
            reasonable opportunity to satisfy the production level requirement.
          </p>
        </Half>
        <Half>
          <Subtitle>When are the Application Deadlines?</Subtitle>
          <p>
            New applications for assessment and taxation under the Utah Farmland
            Assessment Act must be submitted by May 1 of the tax year in which
            assessment is requested. Applications necessary because of ownership
            change, legal description change, assessor request, etc., can be
            filed anytime
          </p>
        </Half>
        <Half>
          <Subtitle>How Do I Apply?</Subtitle>
          <p>
            An application for assessment and taxation of agricultural land
            under the FAA can be obtained from your County Assessor. This
            application should be completed and returned to the County Assessor.
            Supporting documentation may be required such as federal tax
            returns, affidavits, lease agreements, sales receipts, production
            records, etc. which show the production requirement has been met for
            the preceding two years.
          </p>
        </Half>
        <Half>
          <Subtitle>Who May Apply?</Subtitle>
          <p>
            Any owner of agricultural land may apply for assessment and taxation
            under the Farmland Assessment Act.
          </p>
        </Half>
        <Half>
          <Subtitle>Does Leased Land Qualify?</Subtitle>
          <p>
            Leased land can qualify for assessment and taxation under the FAA if
            the acreage requirement is met and the production requirement is
            satisfied. A purchaser or lessee may qualify the land by submitting,
            along with the application from the owner, documents certifying that
            the production levels have been satisfied.
          </p>
        </Half>
        <Half>
          <Subtitle>What Happens When Land is Withdrawn?</Subtitle>
          <p>
            When land becomes ineligible for farmland assessment (such as when
            it is developed or goes into non use), the owner becomes subject to
            what is known as a rollback tax. The rollback tax is the difference
            between the taxes paid while on greenbelt and the taxes which would
            have been paid had the property been assessed at market value. In
            determining the amount of rollback tax due, a maximum of five years
            preceding the change in use will be used. The tax rate for each of
            the years in question will be applied to determine the tax amount.
          </p>
        </Half>
        <Half>
          <Subtitle>Yearly Valuation Changes</Subtitle>
          <p>
            The Utah State Tax Commission, based on a four-year study conducted
            by Utah State University, has adjusted the values used for farmland
            assessment. The basic changes in addition to the valuation changes
            include:
          </p>
          <ol>
            <li>
              A system has been developed to annually update values for land
              assessed under the Farmland Assessment Act.
            </li>
            <li>
              Under the old system land with equal productive capabilities was
              similarly valued by region. Under the new system land values will
              be individualized for each county based upon agricultural
              production, income, and expenses for that county
            </li>
          </ol>
        </Half>

        <Half>
          <Subtitle>Yearly Valuation Changes</Subtitle>
          <ul>
            <li>
              <TextWebLink link="/files/departments/assessor/greenbelt-application.pdf">
                Greenbelt Application
              </TextWebLink>
            </li>
            <li>
              <TextWebLink link="/files/departments/assessor/urban-greenbelt-application.pdf">
                Urban Greenbelt Application
              </TextWebLink>
            </li>
            {/* <li>
              <TextWebLink link="/files/departments/assessor/agricultural-assessment-taxation.pdf">
                Agricultural Land Recertification for Assessment and Taxation
              </TextWebLink>
            </li> */}
            <li>
              <TextWebLink link="/files/departments/assessor/greenbelt-survey.pdf">
                Greenbelt Survey
              </TextWebLink>
            </li>
            <li>
              <TextWebLink link="/files/departments/assessor/urban-greenbelt-survey.pdf">
                Urban Greenbelt Survey
              </TextWebLink>
            </li>
            {/* <li>
              <TextWebLink link="/files/departments/assessor/lessee-affidavit.pdf">
                Lessee Affidavit
              </TextWebLink>
            </li> */}
          </ul>
        </Half>
        <Half>
          <Subtitle>Land Classification Schedules</Subtitle>
          <p>
            The table below represents agricultural use value per acre in Iron
            County for the various classes of agricultural property.
          </p>
          <table style={{ width: "100%" }}>
            <tr>
              <td>Class</td>
              <td>Type of Land</td>
              <td>2020 Values</td>
              <td>2021 Values</td>
            </tr>
            <tr>
              <td>I</td>
              <td>Irrigated</td>
              <td>$668</td>
              <td>$673</td>
            </tr>
            <tr>
              <td>II</td>
              <td>Irrigated</td>
              <td>$586</td>
              <td>$590</td>
            </tr>
            <tr>
              <td>III</td>
              <td>Irrigated</td>
              <td>$465</td>
              <td>$468</td>
            </tr>
            <tr>
              <td>IV</td>
              <td>Irrigated</td>
              <td>$380</td>
              <td>$383</td>
            </tr>
            <tr>
              <td>I</td>
              <td>Orchard</td>
              <td>$493</td>
              <td>$451</td>
            </tr>
            <tr>
              <td>IV</td>
              <td>Meadow</td>
              <td>$220</td>
              <td>$222</td>
            </tr>
            <tr>
              <td>III</td>
              <td>Dry</td>
              <td>$41</td>
              <td>$41</td>
            </tr>
            <tr>
              <td>IV</td>
              <td>Dry</td>
              <td>$13</td>
              <td>$13</td>
            </tr>
            <tr>
              <td>I</td>
              <td>Graze</td>
              <td>$63</td>
              <td>$63</td>
            </tr>
            <tr>
              <td>II</td>
              <td>Graze</td>
              <td>$19</td>
              <td>$19</td>
            </tr>
            <tr>
              <td>III</td>
              <td>Graze</td>
              <td>$5</td>
              <td>$5</td>
            </tr>
            <tr>
              <td></td>
              <td>Non-Productive</td>
              <td>$5</td>
              <td>$5</td>
            </tr>
          </table>
        </Half>
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Assessor: Greenbelt Act</title>
        <meta
          name="description"
          content="Iron County Assessors Greenbelt Act page"
        />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/assessor/greenbelt-act"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<AssessorSidebar />}
        id="assessor-background"
        click={click}
      />
    </>
  );
}

export default GreenbeltAct;

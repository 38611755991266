import React from "react";
import "./Text.css";

function SplitText(props) {
  const text = props.children;
  const newText = text.split("\\n").map((str) => (
    <div>
      {str}
      <br />
    </div>
  ));

  return newText;
}

export default SplitText;

import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import Textbox from "../../../Components/Text/Textbox";
import { Helmet } from "react-helmet-async";
import Title from "../../../Components/Text/Title";
import ClerkSidebar from "./ClerkSidebar";
import Button_Single from "../../../Components/Buttons/Button_Single";
import PhoneLink from "../../../Components/Links/PhoneLink";

function UtilityBilling() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Left = () => (
    <>
      <Title>Utility Billing</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <h1>How it Works</h1>
        <p>
          The County Clerk’s Office is responsible for sending out the billing
          for solid waste, landfill and sewer (where applicable) and the
          collection of these fees for homes in the unincorporated areas of the
          county.
        </p>
        <p>
          Every home in Iron County pays for the operation and use of the
          landfill. <br /> The billing goes out the first of each quarter and is
          due by the end of the quarter. <br /> If the bill is left unpaid, it
          will be added to your property taxes with an additional $25.00 fee.
        </p>
        <Button_Single
          href="https://library.municode.com/ut/iron_county/codes/code_of_ordinances?nodeId=TIT8HESA_CH8.08GACODI"
          isLink={true}
        >
          Documents & Regulations
        </Button_Single>
      </Textbox>
      <Textbox>
        <h1>Payment Options</h1>
        <p>
          <li>
            In Person - You may also pay in person at the Iron County Clerk’s
            Office located at 68 S 100 E, Parowan, UT
          </li>
          <li>
            Pay by Phone - You may pay with a credit or debit card over the
            phone. <PhoneLink>(435) 477-8340 </PhoneLink>{" "}
          </li>
          <li>
            Check or Money Order - Send a check or money order to:
            <ul>
              <li className="no-dots">Iron County Special Service Area #1</li>
              <li className="no-dots">Po Box 429</li>
              <li className="no-dots">Parowan, UT 84761</li>
            </ul>
          </li>
          <li>You may pay online using a credit or debit card here:</li>
        </p>
        <Button_Single
          href="https://secureinstantpayments.com/sip/reports/account_selector.php?FromPopup=1&Merchant=27740&AddrReq=e&Account="
          isLink={true}
        >
          Make Online Payment
        </Button_Single>
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Clerk: Utility Billing</title>
        <meta
          name="description"
          content="Iron County Clerk Utility Billing Page"
        />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/clerk/utility-billing"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<ClerkSidebar />}
        id="clerk-background"
        click={click}
      />
    </>
  );
}

export default UtilityBilling;

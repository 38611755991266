import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import BuildingSidebar from "./BuildingSidebar";
import Textbox from "../../../Components/Text/Textbox";
import Title from "../../../Components/Text/Title";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import PublicNoticesTable from "../../../Components/Card/PublicNoticesTable";
import { Helmet } from "react-helmet-async";

function BuildingForms() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  const forms = [
    {
      title: "A-20 Acknowledgment",
      desc: "This Form is used for...",
      links: [
        {
          title: "Download PDF",
          to: "/files/departments/building/a-20-acknowledgement.pdf",
          isLink: true,
        },
      ],
    },
    {
      title: "Agreement to Remove",
      desc: "",
      links: [
        {
          title: "Download PDF",
          to: "/files/departments/building/agreement-to-remove.pdf",
          isLink: true,
        },
      ],
    },
    {
      title: "ALUP",
      desc: "",
      links: [
        {
          title: "Download PDF",
          to: "/files/departments/building/alup.pdf",
          isLink: true,
        },
      ],
    },
    {
      title: "Building Permit Application",
      desc: "",
      links: [
        {
          title: "Download PDF",
          to: "/files/departments/building/building-packet.pdf",
          isLink: true,
        },
      ],
    },
    {
      title: "Cross Section",
      desc: "",
      links: [
        {
          title: "Download PDF",
          to: "/files/departments/building/cross-section.pub",
          isLink: true,
        },
      ],
    },
    {
      title: "CUP Application",
      desc: "",
      links: [
        {
          title: "Download PDF",
          to: "/files/departments/building/cup-application.pdf",
          isLink: true,
        },
      ],
    },
    {
      title: "Duct Test",
      desc: "",
      links: [
        {
          title: "To Form",
          to: "/departments/building/duct-form",
          isLink: false,
        },
      ],
    },
    {
      title: "Fire Hazard Severity Form",
      desc: "",
      links: [
        {
          title: "Download PDF",
          to: "/files/departments/building/fire-hazard-severity-form.pdf",
          isLink: true,
        },
      ],
    },

    {
      title: "Gas Sizing",
      desc: "",
      links: [
        {
          title: "To Form",
          to: "/departments/building/gas-form",
          isLink: false,
        },
      ],
    },

    {
      title: "Geologic Condition Reporting",
      desc: "",
      links: [
        {
          title: "Download PDF",
          to: "/files/departments/building/geologic-condition-reporting-form.pdf",
          isLink: true,
        },
      ],
    },
    {
      title: "Geotechnical Form Backfill final Grade",
      desc: "",
      links: [
        {
          title: "Download PDF",
          to: "/files/departments/building/geotechnical-form-backfill-final-grade.pdf",
          isLink: true,
        },
      ],
    },
    {
      title: "Geotechnical Form Footing Foundation",
      desc: "",
      links: [
        {
          title: "Download PDF",
          to: "/files/departments/building/geotechnical-form-footing-foundation.pdf",
          isLink: true,
        },
      ],
    },
    // {
    //   title: "Grading Permit",
    //   desc: "",
    //   links: [
    //     {
    //       title: "Download PDF",
    //       to: "/files/departments/building/",
    //       isLink: true,
    //     },
    //   ],
    // },
    {
      title: "Haul Water",
      desc: "",
      links: [
        {
          title: "Download PDF",
          to: "/files/departments/building/deed-restriction-for-hauling-water.pdf",
          isLink: true,
        },
      ],
    },
    {
      title: "Owner Builder Form",
      desc: "",
      links: [
        {
          title: "Download PDF",
          to: "/files/departments/building/owner-builder-form.pdf",
          isLink: true,
        },
      ],
    },
    {
      title: "Plan Review Waiver",
      desc: "",
      links: [
        {
          title: "Download PDF",
          to: "/files/departments/building/plan-review-wavier.docx",
          isLink: true,
        },
      ],
    },
    {
      title: "Prairie Dog Clearance",
      desc: "",
      links: [
        {
          title: "Download PDF",
          to: "/files/departments/building/prairie-dog-clearance.pdf",
          isLink: true,
        },
      ],
    },
  ];

  const Left = () => (
    <>
      <Title>Building Forms</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        {/* <CardSmallMultiple data={forms} /> */}
        <PublicNoticesTable data={forms} />
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Building: All Forms</title>
        <meta name="description" content="Iron County Building Forms Page" />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/building/forms"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<BuildingSidebar />}
        id="building-background"
        click={click}
      />
    </>
  );
}

export default BuildingForms;

import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import Title from "../../../Components/Text/Title";
import Textbox from "../../../Components/Text/Textbox";
import EMSidebar from "./EMSidebar";
import TextWebLink from "../../../Components/Links/TextWebLink";
import Button_Single from "../../../Components/Buttons/Button_Single";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

function CERT() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Left = () => (
    <>
      <Title>Community Emergency Response Team (CERT)</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <p>
          <em>
            In 95 percent of all emergencies, the victim or bystander provides
            the first immediate assistance on the scene. Would you know what to
            do?
          </em>
        </p>
        <p>
          Community Emergency Response Team training is a{" "}
          <TextWebLink link="https://www.fema.gov/emergency-managers/individuals-communities/preparedness-activities-webinars/community-emergency-response-team">
            Federal Emergency Management Agency program
          </TextWebLink>{" "}
          that educates citizens about the hazards they face in their community
          and trains them in lifesaving skills. If needed following a disaster,
          these citizen-responders use their training as part of a neighborhood
          or workplace team to help others when professional responders are
          overwhelmed or not immediately available. CERT members provide
          immediate assistance to victims in their area, organize spontaneous
          volunteers who have not had the training, and collect disaster
          intelligence that will assist professional responders with
          prioritization and allocation of resources when they arrive.
        </p>
      </Textbox>
      <Textbox title="Purpose of the Program">
        <p>
          CERT promotes a partnering between emergency management and response
          agencies and the people in the community that they serve. The goal is
          to train members of neighborhoods and workplaces in basic response
          skills.
        </p>
        <p>
          If there is a natural or man-made event that overwhelms or delays the
          community’s professional responders, CERT members can assist others by
          applying the basic response and organizational skills that they
          learned during their CERT training. These skills can help save and
          sustain lives until help arrives. CERT members also can volunteer for
          special projects that improve a community’s preparedness
        </p>
      </Textbox>
      <Textbox title="How Does it Work?">
        <p>
          The 22-hour CERT Basic Training Course is offered to meet the
          scheduling of the requesting organization or agency. Basic Courses can
          be delivered in two back-to-back weekends (Friday evening and all day
          Saturday for two weekends) or evenings and one weekend. There are nine
          units which present Disaster Preparedness, CERT Organization, fire
          safety and utilities management, Basic First Aid, Light Search and
          Rescue, Disaster Psychology, and Terrorism and CERT.
        </p>
        <p>
          CERT is a positive and realistic approach to emergency and disaster
          situations where citizens will be initially on their own and their
          actions can make a difference. CERT is about readiness, people helping
          people, rescuer safety, and doing the greatest good for the greatest
          number.
        </p>
        <p>
          Iron County has 4 active CERT groups:
          <li>Cedar City</li>
          <li>Enoch</li>
          <li>Kanarraville</li>
          <li>North Iron County (Parowan, Paragonah, Brian Head, Summit)</li>
        </p>
        <p>
          Iron County has two 22-hour CERT Basic Training Courses each year.
          Cost of the course is $20.00. Pre-registration is required. The next
          scheduled courses are: Listed above or TBA
        </p>
        <p>
          CERT information including a CERT Instructor Guide and Student Guide
          are located on the web at:
          <Button_Single
            href="https://www.fema.gov/emergency-managers/individuals-communities/preparedness-activities-webinars/community-emergency-response-team"
            isLink={true}
          >
            CERT Information
          </Button_Single>
          <Button_Single
            href="/files/departments/em/training-registration-liability-form.pdf"
            isLink={true}
          >
            {" "}
            Course Registration and Liability Release Packet{" "}
          </Button_Single>
        </p>

        <h1>Training Benefits</h1>
        <p>
          Participants learn how to:
          <li>Identify and anticipate hazards</li>
          <li>Reduce fire hazards in the home and workplace</li>
          <li>Extinguish Small Fires</li>
          <li>Assist emergency responders</li>
          <li>Conduct light search and rescue</li>
          <li>Set up medical treatment areas</li>
          <li>Apply basic medical first aid</li>
          <li>Help reduce survivor stress</li>
        </p>
        <h1>Questions?</h1>
        <p>
          For comments or questions, contact:
          <li className="no-dots">George Colson</li>
          <li className="no-dots">Iron County Emergency Manager</li>
          <li className="no-dots">(435) 267-1740</li>
        </p>
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Emergency Management: CERT</title>
        <meta
          name="description"
          content="Iron County emergency Management CERT Page"
        />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/emergency-management/cert"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<EMSidebar />}
        id="em-background"
        click={click}
      />
    </>
  );
}

export default CERT;

import React from "react";
import TabView from "../../Components/Tabs/TabView";
import Newsroom from "./Newsroom/Newsroom";
import PopularServices from "./PopularService/PopularServices";
import Divider from "../../Components/Divider/Divider";
import Map from "../../Components/Maps/Map";

function Home() {
  return (
    <div className="home">
      <div className="hero-container center">
        <PopularServices />
      </div>
      <div className="center">
        {/* <Divider id="home" /> */}
        <Newsroom />
      </div>
    </div>
  );
}

export default Home;

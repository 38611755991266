import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import { Helmet } from "react-helmet-async";
import Title from "../../../Components/Text/Title";
import Textbox from "../../../Components/Text/Textbox";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import Aidaform from "../../../Components/Aidaform/Aidaform";
import Half from "../../../Components/Sizes/Half";
import TableDynamic from "../../../Components/Table/TableDynamic";
import { Link } from "react-router-dom";

function ContactUs() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  const ContactInformation = [
    {
      Department: "Assessor",
      Address: "68 S 100 E, Parowan UT 84761",
      Phone_Number: "(435) 477-8310",
    },
    {
      Department: "Attorney",
      Address: "82 N 100 E #201, Cedar City UT 84720",
      Phone_Number: "(435) 865-5310",
    },
    {
      Department: "Auditor",
      Address: "68 S 100 E, Parowan UT 84761",
      Phone_Number: "(435) 477-8330",
    },
    {
      Department: "Building & Zoning",
      Address: "82 N 100 E #102, Cedar City UT 84720",
      Phone_Number: "(435) 865-5350",
    },
    {
      Department: "Childrens Justice Center",
      Address: "545 S 225 E, Cedar City UT 84720",
      Phone_Number: "(435) 867-4275",
    },
    {
      Department: "Clerk",
      Address: "68 S 100 E, Parowan UT 84761",
      Phone_Number: "(435) 477-8340",
    },
    {
      Department: "County Commission",
      Address: "68 S 100 E, Parowan UT 84761",
      Phone_Number: "(435) 477-8340",
    },
    {
      Department: "Emergency Management",
      Address: "1302 Kitty Hawk Dr, Cedar City, UT 84721",
      Phone_Number: "(435) 267-1740",
    },
    {
      Department: "Engineer",
      Address: "82 N 100 E #104, Cedar City UT 84720",
      Phone_Number: "(435) 865-5370",
    },
    {
      Department: "Extension Office",
      Address: "585 N. Main #4 | Box 69 Cedar City, Utah 84721",
      Phone_Number: "(435) 586-8132",
    },
    {
      Department: "Fair",
      Address: "68 S 100 E, Parowan UT 84761",
      Phone_Number: "(435) 477-8380",
    },
    {
      Department: "Fire",
      Address: "646 N Main, Cedar City, UT 84721",
      Phone_Number: "(435) 586-4408",
    },
    {
      Department: "Health Department",
      Address: "260 E. DL Sargent Dr., Cedar City UT 84720",
      Phone_Number: "(435) 586-2437",
    },
    {
      Department: "Human Resources",
      Address: "82 N 100 E #204, Cedar City UT 84720",
      Phone_Number: "(435) 865-5373",
    },
    {
      Department: "IT",
      Address: "68 S 100 E, Parowan UT 84761",
      Phone_Number: "(435) 477-8370",
    },
    {
      Department: "Jail",
      Address: "2136 N Main St, Cedar City UT 84721",
      Phone_Number: "(435) 867-7555",
    },
    {
      Department: "Justice Court",
      Address: "82 N 100 E #101, Cedar City UT 84720",
      Phone_Number: "(435) 865-5335",
    },
    {
      Department: "Natural Resource",
      Address: "82 N 100 E #102, Cedar City UT 84720",
      Phone_Number: "(435) 865-5357",
    },
    {
      Department: "Outdoor Recreation",
      Address: "581 N Main Street, Cedar City UT 84720",
      Phone_Number: "(435) 865-5325",
    },
    {
      Department: "Planning",
      Address: "82 N 100 E #102 Cedar City, Utah 84720",
      Phone_Number: "(435) 865-5325",
    },
    {
      Department: "Recorder",
      Address: "68 S 100 E, Parowan UT 84761",
      Phone_Number: "(435) 477-8350",
    },
    {
      Department: "Road Department",
      Address: "1105 N Bulldog Rd, Cedar City UT 84720",
      Phone_Number: "(435) 865-5400",
    },
    {
      Department: "School District",
      Address: "2077 W. Royal Hunte Dr., Cedar City, UT 84720",
      Phone_Number: "(435) 586-2804",
    },
    {
      Department: "Senior Citizens",
      Address: "489 E 200 S Cedar City, UT 84720",
      Phone_Number: "(435) 586-0832 (Cedar Location)",
    },
    {
      Department: "Sheriff",
      Address: "2132 N Main St, Cedar City UT 84721",
      Phone_Number: "(435) 867-7500",
    },
    {
      Department: "Solid Waste / Landfill",
      Address: "3127 N Iron Springs RD, Cedar City UT 84721",
      Phone_Number: "(435) 865-7015",
    },
    {
      Department: "Tourism and Convention",
      Address: "581 N Main Street, Cedar City UT 84721",
      Phone_Number: "(435) 586-5124",
    },
    {
      Department: "Treasurer",
      Address: "68 S 100 E, Parowan UT 84761",
      Phone_Number: "(435) 477-8360",
    },
    {
      Department: "USU Extension Service",
      Address: "585 N. Main St. #5, Cedar City UT 84721",
      Phone_Number: "(435) 586-8132",
    },
    {
      Department: "Weed Control",
      Address: "1105 N Bulldog Rd, Cedar City UT 84720",
      Phone_Number: "(435) 586-8652",
    },
  ];

  const Left = () => (
    <>
      <Title>Contact Us</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <h1 className="center">Let's Get In Touch</h1>

        <p>
          Iron County believes in giving the public the easiest access to get
          your questions answered. Please select the appropriate department
          below and send your message directly to whom it needs to get it to.
        </p>
        <p>
          To contact the Justice Court directly, please fill out the form found
          here:{" "}
          <Link to="/departments/justice-court/request-court">
            Request to Court Form
          </Link>{" "}
        </p>
      </Textbox>
      <Textbox>
        <Half>
          {" "}
          <Aidaform form="https://forms.office.com/Pages/ResponsePage.aspx?id=Kw5RoFlVfkq8_LRykMDbv8BIGIzucrdDolaJBVIHgbtUMFhYSFRGUzQ4RE40NURHRjhFTkRSUU9SWC4u&embed=true" />
        </Half>
        <Half>
          <TableDynamic tableData={ContactInformation}></TableDynamic>
        </Half>
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Contact Us</title>
        <meta name="description" content="Iron County Contact Us Page" />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/it/contact-us"
        />
      </Helmet>
      <DepartmentTemplate left={<Left />} id="it-background" click={click} />
    </>
  );
}

export default ContactUs;

import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import Aidaform from "../../../Components/Aidaform/Aidaform";
import Textbox from "../../../Components/Text/Textbox";
import Title from "../../../Components/Text/Title";
import ClerkSidebar from "./ClerkSidebar";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import CardAccordion from "../../../Components/Card/CardAccordion";
import TextWebLink from "../../../Components/Links/TextWebLink";
import { Helmet } from "react-helmet-async";

function BusinessLicense() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Left = () => (
    <>
      <Title>Business License</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <h1>How it Works</h1>
        <p>
          Business licenses will be issued by the Iron County Clerk’s Office in
          the areas within the county which are not incorporated. If a business
          which is applying is going to be located within an incorporated city,
          you must apply with the appropriate city office.
        </p>
      </Textbox>
      <Textbox>
        <CardAccordion title="Where to Start">
          <p>
            We will require the following information on your application if it
            applies to your business.
            <li>Business Name</li>
            <li>Owner/Applicant Information</li>
            <li>Detailed Description of Business</li>
            <li>Any State or Federal Tax/License numbers</li>
          </p>
          <p>
            You may want to start by going to{" "}
            <TextWebLink link="https://business.utah.gov/">
              business.utah.gov
            </TextWebLink>{" "}
            to streamline the process of registering a new business in Utah.
            What you will find on this page is the One Stop Online Business
            Registration. Once you have completed Utah business registration (if
            needed) and have your sales tax ID and other required license number
            you can fill out the Business License Application and submit it to
            the Iron County Clerk’s Office.
          </p>
        </CardAccordion>
        <CardAccordion title="Documents & Regulations">
          <p>
            Below you will find a PDF copy or an online version of the Iron
            County Business License Application. If you choose to fill out the
            PDF version, print the application, fill it out and sign it. You
            then can mail or bring the application and fees to the County
            Clerk’s Office.
            <li>
              <TextWebLink link="/files/departments/clerk/business-application-iron.pdf">
                Iron County Business Application (PDF)
              </TextWebLink>
            </li>
            <li>
              <TextWebLink link="https://library.municode.com/ut/iron_county/codes/code_of_ordinances?nodeId=TIT5BULIRE">
                Business License and Regulations – Title 5 Iron County Code
              </TextWebLink>
            </li>
            <li>
              <TextWebLink link="https://library.municode.com/ut/iron_county/codes/code_of_ordinances?nodeId=TIT17ZO_CH17.20USDE">
                Iron County Code of Ordinances, Title 17 – Zoning, Chapter 17.20
                – Use Definitions
              </TextWebLink>
            </li>
          </p>
        </CardAccordion>
        <CardAccordion title="Fees">
          <p>
            The fees for business licenses are as follows:
            <li>$25 for owner + $25 per each full time employee</li>
            <li>Part time employees: Average hours x $0.62</li>
            <li>Beer license: $150 to $250 depending on what class (A or B)</li>
            <li>Fees will not exceed $400</li>
          </p>
        </CardAccordion>
        <CardAccordion title="What Happens Next?">
          <p>
            When you have submitted the business license application copies of
            the application will be sent to other departments for inspections
            such as Fire Department, Building Inspection, County Health
            Department, and/or Sheriff’s Office.
          </p>
          <p>
            The business license will be issued by Iron County when the
            necessary inspections are complete and recommendations for approval
            are returned to the Planning and Development Office. Not all
            businesses will require inspections from all of the above named
            departments.
          </p>
          <p>
            The normal processing time for a new business license is four to six
            weeks. You may not conduct your business until you have received
            your business license.
          </p>
        </CardAccordion>
        <CardAccordion title="When Do I Need to Renew?">
          <p>
            Each Iron County business license is valid until the end of the
            current year. Renewal notices will be mailed in December. In order
            to avoid late fees or penalties the license must be renewed by the
            31st of January. It is the responsibility of the licensee to renew
            the business license each year regardless of whether the business
            receives a renewal notice or not. Please mail fees (check/money
            order only in the mail) and the application above to the Iron County
            Clerk’s Office to renew your license.
          </p>
        </CardAccordion>
      </Textbox>
      <Textbox title="Apply Online">
        <Aidaform form="https://ironcounty.aidaform.com/business-license-application-renewal"></Aidaform>
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Clerk: Business License</title>
        <meta
          name="description"
          content="Iron County Clerk business license Page"
        />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/clerk/business-license"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<ClerkSidebar />}
        id="clerk-background"
        click={click}
      />
    </>
  );
}

export default BusinessLicense;

import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import CommissionSideBar from "./CommissionSidebar";
import Textbox from "../../../Components/Text/Textbox";
import Title from "../../../Components/Text/Title";
import TextWebLink from "../../../Components/Links/TextWebLink";
import PhoneLink from "../../../Components/Links/PhoneLink";
import Third from "../../../Components/Sizes/Third";
import CardSmall from "../../../Components/Card/Card-Small";
import Image from "../../../Components/Images/Image";
import marilyn from "../../../Images/Departments/Commission/marilyn-wood.jpg";
import mike from "../../../Images/Departments/Commission/mike-bleak.jpg";
import paul from "../../../Images/Departments/Commission/paul-cozzens.jpg";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

function Commission() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  const Left = () => (
    <>
      <Title>Commission</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <h1>What We Do</h1>
        <p>
          The Iron County Commission embodies three elected officials. Two
          commission seats are elected at the same election, the other
          commission seat is elected two years later. All terms are four years.
          County commissioners elect a chairman annually who serves as the
          commission’s presiding officer.
        </p>
        <p>
          The Commission’s regular meetings are on the second and fourth Monday
          of each month at 9:00 a.m. in the Iron County Commission Chambers,{" "}
          <TextWebLink link="https://goo.gl/maps/4NdRTWSXUbo5dc9s9">
            68 S 100 E, Parowan UT
          </TextWebLink>
        </p>
        <p>
          To view a full list of public meeting notices, please visit the Utah
          public notice website at{" "}
          <TextWebLink link="https://www.utah.gov/pmn/index.html">
            utah.gov/pmn
          </TextWebLink>
        </p>
      </Textbox>
      <Textbox title="Meet the Commissioners">
        <Third>
          <CardSmall>
            <Image
              width="100%"
              height="200px"
              src={marilyn}
              alt="Picture of Commissioner Marilyn"
            />
            <h1>Marilyn Wood</h1>
            <p>
              <PhoneLink>435-590-4943</PhoneLink>
            </p>
          </CardSmall>
        </Third>
        <Third>
          <CardSmall>
            <Image
              width="100%"
              height="auto"
              src={mike}
              alt="Picture of Comissioner Mike"
            />
            <h1>Mike Bleak</h1>
            <p>
              <PhoneLink>435-590-9666</PhoneLink>
            </p>
          </CardSmall>
        </Third>
        <Third>
          <CardSmall>
            <Image
              width="100%"
              height="auto"
              src={paul}
              alt="Picture of Commissioner Paul"
            />
            <h1>Paul Cozzens</h1>
            <p>
              <PhoneLink>435-590-7618</PhoneLink>
            </p>
          </CardSmall>
        </Third>
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Commission</title>
        <meta name="description" content="Iron County Commission Page" />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/commission"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<CommissionSideBar />}
        id="commission-background"
        click={click}
      />
    </>
  );
}

export default Commission;

import React, { useState } from "react";
import "./Card.css";
import SplitText from "../Text/SplitText";

function CardFAQ(props) {
  const [clicked, setClicked] = useState(false);

  const toggle = (index) => {
    if (clicked === index) {
      return setClicked(null);
    }

    setClicked(index);
  };
  return (
    <div className="hero-container">
      {props.questions.map((item, index) => {
        return (
          <>
            <div
              className="card-accordion "
              id="faq-wrap"
              onClick={() => toggle(index)}
              key={index}
            >
              <h4 className="factsh1">
                {item.question} {clicked === index ? "-" : "+"}
              </h4>
            </div>
            {clicked === index ? (
              <div className="facts-dropdown-a">
                <p>
                  <SplitText>{item.answer}</SplitText>
                </p>
              </div>
            ) : null}
          </>
        );
      })}
    </div>
  );
}

export default CardFAQ;

import React from "react";
import "../../../Components/Sidebar/Sidebar.css";
import AccordionSidemenu from "../../../Components/AccordionMenu/AccordionSidemenu";
import TextWebLink from "../../../Components/Links/TextWebLink";
import HoursContact from "../../../Components/Text/HoursContact";
import PhoneLink from "../../../Components/Links/PhoneLink";

function ITSideBar() {
  const navItems = [
    {
      title: "About",
      to: "/departments/it",
      isLink: false,
    },
    {
      title: "Contact Us",
      to: "/departments/contact-us",
      isLink: false,
    },
    {
      title: "GIS Maps",
      to: "/departments/it/gis",
      isLink: false,
    },
    {
      title: "Privacy Policy",
      to: "/files/departments/it/privacy-policy.pdf",
      isLink: true,
    },
  ];

  return (
    <>
      <div className="nav-menu-side">
        <HoursContact>
          <p>
            Jared Wilson, IT Director
            <br />
            PO Box 1030
            <br />
            <TextWebLink link="https://goo.gl/maps/dWeYhAwocsaNHEyT9">
              68 S 100 E Parowan, UT 84761
            </TextWebLink>
            <br />
            Monday - Friday 8:00 A.M. - 5:00 P.M.
            <br />
            <PhoneLink>435-477-8370</PhoneLink>
          </p>
        </HoursContact>
        <br />

        <AccordionSidemenu nav={navItems} />
      </div>
    </>
  );
}

export default ITSideBar;

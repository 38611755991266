import React from "react";
import ButtonIcons from "../../../../Components/Buttons/Button_Icons";
import "../../Home.css";

function Request() {
  const getButtons = [
    // {
    //
    //   title: "Property Accounts",
    //   to: "",
    // },
    // {
    //
    //   title: "Property Records",
    //   to: "",
    // },
    {
      title: "Change of Address (Treasurer)",
      to: "/departments/treasurer/change-of-address",
    },
    {
      title: "Court Records",
      to: "/departments/justice-court/request-records",
    },
    {
      title: "To Court",
      to: "/departments/justice-court/request-court",
    },
  ];

  return (
    <div id="apply-container">
      <ButtonIcons
        buttons={getButtons}
        id="home-buttons"
        className="buttons-container-home"
      />
    </div>
  );
}

export default Request;

import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import Title from "../../../Components/Text/Title";
import Textbox from "../../../Components/Text/Textbox";
import EMSidebar from "./EMSidebar";
import TextWebLink from "../../../Components/Links/TextWebLink";
import Half from "../../../Components/Sizes/Half";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

function LEPC() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Left = () => (
    <>
      <Title>Local Emergency Planning Committee (LEPC)</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <p>
          Iron County’s Local Emergency Planning Committee (LEPC) is organized
          and exists pursuant to 42 U.S.C. 11001 and Utah Code Annotated,
          63K-3-301 and the rules adopted by the State Emergency Response
          Commission. The purpose of the LEPC is to carry out those duties set
          forth in the Emergency Planning and Community Right-to-Know Act of
          1986 (EPCRA).
        </p>

        <Half>
          <h1>Public Access to Information</h1>
          <li>
            <TextWebLink link="/files/departments/em/lepc-public-access-to-info.pdf">
              Iron County LEPC Guide for Requests and Release of Information
            </TextWebLink>
          </li>
          <li>
            <TextWebLink link="/files/departments/em/lepc-request-info-form.pdf">
              Iron County LEPC Request for Public Information Form
            </TextWebLink>
          </li>
        </Half>
        <Half>
          <h1>Minutes & Agendas</h1>
          <p>
            Iron County LEPC has copies of Meeting agendas and Meeting minutes
          </p>
        </Half>
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Emergency Management: LEPC</title>
        <meta
          name="description"
          content="Iron County emergency Management lepc Page"
        />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/emergency-management/lepc"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<EMSidebar />}
        id="em-background"
        click={click}
      />
    </>
  );
}

export default LEPC;

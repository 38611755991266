import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import ParksSidebar from "./ParksSidebar";
import Title from "../../../Components/Text/Title";
import Textbox from "../../../Components/Text/Textbox";
import Half from "../../../Components/Sizes/Half";
import Map from "../../../Components/Maps/Map";
import ButtonSingle from "../../../Components/Buttons/Button_Single";
import TextWebLink from "../../../Components/Links/TextWebLink";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

function FiveMile() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Left = () => (
    <>
      <Title>Five Mile</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <ButtonSingle
          href="/files/departments/parks/five-mile-reservation-applicaton.pdf"
          isLink={true}
        >
          Make a Reservation
        </ButtonSingle>
        <p>
          Please email form to:{" "}
          <a href="mailto:sorton@ironcounty.net">sorton@ironcounty.net</a>{" "}
        </p>
      </Textbox>
      <Textbox>
        <h1>About</h1>
        <p>
          Located five miles southeast of Parowan, the 5-Mile Picnic Area is a
          scenic haven nestled amidst the striking red rocks of the region.
          Surrounded by the natural beauty of pinyon-juniper forest, the area
          offers a tranquil retreat for picnicker and outdoor enthusiast alike.
          Bowery Creek flows through the area. Noah’s Ark Trail and Vermillion
          Castle Trail both start in this area.
        </p>
        <p>
          Overnight camping is not permitted at 5-Mile Picnic Area; however
          there are Forest Service campsites nearby. Contact the Dixie National
          Forest for camping information or information about trails at (435)
          865-3200 or visit recreation.gov.
        </p>
        <p>
          This area is open seasonally from May 1st - September 30th (weather
          permitting).
        </p>
      </Textbox>
      <Textbox>
        <Half>
          <h1>
            <b>
              <u>Rules</u>
            </b>
          </h1>
          <ul>
            <li>
              <b>NO OVERNIGHT CAMPING</b>
            </li>
            <li>
              <b>CLOSED AFTER DARK</b>
            </li>
            <li>No pallet burning allowed</li>
            <li>No fireworks</li>
            <li>Fires in provided metal fire rings only</li>
            <li>Pets must be on a leash at all times</li>
            <li>No discharging firearms</li>
            <li>Follow posted speed limits</li>
            <li>Follow state fire restrictions when posted</li>
            <li>No smoking or vaping</li>
            <li>Alcohol permit requirement</li>
          </ul>
        </Half>
        <Half>
          <h1>
            <b>
              <u>Amenities</u>
            </b>
          </h1>
          <ul>
            <li>
              Ten first-come-first-serve picnic sites
              <ul>
                <li>
                  Picnic table, fire pit with a cooking grate and a charcoal
                  grill
                </li>
              </ul>
            </li>
            <li>
              One group picnic site
              <ul>
                <li>
                  Must be reserved and a reservation fee is required. Call (435)
                  586-5124{" "}
                  <ul>
                    <li>
                      5 picnic tables, 2 serving tables, large charcoal grill
                      and a fire pit
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              One ADA Compliant picnic site
              <ul>
                <li>
                  Picnic table, fire pit with a cooking grate and a charcoal
                  grill
                </li>
              </ul>
            </li>
            <li>One double vault toilet</li>
            <li>Trash cans</li>
            <li>Seasonal Water</li>
            <li>No electricity</li>
          </ul>
        </Half>
      </Textbox>
      <Textbox>
        <Map src="https://calendar.google.com/calendar/embed?height=600&wkst=1&ctz=America%2FDenver&bgcolor=%23ffffff&src=YTU1NzdjYmQ2OWMzNDllMjIxOGVmYjU1MGMxOWE4YWI0ZWZlYjRhNWQxNjZmZWMyNDgwNjdlZWFiNWY5MGQxNkBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&color=%239E69AF"></Map>
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Parks and Recreation</title>
        <meta
          name="description"
          content="Iron County  Parks and Recreation Page"
        />
        <link rel="canonical" href="https://ironcounty.net/departments/parks" />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<ParksSidebar />}
        id="parks-background"
        click={click}
      />
    </>
  );
}

export default FiveMile;

import React from "react";
import ButtonIcons from "../Buttons/Button_Icons";
import TextWebLink from "./TextWebLink";

function TextWebLinkList(props) {
  return (
    <div>
      <h2>{props.title}</h2>
      {props.links.map((x) => {
        return (
          <div>
            <TextWebLink link={x.link}>{x.name}</TextWebLink>
          </div>
        );
      })}
    </div>
  );
}

export default TextWebLinkList;

import React from "react";
import "../../../Components/Sidebar/Sidebar.css";
import AccordionSidemenu from "../../../Components/AccordionMenu/AccordionSidemenu";
import TextWebLink from "../../../Components/Links/TextWebLink";
import HoursContact from "../../../Components/Text/HoursContact";
import PhoneLink from "../../../Components/Links/PhoneLink";
function EMSideBar() {
  const navItems = [
    {
      title: "About",
      to: "/departments/emergency-management",
      isLink: false,
    },
    {
      title: "CERT",
      to: "/departments/emergency-management/cert",
      isLink: false,
    },
    {
      title: "Citizen Alert",
      to: "/departments/emergency-management/citizen-alert",
      isLink: false,
    },
    {
      title: "Iron County Emergency Operations Plan",
      to: "/files/departments/em/ic-eo-plan-2018.pdf",
      isLink: true,
    },
    {
      title: "Iron County Preparedness Guide",
      to: "/files/departments/em/ic-preparedness-guide.pdf",
      isLink: true,
    },
    {
      title: "LEPC",
      to: "/departments/emergency-management/lepc",
      isLink: false,
    },

    {
      title: "Storm Damage Report",
      to: "/departments/emergency-management/storm-damage",
      isLink: false,
    },

    {
      title: "Volunteer Hours",
      to: "/departments/emergency-management/volunteer-hours",
      isLink: false,
    },
  ];

  return (
    <>
      <div className="nav-menu-side">
        <HoursContact>
          <p>
            George Colson, Emergency
            <br />
            <TextWebLink link="https://maps.app.goo.gl/y9STYkg6dmerHUBz7">
              1302 Kitty Hawk Dr, Cedar City, UT 84721
            </TextWebLink>
            <br />
            Monday - Friday 8:00 A.M. - 5:00 P.M.
            <br />
            <PhoneLink>(435) 267-1740</PhoneLink>
          </p>
        </HoursContact>
        <br />

        <AccordionSidemenu nav={navItems} />
      </div>
    </>
  );
}

export default EMSideBar;

import React from "react";
import AccordionSidemenu from "../../../Components/AccordionMenu/AccordionSidemenu";
import TextWebLink from "../../../Components/Links/TextWebLink";
import HoursContact from "../../../Components/Text/HoursContact";
import PhoneLink from "../../../Components/Links/PhoneLink";
import "../../../Components/Sidebar/Sidebar.css";
import EmailLink from "../../../Components/Links/EmailLink";

function BuildingSideBar() {
  const navItems = [
    {
      title: "About",
      to: "/departments/building",
      isLink: false,
    },
    {
      title: "Apply for a building Permit",
      to: "https://ironcounty.mycityinspector.com/register?registration_id=15ccc64ec1d01c",
      isLink: true,
    },

    {
      title: "Encroachment Permit Driveways, Culverts, etc ",
      to: "/departments/engineering/encroachment-permit",
      isLink: false,
    },
    {
      title: "FAQ’s",
      to: "/departments/building/faq",
      isLink: false,
    },

    {
      title: "Forms",
      // to: "/departments/building/forms",
      // isLink: false,
      sub: [
        {
          title: "All Forms",
          to: "/departments/building/forms",
          isLink: false,
        },
        {
          title: "A-20 Acknowledgment",
          to: "/files/departments/building/a-20-acknowledgement.pdf",
          isLink: true,
        },
        {
          title: "Agreement to Remove",
          to: "/files/departments/building/agreement-to-remove.pdf",
          isLink: true,
        },
        {
          title: "ALUP",
          to: "/files/departments/building/alup.pdf",
          isLink: true,
        },
        {
          title: "Building Permit Application ",
          to: "/files/departments/building/building-packet.pdf",
          isLink: true,
        },
        {
          title: "Cross Section",
          to: "/files/departments/building/cross-section.pub",
          isLink: true,
        },
        {
          title: "CUP Application",
          to: "/files/departments/building/cup-application.pdf",
          isLink: true,
        },
        {
          title: "Duct Test",
          to: "/departments/building/duct-form",
          isLink: false,
        },
        {
          title: "Fire Hazard Severity",
          to: "/files/departments/building/fire-hazard-severity-form.pdf",
          isLink: true,
        },

        {
          title: "Gas Sizing Form",
          to: "/departments/building/gas-form",
          isLink: false,
        },
        {
          title: "Geologic Condition Reporting",
          to: "/files/departments/building/geologic-condition-reporting-form.pdf",
          isLink: true,
        },
        {
          title: "Geotechnical Form Backfill final Grade",
          to: "/files/departments/building/geotechnical-form-backfill-final-grade.pdf",
          isLink: true,
        },
        {
          title: "Geotechnical Form Footing Foundation",
          to: "/files/departments/building/geotechnical-form-footing-foundation.pdf",
          isLink: true,
        },
        // {
        //
        //   title: "Grading Permit",
        //   to: "",
        //   isLink: true,
        // },
        {
          title: "Haul Water",
          to: "/files/departments/building/deed-restriction-for-hauling-water.pdf",
          isLink: true,
        },
        {
          title: "Owner Builder Form",
          to: "/files/departments/building/owner-builder-form.pdf",
          isLink: true,
        },
        {
          title: "Plan Review Waiver",
          to: "/files/departments/building/plan-review-wavier.docx",
          isLink: true,
        },
        {
          title: "Prairie Dog Clearance",
          to: "/files/departments/building/prairie-dog-clearance.pdf",
          isLink: true,
        },
      ],
    },
    {
      title: "Iron County Planner ",
      to: "/departments/planning/",
      isLink: false,
    },

    // {
    //
    //   title: "Pay for Building Permit",
    //   to: "/",
    //   isLink: false,
    // },
    {
      title: "Permit Refund Policy ",
      to: "/departments/building/faq",
      isLink: false,
    },
    {
      title: "Resources",
      sub: [
        {
          title: "Animal Units (Domestic)",
          to: "https://library.municode.com/ut/iron_county/codes/code_of_ordinances?nodeId=TIT17ZO_CH17.30LIRE_17.30.040ANOPOTSW",
          isLink: true,
        },
        {
          title: "Building Code “General Design Criteria Chart” ",
          to: "/files/departments/building/general-design-information.docx",
          isLink: true,
        },
        {
          title: "Building Permit Plan Requirements ",
          to: "/files/departments/building/building-permit-requirements.pdf",
          isLink: true,
        },
        {
          title: "Building Inspection Requirements ",
          to: "/files/departments/building/minimum-required-inspections.doc",
          isLink: true,
        },

        {
          title: "Moderate Income Housing Report",
          to: "/files/departments/building/moderate-income-housing.pdf",
          isLink: true,
        },
        {
          title: "Site Development Standards",
          to: "https://library.municode.com/ut/iron_county/codes/code_of_ordinances?nodeId=TIT17ZO_CH17.16ESZODI_17.16.040TASIDEST",
          isLink: true,
        },
        {
          title: "Utah Wildland-Urban Interface Code",
          to: "https://www.ironcounty.net/assets/wp-content/uploads/06_Utah_Wildland%20Urban%20Interface%20Code.pdf",
          isLink: true,
        },
        {
          title: "Water Rights Requirement ",
          to: "",
          isLink: true,
        },
      ],
    },
    {
      title: "Schedule Inspection(s)",
      to: "https://ironcounty.mycityinspector.com/register?registration_id=15ccc64ec1d01c",
      isLink: true,
    },
    {
      title: "Table of Uses",
      to: "https://library.municode.com/ut/iron_county/codes/code_of_ordinances?nodeId=TIT17ZO_CH17.16ESZODI_17.16.030TAUS",
      isLink: true,
    },
    {
      title: "Use Definitions",
      to: "https://library.municode.com/ut/iron_county/codes/code_of_ordinances?nodeId=TIT17ZO_CH17.20USDE_17.20.010DE",
      isLink: true,
    },

    {
      title: "Zoning/Code Violation",
      to: "/departments/building/zoning-violation",
      isLink: false,
    },
    {
      title: "Zoning Map",
      to: "https://irongis.maps.arcgis.com/apps/webappviewer/index.html?id=2eecc94438c04796a65b4d3036e49972",
      isLink: true,
    },
  ];

  return (
    <>
      <div className="nav-menu-side">
        <HoursContact>
          <p>
            Terry Palmer – Building Official/Zoning Administrator
            <br />
            <br />
            <TextWebLink link="https://goo.gl/maps/n4UaZjbAxr5HBvz78">
              82 N 100 E #102 Cedar City, Utah 84720
            </TextWebLink>
            <br />
            Monday - Friday 7:30 AM to 4:30 PM
            <br />
            Phone: <PhoneLink>435-865-5350</PhoneLink>
            <br />
            Email: <EmailLink>building.group@ironcounty.net</EmailLink>
          </p>
        </HoursContact>
        <br />
        <AccordionSidemenu nav={navItems} id="department-sidebar" />
      </div>
    </>
  );
}

export default BuildingSideBar;

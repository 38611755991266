import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import ParksSidebar from "./ParksSidebar";
import Title from "../../../Components/Text/Title";
import Textbox from "../../../Components/Text/Textbox";
import Half from "../../../Components/Sizes/Half";
import Map from "../../../Components/Maps/Map";
import ButtonSingle from "../../../Components/Buttons/Button_Single";
import PhoneLink from "../../../Components/Links/PhoneLink";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

function ShootingRange() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Left = () => (
    <>
      <Title>Shooting Range</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <h1>Directions:</h1>
        <p>
          The Iron County Shooting Range is located 15 miles northwest of Cedar
          City. Our range caters to shooters of all levels with a diverse array
          of options. This is an unsupervised range. Use of the Iron County
          Shooting Range is at YOUR OWN RISK and YOUR SAFETY IS YOUR
          RESPONSIBILITY.
        </p>

        <ButtonSingle
          href="/files/departments/parks/shooting-range-reservation.pdf"
          isLink={true}
        >
          Make a Reservation
        </ButtonSingle>
        <p>
          Please email form to:{" "}
          <a href="mailto:sorton@ironcounty.net">sorton@ironcounty.net</a>{" "}
        </p>
        <Map src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10520.346732158841!2d-113.209459!3d37.775033!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80b57aa47703e1cd%3A0x50695a1b56a95cc0!2sShooting%20Range!5e1!3m2!1sen!2sus!4v1694035776262!5m2!1sen!2sus"></Map>
      </Textbox>
      <Textbox title="About the Range">
        <Half>
          <h1>
            <b>
              <u>Amenities</u>
            </b>
          </h1>
          <ul>
            <li>
              300yd Range
              <ul>
                <li>Concrete pads</li>
                <li>Nine concrete shooting benches</li>
                <li>One shooting bench with sunshade awning</li>
              </ul>
            </li>
            <li>
              1000yd Range
              <ul>
                <li>Concrete pads</li>
                <li>Two adjustable metal shooting benches</li>
                <li>Steel AR500 Targets from 200yd to 1000yd</li>
              </ul>
            </li>
            <li>
              Six short range bays
              <ul>
                <li>All six bays have concrete pads</li>
                <li>
                  The three north facing bays are 50yds deep{" "}
                  <ul>
                    <li>
                      Pistols, rifles and shotguns may be used in these bays
                    </li>
                    <li>Two bays have sunshade awnings</li>
                  </ul>
                </li>
                <li>
                  The three south facing bays are 40yds deep
                  <ul>
                    <li>
                      {" "}
                      <b>PISTOLS ONLY</b> on these bays
                    </li>
                    <li>One bay has a sunshade awning</li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              Group Area
              <ul>
                <li>
                  Must be reserved and a reservation fee is required. Call (435)
                  586-5124
                </li>
                <li>
                  Six bays that are 45yds deep
                  <ul>
                    <li>All firearms are allowed on these bays</li>
                  </ul>
                </li>
                <li>The first bay has a long sunshade awning</li>
              </ul>
            </li>
            <li>Two double vault toilets</li>
            <li>Trash Cans</li>
            <li>Emergency satellite phone</li>
            <li>No water or electricity</li>
          </ul>
        </Half>
        <Half>
          <h1>
            <b>
              <u>Rules</u>
            </b>
          </h1>
          <ul>
            <li>
              General Range Rules apply to the entire Iron County Shooting Range
              facility to insure the safety of all visitors and minimize
              activities that detract from an enjoyable and safe shooting
              experience for all.
            </li>
            <li>
              Where firearms are concerned, an abundance of safety and
              neighborly manners are in order. Those that fail to follow safety
              rules will be removed from the premises.
            </li>
            <li>
              Any destruction of public property or littering is a criminal
              offense punishable by fine and /or incarceration. Shooting is not
              allowed in any other areas of the Three Peaks Recreation Area.
            </li>
            <li>
              Children
              <ul>
                <li>
                  Children brought to the range must be fully supervised at all
                  times. All children should have eye and hearing protection at
                  all times. Young ears are sensitive and permanent hearing
                  damage can occur with the noise of a single shot.
                </li>
              </ul>
            </li>
            <li>
              ATVs & Motorcycles
              <ul>
                <li>
                  ATV’s and Motorcycles may be used to go downrange to manage
                  targets or as transportation to toilet facilities, but
                  otherwise cannot not be used in the area for recreational
                  riding. The range has a 5 mph speed limit to avoid excessive
                  noise and raising dust.
                </li>
              </ul>
            </li>
            <li>
              Keeping the Range Clean
              <ul>
                <li>
                  Everyone’s help is needed to keep the range clean. Trash cans
                  are provided. Pick up any trash and spent shotgun hulls and
                  put them in the cans.
                </li>
              </ul>
            </li>
          </ul>
        </Half>
      </Textbox>
      <Textbox title="General Rules">
        <p>
          <ul>
            <li>
              No exploding targets or incendiary ammunition (tannerite, tracer
              rounds)
            </li>
            <li>
              <b>No shooting clay pigeons on the south facing bays</b>
            </li>
            <li>
              Direction of fire is always directly down range. Cross range fire
              is prohibited.
            </li>
            <li>
              Before going downrange to set or change targets, coordinate with
              ALL other shooters at the line to establish a cease fire. Honor
              all cease fires and refrain from handling guns while anyone is
              downrange.
            </li>
            <li>
              All guns not in a case should always be pointed down range or
              straight up and never at anyone along or behind the firing line.
            </li>
            <li>
              When a cease fire is called, ALL guns should be made safe by:
              <ul>
                <li>
                  <b>Semiautomatic guns:</b> Remove the magazine, ensuring the
                  chamber is empty, and lock the slide open.
                </li>
                <li>
                  <b>Bolt action guns:</b> Remove the magazine, ensuring the
                  chamber is empty and leave the bolt open.
                </li>
                <li>
                  <b>Lever action guns:</b> Ensure the magazine is empty, ensure
                  the chamber is empty, and leave the lever down and action
                  open.
                </li>
                <li>
                  <b>Falling block or rolling block guns:</b> Ensure the chamber
                  is empty and leave the action open.
                </li>
                <li>
                  <b>Break barrel guns:</b> Verify the chamber is empty and
                  leave the gun clearly broken open.
                </li>
                <li>
                  <b>Revolvers:</b> For double action revolvers, verify the
                  cylinder is empty and prop the cylinder open. For single
                  action revolvers, verify all chambers of the cylinder are
                  empty and leave the loading gate open.
                </li>
              </ul>
            </li>
            <li>
              Guns should not be handled again until the range is clear and ok
              to fire is agreed upon.
            </li>
          </ul>
        </p>

        <p>
          <b>
            These are standard range rules at all managed shooting events.
            Develop good safety habits and teach others how to be safe at the
            range.
          </b>
        </p>
      </Textbox>
      <Textbox>
        <Map src="https://calendar.google.com/calendar/embed?height=600&wkst=1&ctz=America%2FDenver&bgcolor=%23ffffff&src=c2NkMnFzbTE5YWw3aGVlNmxjdGhodWo0aDRAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&color=%237986CB"></Map>
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Parks and Recreation: Shooting Range</title>
        <meta
          name="description"
          content="Iron County  Parks and Recreation shooting range Page"
        />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/parks/shooting-range"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<ParksSidebar />}
        id="parks-background"
        click={click}
      />
    </>
  );
}

export default ShootingRange;

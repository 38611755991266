import React from "react";
import Carousel from "../../../Components/Carousel/Carousel";

import { NewsroomData } from "./NewsroomData";
import CardLarge from "../../../Components/Card/Card-Large";

function Newsroom() {
  return (
    <>
      <CardLarge {...{ ...NewsroomData[0] }} />
      {/* <CardLarge {...{ ...NewsroomData[1] }} />
      <CardLarge {...{ ...NewsroomData[2] }} /> */}
      <CardLarge {...{ ...NewsroomData[1] }} />
      {/* <Carousel slides={CarouselData} /> */}
    </>
  );
}

export default Newsroom;

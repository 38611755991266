import React from "react";
import { Link } from "react-router-dom";
import "./Card.css";
import ButtonSingle from "../Buttons/Button_Single";
import Image from "../Images/Image";
import Third from "../Sizes/Third";

function CardSmallMultiple(props) {
  return (
    <>
      {props.data.map((card) => {
        return (
          <Third>
            <div className="card-container-text">
              <div className="card-text-fill">
                <h1>{card.title}</h1>
                <p>{card.desc}</p>
                {card.links.map((linkItems) => {
                  if (linkItems.isLink === true) {
                    return (
                      <a target="_blank" href={linkItems.to}>
                        <button className="icon-button">
                          {linkItems.title}
                        </button>
                      </a>
                    );
                  } else {
                    return (
                      <Link to={linkItems.to}>
                        <button className="icon-button">
                          {linkItems.title}
                        </button>
                      </Link>
                    );
                  }
                })}
              </div>
            </div>
          </Third>
        );
      })}
    </>
  );
}

export default CardSmallMultiple;

import React from "react";
import { Link } from "react-router-dom";
import "./Card.css";
import ButtonSingle from "../Buttons/Button_Single";
import Image from "../Images/Image";
import Third from "../Sizes/Third";

function PublicNoticesTable(props) {
  return (
    <>
      <div>
        <div>
          <table className="table-test">
            {/* <thead>
              <tr>
                <th>
                  {" "}
                  <h1>Date</h1>{" "}
                </th>
                <th>
                  {" "}
                  <h1>Description</h1>{" "}
                </th>
                <th>
                  {" "}
                  <h1>Links</h1>{" "}
                </th>
              </tr>
            </thead> */}
            {props.data.map((card) => {
              return (
                <tbody>
                  <>
                    <tr>
                      <td width="10%">
                        <p>{card.title}</p>
                      </td>
                      <td width="70%">
                        <p>{card.desc}</p>
                      </td>
                      <td>
                        {card.links.map((linkItems) => {
                          if (linkItems.isLink === true) {
                            return (
                              <a target="_blank" href={linkItems.to}>
                                <button className="table-button">
                                  {linkItems.title}
                                </button>
                              </a>
                            );
                          } else {
                            return (
                              <Link to={linkItems.to}>
                                <button className="table-button">
                                  {linkItems.title}
                                </button>
                              </Link>
                            );
                          }
                        })}
                      </td>
                    </tr>
                  </>
                </tbody>
              );
            })}
          </table>
        </div>
      </div>
    </>
  );
}

export default PublicNoticesTable;

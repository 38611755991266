import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import EngineeringSidebar from "./EngineeringSidebar";
import Textbox from "../../../Components/Text/Textbox";
import Title from "../../../Components/Text/Title";
import Half from "../../../Components/Sizes/Half";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import ButtonSingle from "../../../Components/Buttons/Button_Single";
import { Helmet } from "react-helmet-async";

function Engineering() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Left = () => (
    <>
      <Title>Engineering</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <Half>
          <h1>What We Do</h1>
          <p>
            The Iron County Engineering Department has responsibility for
            advising County & Planning Commissions, the public, the
            Building/Zoning/Planning Department, the Emergency Management
            Department and the Road Department for issues relating to the County
            Rights of Way, Easements, Public Roads, Water Flow, Flooding,
            Subdivision and Road Standards, Encroachement Permits, Signage and
            Addressing.
          </p>
        </Half>
        <Half>
          <h1>What We Don't Do</h1>
          <p>
            The Engineering Department does not do surveying for private
            property. Please contact a private engineering/surveying firm for
            this.
          </p>
          <p>
            Filed Surveys are kept by the Iron County Recorder's Office and are
            available online using the link below
          </p>
          <ButtonSingle
            href="https://ironcountyut-my.sharepoint.com/personal/ironits_ironcounty_net/_layouts/15/onedrive.aspx?id=%2Fpersonal%2Fironits%5Fironcounty%5Fnet%2FDocuments%2FRecorders%2FFiled%20Survey%20Plats&ga=1"
            isLink={true}
          >
            Filed Surveys
          </ButtonSingle>
        </Half>
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Engineering</title>
        <meta name="description" content="Iron County engineering Page" />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/engineering"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<EngineeringSidebar />}
        id="engineering-background"
        click={click}
      />
    </>
  );
}

export default Engineering;

import React from "react";
import AccordionSidemenu from "../../../Components/AccordionMenu/AccordionSidemenu";
import "../../../Components/Sidebar/Sidebar.css";
import HoursContact from "../../../Components/Text/HoursContact";
import TextWebLink from "../../../Components/Links/TextWebLink";
import PhoneLink from "../../../Components/Links/PhoneLink";

function ResourcesSideBar() {
  const navItems = [
    {
      title: "About",
      to: "/departments/natural-resources",
      isLink: false,
    },
    {
      title: "Local & Regional Projects",
      to: "/departments/natural-resources/projects",
      isLink: false,
    },
    {
      title: "Resource Management Plan",
      to: "/files/departments/nr/nr-plan.pdf",
      isLink: true,
    },
  ];

  return (
    <>
      <div className="nav-menu-side">
        <HoursContact>
          <p>
            Mike Worthen, Natural Resource Management Specialist
            <br />
            <TextWebLink link="https://goo.gl/maps/n4UaZjbAxr5HBvz78">
              82 N 100 E #102 Cedar City, Utah 84720
            </TextWebLink>
            <br />
            Monday - Friday 8:00 A.M. - 5:00 P.M.
            <br />
            <PhoneLink>435-865-5357</PhoneLink>
          </p>
        </HoursContact>
        <br />
        <AccordionSidemenu nav={navItems} id="department-sidebar" />
      </div>
    </>
  );
}

export default ResourcesSideBar;

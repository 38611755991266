import React, { useState } from "react";
import "../Departments.css";
import AssessorSidebar from "./AssessorSidebar";
import Textbox from "../../../Components/Text/Textbox";
import PhoneLink from "../../../Components/Links/PhoneLink";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import TextWebLink from "../../../Components/Links/TextWebLink";
import Title from "../../../Components/Text/Title";
import ButtonIcons from "../../../Components/Buttons/Button_Icons";
import Youtube from "../../../Components/Links/Youtube";
import Subtitle from "../../../Components/Text/Subtitle";
import CardFAQ from "../../../Components/Card/CardFAQ";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

function YourAssessment() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const questions = [
    {
      question: "What does the Assessor's Office Do?",
      answer: `Utah Law requires the Assessor to list and value all property subject to ad valorem taxation on an assessment roll each year. "Ad valorem" means that all property should be taxed "according to value". In Utah, all property, except primary residential, is appraised and taxed at 100% of fair market value. The Iron County Assessor’s Office values approximately 43,000 parcels of property. The Utah State Tax Commission values all transportation, utility and mining properties.`,
    },
    {
      question: "What does the Assessor's Office Not Do?",
      answer:
        "The Assessor does not make the tax laws that affect property owners. The Constitution of the State of Utah, as adopted by the voters, provides the basic framework for taxation. Tax laws are made by the Utah Legislature. The Utah Tax Commission sets the rules and regulations for assessment. Tax dollars are levied by taxing entities, such as the school board, cities, and counties. \\n \\n Tax dollars are collected by the Iron County Treasurer. \\n \\n The Assessor’s Office has nothing to do with the total amount of tax collected. The Assessor’s primary responsibility is to find the fair market value of your property so that you pay only your fair share of the tax. The amount of tax you pay is determined by a tax rate applied to your property’s taxable value. The tax rate is the basis for the budget needed or demanded by the voters to provide for services such as schools, roads, snow removal, law enforcement, libraries, health programs, TV relay stations, weed control, building inspection, and so on. Tax rates are simply those rates that will provide funds to pay for those services.",
    },
    {
      question: "How are Taxes Calculated?",
      answer:
        "Tax rates are based on bond issues and the budgets of the taxing entities. Tax monies collected are used to pay for those services previously listed and any others demanded by taxpayers. To calculate the taxes on your property, the taxable value must be multiplied by the appropriate tax rate. If, as an example, you have $10,000 of taxable value and the tax rate is .012345 you would pay $10,000 x .012345 or $123.45. If your home is valued at $100,000 and is eligible for a primary exemption, you would calculate your taxes as follows: \\n \\n $100,000 Fair Market Value \\n x 55% Primary Exemption \\n $55,000 Taxable Value \\n x .012345 Tax Rate \\n \\n = $678.98 Total Taxes Due  ",
    },
    {
      question: "Why do Taxes Increase?",
      answer: `When the people vote additional taxes, an individual’s property tax bill will increase. Also, when market value increases, naturally, so does the appraised value. For instance, if you were to make improvements to your existing property – add a garage, an additional room, or a swimming pool – the "fair market value" would increase. The Assessor has not created the value. People create value by their transactions in the market place. The Assessor has the legal and moral responsibility to study those transactions and appraise your property accordingly.`,
    },
    {
      question: "2021 Tax Rates",
      answer:
        "Parowan .009575 \\n Paragonah .007717 \\n Kanarraville .008679 \\n Enoch .008554 \\n Brian Head .009925 \\n Cedar City .009193 \\n Cedar Highlands .010102 \\n County Outside .008472 \\n County Outside Water .008946",
    },
    {
      question: "How are Values Determined?",
      answer: `To arrive at "fair market value" for your property, the Assessor must know what "willing sellers" and "willing buyers" are doing in the marketplace. The Assessor’s Office must also keep current on the costs of construction in the area and be aware of any changes in zoning, financing, and economic conditions that may affect property values. \\n \\n The Assessor uses the three nationally recognized appraisal approaches to value: cost, income, and market. Data obtained using these approaches is correlated into a final value by an appraiser in the Assessor’s Office.`,
    },
    {
      question: "What is Fair Market Value?",
      answer: `Fair market value is defined by Utah Code as: "…the amount at which property would change hands between a willing buyer and a willing seller, neither being under any compulsion to buy nor sell with both having reasonable knowledge of the relevant facts…" \\n \\n Finding the "fair market value" of property involves discovering the price most people would pay for it in its present condition. However, the Assessor has to find what this value is for every property in Iron County every year. The Assessor’s job does not stop there. The Assessor’s Office must immediately begin gathering sales and other data for future years as the market constantly changes.`,
    },
    {
      question: "What Should I Do If I Disagree With a Valuation?",
      answer:
        "As a taxpayer, you have a responsibility to furnish correct information on your property to the Assessor’s Office. They rely upon you to provide accurate data and information. You are entitled to question values placed on your property. If your opinion of the value of your property differs from the Assessor’s, by all means go to the office and discuss the matter. Be prepared to show evidence that the valuation is incorrect. The staff will be glad to answer your questions about the assessment, explaining how it was done. If, after discussing the matter with the Assessor, a difference of opinion still exists, you may appeal your assessment to the Iron County Board of Equalization. If the Board, after hearing your petition, agrees with the Assessor, you may still appeal this decision to the Utah State Tax commission. If the Commission agrees with the Board and the Assessor, you can then plead your case before the courts should you choose to do so.",
    },
    {
      question: "Dates to Remember",
      answer:
        "January 1st – Property is assessed as to condition and ownership as of this date \\n May 22nd – Assessor’s deadline for having all properties appraised and on the Assessment Roll \\n July 22nd – Notices of Valuation and Taxes are sent to taxpayers by the Iron County Auditor \\n August – Board of Equalization opens for appeals of value \\nSeptember 15th – The deadline for all appeals to Board of Equalization (Appeals must be to Auditor by 5:00 P.M. this date) \\n November 1st – Tax notices sent to the taxpayers by the Iron County Treasurer \\n November 30th – Property taxes are due and payable by this date and become delinquent thereafter",
    },
  ];
  const Left = () => (
    <>
      <Title>Understanding Your Assessment</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <section>
          <h1>Policy Statement</h1>
          <p>
            Good information is one fundamental aspect of good government. The
            information on this page has been compiled to give you, the
            taxpayer, an overview of the Assessor’s Office, how our work affects
            you, and what some of your rights and responsibilities are. Please
            contact my office whenever you have questions or need further
            information about your property valuation, taxes, or the laws that
            govern them. Thank you for your continued support. We are here to
            serve you.
          </p>
        </section>
        <section>
          <Subtitle>FAQs</Subtitle>
          To express your concerns effectively, you must understand the two
          parts of the property tax system:
          <li>Taxation</li>
          <li>Valuation</li>
          This information has been designed for Iron county property owners to
          answer your most commonly asked questions about the property tax
          system:
        </section>
      </Textbox>
      <br />
      <CardFAQ questions={questions}></CardFAQ>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Assessor: Your Assessment</title>
        <meta
          name="description"
          content="Iron County Assessors your assessment Page"
        />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/assessor/your-assessment"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<AssessorSidebar />}
        id="assessor-background"
        click={click}
      />
    </>
  );
}

export default YourAssessment;

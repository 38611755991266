import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import CJCSidebar from "./CJCSidebar";
import Title from "../../../Components/Text/Title";
import Textbox from "../../../Components/Text/Textbox";
import TextWebLink from "../../../Components/Links/TextWebLink";
import CardAccordion from "../../../Components/Card/CardAccordion";
import EmailLink from "../../../Components/Links/EmailLink";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

function DonateCJC() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Left = () => (
    <>
      <Title>Donate</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <p>
          The fundraising arm of the Children's Justice Center is the Friends of
          the Iron County Children's Justice Center. It is a volunteer
          organization and 501c3 that cares deeply for the welfare of children
          in our community. The members donate their time, money, and talents to
          help. The Friends Board also:
        </p>
        <p>
          <ul>
            <li>Hosts annual fundraising events</li>
            <ul>
              <li>CJC 5k Fun Run </li>
              <li>CJC Golf Tournament </li>
              <li>Dancing With the Community Stars</li>
            </ul>
            <li>Raises funds to help with building improvements</li>
            <li>
              Looks for ways to support the mission of the Iron County
              Children’s Justice Center
            </li>
          </ul>
        </p>
        <p>
          If you are interested in becoming a Friends Board member or would like
          more information, you may email us at{" "}
          <EmailLink>cjc.group@ironcounty.net</EmailLink> and we will send your
          information to the Friends Board.
        </p>
        <h1>How to make a tax-deductible donation:</h1>
        <p>
          The Friends of the Iron County Children’s Justice Center is a
          501(c)(3) non-profit, charitable organization established to support
          the Iron County Children’s Justice Center. All donations are used
          locally to support the services of the Center. Our 501(c)(3) # is
          71-0890569 and our Charitable Solicitation Permit number is
          6964226-CHAR.
        </p>
        <p>
          Donations made to the Friends of the Iron County Children’s Justice
          Center are tax-deductible as allowed by law. All donors will receive a
          receipt/letter for tax purposes.
        </p>
        <h1>Donation Options:</h1>
        <CardAccordion title="Mail-in Contribution">
          <ul className="no-dots">
            <li>You can mail your cash donation to:</li>
            <li>Iron County Children’s Justice Center</li>
            <li>545 S. 225 E.</li>
            <li>Cedar City, Utah 84720</li>
          </ul>
        </CardAccordion>
        <CardAccordion title="Wish List Donation Items">
          <p>
            We are in need of many items here at the Children’s Justice Center.
            If your neighborhood, scout groups, school groups, families are in
            need of a service project, we would be ever grateful for your
            assistance! The items listed below we need for the children that
            come to our center; Snacks and drinks, supplies for bathrooms,
            kitchen and cleaning. We welcome gift cards from Costco, Wal-Mart,
            Target etc. in any denomination so we can purchase necessary
            supplies as needed. Your assistance is always appreciated!
          </p>
          <h3>Gift Cards</h3>
          <ul>
            <li>Walmart</li>
            <li>Gas Cards</li>
            <li>Target</li>
            <li>Staples</li>
            <li>Amazon</li>
          </ul>
          <h3>Beverages/Snack Foods</h3>
          <p>(pre-packaged, individual servings)</p>
          <ul>
            <li>Granola Bars</li>
            <li>Chips</li>
            <li>Nuts</li>
            <li>Fruit Snacks & Fruit Roll Ups</li>
            <li>Beef Jerky</li>
            <li>String Cheese</li>
            <li>Yogurt</li>
            <li>Crackers</li>
            <li>Juice Boxes, Capri Sun</li>
            <li>Water bottles</li>
          </ul>
          <h3>Cleaning Supplies</h3>
          <ul>
            <li>Lysol/Clorox disinfectant wipes</li>
          </ul>
          <h3>Kitchen/Restroom Supplies</h3>
          <ul>
            <li>Toilet Paper</li>
            <li>Paper Towels</li>
            <li>Paper Cups</li>
            <li>Plastic Forks, Spoons, Knives</li>
            <li>Dinner and Dessert Size Paper Plates</li>
            <li>Kitchen/Garden Trash Bags</li>
          </ul>
          <h3>Office Supplies</h3>
          <ul>
            <li>Copy Paper, White</li>
            <li>8 x 11 Legal Pads</li>
          </ul>
        </CardAccordion>
        <CardAccordion title="Online Contribution">
          <TextWebLink link="https://www.clover.com/pay-widgets/b58df793-7c76-4042-8f04-59bc4521f340">
            Click here to Donate
          </TextWebLink>
        </CardAccordion>
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Children's Justice Center: Donate</title>
        <meta
          name="description"
          content="Iron County Children's Justice Center Donate Page"
        />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/cjc/donate"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<CJCSidebar />}
        id="cjc-background"
        click={click}
      />
    </>
  );
}

export default DonateCJC;

import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import AuditorSideBar from "./AuditorSideBar";
import Title from "../../../Components/Text/Title";
import CardFAQ from "../../../Components/Card/CardFAQ";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

function FAQ() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const questions = [
    {
      question: "What is a Qualified Real Property?",
      answer:
        "A qualified real property is real property subject to appeal in the current year and: Was appealed in the previous year and had its value lowered AND Was NOT improved (e.g. renovations, additions) in the previous year ",
    },
    {
      question: "What is the Inflation Adjusted Value?",
      answer: `The county Assessor will look at the "median property value change" for real property in the same class and market area as your property (e.g. if the property is a residence, it may be the other homes in your neighborhood or town). This is a percentage that will be applied to the "final assessed value" to arrive at the inflation adjusted value.`,
    },
    {
      question: "What Happens if My Appeal Involves a Qualfied Real Property?",
      answer: `The county Auditor will ask the county Assessor to determine the "inflation adjusted value". Depending on what value is asserted by either the taxpayer or the county Assessor, the burden of proof may change.`,
    },
    {
      question: "What is the Final Assessed Value?",
      answer: `This is the amount your value was lowered to in last year’s appeal. So, the median property value change (for your class of property in your market area) plus the final assessed value is the inflation adjusted value.`,
    },
    {
      question:
        "If I Agree with the Inflation Adjusted Value (and the County Assessor Believes the Value Should Be Higher), Will this Change How I Should Prepare for My Appeal?",
      answer: `No. In a valuation appeal, both parties are arguing an opinion of value. Even if the inflation adjusted value is presumed most correct, the county BOE may agree with the county Assessor, or determine a third value based on evidence presented by both parties.`,
    },
    {
      question:
        "What Effect Will the Inflation Adjusted Value Have on the Burden of Proof in My Appeal?",
      answer: `The inflation adjusted value will be considered the most correct value by the county Board of Equalization (BOE), and the county Assessor must prove their assertion of fair market value if they believe the property is equal to or greater than the inflation adjusted value.`,
    },
    {
      question: "What if My Property is Not a Qualified Real Property?",
      answer: `The burden of proof functions normally. You carry the burden of proof unless the county Assessor asserts a value higher than the one they originally assessed.`,
    },
    {
      question:
        "What Happens if I Believe My Value is Below the Inflation Adjusted Value?",
      answer: `Then you carry the burden of proof. The onus shifts back to you to prove your lower value is the fair market value.`,
    },
    {
      question: "What if I Have Further Questions?",
      answer: `As well as the county, you may contact Alex at the Property Tax Division at the Utah State Tax Commission at (801) 297-3631.`,
    },
  ];

  const Left = () => (
    <>
      <Title>Board of Equalization Valuation Appeals</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <br />
      <CardFAQ questions={questions} />
    </>
  );

  return (
    <>
      <Helmet>
        <title>Auditor: Frequently Asked Questions</title>
        <meta name="description" content="Iron County Auditor FAQ Page" />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/auditor/faq"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<AuditorSideBar />}
        id="auditor-background"
      />
    </>
  );
}

export default FAQ;

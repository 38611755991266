import React from "react";

import ButtonIcons from "../../Components/Buttons/Button_Icons";
import { BusinessData } from "./BusinessData";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";

function Business() {
  return (
    <div className="hero-container center">
      <ButtonIcons buttons={BusinessData} />
    </div>
  );
}

export default Business;

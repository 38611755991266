import React, { useState } from "react";
import "../Departments.css";
import RecordersSidebar from "./RecordersSidebar";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import Textbox from "../../../Components/Text/Textbox";
import Title from "../../../Components/Text/Title";
import TextWebLink from "../../../Components/Links/TextWebLink";
import Map from "../../../Components/Maps/Map";
import ButtonSingle from "../../../Components/Buttons/Button_Single";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

function Recorder() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Left = () => (
    <>
      <Title>Recorder & Surveyor</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <p>
          The duties of the County Recorder are outlined in the Utah State Code.
          Documents pertaining to real estate such as deeds, mortgages, liens,
          plats, etc are recorded and indexed for public access. Ownership plats
          are also maintained by the Recorder’s Office. Plats identify
          ownership, acreage, parcel address, taxing units and owners address.
          This information is used in the assessing and collecting for property
          taxes.
          <table>
            <tr>
              <th style={{ width: "50%" }}>
                <h3>What We Do</h3>
              </th>
              <th>
                <h3>What We Don't Do</h3>
              </th>
            </tr>
            <tr>
              <td>
                <ul>
                  <li>
                    Record, Store and retrieve land documents in the public
                    record
                  </li>
                  <li>
                    Assist the public in locating real property parcels on
                    ownership plats
                  </li>
                  <li>Assist the public in locating records</li>
                  <li>
                    Record Military Discharges and provide certified copies of
                    the documents at no charge
                  </li>
                  <li>Sell copies of documents and plats to the public</li>
                  <li>
                    Provide certified copies of documents for a fee. These may
                    be recorded in the office of any other County Recorder in
                    the State. (Utah Code 17-21-4, 57-3-4)
                  </li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>Prepare legal Documents</li>
                  <li>Interpret legal documents</li>
                  <li>Provide engineering services for the public</li>
                  <li>Give legal advice</li>
                  <li>Perform title searches for the public</li>
                </ul>
              </td>
            </tr>
          </table>
        </p>
      </Textbox>
      <Textbox title="Iron County Plat Map">
        <Map src="https://irongis.ironcounty.net/portal/apps/webappviewer/index.html?id=7a0b58d7d4f44aa8a58bc046cf7d91a3" />
        <ButtonSingle
          href="https://irongis.ironcounty.net/portal/apps/webappviewer/index.html?id=7a0b58d7d4f44aa8a58bc046cf7d91a3"
          isLink={true}
        >
          Open in New Window
        </ButtonSingle>
      </Textbox>

      <Textbox title="Fees">
        <div className="hero-child">
          <p>
            <h3>
              <u>
                <b>Fee Schedule</b>
              </u>
            </h3>
            <li>
              Effective May 14th, 2019.{" "}
              <TextWebLink link="https://le.utah.gov/xcode/Title17/Chapter21/17-21-S18.5.html?v=C17-21-S18.5_2014040320150701">
                Utah Code 17-21-18.5
              </TextWebLink>
            </li>
            <br />

            <table style={{ width: "100%", border: "solid" }}>
              <tr>
                <th colSpan={2}>Document Recording Fees</th>
              </tr>

              <tr>
                <td style={{ width: "80%" }}>Standard Document Fee</td>
                <td>$40.00</td>
              </tr>
              <tr>
                <td>Each Lot/Description over 10</td>
                <td>$2.00</td>
              </tr>
              <tr>
                <td>Subdivision Plat each page</td>
                <td>$50.00</td>
              </tr>
              <tr>
                <td>Each Lot/Unit on a Subdivision Plat</td>
                <td>$2.00</td>
              </tr>
            </table>

            <table style={{ width: "100%", border: "solid" }}>
              <tr>
                <th colSpan={2}>Copy Fees</th>
              </tr>

              <tr>
                <td style={{ width: "80%" }}>Copy of recorded document</td>
                <td>$1.00</td>
              </tr>
              <tr>
                <td>Xerox copies (per copy)</td>
                <td>10¢</td>
              </tr>
              <tr>
                <td>Certificate under seal</td>
                <td> $5.00</td>
              </tr>
            </table>
            <table style={{ width: "100%", border: "solid" }}>
              <tr>
                <th colSpan={2}>Document Recording Fees</th>
              </tr>

              <tr>
                <td style={{ width: "80%" }}>Ownership Plat, 18″x18″</td>
                <td>$3.00</td>
              </tr>
              <tr>
                <td>Official Plat, 24″x36″</td>
                <td>$5.00</td>
              </tr>
              <tr>
                <td>County Map</td>
                <td>$5.00</td>
              </tr>
            </table>
          </p>
        </div>
      </Textbox>
      <Textbox title="Checklist to Assist Individuals Recording a Document">
        <p>
          <ul>
            <h3>Each Document must:</h3>
            <li>Contain original signatures and notary</li>
            <li>
              Contain a legible legal description of the property and the serial
              number
            </li>
            <li>
              If the document is conveying property, the grantee’s mailing
              address must be included
            </li>
            <br />
            <b>
              NOTE: The recording stamp requires a space 2.5 inches down and 4.5
              inches across on the upper right corner of the first page, and a
              margin of 1 inch at the bottom of each successive page.
            </b>
          </ul>
        </p>
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Recorders</title>
        <meta name="description" content="Iron County Recorders Page" />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/recorders"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<RecordersSidebar />}
        id="recorder-background"
        click={click}
      />
    </>
  );
}

export default Recorder;

import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import PlanningSidebar from "./PlanningSidebar";
import Title from "../../../Components/Text/Title";
import Aidaform from "../../../Components/Aidaform/Aidaform";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

function RequestSketch() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Left = () => (
    <>
      <Title>Request Sketch </Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>

      <Aidaform form="https://ironcounty.aidaform.com/request-sketch-meeting" />
    </>
  );

  return (
    <>
      <Helmet>
        <title>Planning: Request a Sketch</title>
        <meta
          name="description"
          content="Iron County Planning request a sketch Page"
        />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/planning/request-sketch"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<PlanningSidebar />}
        id="engineering-background"
        click={click}
      />
    </>
  );
}

export default RequestSketch;

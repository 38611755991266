import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../Images/Navbar/small-logo.png";
import Dropdown from "./Dropdown";
import { submenuItems } from "./submenuItems";
import "../Navbar/Navbar.css";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";

const Navbar = () => {
  const [click, setClick] = useState(false);
  // const [small, setSmall] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [dropdownD, setDropdownD] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  const onMouseEnter = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
  };

  const onMouseLeave = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(false);
    }
  };

  const onMouseEnterD = () => {
    if (window.innerWidth < 960) {
      setDropdownD(false);
    } else {
      setDropdownD(true);
    }
  };

  const onMouseLeaveD = () => {
    if (window.innerWidth < 960) {
      setDropdownD(false);
    } else {
      setDropdownD(false);
    }
  };

  // const changeSize = () => {
  //   if (window.scrollY >= 1) {
  //     setSmall(true);
  //   } else {
  //     setSmall(false);
  //   }
  // };

  // window.addEventListener("scroll", changeSize);

  return (
    //    Navbar Logo
    <>
      {/* <nav className={small ? "navbar resize" : "navbar"}> */}
      <nav className="navbar">
        <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
          <img src={logo} alt="logo" id="logo" />
        </Link>

        {/*  */}

        <div className="menu-icon" onClick={handleClick}>
          {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
        </div>

        {/*  */}

        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li
            className="nav-item"
            onMouseEnter={onMouseEnterD}
            onMouseLeave={onMouseLeaveD}
          >
            <Link
              to="/Departments"
              className="nav-links"
              onClick={closeMobileMenu}
            >
              Departments <i className="fas fa-caret-down" />
            </Link>
            {dropdownD && <Dropdown list={submenuItems[0]} />}
          </li>

          <li
            className="nav-item"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            <Link
              to="/business"
              className="nav-links"
              onClick={closeMobileMenu}
            >
              Business <i className="fas fa-caret-down" />
            </Link>
            {dropdown && <Dropdown list={submenuItems[1]} />}
          </li>

          {/*  */}

          <li className="nav-item">
            <a
              href="https://visitcedarcity.com/"
              className="nav-links"
              onClick={closeMobileMenu}
              target="_blank"
              rel="noreferrer"
            >
              Visitors
            </a>
          </li>

          {/*  */}

          <li className="nav-item">
            <Link
              to="/departments/clerk/public-notices"
              className="nav-links"
              onClick={closeMobileMenu}
            >
              Public Notices
            </Link>
          </li>

          {/*  */}

          <li className="nav-item">
            <Link
              to="/departments/contact-us"
              className="nav-links"
              onClick={closeMobileMenu}
            >
              Contact us
            </Link>
          </li>
        </ul>
        {/* <div className="hide">
          <script
            async
            src="https://cse.google.com/cse.js?cx=9474916a429ab476d"
          ></script>
          <div class="gcse-search"></div>
        </div> */}
      </nav>
    </>
  );
};

export default Navbar;

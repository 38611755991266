import React from "react";
import "../../../Components/Sidebar/Sidebar.css";
import AccordionSidemenu from "../../../Components/AccordionMenu/AccordionSidemenu";
import TextWebLink from "../../../Components/Links/TextWebLink";
import HoursContact from "../../../Components/Text/HoursContact";
import PhoneLink from "../../../Components/Links/PhoneLink";

function ClerkSidebar() {
  const navItems = [
    {
      title: "About",
      to: "/departments/clerk",
      isLink: false,
    },
    {
      title: "Business License",
      to: "/departments/clerk/business-license",
      isLink: false,
    },
    {
      title: "Commission Meetings",
      to: "/departments/commission/commission-archive",
      isLink: false,
    },
    {
      title: "Elections Information",
      sub: [
        {
          title: "Election Homepage",
          to: "/departments/clerk/elections",
        },
        {
          title: "Ballot Trax",
          to: "https://ballottrax.utah.gov/voter/",
          isLink: true,
        },

        {
          title: "Candidate Information",
          to: "/departments/clerk/candidate-information",
        },
        {
          title: "Candidate Financial Reports",
          to: "/departments/clerk/financial-reports",
        },
        {
          title: "Primary Notice of Election",
          to: "https://www.utah.gov/pmn/files/1113989.pdf",
          isLink: true,
        },

        {
          title: "Results",
          to: "/departments/clerk/election-results",
        },

        // {
        //   title: "Democratic Sample Ballot",
        //   to: "/files/departments/clerk/elections/democratic-sample-ballot.pdf",
        //   isLink: true,
        // },
        // {
        //   title: "2024 Notice of Election",
        //   to: "/files/departments/clerk/elections/2024-election-notice.pdf",
        //   isLink: true,
        // },
      ],
    },
    {
      title: "Make Online Payments",
      to: "https://secureinstantpayments.com/sip/cart/event.php?EID=2879",
      isLink: true,
    },
    {
      title: "Marriage License",
      to: "/departments/clerk/marriage-license",
      isLink: false,
    },
    {
      title: "Passport",
      to: "/departments/clerk/passport",
      isLink: false,
    },

    {
      title: "Property Surplus (Current)",
      to: "https://ironcountyut-my.sharepoint.com/:x:/g/personal/jmcdonald_ironcounty_net/ETXItaK34TxJqb5VDVSzYvoBpoQIPTcl9c9DJIsYeHItLA?rtime=DcOItmGf20g",
      isLink: true,
    },
    {
      title: "Public Notices",
      to: "/departments/clerk/public-notices",
      isLink: false,
    },
    {
      title: "Solid Waste Payments",
      to: "https://secureinstantpayments.com/sip/reports/account_selector.php?FromPopup=1&Merchant=27740&AddrReq=e&Account=",
      isLink: true,
    },
    {
      title: "Utility Billing",
      to: "/departments/clerk/utility-billing",
      isLink: false,
    },
  ];

  return (
    <>
      <div className="nav-menu-side">
        <HoursContact>
          <p>
            Jon Whittaker, County Clerk
            <br />
            PO Box 429
            <br />
            <TextWebLink link="https://goo.gl/maps/dWeYhAwocsaNHEyT9">
              68 S 100 E Parowan, UT 84761
            </TextWebLink>
            <br />
            Monday - Friday 8:00 A.M. - 5:00 P.M.
            <br />
            <PhoneLink>435-477-8340</PhoneLink>
          </p>
        </HoursContact>
        <br />

        <AccordionSidemenu nav={navItems} />
      </div>
    </>
  );
}

export default ClerkSidebar;

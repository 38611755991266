import React from "react";
import AccordionSidemenu from "../../../Components/AccordionMenu/AccordionSidemenu";
import "../../../Components/Sidebar/Sidebar.css";
import TextWebLink from "../../../Components/Links/TextWebLink";
import HoursContact from "../../../Components/Text/HoursContact";
import PhoneLink from "../../../Components/Links/PhoneLink";

function ParksSideBar() {
  const navItems = [
    {
      title: "About",
      to: "/departments/parks",
      isLink: false,
    },
    {
      title: "Make a Reservation",
      to: "/departments/parks/reservations",
      isLink: false,
    },
    {
      title: "Five Mile",
      to: "/departments/parks/five-mile",
      isLink: false,
    },

    {
      title: "Shooting Range",
      to: "/departments/parks/shooting-range",
      isLink: false,
    },
    {
      title: "Three Peaks",
      to: "/departments/parks/three-peaks",
      isLink: false,
    },
    {
      title: "Wood Ranch",
      to: "/departments/parks/wood-ranch",
      isLink: false,
    },
    {
      title: "Trails",
      to: "/departments/parks/trails",
      isLink: false,
    },
  ];

  return (
    <>
      <HoursContact>
        <p>
          Maria Twitchell, Director of Cedar City-Brian Head Tourism Bureau
          <br />
          <TextWebLink link="https://goo.gl/maps/cytmXwEYEreYC2PU9">
            581 N Main Street, Cedar City, UT 84721
          </TextWebLink>
          <br />
          Monday – Friday 8:30 A.M. – 5:00 P.M.
          <br />
          <PhoneLink>435-586-5124</PhoneLink>
          <br />
          <TextWebLink link="https://visitcedarcity.com/">
            visitcedarcity.com
          </TextWebLink>
        </p>
      </HoursContact>
      <br />
      <div className="nav-menu-side">
        <AccordionSidemenu nav={navItems} id="department-sidebar" />
      </div>
    </>
  );
}

export default ParksSideBar;

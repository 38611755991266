import React from "react";
import "../../../Components/Sidebar/Sidebar.css";
import AccordionSidemenu from "../../../Components/AccordionMenu/AccordionSidemenu";
import TextWebLink from "../../../Components/Links/TextWebLink";
import HoursContact from "../../../Components/Text/HoursContact";
import PhoneLink from "../../../Components/Links/PhoneLink";

function AssessorSidebar() {
  const navItems = [
    {
      title: "About",
      to: "/departments/assessor",
      isLink: false,
      sub: null,
    },
    {
      title: "Greenbelt Act Information",
      to: "/departments/assessor/greenbelt-act",
      sub: null,
    },
    {
      title: "Online Forms & Questionnaires",
      sub: [
        {
          title: "Standard Residential Questionnaire",
          to: "https://docs.google.com/forms/d/e/1FAIpQLSeY-SUeKjmWwFQ09kBZfbNlgv2ebJ6PQJ873bjYWfDkpXvBCg/viewform",
          isLink: true,
        },
        {
          title: "Commercial/Industrial Building Sales/Rental Questionnaire",
          to: "https://docs.google.com/forms/d/e/1FAIpQLSeY-SUeKjmWwFQ09kBZfbNlgv2ebJ6PQJ873bjYWfDkpXvBCg/viewform",
          isLink: true,
        },
        {
          title: "Storage Unit Questionnaire",
          to: "https://docs.google.com/forms/d/e/1FAIpQLSeD6B490wCxMYARDpQgDoF-72i5W6BO6qNyEfdBakVDRw6XXA/viewform",
          isLink: true,
        },
        {
          title: "Application for Residential Property Exemption",
          to: "/departments/assessor/property-exemption-form",
          isLink: false,
        },
      ],
    },
    {
      title: "Personal Property Information",
      to: "/departments/assessor/personal-property",
      sub: null,
    },
    {
      title: "Recorder Property Accounts",
      to: "https://eagleweb.ironcounty.net/eaglesoftware/web/splash.jsp",
      isLink: true,
      sub: null,
    },
    {
      title: "Understanding Your Assessment",
      to: "/departments/assessor/your-assessment",
      sub: null,
    },
  ];

  return (
    <>
      <HoursContact>
        <p>
          <b>Assessor's Office</b>
          <br />
          Karsten Reed, Iron County Assessor
          <br />
          PO Box 537 Parowan, UT 84761
          <br />
          Monday - Friday 8:00 A.M. - 5:00 P.M.
          <br />
          <PhoneLink>435-477-8310</PhoneLink>
          <br />
          <br />
          <b>Motor Vehicle - Parowan Office</b>
          <br />
          Temporarily Closed
          {/* <TextWebLink link="https://goo.gl/maps/dWeYhAwocsaNHEyT9">
            68 S 100 E Parowan, UT 84761
          </TextWebLink>
          <br />
          <PhoneLink>435-477-8320</PhoneLink>
          <br />
          Monday - Thursday 10:30 A.M. - 5:00 P.M. */}
          <br />
          <br />
          <b>Motor Vehicle - Cedar City Office</b>
          <br />
          <TextWebLink link="https://goo.gl/maps/iwpEbDjqgh65FuKw5">
            82 N 100 E #201 Cedar City, UT 84720
          </TextWebLink>
          <br />
          <PhoneLink>435-865-5360</PhoneLink>
          <br />
          Monday - Thursday 7:00 A.M. - 6:00 P.M.
          <br />
        </p>
      </HoursContact>
      <br />
      <AccordionSidemenu nav={navItems} />
    </>
  );
}

export default AssessorSidebar;

export const DepartmentsData = [
  {
    title: "Assessor",
    to: "/departments/assessor",
    isLink: false,
  },
  {
    title: "Attorney",
    to: "/departments/attorney",
    isLink: false,
  },
  {
    title: "Auditor",
    to: "/departments/auditor",
    isLink: false,
  },
  {
    title: "Building",
    to: "/departments/building",
    isLink: false,
  },
  {
    title: "Children's Justice Center",
    to: "/departments/cjc",
    isLink: false,
  },
  {
    title: "Clerk",
    to: "/departments/clerk",
    isLink: false,
  },
  {
    title: "Commission",
    to: "/departments/commission",
    isLink: false,
  },
  {
    title: "Emergency Management",
    to: "/departments/emergency-management",
    isLink: false,
  },
  {
    title: "Engineering",
    to: "/departments/engineering",
    isLink: false,
  },
  {
    title: "Event Center / Fair",
    to: "https://ironcountyfair.net/",
    isLink: true,
  },

  {
    title: "Fire",
    to: "/departments/fire",
    isLink: false,
  },
  {
    title: "Fleet",
    to: "/departments/fleet",
    isLink: false,
  },

  {
    title: "HR",
    to: "/departments/hr",
    isLink: false,
  },

  {
    title: "IT",
    to: "/departments/it",
    isLink: false,
  },
  {
    title: "Justice Court",
    to: "/departments/justice-court",
    isLink: false,
  },
  {
    title: "Natural Resources",
    to: "/departments/natural-resources",
    isLink: false,
  },
  {
    title: "Parks & Recreation",
    to: "/departments/parks",
    isLink: false,
  },
  {
    title: "Planning",
    to: "/departments/planning",
    isLink: false,
  },
  {
    title: "Recorders",
    to: "/departments/recorders",
    isLink: false,
  },
  {
    title: "Roads",
    to: "/departments/roads",
    isLink: false,
  },
  {
    title: "Senior Center",
    to: "/departments/senior-center",
    isLink: false,
  },
  {
    title: "Solid Waste",
    to: "/departments/solid-waste",
    isLink: false,
  },
  {
    title: "Treasurer",
    to: "/departments/treasurer",
    isLink: false,
  },
  {
    title: "Weeds",
    to: "/departments/weeds",
    isLink: false,
  },
];

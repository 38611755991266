import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import RoadSidebar from "./RoadSidebar";
import Title from "../../../Components/Text/Title";
import Textbox from "../../../Components/Text/Textbox";
import TextWebLink from "../../../Components/Links/TextWebLink";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

function Roads() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Left = () => (
    <>
      <Title>Road Department</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <p>
          The main responsibilities of the Road Department are maintaining the
          968 miles of road in Iron County. As of June of 2013 Iron County has
          224 miles of paved, 453 miles of gravel and 291 miles of dirt roads.
          The Roads Department is also responsible for snow removal during the
          winter months.
        </p>
      </Textbox>
      <Textbox title="Service Requests">
        <p>
          Service Request is a way for the general public to submit
          non-emergency problems or requests for service in their community.
        </p>
        <p>
          For service requests, or to report road or sign damage, please fill
          out the{" "}
          <TextWebLink link="https://survey123.arcgis.com/share/ce4f372f3ad64ee8966dfe37485142bf">
            Service Request form
          </TextWebLink>
        </p>
      </Textbox>
      <Textbox title="Class B&C Road Program">
        <p>
          The Class B & C road system with a funding program was established by
          the Utah Legislature in 1937 as a means of providing assistance to
          counties and incorporated municipalities for the improvement of roads
          and streets throughout the State.
        </p>
        <p>
          The funds differ from ordinary local revenues inasmuch as they are
          subject to administrative direction by the State in accordance with
          legislative provisions. The{" "}
          <TextWebLink link="https://www.udot.utah.gov/connect/">
            Utah Department of Transportation
          </TextWebLink>{" "}
          is the administrative authority on behalf of the State (Utah Code
          72-2-109). The procedures and regulations are designed to function by
          mutual accord among all agencies concerned. They have the approval and
          the endorsement of both the{" "}
          <TextWebLink link="https://www.uacnet.org/">
            Utah Association of Counties
          </TextWebLink>{" "}
          and the{" "}
          <TextWebLink link="https://www.ulct.org/">
            Utah League of Cities
          </TextWebLink>{" "}
          and Towns through the{" "}
          <TextWebLink link="https://www.ulct.org/about/appointments-on-boards-and-commissions/">
            Joint Highway Committeeand
          </TextWebLink>{" "}
          are looked upon as measures necessary in the perpetuation of the
          annual allocations that are so vital to each local authority in
          meeting ever increasing traffic demands. Commendable accomplishments
          have been made in the use of Class B & C funds due to the continued
          cooperation extended by the individual agencies.
        </p>
        <p>
          Class B & C Roads: All public highways, roads or streets that are
          designated as Class B by the county or Class C by the municipality
          having jurisdictional authority to do so. Counties and municipalities
          cannot designate Class B or Class C roads outside of their
          geographical boundaries, with exception that Class B roads can exist
          inside of municipal boundaries (Utah Codes 17-50-305, 72-3-103 and
          72-3-104).
        </p>
        {/* <p>More information on Class B&C road regulations can be found here.</p> */}
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Roads</title>
        <meta name="description" content="Iron County Roads Page" />
        <link rel="canonical" href="https://ironcounty.net/departments/roads" />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<RoadSidebar />}
        id="road-background"
        click={click}
      />
    </>
  );
}

export default Roads;

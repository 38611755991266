import React, { useState } from "react";
import { Link } from "react-router-dom";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import WasteSidebar from "./WasteSidebar";
import Title from "../../../Components/Text/Title";
import Textbox from "../../../Components/Text/Textbox";
import Half from "../../../Components/Sizes/Half";
import { Helmet } from "react-helmet-async";
import TextWebLink from "../../../Components/Links/TextWebLink";
import TableDynamic from "../../../Components/Table/TableDynamic";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";

function SolidWaste() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  const holidayPickup = [
    {
      Holiday: "New Year's Day",
      Regular_Scheduled_Day: "Monday- January 1, 2024",
      Holiday_Pick_Up_Day: "Tuesday – January 2, 2024",
    },
    {
      Holiday: "Memorial Day",
      Regular_Scheduled_Day: "Monday - May 27, 2024",
      Holiday_Pick_Up_Day: "Tuesday - May 28, 2024",
    },
    {
      Holiday: "Independence Day",
      Regular_Scheduled_Day: "Thursday - July 4, 2024",
      Holiday_Pick_Up_Day: "Wednesday – July 3, 2024",
    },
    {
      Holiday: "Pioneer Day",
      Regular_Scheduled_Day: "Wednesday - July 24, 2024",
      Holiday_Pick_Up_Day: "Thursday – July 25, 2024",
    },
    {
      Holiday: "Labor Day",
      Regular_Scheduled_Day: "Monday - September 2, 2024",
      Holiday_Pick_Up_Day: "Tuesday - September 3, 2024",
    },
    {
      Holiday: "Thanksgiving Day",
      Regular_Scheduled_Day: "Thursday - November 28, 2024",
      Holiday_Pick_Up_Day: "Wednesday – November 27, 2024",
    },
    {
      Holiday: "Christmas Day",
      Regular_Scheduled_Day: "Wednesday - December 25, 2024",
      Holiday_Pick_Up_Day: "Thursday December 26, 2024",
    },
    {
      Holiday: "New Year's Day",
      Regular_Scheduled_Day: "Monday- January 1, 2025",
      Holiday_Pick_Up_Day: "Tuesday – January 2, 2025",
    },
  ];

  const holidayClosures = [
    {
      Holiday: "New Year's Day ( Observed )",
      Date_and_Day: "Monday- January 1, 2024",
    },
    {
      Holiday: "Memorial Day",
      Date_and_Day: "Monday - May 27, 2024",
    },
    {
      Holiday: "Independence Day",
      Date_and_Day: "Thursday - July 4, 2024",
    },
    {
      Holiday: "Pioneer Day",
      Date_and_Day: "Wednesday - July 24, 2024",
    },
    {
      Holiday: "Labor Day",
      Date_and_Day: "Monday - September 2, 2024",
    },
    {
      Holiday: "Thanksgiving Day",
      Date_and_Day: "Thursday - November 28, 2024",
    },
    {
      Holiday: "Christmas Day",
      Date_and_Day: "Wednesday - December 25, 2024",
    },
    {
      Holiday: "New Year's Day",
      Date_and_Day: "Wednesday- January 1, 2025",
    },
  ];

  const Left = () => (
    <>
      <Title>Solid Waste</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <h1 className="center">
          <b>NOTICE:</b>
        </h1>

        <h3 className="center">
          <b>We will be open on Monday June 2th with normal operations</b>
        </h3>
        <p>
          <li>ALL loads must be covered and secured!</li>
          <li>
            PROPANE/OXYGEN TANKS: Any propane or oxygen tanks must have the
            valve removed before entering the landfill.
          </li>
          <li>
            UNINCORPORATED IRON COUNTY RESIDENTS: If your solid waste pickup day
            falls on a holiday, please place your can out for pick up the
            following business day.
          </li>
          <li>
            FREE TO RESIDENTIAL CUSTOMERS to dispose of mattress, couches, etc.
            at the county landfill. Fines will be issued for the illegal dumping
            of these items in and around any solid waste containers.
          </li>
        </p>
      </Textbox>
      <Textbox>
        <h1>What We Do</h1>
        <p>
          For inquiries regarding billing and residential trash collection
          contact the <Link to="/departments/clerk">Clerk’s office.</Link>
        </p>
        <p>
          The Iron County Solid Waste Department has two locations:
          <li>
            The main landfill is located in Cedar City at 3127 N Iron Springs
            Rd, Cedar City, UT.
          </li>
          <li>
            The secondary landfill is located in Parowan at 588 S 1200 W (1 mile
            south of Parowan on Old Highway 91)
          </li>
        </p>
      </Textbox>
      <Textbox title="Holiday Trash Pickup">
        <p>
          Below is a list of the County recognized Holidays for the year of
          2023. The Landfill will be closed and there will be no pickup on these
          dates. If your regularly scheduled day falls on a holiday, it will
          then be picked up the following day, EXCEPT IN NOVEMBER, PLEASE TAKE
          NOTE. You must have your can out to the street by 7:00 A.M on your
          pickup day.
        </p>
        <TableDynamic tableData={holidayPickup}></TableDynamic>
      </Textbox>
      <Textbox>
        <Half>
          <h1>Recycling</h1>
          <p>
            We recycle any metal or green-waste (trees/trimmings, Christmas
            trees, bushes).
          </p>
          <h1>Mulch</h1>
          <p>
            Ground green waste is sold for $35.00/loader bucket or $35 per ton
            and is loaded by the landfill staff.
          </p>
        </Half>
        <Half>
          <h1>Delinquent Accounts</h1>
          <p>
            <li>
              Accounts delinquent on first day of billing cycle are issued a $5
              late fee
            </li>
            <li>
              Accounts delinquent from 3rd & 4th Quarter of the previous year
              and/or 1st & 2nd Quarter of the current year will be attached to
              Property Taxes on September 15, with an additional $25.00
              attachment fee.
            </li>
          </p>
          <p>
            Ways to Pay
            <li>
              <Link to="/departments/clerk/utility-billing">
                Online at Iron County Utility Billing
              </Link>
            </li>
            <li>
              Mail a check (note account number on check) **PO Box 429 Parowan,
              UT 84761**
            </li>
            <li>
              Call our office with a debit/credit card at 435-477-8343
              **Merchant Fees Apply**
            </li>
          </p>
        </Half>
      </Textbox>
      <Textbox title="Landfill Hours & Locations">
        <Half>
          <h1>Iron Springs Landfill</h1>
          <h3>
            <b>Directions:</b>
          </h3>
          <p>
            Go West from Cedar City 5 miles on Hwy 56 and then turn Right onto
            Iron Springs Road. Continue on Iron Springs Road for 5 miles and the
            Landfill is on the left.
          </p>
          <h3>
            <b>Hours:</b>
          </h3>
          <p>
            <li>Businesses: Mon – Sat 8:00 AM to 5:00 PM</li>
            <li>Residents: Mon – Sat 8:00 AM to 5:30 PM</li>
            <li>Closed Sundays and all major holidays</li>
            <li>
              All customers must be unloaded and out of the landfill by 6:00 PM
            </li>
          </p>
        </Half>
        <Half>
          <h1>Northern Iron County Landfill (Parowan)</h1>
          <p>
            <b>
              All asbestos loads need to be scheduled through Iron County
              Landfill prior to disposal
            </b>
          </p>
          <p>
            <b>Directions from Parowan:</b> <br />
            Head South on Main Street for one mile until you get to 1200 West.
            Turn left and go up the hill 1/4 of a mile and the landfill is on
            the right.
          </p>

          <p>
            <b>Directions from Summit:</b>
            <br />
            Head North on Hwy 91 from Main Street for 5 miles. Turn Right at
            1200 West and go up the hill 1/4 of a mile. The landfill is on the
            right.
          </p>
          <p>
            <b>Hours:</b>
            <br />
            <li>
              1st and 3rd Saturday of each month it will be open, those weeks it
              will be open Wednesday thru Saturday 8 AM to 6 PM
            </li>
            <li>
              2nd, 4th, and 5th Saturdays it will be closed, those weeks it will
              be open Tuesday thru Friday 8 AM to 6 PM
            </li>
            <li>Closed Sundays, Mondays, and all major holidays</li>
            <li>
              All customers must be unloaded and out of the landfill by 6:00 PM
            </li>
          </p>
        </Half>
      </Textbox>
      <Textbox title="Holiday Closures">
        <TableDynamic tableData={holidayClosures}></TableDynamic>
      </Textbox>
      <Textbox title="Information on Waste Accepted">
        <Half>
          <h1>What Type of Waste is Accepted?</h1>
          <p>
            Cedar landfill accepts all wastes except those that are hazardous
            (batteries, pesticides, oil, etc) or those that are in liquid form
            (paint, oil, etc).
          </p>
          <p>
            Parowan landfill does not accept business or commercial waste with
            exception of construction and yard waste.
          </p>
          <p>
            <TextWebLink link="/files/departments/solid-waste/container-guidelines.pdf">
              Click here for solid waste guidelines
            </TextWebLink>
          </p>
          <p>
            <b>Refrigerator or Freezers:</b> We accept any appliance or
            equipment containing Freon for $10/unit. It does not matter if the
            unit currently contains Freon or if the compressor has been removed.
            All units that have ever contained Freon will cost $10 to dispose of
            them.
          </p>
          <p>
            <b>Tires:</b> We strongly encourage all tires to be recycled at a
            tire recycler/tire shop. However, we will accept up to 12
            non-commercial tires from a resident for $4.00 a tire. Tire with
            rims and tires over 24.5″ will not be accepted. We do not accept
            tires from any businesses.
          </p>
          <p>
            All Fees are on a per ton basis using the Iron County State
            Certified Scales at the Landfill.
            <em>
              A two week notification prior to disposal is required for all
              asbestos related material.
            </em>
          </p>
        </Half>
        <Half>
          <h1>Northern Iron County Landfill (Parowan)</h1>
          <p>
            <b>Friable Asbestos:</b>
            <li>Pipe Insulation</li>
            <li>Blown On Insulation</li>
            <li>Packing/Roping</li>
            <li>Boiler Casing</li>
            <li>Plaster</li>
            <li>Duct Insulation</li>
          </p>
          <p>
            <b>Friable Asbestos Disposal Fee:</b>
            <li>In County: $500</li>
            <li>Out of County: $750</li>
            <li>Minimum Fee: $300</li>
          </p>
          <p>
            <b>Non-Friable Asbestos:</b>
            <li>V.A.T/Mastic</li>
            <li>Brick</li>
            <li>Roofing/Tile</li>
            <li>Soils</li>
            <li>Utility Piping</li>
            <li>Transite</li>
            <li>Lab Countertops</li>
          </p>
          <p>
            <b>Non-Friable Asbestos Disposal Fee:</b>
            <li>In County: $65</li>
            <li>Out of County: $100</li>
            <li>Minimum Fee: $50</li>
          </p>
          <p>
            <b>Demolition Debris Containing Asbestos Fee:</b>
            <li>In County: $500</li>
            <li>Out of County: $750</li>
          </p>
        </Half>
      </Textbox>
      <Textbox title="Fee Information">
        <Half>
          <h1>General Information</h1>
          <p>
            <b>
              Residents of Iron County can dispose of reasonable amounts of
              household residential waste and yard debris from their own
              residence at no charge. Reasonable amounts mean a pickup or pickup
              and pickup bed sized trailer load anything above and beyond
              household waste and yard debris or if it is brought out in a
              commercial vehicle will be charged based on the fees below
            </b>
          </p>
          <p>
            The Iron County Landfill uses fair and accurate weight system, based
            on tonnage in order to assess fees for disposal. A copy of all weigh
            tickets will be given to every customer as they cross the scales.
          </p>
          <p>
            <b>The fees are as follows:</b>
            <li>Class I Landfill – MSW -$22.00 a ton</li>
            <li>Mixed Loads – Class I - $22.00 a ton</li>
            <li>
              Class IV-b Landfill - $22.00 a ton (clean loads only, acceptable
              waste listed below)
            </li>
            <li>Camp Trailers, Campers and Boats - $22.00 a ton</li>
            <li>Large Dead Animals - $22.00 a ton</li>
            <li>Uncovered/Uncontained Loads - $10.00 Residential</li>
            <li>Uncovered/Uncontained Loads - $25.00 Commercial</li>
            <li>Special Handling Fee (small demands) $25.00</li>
            <li>Special Handling Fee (large demands) $50.00</li>
            <li>Tires under 24” $4.00 each (no commercial tires)</li>
            <li>
              Pickup Truck or Small single axle Trailer with 500 lbs or less
              $5.00 C&D/Business
            </li>
            <li>
              All other vehicles will be weighed and charged at above fees.
            </li>
          </p>

          <p>
            For information you can contact the Landfill Scale House or office
            at 435-865-7015 between 8:00 am and 5:30 pm Monday thru Saturday.
          </p>
          <p>Class I Landfill hours 8:00 am to 5:30 pm Monday thru Saturday</p>
          <p>
            Class IVb Landfill hours 8:00 am to 5:30 pm Monday thru Saturday
          </p>
          <h1>Billing Information</h1>
          <p>
            Utility billing information can be found{" "}
            <Link to="/departments/clerk/utility-billing">Utilty Billing</Link>
          </p>
        </Half>
        <Half>
          <h1>
            Out of County Waste – Double fee for waste type/disposal area.
          </h1>
          <p>
            As allowed in the permit issued to Iron County by the Division of
            Solid & Hazardous Waste the only types of waste that can be disposed
            of at the Class IV b landfills are as follows:
          </p>
          <p>
            Construction Wastes – waste generated from construction and includes
            building materials used in construction. Construction related
            materials include packaging materials from products, waste lumber,
            wallboard, boxes from appliances, empty paint cans, empty caulking
            tubes and empty sealer and adhesive cans. “EMPTY” means that no more
            than 10% of the product remains in the container.
          </p>
          <p>
            Demolition Wastes- waste generated from the destruction or
            remodeling of buildings and houses. Demolition wastes may include
            furnaces, pipes, ducting, and water heaters. Furniture and other
            materials that are not part of the building structure must be
            removed prior to the demolition.
          </p>
          <p>
            Untreated wood, including pallets, crates, asphalt from roads and
            other surfaces.
          </p>
          <h1>
            Waste materials that are specifically Prohibited from class IV b
            landfills include the following:
          </h1>
          <p>
            <b>
              Household Wastes, Contaminated Soils, Friable Asbestos, Tanks of
              any kind, Railroad Ties, Cardboard not directly generated from
              construction or demolition activities, Furniture of all kinds,
              Metal not directly generated from construction or demolition
              activities, Electronics of all kinds, Treated Lumber(wood), Tires,
              Dead Animals
            </b>
          </p>

          <p>
            To keep prohibited waste from being disposed of in the Class IV b
            (C&D) Landfills it will be necessary for the business name and
            location of the container being delivered to the landfill to be
            disclosed to the scale attendant during the weighing and ticket
            generation process. This will help to prevent problems of waste
            being disposed of in the wrong areas, if a prohibited waste is found
            to have to have been dumped in the wrong area it will be placed back
            on the delivering vehicle and removed to the appropriate disposal
            area.
          </p>
          <p>
            Any loads containing Hazardous Substances cannot be accepted. The
            entire load will be rejected and will be placed back on the
            delivering vehicle for removal and the necessary DEQ agencies
            notified.
          </p>
        </Half>
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Solid Waste</title>
        <meta name="description" content="Iron County Solid Waste Page" />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/solid-waste"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<WasteSidebar />}
        id="waste-background"
        click={click}
      />
    </>
  );
}

export default SolidWaste;

import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import SeniorSidebar from "./SeniorSidebar";
import Half from "../../../Components/Sizes/Half";
import Textbox from "../../../Components/Text/Textbox";
import Title from "../../../Components/Text/Title";
import TextWebLink from "../../../Components/Links/TextWebLink";
import ButtonSingle from "../../../Components/Buttons/Button_Single";
import Image from "../../../Components/Images/Image";
import CedarMenu from "../../../Images/Departments/SeniorCenter/Menu-Cedar.png";
import CedarActivity from "../../../Images/Departments/SeniorCenter/Activity-Cedar.png";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

function CedarCenter() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Left = () => (
    <>
      <Title>Cedar City Senior Center</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <h1>Get in Touch</h1>
        <p>
          For our seniors convenience, you may call our office 24 hours a day at
          (435)-586-0832 and leave a message. We check for messages every
          morning. Please visit our Facebook page to see what is going on!
        </p>
        <p>
          <ButtonSingle
            href="https://www.facebook.com/people/Iron-County-Cedar-Senior-Center/100088178137223/"
            isLink={true}
          >
            Facebook
          </ButtonSingle>
        </p>

        <Half>
          <section>
            <Image
              width="100%"
              height="auto"
              src={CedarMenu}
              alt="picture of the Cedar city menu"
            ></Image>
            <h1>Center Meals</h1>
            <p>
              The Cedar City Senior Center provides a delicious “home cooked”
              lunch meal by a professional staff of cooks. Their goal is to
              provide the most nutritious and delicious meal as possible. The
              meals meet all the governmental regulations for nutrition, dietary
              and sanitation conditions.
            </p>
            <p>
              The Cedar City Senior Center lunch meal is served on Tuesday,
              Wednesday, and Friday. The center begins serving promptly at Noon.
              Music, entertainment, or presentations are often performed during
              lunch.
            </p>
            <p>
              Reservations are required by calling the center before 9 A.M. This
              allows the cooks to know how many meals they need to prepare.
              There is a suggested donation of $4.00 per meal for those 60 years
              of age and older. Those under 60 years of age must pay a charge of
              $7.00 per meal.
            </p>
          </section>
        </Half>
        <Half>
          <section>
            <Image
              width="100%"
              height="auto"
              src={CedarActivity}
              alt="picture of the cedar activity menu"
            ></Image>
            <p>
              The Cedar City Senior Center has a variety of activities. No
              matter where you live you are welcome to attend. Some of these
              activities include:
              <li>Bingo</li>
              <li>Billiards & Table Tennis</li>
              <li>Computer Availability</li>
              <li>Fit & Fab Exercise</li>
              <li>Folk Dance Class</li>
              <li>Ham Radio (R.C.A.R.C)</li>
              <li>Knitting</li>
              <li>O.H.C (Off Highway Club)</li>
              <li>Parkinson's Support Group</li>
              <li>Pinochle</li>
              <li>Quilters</li>
              <li>Tai Chi</li>
              <li>Yoga</li>
            </p>
          </section>
        </Half>
      </Textbox>
      <Textbox title="Senior Shuttle Bus">
        <p>
          The shuttle can take you to the center for lunch. On Tuesday after
          lunch it can take you to walmart. The driver will pick you up in the
          morning around 10:30 – 11 A.M.
        </p>
        <h1>Lunch at the Cedar Center</h1>
        <p>
          On Tuesday, Wednesday and Friday the bus can pick you up at your home
          around 10-30 – 11 A.M. and bring you to the Center for bingo and lunch
          at noon. On Tuesday the shuttle will have you there in time to play
          Bingo before lunch at 11 A.M. On Tuesday after lunch is “shopping day”
          and the shuttle will take you home after the shopping, There is a
          suggested donation of $4.00 round trip per person 60 years and older
          and cost of $7.00 for those that are under 60 years old.
        </p>
        <p>
          <em>
            Please note: There are some restrictions associated with the Senior
            Bus, such as where you live, health ability to ride on the bus, etc.
          </em>
        </p>
        <h1>Meals on Wheels</h1>
        <p>
          <TextWebLink link="https://www.mealsonwheelsamerica.org/">
            Meals on Wheels
          </TextWebLink>{" "}
          is available for senior citizens 60 years of age or older. Customers
          must meet the qualification to be “home bound”, meaning they are
          unable to transport themselves, or unable to have someone else
          transport them (senior bus, friends, relatives, etc.) to the Senior
          Center where lunch is available.
        </p>
        <p>
          Lunch meals are delivered in the Cedar City area on Tuesday, Wednesday
          and Friday. Delivery time is anytime between 11:00 A.M and 1:00 P.M.
          There is a suggested donation of $4.00 per meal.
        </p>
        <p>
          The meals are delivered in 3 sections: a sealed food tray with cold
          items, such as salads, desserts, etc. and a hot food tray with the
          main course, vegetables, etc. and drink, which is choice of milk or
          water. Refer to the monthly menu calendar. The meals are “home cooked”
          by a staff of cooks in the Senior Center kitchens. Meals are inspected
          regularly for nutrition qualifications, dietary specifications,
          sanitation and other local and governmental regulations. They are
          fresh and delicious.
        </p>
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Senior Center: Cedar Center</title>
        <meta
          name="description"
          content="Iron County Senior Center Cedar  Page"
        />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/senior-center/cedar"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<SeniorSidebar />}
        id="Senior-background"
        click={click}
      />
    </>
  );
}

export default CedarCenter;

import React from "react";
import "../../../Components/Sidebar/Sidebar.css";
import AccordionSidemenu from "../../../Components/AccordionMenu/AccordionSidemenu";
import TextWebLink from "../../../Components/Links/TextWebLink";
import HoursContact from "../../../Components/Text/HoursContact";
import PhoneLink from "../../../Components/Links/PhoneLink";

function SeniorSideBar() {
  const navItems = [
    {
      title: "About",
      to: "/departments/senior-center",
      isLink: false,
    },
    {
      title: "Cedar City Senior Center",
      to: "/departments/senior-center/cedar",
      isLink: false,
    },
    {
      title: "Parowan Senior Center",
      to: "/departments/senior-center/parowan",
      isLink: false,
    },
  ];

  return (
    <>
      <div className="nav-menu-side">
        <HoursContact>
          <p>
            Stephanie Rainey
            <br />
            PO Box 2647
            <br />
            <hr />
            Cedar City Senior Center
            <br />
            <TextWebLink link="https://goo.gl/maps/tcp7TZkbSw14qCBJ6">
              489 E 200 S Cedar City, UT 84721
            </TextWebLink>
            <br />
            <PhoneLink>435-586-0832</PhoneLink>
            <br />
            <hr />
            Parowan Senior Center
            <br />
            <TextWebLink link="https://goo.gl/maps/VxVUj7jxyrGNpErFA">
              695 N 300 E Parowan, UT 84761
            </TextWebLink>
            <br />
            <PhoneLink>435-477-8925</PhoneLink>
          </p>
        </HoursContact>
        <br />
        <AccordionSidemenu nav={navItems} id="department-sidebar" />
      </div>
    </>
  );
}

export default SeniorSideBar;

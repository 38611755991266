import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import JailSideBar from "./JailSidebar";
import Textbox from "../../../Components/Text/Textbox";
import Title from "../../../Components/Text/Title";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import Image from "../../../Components/Images/Image";
import News1 from "../../../Images/Departments/Commission/Jail/News.png";
import News2 from "../../../Images/Departments/Commission/Jail/News2.png";
import { Helmet } from "react-helmet-async";

function Jail() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  const Left = () => (
    <>
      <Title>Jail Bond Election</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <h1>Iron County Jail GO Bond FOR Argument</h1>
        <p>
          The Iron County Jail, built in 1987, stands on the brink of crisis,
          threatening the safety of all residents of Iron County. With your
          support can this crisis be avoided.
        </p>
        <p>
          Iron County urgently seeks $89,500,000 in bonding to construct a new
          jail and Sheriff's complex southeast of the airport. With your
          support, this new facility will be built by 2026, solving the short
          and long-term needs of our community for the next 50 years.
        </p>
        <p>
          Due to limited space at the jail, law enforcement will make arrests
          only to find there is no room to house the criminals. They are forced
          to book them and then release them back into the public, jeopardizing
          the safety of Iron County residents. Many inmates are crammed into
          makeshift common areas converted into housing. The County is not able
          to expand the current jail to address the overcrowding concerns, nor
          is it built in a way that would be cost effective to do so.
        </p>
        <p>
          Health, safety, and maintenance at the current jail have deteriorated
          to levels that would cost the County nearly <b>$30,000,000</b> dollars
          to fix and still have a jail without enough capacity to serve the
          community. Sewage floods during heavy rains, turning the facility into
          a hazardous area. Wastewater lines, encased in concrete, are beginning
          to fail. The current state of the roof is in disrepair, threatening
          important computer and evidence storage areas. Red tape and practical
          constraints are holding us back from remodeling the jail, leaving us
          with limited solutions on how best to address the problem.
        </p>
        <p>
          Our current jail has a limited capacity of 180 inmates, requiring us
          to return many criminals back into the community. The new jail will
          have room for 672 inmates. This vital expansion will not only meet our
          immediate needs but also provide an opportunity to generate revenue by
          housing contract prisoners. This additional income will help Iron
          County avoid further tax increases while ensuring our community's
          safety. The new jail may eventually be self-sufficient, allowing the
          county to offset future bond payments with this revenue. The goal of
          the county will be to use these revenues to pay down the bonds and
          once again bring Iron County back to one of the lowest-taxing counties
          in the state. When this process began 3 years ago, the price for the
          same size jail was nearly $25,000,000 less! This has been a nearly 25%
          increase in the total cost of the project. It is anticipated that
          these prices will continue to rise. If we act now, the anticipated
          cost to the average residential homeowner will be $12.75 per month.
          Iron County hopes this cost will be even less with the additional
          revenue it makes from housing other state inmates.
        </p>
        <p>
          We believe this proposal is a crucial step forward for our county,
          addressing both immediate challenges and future growth while providing
          the best financial solution. Your vote in FAVOR of this bond will be a
          vote to ensure the well-being and safety of Iron County.
        </p>
      </Textbox>
      <Textbox>
        <Image
          src={News1}
          width="100%"
          alt="Picture of a pamphlet of the new jail information"
        />
      </Textbox>
      <Textbox>
        <Image
          src={News2}
          width="100%"
          alt="Picture of a pamphlet of the new jail information"
        />
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Commission: Jail</title>
        <meta name="description" content="Iron County Commission jail Page" />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/commission/jail"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<JailSideBar />}
        id="commission-background"
        click={click}
      />
    </>
  );
}

export default Jail;

import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import JusticeSideBar from "./JusticeSidebar";
import Title from "../../../Components/Text/Title";
import Textbox from "../../../Components/Text/Textbox";
import TextWebLink from "../../../Components/Links/TextWebLink";
import TextWebLinkList from "../../../Components/Links/TextWebLinkList";
import EmailLink from "../../../Components/Links/EmailLink";
import CardAccordion from "../../../Components/Card/CardAccordion";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

function SmallClaims() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const smallClaims = [
    {
      name: "Satisfaction of Judgement – PLA",
      link: "/files/departments/justice-court/small-claims/satisfaction_of_judgement.pdf",
    },
    {
      name: "Motion to Declare the Judgment Satisfied – DEF",
      link: "/files/departments/justice-court/small-claims/debtors_motion_to_declare_the_judgement_satisfied.pdf",
    },
    {
      name: "Order to Declare the Judgment Satisfied – DEF",
      link: "/files/departments/justice-court/small-claims/order_on_debtors_motion_to_declare_the_judgement_satisfied.pdf",
    },
    {
      name: "Proof of Service",
      link: "/files/departments/justice-court/small-claims/proof_of_service.pdf",
    },
  ];

  const supProceedings = [
    {
      name: "Checklist",
      link: "/files/departments/justice-court/small-claims/creditor_checklist.pdf",
    },
    {
      name: "Ex Parte Motion for Hearing to Identify Judgment Debtor’s Property",
      link: "/files/departments/justice-court/small-claims/ex_parte_motion_for_hearing_to_identify_debtors_property.pdf",
    },
    {
      name: "Order Scheduling Hearing to Identify Judgment Debtor’s Property",
      link: "/files/departments/justice-court/small-claims/order_scheduling_hearing_to_identify_debtors_property.pdf",
    },
    {
      name: "Answers to questions about Judgment Debtor’s Property",
      link: "/files/departments/justice-court/small-claims/answers_to_questions.pdf",
    },
  ];

  const garnishment = [
    {
      name: "Checklist",
      link: "/files/departments/justice-court/small-claims/garnishment_checklist.pdf",
    },
    {
      name: "Employment Verification",
      link: "/files/departments/justice-court/small-claims/verification_of_employment.pdf",
    },
    {
      name: "Response to Request for Employment Verification",
      link: "/files/departments/justice-court/small-claims/response_to_verification_of_employment.pdf",
    },
    {
      name: "Judgement Information Statement",
      link: "/files/departments/justice-court/small-claims/judgement_information_statement.pdf",
    },
    {
      name: "Application for Writ of Garnishment",
      link: "/files/departments/justice-court/small-claims/application_for_writ_of_garnishment.pdf",
    },
    {
      name: "Writ of Garnishment and Instructions",
      link: "/files/departments/justice-court/small-claims/writ_of_garnishment_and_instructions.pdf",
    },
    {
      name: "Writ of Continuing Garnishment and Instructions",
      link: "/files/departments/justice-court/small-claims/writ_of_continuing_garnishment_and_instructions.pdf",
    },
    {
      name: "Garnishee’s Answers to Interrogatories",
      link: "/files/departments/justice-court/small-claims/garnishee_answers_to_interrogatories_for_earnings.pdf",
    },
    {
      name: "Garnishee’s Answers to Interrogatories for Property other than Earnings",
      link: "/files/departments/justice-court/small-claims/garnishees_answers_to_interrogatories_for_property_other_than_earnings.pdf",
    },
    {
      name: "Notice of Garnishment and Exemptions",
      link: "/files/departments/justice-court/small-claims/notice_of_garnishment_and_exemptions.pdf",
    },
    {
      name: "Request for Hearing",
      link: "/files/departments/justice-court/small-claims/reply_and_request_for_hearing.pdf",
    },
    {
      name: "Motion for Order to Garnishee to Show Cause",
      link: "/files/departments/justice-court/small-claims/motion_for_order_to_garnishee_to_show_cause.pdf",
    },
    {
      name: "Order to Garnishee to Show Cause",
      link: "/files/departments/justice-court/small-claims/order_to_garnishee_to_show_cause.pdf",
    },
  ];

  const lien = [
    {
      name: "Abstract of Judgment",
      link: "/files/departments/justice-court/small-claims/abstract_of_judgement.pdf",
    },
    {
      name: "Judgment of Information Statement",
      link: "/files/departments/justice-court/small-claims/judgement_information_statement.pdf",
    },
  ];

  const execution = [
    {
      name: "Application for Writ of Execution",
      link: "/files/departments/justice-court/small-claims/application_for_writ_of_execution.pdf",
    },
    {
      name: "Writ of Execution",
      link: "/files/departments/justice-court/small-claims/writ_of_execution.pdf",
    },
    {
      name: "Notice of Execution and Exemptions",
      link: "/files/departments/justice-court/small-claims/notice_of_execution_and_exemptions.pdf",
    },
    {
      name: "Reply and Request for Hearing",
      link: "/files/departments/justice-court/small-claims/reply_and_request_for_hearing-1.pdf",
    },
  ];

  const service = [
    {
      name: "Ex Parte Motion for Alternative Service",
      link: "/files/departments/justice-court/small-claims/16.pdf",
    },
    {
      name: "Order on Ex Parte Motion for Alternative Service",
      link: "/files/departments/justice-court/small-claims/16a.pdf",
    },
    {
      name: "Proof of Alternative Service",
      link: "/files/departments/justice-court/small-claims/16b.pdf",
    },
  ];

  const Left = () => (
    <>
      <Title>Small Claims</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <p>
          <em>
            Effective September 20, 2021, Iron County Justice Court’s Small
            Claims cases will be handled through a new process, Online Dispute
            Resolution, or ODR. Visit{" "}
            <TextWebLink link="https://legacy.utcourts.gov/smallclaimsodr/">
              ODR Pilot Project web page.
            </TextWebLink>{" "}
            Additionally,
            <TextWebLink link="/files/departments/justice-court/small-claims/odr-standing-order-13.pdf">
              Supreme Court Standing Order No. 13
            </TextWebLink>{" "}
            is the most valuable resource for information on this new process.
          </em>
        </p>
        <p>
          The Iron County Justice Court has jurisdiction over small claims cases
          if the defendant (the person being sued) resided in Iron County or if
          the debt arose within the Iron County limits. If you have further
          questions, you can go directly to{" "}
          <TextWebLink link="https://www.utcourts.gov/en/self-help/case-categories/consumer/small-claims.html">
            www.utcourts.gov/howto/smallclaims{" "}
          </TextWebLink>
          or consult an attorney of your choosing. Additionally, refer to the
          Utah Rules of Civil Procedure for addtional insight:
          <TextWebLink link="https://legacy.utcourts.gov/resources/rules/urcp/">
            legacy.utcourts.gov/resources/rules/urcp/
          </TextWebLink>
        </p>
        <p>
          Small claims can be filed for a claim up to $15,000.00. The claim must
          be for actual damages.
        </p>
        <table width="100%" border="solid">
          <tr>Fee Schedule for Small Claims Cases</tr>
          <tr>
            <td>Claims Amounts</td>
            <td>Fee</td>
          </tr>
          <tr>
            <td>$2,000 or less</td>
            <td>$60</td>
          </tr>
          <tr>
            <td>$2,001 - $7,500</td>
            <td>$100.00</td>
          </tr>
          <tr>
            <td>$7,501 - $15,000</td>
            <td>$185.00</td>
          </tr>
        </table>
        <p>
          After the Affidavit and Summons is filed with the court, the plaintiff
          (the person filing the claim) is responsible to serve the defendant
          with notice of the suit and the trial date. It is the Plaintiff's
          responsibility to provide proof of service to the Court at least 20
          days prior to the scheduled Small Claim Trial date.
        </p>
      </Textbox>
      <Textbox title="Filing a Small Claims Case using ODR (Online Dispute Resolution)">
        <p>
          All email communications to the Court, need to be directed to:
          <EmailLink>jcironcountysc@utcourts.gov</EmailLink>
        </p>
        <p>
          Filing a small claims case in the Iron County Justice Court requires
          participation in ODR, you must use this Small Claims Affidavit and
          Summons:
          <ul>
            <li>
              Checklist for Completing ODR Small Claims Affidavit and Summons -{" "}
              <TextWebLink link="/files/departments/justice-court/small-claims/checklist-for-completing-small-claims.pdf">
                PDF
              </TextWebLink>
            </li>
            <li>
              Suing a Business in a Small Claims Court -{" "}
              <TextWebLink link="/files/departments/justice-court/small-claims/suing-small-business.pdf">
                PDF
              </TextWebLink>
            </li>
            <li>
              <TextWebLink link="/files/departments/justice-court/small-claims/military_service_declaration.pdf">
                Military Service Declaration
              </TextWebLink>
              - (<strong className="text-red">REQUIRED</strong> when filing
              Affidavit and Summons. Website to verify active military service
              record is listed on this form.)
            </li>
            <li>
              ODR Small Claims Affidavit and Summons -{" "}
              <TextWebLink link="/files/departments/justice-court/small-claims/odr-affidavit-and-summons.pdf">
                PDF
              </TextWebLink>{" "}
              |{" "}
              <TextWebLink link="/files/departments/justice-court/small-claims/odr-affidavit-and-summons.doc">
                Word Doc
              </TextWebLink>
            </li>
            <li>
              To log into or register your ODR account go to{" "}
              <TextWebLink link="https://pubapps.utcourts.gov/OnlineDisputeResolutionWEB/ODRServlet">
                https://pubapps.utcourts.gov/OnlineDisputeResolutionWEB
              </TextWebLink>
            </li>
            <li>
              Request to be Excused from Online Dispute Resolution -{" "}
              <TextWebLink link="/files/departments/justice-court/small-claims/request-to-be-excused.pdf">
                PDF
              </TextWebLink>{" "}
              |{" "}
              <TextWebLink link="/files/departments/justice-court/small-claims/request-to-be-excused.doc">
                Word Doc
              </TextWebLink>
            </li>
            <ul>
              <li>
                If no agreement was reached in the ODR, facilitation portion of
                the Small Claim process, you will need to prepare for Trial and
                a Court date will be set.
              </li>
            </ul>

            <li>
              <TextWebLink link="/files/departments/justice-court/small-claims/small-claims-reminders-for-trial-readiness.docx">
                Small Claims Reminders for Trial Readiness
              </TextWebLink>
            </li>
          </ul>
        </p>
        {/* <em>
            Please make sure that you present the above Affidavit and Summons to
            the Court when filing. This is important for anyone you have serving
            papers, as it provides direction for the ODR process to the
            Defendant being served in the matter.
          </em> */}
      </Textbox>
      <Textbox title="ODR - Additional Forms">
        <p></p>
        <CardAccordion title="Default Judgment - ODR">
          <ul>
            <li>
              ODR Motion for Default Judgment -
              <TextWebLink link="/files/departments/justice-court/small-claims/odr-motion-for-default-judgment.pdf">
                PDF
              </TextWebLink>{" "}
              |{" "}
              <TextWebLink link="/files/departments/justice-court/small-claims/odr-motion-for-default-judgment.doc">
                Word Doc
              </TextWebLink>
            </li>
            <li>
              Order Granting Default Judgment in ODR -{" "}
              <TextWebLink link="/files/departments/justice-court/small-claims/order-granting-default-judgment.pdf">
                PDF
              </TextWebLink>{" "}
              |{" "}
              <TextWebLink link="/files/departments/justice-court/small-claims/order-granting-default-judgment.doc">
                Word Doc
              </TextWebLink>
            </li>
            <li>
              <TextWebLink link="/files/departments/justice-court/small-claims/military_service_declaration.pdf">
                Military Service Declaration
              </TextWebLink>{" "}
              - Default Judgment will only be considered if a Military Service
              Declaration was filed at initial filing of Affidavit and Summons.
            </li>
          </ul>
          {/* <em>
              The above form(s) are to be used in the event one of the parties
              failed to register online or seek exemption in the time frame
              allotted.
              <TextWebLink link="/files/departments/justice-court/small-claims/supreme-court-standing-order-no.13.pdf">
                (Reference Supreme Court Standing Order No. 13, paragraph 4)
              </TextWebLink>
            </em> */}
        </CardAccordion>
        <CardAccordion title="Enforce Settlement Agreement - ODR">
          <ul>
            <li>
              <TextWebLink link="/files/departments/justice-court/small-claims/motion-enforce-settlement-agreement.pdf">
                Motion to Enforce Settlement Agreement
              </TextWebLink>{" "}
              - Small Claims Case
            </li>
            <li>
              <TextWebLink link="/files/departments/justice-court/small-claims/order-to-motion-enforce-settlement-agreement.pdf">
                Order on Motion to Enforce Settlement Agreement and Judgment
              </TextWebLink>{" "}
              - Small Claims Case
            </li>
            <li>
              <TextWebLink link="/files/departments/justice-court/small-claims/military_service_declaration.pdf">
                Military Service Declaration
              </TextWebLink>{" "}
              -(Required when filing Affidavit and Summons. Website to verify
              active military service record is listed on this form.)
            </li>
          </ul>
          {/* <em>
            The above form(s) are to be used if a settlement agreement isn't
            adhered to, and there wasn't a judgment entered at the time of the
            settlement. The plaintiff can provide these documents to the court
            to get a judgment. It requires the plaintiff to account for any
            payments that were made to ensure the judgment is for the correct
            amount.
          </em> */}
        </CardAccordion>
        <CardAccordion title="Dismissal for Plaintiffs Lack of Action - ODR">
          <ul>
            <li>
              Motion to Dismiss for Lack of Plaintiff Participation in ODR-
              <TextWebLink link="/files/departments/justice-court/small-claims/motion-to-dismiss-for-lack-of-plaintiff-particiation.pdf">
                PDF
              </TextWebLink>{" "}
              |{" "}
              <TextWebLink link="/files/departments/justice-court/small-claims/motion-to-dismiss-for-lack-of-plaintiff-particiation.doc">
                Word Doc
              </TextWebLink>
            </li>
            <li>
              Order to Dismiss for Lack of Plaintiff Participation in ODR -{" "}
              <TextWebLink link="/files/departments/justice-court/small-claims/order-to-dismiss-for-lack-of-plaintiff-participation.pdf">
                PDF
              </TextWebLink>{" "}
              |{" "}
              <TextWebLink link="/files/departments/justice-court/small-claims/order-to-dismiss-for-lack-of-plaintiff-participation.doc">
                Word Doc
              </TextWebLink>
            </li>
          </ul>
        </CardAccordion>
        {/* <em>
            The above form(s) are to be used in the event one of the parties
            failed to register online or seek exemption in the time frame
            allotted.
            <TextWebLink link="/files/departments/justice-court/small-claims/supreme-court-standing-order-no.13.pdf">
              (Reference Supreme Court Standing Order No. 13, paragraph 4)
            </TextWebLink>
          </em>
          <ul></ul>
          <em>
            The above form is to be used if you qualify to exempt from this
            process.
            <TextWebLink link="/files/departments/justice-court/small-claims/supreme-court-standing-order-no.13.pdf">
              (Reference Supreme Court Standing Order No. 13, paragraph 4)
            </TextWebLink>
          </em> */}

        {/* <p>
          <b>
            If no agreement was reached in the ODR, facilitation portion of the
            Small Claim process, you will need to prepare for Trial and a Court
            date will be set.
          </b>
          <ul>
            
          </ul>
        </p> */}
      </Textbox>
      <p></p>

      <Textbox title="All Other Small Claim Forms">
        <p></p>
        <CardAccordion title="Forms for Counter Claims">
          <TextWebLink link="/files/departments/justice-court/small-claims/counter_affidavit_and_summons.pdf">
            Counter-Claim Affidavit
          </TextWebLink>{" "}
          <TextWebLink link="/files/departments/justice-court/small-claims/checklist-for-counter-affidavit-and-summons.pdf">
            + Instructions
          </TextWebLink>
        </CardAccordion>
        <CardAccordion title="Forms for Resolution of Small Claims Filings">
          <p>
            <TextWebLink link="/files/departments/justice-court/small-claims/motion-to-postpone.pdf">
              Motion
            </TextWebLink>{" "}
            and{" "}
            <TextWebLink link="/files/departments/justice-court/small-claims/order-motion-to-postpone.pdf">
              Order
            </TextWebLink>{" "}
            to Postpone +{" "}
            <TextWebLink link="/files/departments/justice-court/small-claims/checklist-to-postpone.pdf">
              Instructions
            </TextWebLink>
            <br />
            <TextWebLink link="/files/departments/justice-court/small-claims/motion_to_dismiss.pdf">
              Motion
            </TextWebLink>{" "}
            and{" "}
            <TextWebLink link="/files/departments/justice-court/small-claims/order_on_motion_to_dismiss.pdf">
              Order
            </TextWebLink>{" "}
            to Dismiss +{" "}
            <TextWebLink link="/files/departments/justice-court/small-claims/checklist_to_dismiss_a_small_claims_case.pdf">
              Instructions
            </TextWebLink>
            <br />
            <TextWebLink link="/files/departments/justice-court/small-claims/motion_to_set_aside.pdf">
              Motion
            </TextWebLink>{" "}
            and{" "}
            <TextWebLink link="/files/departments/justice-court/small-claims/order_on_motion_to_set_aside.pdf">
              Order
            </TextWebLink>{" "}
            to Set Aside +{" "}
            <TextWebLink link="/files/departments/justice-court/small-claims/checklist_to_set_aside.pdf">
              Instructions
            </TextWebLink>
          </p>
          <TextWebLinkList links={smallClaims}></TextWebLinkList>
        </CardAccordion>
        <CardAccordion title="Forms for Filing an Appeal">
          <TextWebLink link="/files/departments/justice-court/small-claims/notice_of_appeal.pdf">
            Notice of Appeal
          </TextWebLink>{" "}
          +{" "}
          <TextWebLink link="/files/departments/justice-court/small-claims/small-claims-appeal.pdf">
            Instructions
          </TextWebLink>
        </CardAccordion>
        <CardAccordion title="Collecting on a Judgement">
          <TextWebLink link="/files/departments/justice-court/small-claims/collect-small-claim-judgement.pdf">
            Instructions
          </TextWebLink>
        </CardAccordion>
        <CardAccordion title="Forms for Supplemental Proceedings">
          <TextWebLinkList links={supProceedings}></TextWebLinkList>
        </CardAccordion>
        <CardAccordion title="Forms for Garnishment and Writs">
          <TextWebLinkList links={garnishment}></TextWebLinkList>
        </CardAccordion>
        <CardAccordion title="Abstract of Judgement / Judgement Lien Forms">
          <TextWebLinkList links={lien}></TextWebLinkList>
        </CardAccordion>
        <CardAccordion title="Writ of Execution Forms">
          <TextWebLinkList links={execution}></TextWebLinkList>
        </CardAccordion>
        <CardAccordion title="Forms for Alternative Service">
          <TextWebLinkList links={service}></TextWebLinkList>
        </CardAccordion>
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Justice Court: Small Claims</title>
        <meta
          name="description"
          content="Iron County Justice Court small claims Page"
        />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/justice-court/small-claims"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<JusticeSideBar />}
        id="justice-background"
        click={click}
      />
    </>
  );
}

export default SmallClaims;

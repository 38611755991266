import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import HRSidebar from "./HRSidebar";
import "../Departments.css";
import Textbox from "../../../Components/Text/Textbox";
import { Helmet } from "react-helmet-async";
import Title from "../../../Components/Text/Title";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";

function HumanResources() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  const Left = () => (
    <>
      <Title>Human Resources</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <p>
          The Human Resource Office strives to provide equal employment
          opportunities to all employees and applicants. We effectively
          administer County policies & procedures, compensation plans,
          performance evaluations, and benefits. We provide advice and guidance
          to the Commission, Elected Officials, and Department Heads regarding
          compliance of human resource related federal, state, local laws and
          regulations.
        </p>
        <p>
          <h3>Mission Statement:</h3>
          <ul>
            <li>
              Provide exceptional services to all Iron County Employees and
              volunteers.
            </li>
            <li>
              Develop, communicate, and maintain personnel policies of Iron
              County.
            </li>
            <li>
              Facilitate and assure compliance with applicable laws and
              regulations.
            </li>
            <li>
              Maintain a benefit program that provide the best quality and value
              to employees.
            </li>
          </ul>
          <br />
          <h3>Vision Statement:</h3>
          <em>
            The goal of the Iron County Human Resource Department is to improve
            the workplace through teamwork and open communication.
          </em>
          <br />
          <br />
          <h3> Equal Opportunity Employer:</h3>
          <em>
            Iron County is an equal opportunity employer committed to Federal,
            State, and Local compliance with employment laws and ordinances. We
            provide equal employment opportunities regardless of sex, race, or
            national origin.
          </em>
        </p>
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Human Resources</title>
        <meta name="description" content="Iron County Human Resources Page" />
        <link rel="canonical" href="https://ironcounty.net/departments/hr" />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<HRSidebar />}
        id="HR-background"
        click={click}
      />
    </>
  );
}

export default HumanResources;

import testP from "../../../Images/Carousel/Galaxy.jpg";
import CedarDowntown from "../../../Images/Home/CedarDowntown.jpg";
import NewJail from "../../../Images/Home/NewJail.jpg";
import Mic from "../../../Images/Home/Mic.jpg";
import Vote from "../../../Images/Home/Vote.jpg";

export const NewsroomData = [
  // {
  //   image: NewJail,
  //   title: "Jail Bond Election",
  //   content: ["Learn more about the Jail Bond Election"],
  //   imageRight: true,
  //   to: "/departments/commission/jail",
  // },
  // {
  //   image: Mic,
  //   title: "Commission Meetings",
  //   content: [
  //     "The commission meet every other week to discuss issues going on in the community. Find out what is next and what has already been said.",
  //   ],
  //   imageRight: false,
  //   to: "/departments/commission/commission-archive",
  // },
  {
    image: Vote,
    title: "2024 Candidate Information",
    content: ["See who is running in this years election."],
    imageRight: true,
    to: "/departments/clerk/candidate-information",
  },
  {
    image: CedarDowntown,
    title: "What's going on in Cedar",
    content: [
      "Find out what is going on in your community using this calendar",
    ],
    imageRight: false,
    to: "https://visitcedarcity.com/events/",
  },
];

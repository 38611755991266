import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import AuditorSideBar from "./AuditorSideBar";
import Title from "../../../Components/Text/Title";
import CardFAQ from "../../../Components/Card/CardFAQ";
import Subtitle from "../../../Components/Text/Subtitle";
import Textbox from "../../../Components/Text/Textbox";
import PhoneLink from "../../../Components/Links/PhoneLink";
import EmailLink from "../../../Components/Links/EmailLink";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

function BOA() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const questions = [
    {
      question: "What is a Qualified Real Property?",
      answer:
        "A qualified real property is real property subject to appeal in the current year and: Was appealed in the previous year and had its value lowered AND Was NOT improved (e.g. renovations, additions) in the previous year ",
    },
    {
      question: "What is the Inflation Adjusted Value?",
      answer: `The county Assessor will look at the "median property value change" for real property in the same class and market area as your property (e.g. if the property is a residence, it may be the other homes in your neighborhood or town). This is a percentage that will be applied to the "final assessed value" to arrive at the inflation adjusted value.`,
    },
    {
      question: "What Happens if My Appeal Involves a Qualfied Real Property?",
      answer: `The county Auditor will ask the county Assessor to determine the "inflation adjusted value". Depending on what value is asserted by either the taxpayer or the county Assessor, the burden of proof may change.`,
    },
    {
      question: "What is the Final Assessed Value?",
      answer: `This is the amount your value was lowered to in last year’s appeal. So, the median property value change (for your class of property in your market area) plus the final assessed value is the inflation adjusted value.`,
    },
    {
      question:
        "If I Agree with the Inflation Adjusted Value (and the County Assessor Believes the Value Should Be Higher), Will this Change How I Should Prepare for My Appeal?",
      answer: `No. In a valuation appeal, both parties are arguing an opinion of value. Even if the inflation adjusted value is presumed most correct, the county BOE may agree with the county Assessor, or determine a third value based on evidence presented by both parties.`,
    },
    {
      question:
        "What Effect Will the Inflation Adjusted Value Have on the Burden of Proof in My Appeal?",
      answer: `The inflation adjusted value will be considered the most correct value by the county Board of Equalization (BOE), and the county Assessor must prove their assertion of fair market value if they believe the property is equal to or greater than the inflation adjusted value.`,
    },
    {
      question: "What if My Property is Not a Qualified Real Property?",
      answer: `The burden of proof functions normally. You carry the burden of proof unless the county Assessor asserts a value higher than the one they originally assessed.`,
    },
    {
      question:
        "What Happens if I Believe My Value is Below the Inflation Adjusted Value?",
      answer: `Then you carry the burden of proof. The onus shifts back to you to prove your lower value is the fair market value.`,
    },
    {
      question: "What if I Have Further Questions?",
      answer: `As well as the county, you may contact Alex at the Property Tax Division at the Utah State Tax Commission at (801) 297-3631.`,
    },
  ];

  const Left = () => (
    <>
      <Title>Valuation Appeals / Board of Equalization </Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <br />
      <Textbox>
        <section>
          <p>
            If you feel the market value of your property is incorrect, you can
            file an appeal with the Iron County Board of Equalization (Board).
            The appeal window is from July 22nd through September 15th every
            year. The Board is in session each year from late September through
            early October.
          </p>
          <p>
            <u>To file an appeal:</u> Please contact{" "}
            <u>Carrie Christiansen in the Auditor’s office @ </u>
            <PhoneLink>435-477-8333</PhoneLink> or you can email a request to{" "}
            <EmailLink>boe@ironcountyut.gov</EmailLink>. Please include your
            name, phone number and either your account number and/or parcel
            number for the property you wish to appeal.
          </p>
          <h2>
            <u>Information on valuation appeals</u>
          </h2>

          <p>
            <b>
              Appeal applications and supporting documentation must be submitted
              by the later of September 15th of the current year, or the last
              day of a 45-day period beginning on the day the county auditor
              mails the valuation notice. (§ 59-2-1004). Applications must be
              postmarked or received by 5:00 p.m. MDT (County Resolution
              2023-6).
            </b>
          </p>

          <p>
            The Board only considers matters related to the{" "}
            <u>valuation of property</u> for tax purposes. The amount of tax and
            other issues not related to value cannot be considered by the Board.
            Only current year values can be appealed. If you have an appeal
            pending with the State Tax Commission for a previous year, you must
            still file an appeal with the Board to dispute the value for the
            current year.
          </p>
          <p>
            <b>Burden of proof:</b> (The burden may shift if the property is a
            “qualified real property”.) In an appeal, the assessed value is
            generally presumed to be correct unless evidence you submit
            indicates otherwise. You{" "}
            <b>
              <u>must</u>
            </b>{" "}
            provide firm evidence that the value you are proposing is more
            representative of fair market value than the value set by the County
            Assessor. (Please refer to the “Qualified Real Property” section at
            the bottom for additional information.)
          </p>
        </section>
      </Textbox>
      <Subtitle>Frequently Asked Questions</Subtitle>
      <CardFAQ questions={questions} />
    </>
  );

  return (
    <>
      <Helmet>
        <title>Auditor: BOA</title>
        <meta name="description" content="Iron County Auditor BOA Page" />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/auditor/boa"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<AuditorSideBar />}
        id="auditor-background"
      />
    </>
  );
}

export default BOA;

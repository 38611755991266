import React from "react";
import "./Card.css";

function Archive(props) {
  {
    return (
      <div className="container-commission">
        {props.meetings.map((item, index) => {
          return (
            <>
              <div className="commission-size">
                <h2 className="center">{item.date}</h2>
                <div className="center">
                  {item.agenda ? (
                    <a target="_blank" rel="noreferrer" href={item.agenda}>
                      <button className="agenda-button">Agenda</button>
                    </a>
                  ) : null}

                  {item.minutes ? (
                    <a target="_blank" rel="noreferrer" href={item.minutes}>
                      <button className="minutes-button">Minutes</button>
                    </a>
                  ) : null}

                  {item.youtube ? (
                    <a target="_blank" rel="noreferrer" href={item.youtube}>
                      <button className="video-button">Media</button>
                    </a>
                  ) : null}
                </div>
              </div>
            </>
          );
        })}
      </div>
    );
  }
}

export default Archive;

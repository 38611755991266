import React from "react";
import { Link } from "react-router-dom";
import "./Buttons.css";

function ButtonImage(props) {
  return (
    <div className="button-image-container">
      {props.buttons.map((butt) => {
        const imges = butt.image;

        if (butt.isLink === true) {
          return (
            <a target="_blank" rel="noopener noreferrer" href={butt.to}>
              <button
                style={{ backgroundImage: `url(${imges})` }}
                className="button-image"
              >
                {butt.textDark ? (
                  <div className="black_bg">{butt.title}</div>
                ) : (
                  <div className="white_bg">{butt.title}</div>
                )}
              </button>
            </a>
          );
        } else {
          return (
            <Link to={butt.to}>
              <button
                style={{ backgroundImage: `url(${imges})` }}
                className="button-image"
              >
                {butt.textDark ? (
                  <div className="black_bg">{butt.title}</div>
                ) : (
                  <div className="white_bg">{butt.title}</div>
                )}
              </button>
            </Link>
          );
        }
      })}
    </div>
  );
}

export default ButtonImage;

import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import { Helmet } from "react-helmet-async";
import Textbox from "../../../Components/Text/Textbox";
import Title from "../../../Components/Text/Title";
import ClerkSidebar from "./ClerkSidebar";
import ButtonSingle from "../../../Components/Buttons/Button_Single";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";

function Clerk() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Left = () => (
    <>
      <Title>Clerk's Office</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <h1>What We Do</h1>
        <p>
          The Iron County Clerk directs the Elections, issues all licenses for
          marriages, business, and processes passports for qualified candidates.
          The Clerk also serves as the billing/collection division for the
          Special Service Area #1 (garbage collection) and is responsible for
          preparing agendas and taking minutes of the Board of County Commission
          meetings.
        </p>
        <p>
          <b>The Iron County Clerk is not the Clerk of the Courts.</b>
        </p>
        {/* <ButtonSingle
          href="/files/departments/clerk/elections/republican-primary.pdf"
          isLink={true}
        >
          June 25th, Republican Election Results
        </ButtonSingle> */}
        <ButtonSingle
          href="/files/departments/clerk/elections/2024-election-notice.pdf"
          isLink={true}
        >
          2024 Notice of Election
        </ButtonSingle>

        <ButtonSingle
          href="/files/departments/clerk/elections/recount/canvass-results-8-5.pdf"
          isLink={true}
        >
          August 5th - Recount
        </ButtonSingle>
      </Textbox>
      <Textbox title="Commission Clerk">
        <p>
          Meetings are held on the 2nd and 4th Mondays of each month in the
          County Commission Chambers located at 68 S 100 E, Parowan, UT.
        </p>
        <p>
          The Commission Secretary (Iron County Clerk) prepares and retains all
          minutes, agendas, and correspondence for the Iron County Board of
          Commissioners meetings.
        </p>
        <p>
          County departments, the media, and the general public may obtain
          information and official copies of actions of these meetings from the
          Commission Secretary.
        </p>
        <p>You may also view the agendas and minutes online.</p>
        <ButtonSingle href="/departments/commission/commission-archive">
          Agenda and Minutes
        </ButtonSingle>
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Clerk</title>
        <meta name="description" content="Iron County Clerk Page" />
        <link rel="canonical" href="https://ironcounty.net/departments/clerk" />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<ClerkSidebar />}
        id="clerk-background"
        click={click}
      />
    </>
  );
}

export default Clerk;

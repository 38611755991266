import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import PlanningSidebar from "./PlanningSidebar";
import Textbox from "../../../Components/Text/Textbox";
import Title from "../../../Components/Text/Title";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

function Planning() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Left = () => (
    <>
      <Title>Planning</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <p>
          The Planning Department is responsible for land use, regulations
          (including zoning, subdiviions and special land use permits) and
          coordiantes all of the planning commission discussiona and meetings.
        </p>
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Planning</title>
        <meta name="description" content="Iron County Planning Page" />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/planning"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<PlanningSidebar />}
        id="engineering-background"
        click={click}
      />
    </>
  );
}

export default Planning;

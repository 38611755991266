import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import Textbox from "../../../Components/Text/Textbox";
import Title from "../../../Components/Text/Title";
import ClerkSidebar from "./ClerkSidebar";
import PublicNoticesTable from "../../../Components/Card/PublicNoticesTable";
import ButtonSingle from "../../../Components/Buttons/Button_Single";
import { Helmet } from "react-helmet-async";

function PublicNotices() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  const notices = [
    {
      title: "Notice of Scam in Iron County",
      desc: "I'm asking for your help in spreading the word to all Utah citizens. Iron County is experiencing a significant increase in fraud-related incidents involving male individuals impersonating law enforcement officers from the Iron County Sheriff’s Office. This is not just a problem but a serious impact on our community that we all need to address.",
      links: [
        {
          title: "PDF",
          to: "/files/public-notices/iron-county-scam.pdf",
          isLink: true,
        },
      ],
    },
    {
      title: "CRUSH AND STOCKPILE UNTREATED BASE COURSE AGREGATE",
      desc: "Notice is hereby given that the Iron County Engineer will receive sealed bids until the hour of 5:00 pm, August 30, 2024, at the office of the Iron County Engineer located at 82 N 100 E, Suite 104, Cedar City, Utah 84720. Bids are for the crushing and stockpiling of Untreated Base Course Aggregate at two locations for a total of approximately 40,000 cubic yards, as follows: Site 1) Approximately 20,000 cubic yards located at the Iron County Road Department yard on Kitty Hawk Road, and Site 2) Approximately 20,000 cubic yards at the Iron County Landfill on Iron Springs Road. ",
      links: [
        {
          title: "Notice to Contractors",
          to: "https://www.utah.gov/pmn/files/1158720.pdf",
          isLink: true,
        },
        {
          title: "Instruction Packet",
          to: "https://www.utah.gov/pmn/files/1158722.pdf",
          isLink: true,
        },
      ],
    },
    {
      title: "Notice of Budget Amendment Hearing",
      desc: "NOTICE IS HEREBY GIVEN that the Iron County Board of Commissioners will conduct a public hearing during their regular meeting, located at the Iron County Courthouse, Commisssion Chambers, 68 South 100 East Parowan, Utah on Monday, August 12, 2024 at 10 AM.",
      links: [
        {
          title: "PDF",
          to: "https://www.utah.gov/pmn/files/1152833.pdf",
          isLink: true,
        },
      ],
    },
    {
      title: "Notice of Public Hearing",
      desc: "NOTICE IS HEREBY GIVEN pursuant to the provisions of the Utah Industrial Facilities and Development Act, Title 11, Chapter 17, Utah Code Annotated 1953, as amended, that on July 22, 2024, the Board of County Commissioners (the “Board”) of Iron County, Utah (the “County”) adopted a resolution (the “Resolution”) in which it indicated its intent to issue the County’s Industrial Development Revenue Bonds (New Castle Biogas Project), Series 2024 (with such other designation as may be determined by the County) (the “Bonds”) in the aggregate principal amount of not to exceed $10,000,000.",
      links: [
        {
          title: "PDF",
          to: "/files/public-notices/iron-co-irb-bonds.pdf",
          isLink: true,
        },
      ],
    },

    {
      title: "Recount in the Republican for U.S. House District 2 ",
      desc: "PUBLIC NOTICE is hereby given that, in the event a recount is requested for the Republican for U.S. House District 2 contest of the June 25th Primary Election.",
      links: [
        {
          title: "PDF",
          to: "https://www.utah.gov/pmn/files/1147313.pdf",
          isLink: true,
        },
      ],
    },
    {
      title:
        "Request for Proposal: UNDERWRITING SERVICES - SENIOR MANAGER AND/OR CO-MANAGER ",
      desc: "Deadline Submission July 11th. Iron County, Utah (the “County”) is seeking qualified firms to serve as senior managing underwriter and/or comanaging underwriters in connection with the issuance of up to $95,000,000 Sales Tax Revenue Bonds, Series 2024 (the “Series 2024 Bonds”) anticipated to be sold in September 2024 as market conditions permit. The County reserves the right to sell the Series 2024 Bonds through a competitive sale, negotiated sale, direct purchase or private placement. ",
      links: [
        {
          title: "PDF",
          to: "https://www.utah.gov/pmn/files/1139127.pdf",
          isLink: true,
        },
      ],
    },
    {
      title: "Request for Proposal: Safe Streets for All",
      desc: "Iron County and the Municipalities within Iron County (Cedar City, Enoch City, Parowan City, Paragonah Town, Kanarraville Town & Brian Head Town) are soliciting Proposals from qualified engineering consultant teams to complete a comprehensive Safety Action Plan for all of Iron County, Utah. The Iron County Safe Streets for All Action Plan is intended to be a robust Safety Action Plan that will identify solutions to help reduce roadway fatalities and serious injury cases and help identify feasible roadway safety improvement projects and strategies within the study area.",
      links: [
        {
          title: "PDF",
          to: "/files/public-notices/rfp-safe-streets-for-all.pdf",
          isLink: true,
        },
      ],
    },
    {
      title: "Notice of Public Hearing and Bonds to be Issued",
      desc: "The purpose of the hearing is to receive input from the public with respect to (a) the issuance of the Series 2024 Bonds for the Project described herein and (b) any potential economic impact that the public infrastructure to be financed with the proceeds of the Series 2024 Bonds may have on the private sector. All members of the public are invited to attend and participate.",
      links: [
        {
          title: "Public Hearing",
          to: "/files/public-notices/Notice-of-Public-Hearing-Iron-Co-Sales-Tax-Rev-2024.pdf",
          isLink: true,
        },
      ],
    },
    {
      title: "Public Hearing May 13: Intent to Issue Debt",
      desc: "The County shall hold a public hearing on Monday, May 13, 2024 at the hour of 10:00 AM. The location of the public hearing is 68 South 100 East, Parowan, Utah. The purpose of the hearing is to hear public comments regarding the Resolution and the issuance of the debt and to explain to the public the reasons for the issuance of the Bonds. All members of the public are invited to attend and participate. ",
      links: [
        {
          title: "Intent to Issue Debt",
          to: "/files/public-notices/intent-to-issue-debt.pdf",
          isLink: true,
        },
      ],
    },
    {
      title: "Due: April 9th",
      desc: "Iron County Children’s Justice Center is seeking an Asphalt Parking Lot & Driveway",
      links: [
        {
          title: "Request for Proposals PDF",
          to: "/files/public-notices/cjc-parking-lot.pdf",
          isLink: true,
        },
        {
          title: "Site Plan PDF",
          to: "/files/public-notices/cjc-site-plan.pdf",
          isLink: true,
        },
      ],
    },
    {
      title: "Due: February 29th",
      desc: "Iron County Children’s Justice Center is seeking an Interview Recording and Management System (IRMS) to be installed for the new facility located at 1622 E. 5250 N. Enoch Utah, 84721.",
      links: [
        {
          title: "Request for Proposals",
          to: "/files/public-notices/CJC_RFP24.pdf",
          isLink: true,
        },
      ],
    },
    {
      title: "First Deadline: January 31st",
      desc: "Invitation for Bid, Iron County EWP Project. Important Dates: January 31, 2024 at 11:00 am: Mandatory virtual pre-bid meeting. February 7, 2024 at 11:00 am: Final bids due. Before or on June 28: 2024 Completion of Project must be done.   ",
      links: [
        {
          title: "Invitation to Bid",
          to: "/files/departments/building/public/invitation-to-bid.pdf",
          isLink: true,
        },
        {
          title: "Specification PDF",
          to: "/files/departments/building/public/iron-county-ewp-project-specifications.pdf",
          isLink: true,
        },
        {
          title: "Drawings PDF",
          to: "/files/departments/building/public/iron-county-ewp-project-design-drawings.pdf",
          isLink: true,
        },
      ],
    },
    {
      title: "January 18th",
      desc: "Request for proposals (RFP) Media Buying Services January 2024",
      links: [
        {
          title: "Download PDF",
          to: "/files/departments/parks/request-for-proposals-media-buying.pdf",
          isLink: true,
        },
      ],
    },
    {
      title: "December 1st",
      desc: "In accordance with the requirements and provisions of Section 59-2-1332.5 Utah Code Annotated, (U.C.A), notice is hereby given that unpaid 2022 property taxes in Iron County, Utah became delinquent on December 1, 2023.",
      links: [
        {
          title: "Download PDF",
          to: "/files/departments/treasurer/delinquent-tax-list-new.pdf",
          isLink: true,
        },
      ],
    },
  ];

  const Left = () => (
    <>
      <Title>Public Notices</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <ButtonSingle href="/departments/planning/agenda">
          Planning Commission Agendas
        </ButtonSingle>
        <ButtonSingle href="/departments/planning/items">
          Planning Commission Items
        </ButtonSingle>
        <ButtonSingle href="/departments/commission/commission-archive">
          County Commission Items
        </ButtonSingle>
        <br />
        <PublicNoticesTable data={notices} />
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Public Notices</title>
        <meta name="description" content="Iron County public notices Page" />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/clerk/public-notices"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<ClerkSidebar />}
        id="clerk-background"
        click={click}
      />
    </>
  );
}

export default PublicNotices;

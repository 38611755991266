import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import CJCSidebar from "./CJCSidebar";
import Title from "../../../Components/Text/Title";
import Textbox from "../../../Components/Text/Textbox";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import Image from "../../../Components/Images/Image";
import Model from "../../../Images/Departments/CJC/CJC-Model.png";
import { Helmet } from "react-helmet-async";

function ForensicInterviews() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Left = () => (
    <>
      <Title>Forensic Interviews</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <h1>Forensic Interviews performed by specially-trained interviewers</h1>
        <p>
          Before Children’s Justice Centers, investigations of child sexual or
          physical abuse required multiple interviews of the child victim by
          police, social services, medical personnel, and attorneys. Each
          interview occurred at a different place and rarely in a setting that
          was familiar or comfortable for the child. The CJC is a safe,
          home-like environment where the necessary interviews can occur in a
          calm atmosphere.
        </p>
        <p>
          The forensic interview is conducted by one highly-trained individual
          and addresses the concerns of all investigative agencies involved in
          the case. The focus of the interview is to collect facts and
          information from the child in a non-leading way; it is the child’s
          opportunity to tell what happened to them. The interview is conducted
          in a manner that reduces re-victimization and trauma and focuses on
          the child’s safety and well-being.
        </p>
        <p>
          <Image width="100%" height="auto" src={Model} alt="CJC Model" />
        </p>
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Children's Justice Center: Forensic Interviews</title>
        <meta
          name="description"
          content="Iron County Children's Justice Center Forensic Interview Page"
        />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/cjc/forensic-interviews"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<CJCSidebar />}
        id="cjc-background"
        click={click}
      />
    </>
  );
}

export default ForensicInterviews;

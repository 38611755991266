import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import JusticeSideBar from "./JusticeSidebar";
import CardAccordion from "../../../Components/Card/CardAccordion";
import Title from "../../../Components/Text/Title";
import TextWebLink from "../../../Components/Links/TextWebLink";
import { Link } from "react-router-dom";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

function FAQJC() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Left = () => (
    <>
      <Title>FAQ</Title>
      <br />
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <CardAccordion title="Can I Pay with a Credit Card?">
        <p>
          For your convenience, the Court allows payments to be
          <TextWebLink link="https://secureinstantpayments.com/sip/reports/CORIS.php?m=3016&submit=Run">
            made online{" "}
          </TextWebLink>
          using Visa, Mastercard or Discover.
        </p>

        <p>
          If the ticket does not come up on your search, then wait a few days
          and try accessing again, as it can take up to 5 days for a ticket to
          appear on our system. Please make sure to select our Court in the drop
          down menu for Courts to ensure proper payment and that your name
          appears. Once you select the ticket and pay, the payment update is
          immediate. The case will be closed the following day.
        </p>

        <p>
          {" "}
          If it has been more than 5 days since you received the ticket and it
          still isn’t coming up on the search, please contact the court at
          435-865-5335.
        </p>

        <p>
          Please be aware a processing fee of 2.5% will be added to your
          payment. You will also need a citation or case number for online
          payments.
        </p>

        <p>
          You may also appear in person at the Justice Court payment window at
          82 N 100 E, Cedar City, Utah 84720. We have two kiosks available for
          you to pay online in the Court lobby.
        </p>
      </CardAccordion>
      <CardAccordion title="I Received a Ticket. What Do I Do?">
        <p>
          You must first determine if a court appearance is required. You may
          access the
          <TextWebLink link="https://legacy.utcourts.gov/rules/view.php?type=ucja&rule=10C">
            Administrative Office of the Court’s State Bail Schedule
          </TextWebLink>
            for more information regarding mandatory offenses.
        </p>

        <p>
          If your charge requires you to appear before the Court, you will
          receive a Notice of Arraignment Hearing directed to the address on
          your citation. If that address needs to be updated, please contact the
          Court as soon as possible with updated contact information.{" "}
          <Link to="/departments/justice-court/request-court">
            Contact Form
          </Link>
        </p>
        <p>
          Many traffic tickets can be taken care of without a courtroom
          appearance either through the mail or at the Justice Court payment
          window.
        </p>

        <p>
          You may make a payment in several different ways:
          <ol>
            <li>
              Send a cashier’s check or money order payable to the Iron County
              Justice Court to our address, 82 N 100 E, Cedar City, UT 84720
            </li>
            <br />
            <li>
              <TextWebLink link="https://secureinstantpayments.com/sip/reports/CORIS.php?m=3016&submit=Run">
                Pay online with Secure Instant Payments
              </TextWebLink>{" "}
              using a credit card or Visa check card. There is a convenience fee
              charge to you to use this service. The rate varies and you will be
              informed of it before you authorize the payment.
            </li>
            <br />
            <li>
              Make phone payments by contacting Instant Payments directly at
              435-261-7571
            </li>
            <br />
            <li>
              You may pay by cash, cashier’s check or money order at our payment
              windows btween 8:00 a.m. and 4:00 p.m. However, we do have a
              secure lock drop box for cash payments between 4:00 p.m. and 5:00
              p.m. for your convenience. These payments will be receipted the
              following business day and a receipt emailed to you within two
              business days.
              <br />
              <br />
              You may also pay with a credit or debit card at a Kiosk in our
              Lobby. The Kiosk's are available for credit card payments from
              8:00 a.m. to 5:00 p.m. There is a 2.5% merchant fee assessed by
              Instant Payments. We are closed on all major holidays, except
              Columbus Day.
            </li>
          </ol>
          If you wish to have a receipt sent to you, you will need to send a
          self-addressed, stamped envelope, or your email address, along with
          your payment. If you pay by mail or by using a credit card online or
          over the phone, feel free to email us, if you would like to request a
          receipt be emailed to you.
        </p>
      </CardAccordion>
      <CardAccordion title="Does the Justice Court provide Traffic School?">
        <p>
          Yes. For specifics, please visit our{" "}
          <Link to="/departments/justice-court/traffic-school">
            Online Traffic School
          </Link>{" "}
          page.
        </p>
      </CardAccordion>
      <CardAccordion title="Can I Make Payments?">
        <p>
          On non-mandatory charges, payment plans are not allowed. You can enter
          a Not Guilty Plea, and the Court will set a Pre-Trial Conference
          Hearing. If you pay off your fine amount prior to that hearing date,
          you can change your plea to Guilty, and the Court will enter that
          guilty disposition, and your case will then be closed.
        </p>
        <p>
          If you were given a payment plan during the sentencing phase, and
          would like this revised, you will need to email the court with a
          proposed new plan and we will submit that to the Judge for his
          consideration.{" "}
          <Link to="/departments/justice-court/traffic-school-contact">
            Contact Form
          </Link>
        </p>
      </CardAccordion>
      <CardAccordion title="Appealing a Criminal Judgement">
        <p>
          You have the right to Appeal a Criminal Judgment. You will need to
          fill out a{" "}
          <TextWebLink link="/files/departments/justice-court/notice-of-appeal.pdf">
            Notice of Appeal from Justice Court form
          </TextWebLink>{" "}
          and submit to the Justice Court within 28 days of Judgment. Please
          refer to{" "}
          <TextWebLink link="https://www.utcourts.gov/en/self-help/case-categories/appeals.html">
            www.utcourts.gov/en/self-help/case-categories/appeals.html
          </TextWebLink>
        </p>
      </CardAccordion>
      <CardAccordion title="How do I Contest or Fight My Charges?">
        <p>
          If your charges are <b>non-mandatory</b>, and you want to enter a “not
          guilty plea,” you may do so in one of several ways: You can go to the{" "}
          <Link to="/departments/justice-court/entry-of-plea-form">
            Entry of Plea Form
          </Link>{" "}
          , or submit your plea in person at the Court. A Pre-Trial Conference
          Hearing date will be set and you will be notified via mail or email,
          based on your expressed preference.
        </p>
        <p>
          If your charges are mandatory, and you wish to enter a “not guilty
          plea,” you can go to the{" "}
          <Link to="/departments/justice-court/entry-of-plea-form">
            Entry of Plea Form
          </Link>{" "}
          , or submit your plea in person at the Court. A Pre-Trial Conference
          Hearing date will be set and you will be notified via mail or email,
          based on your expressed preference.
        </p>
      </CardAccordion>
      <CardAccordion title="Is My License Suspended?">
        <p>
          Please contact the{" "}
          <TextWebLink link="https://dld.utah.gov/">
            Driver’s License Division
          </TextWebLink>{" "}
          about questions concerning your license at 801-965-4437 or toll free
          at 888-353-4224. You can visit their website at{" "}
          <TextWebLink link="https://dld.utah.gov/offices/cedar/">
            dld.utah.gov/offices/cedar/
          </TextWebLink>{" "}
          If it is determined that your license is suspended and you need
          further action from the court, please obtain a court case number so we
          may better assist you.
        </p>
      </CardAccordion>
      <CardAccordion title="Do I Have Any Warrants?">
        <p>
          You may contact our court between the hours of 8 am – 5 pm, Monday
          through Friday, in order to determine if you have a warrant. Please be
          aware that the warrant will not be recalled until payment is received
          in full. You may request a court date, but the warrant remains active
          until either the bail is paid or you have been seen by a Judge.
        </p>
      </CardAccordion>
      <CardAccordion title="How Much do I Owe?">
        <p>
          You may access the{" "}
          <TextWebLink link="https://legacy.utcourts.gov/rules/view.php?type=ucja&rule=10C">
            Administrative Office of the Court’s State Bail Schedule{" "}
          </TextWebLink>{" "}
          for information on bail amounts set by the State of Utah. Please be
          sure to access the bail schedule for the Justice Courts.
        </p>
        <p>
          Please be aware that any charge requiring a mandatory court appearance
          will need to be addressed by the Court.
        </p>
      </CardAccordion>
      <CardAccordion title="I Received a Letter from the Driver's License Division. What do I do?">
        <p>
          If you received a letter from Driver’s license Division, it is
          imperative that you act accordingly. Often you have a limited amount
          of time in which to avoid some type of action being taken on your
          license. Please contact Driver’s License Division at 801-965-4437 or
          toll free at 888-353-4224 for any questions you may have which are not
          answered in the letter.
        </p>
        <p>
          If the letter directs you to contact the court, please contact us
          immediately at 435-865-5335 so we may assist you.
        </p>
      </CardAccordion>
      <CardAccordion title="How Long do I Have to Change My Driver's License Address?">
        <p>
          Pursuant to{" "}
          <TextWebLink link="https://le.utah.gov/xcode/Title53/Chapter3/53-3-S216.html?v=C53-3-S216_1800010118000101">
            Utah Code 53-3-216
          </TextWebLink>{" "}
          , you are required to change your driver’s license address within (10)
          days of your move. If you possess a commercial driver’s license, you
          must change your address within thirty (30) days and you must do it in
          person at the Driver’s License Division.
        </p>
        <p>
          For this and any other driver’s license related questions, you may
          access the Driver’s License FAQ section at{" "}
          <TextWebLink link="https://dld.utah.gov/faqs/">
            dld.utah.gov/other-resources/faqs/.
          </TextWebLink>
        </p>
      </CardAccordion>
      <CardAccordion title="How Many Points will this Ticket Add to My Record?">
        <p>
          Please refer to the{" "}
          <TextWebLink link="https://dld.utah.gov/">
            Utah Driver Handbook
          </TextWebLink>{" "}
          for points assessed on certain violations. You may also contact
          Driver’s License Division directly at 801-965-4437 or toll free at
          888-353-4224.
        </p>
      </CardAccordion>
      <CardAccordion title="How do I Expunge My Record?">
        <p>
          See{" "}
          <Link to="/departments/justice-court/expungement">
            Expungement Page
          </Link>{" "}
          .
        </p>
      </CardAccordion>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Justice Court: FAQ</title>
        <meta name="description" content="Iron County Justice Court faq Page" />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/justice-court/faq"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<JusticeSideBar />}
        id="justice-background"
        click={click}
      />
    </>
  );
}

export default FAQJC;

import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import JusticeSidebar from "./JusticeSidebar";
import Title from "../../../Components/Text/Title";
import Textbox from "../../../Components/Text/Textbox";
import TextWebLink from "../../../Components/Links/TextWebLink";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

function JusticeCourt() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Left = () => (
    <>
      <Title>Justice Court</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>

      <Textbox>
        <h1>Hearings</h1>
        <ul>
          <li>All Hearings are currently being held in person.</li>

          <li>
            In order to be sentenced by mail/email, you must live outside the
            Iron County area.
          </li>
        </ul>
      </Textbox>
      <Textbox title="How to Resolve your Traffic Ticket">
        <p>
          Following are options to resolve your traffic ticket.{" "}
          <b> DO NOT PAY YOUR FINE UNTIL YOU HAVE CHOSEN ONE OF THE OPTIONS</b>{" "}
          below and are instructed to do so. Paying your fine before instructed
          to will result in a bail forfeiture and a conviction reported to the
          Utah Driver’s License Division. This results in your case being closed
          and no further action by the Court.
        </p>
        <p>
          <u>
            <b>Deferred Traffic Prosecution:</b>
          </u>{" "}
          This is a program managed by the Utah State Courts. It can keep points
          off your driving record. You must have a Utah driver’s license and be
          21 years of age or older. You must qualify for this program. To learn
          more about the program and view the requirements click on{" "}
          <TextWebLink link="https://www.utcourts.gov/en/self-help/case-categories/criminal-justice/deferred.html">
            “Deferred Prosecution.”
          </TextWebLink>{" "}
          If you choose this option, do not pay your fine until instructed to do
          so.
        </p>
        <p>
          <u>
            <b> Non Appearance Plea in Abeyance, aka Traffic School:</b>
          </u>
          This is an agreement between you and the prosecutor that will allow
          you to have your case dismissed upon completion of the agreement. You
          will be required to participate in an approved traffic school program
          and pay additional fees. To learn more about this option click on{" "}
          <Link to="/departments/justice-court/traffic-school">
            “Traffic School”.
          </Link>{" "}
          If you choose this option, do not pay your fine until instructed to do
          so.
        </p>
        <p>
          <u>
            <b>Not Guilty Plea: </b>
          </u>
          If you are wishing to contest the traffic ticket, you will choose this
          option. A pretrial conference will be set, in which you will be able
          to speak to the prosecutor to see if there is any other type of
          resolution. If there are no resolutions, the case will be set for
          trial. You may enter your plea by clicking on{" "}
          <Link to="/departments/justice-court/entry-of-plea">
            “Entry of Plea.”
          </Link>{" "}
          If you chose this option, do not pay your fine until the case is
          resolved.
        </p>
        <p>
          <u>
            <b>Guilty Plea:</b>
          </u>
          If you simply wish to pay your fine and have your case closed, click
          on{" "}
          <TextWebLink link="https://secureinstantpayments.com/sip/reports/CORIS.php?m=3016&submit=Run">
            “Make Online Payments Here.”
          </TextWebLink>{" "}
          A conviction will be entered, and the case will be closed with no
          further action by the court.
        </p>
        <p>
          <u>
            <b>Speeding, 100 mph or Over:</b>
          </u>
          Technically these charges require a mandatory appearance at a
          scheduled Arraignment Hearing at the Court. To simply pay your fine
          amount, select{" "}
          <Link to="/departments/justice-court/request-court">
            “Make a Request to Court”
          </Link>{" "}
          and specify your intent. Once received, your fine amount will be
          assessed. Your Arraignment Hearing will be cancelled once you have
          paid the fine amount in full.
        </p>
        <p>
          <b>
            For a quick glimpse of frequently written violations, click{" "}
            <TextWebLink link="/files/departments/justice-court/fine-schedule-citations.pdf">
              Here
            </TextWebLink>{" "}
          </b>
          The Uniform Bail Schedule, which has the most comprehensive list of
          violations, can be found by clicking{" "}
          <TextWebLink link="https://legacy.utcourts.gov/rules/view.php?type=ucja&rule=10C">
            Here.
          </TextWebLink>
        </p>
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Justice Court</title>
        <meta name="description" content="Iron County Justice Court Page" />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/justice-court"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<JusticeSidebar />}
        id="justice-background"
        click={click}
      />
    </>
  );
}

export default JusticeCourt;

{
  /* <h1>About Us</h1>
        <p>
          This Court handles Iron County Sheriff’s Office, Utah Highway Patrol,
          Cedar City Police Department, SUU Police Department, Enoch City Police
          Department, Department of Wildlife Resources (DWR), and Brian Head
          Marshal’s Office arrests and citations, that are class B or C
          misdemeanors and infractions. We also handle Civil Small Claims, up to
          $15,000.00.
        </p>
        <p>
          <b>
            For a quick glimpse of frequently written violations, click{" "}
            <TextWebLink link="/files/departments/justice-court/fine-schedule-citations.pdf">
              Here
            </TextWebLink>
          </b>
          <br />
          The Uniform Bail Schedule, which has the most comprehensive list of
          violations, can be found by clicking{" "}
          <TextWebLink link="https://legacy.utcourts.gov/rules/view.php?type=ucja&rule=10C">
            Here.
          </TextWebLink>
        </p> */
}

import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import AuditorSideBar from "./AuditorSideBar";
import Aidaform from "../../../Components/Aidaform/Aidaform";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

function Fraud() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Left = () => (
    <>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Aidaform
        form="https://ironcounty.aidaform.com/iron-county-fraud-hotline"
        title="Iron County Fraud Hotline"
      />
    </>
  );

  return (
    <>
      <Helmet>
        <title>Auditor: Fraud Hotline</title>
        <meta
          name="description"
          content="Iron County Auditor fraud hotline Page"
        />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/auditor/fraud"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<AuditorSideBar />}
        id="auditor-background"
      />
    </>
  );
}

export default Fraud;

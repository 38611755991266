import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import EngineeringSidebar from "./EngineeringSidebar";
import Textbox from "../../../Components/Text/Textbox";
import Title from "../../../Components/Text/Title";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import ButtonSingle from "../../../Components/Buttons/Button_Single";
import PhoneLink from "../../../Components/Links/PhoneLink";
import CardAccordion from "../../../Components/Card/CardAccordion";
import Aidaform from "../../../Components/Aidaform/Aidaform";
import { Helmet } from "react-helmet-async";

function EncroachmentPermit() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Left = () => (
    <>
      <Title>Encroachment Permit Driveways, Culverts, etc</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <h1>When is an Encroachment Permit Required?</h1>
        <p>
          You must compolete an Encroachment Permit Application if you are going
          to do any work within a county Right of Way. There are specific
          documents required, as well as fees and bond funds--the bond funds
          will be refunded upon the passing of an inspection one year after
          proof completion is provided to the Engineer's office. See the permit
          application for more details.{" "}
        </p>
        <h1>Encroachment Permit Application:</h1>
        <CardAccordion title="Online Fillable Form">
          <Aidaform form="https://ironcounty.aidaform.com/encroachment-permit-application"></Aidaform>
        </CardAccordion>
        <ButtonSingle
          href="/files/departments/engineering/encroachment-form.docx"
          isLink={true}
        >
          Download Form - Word
        </ButtonSingle>
        <ButtonSingle
          href="/files/departments/engineering/encroachment-form.pdf"
          isLink={true}
        >
          Download Form - PDF
        </ButtonSingle>
      </Textbox>
      <Textbox title="Payment Information">
        <p>
          <li>Check - Make out to Iron County Engineering</li>
          <li>Cash</li>
          <li>
            Credit Card or Debit Card (processing fee of 2.5% will be added to
            any credit or debit card payments.) - Contact the Engineer's Office
            at <PhoneLink>435-865-5370</PhoneLink>
          </li>
        </p>
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Engineering: Encroachment Permit</title>
        <meta
          name="description"
          content="Iron County engineering encroachment permit Page"
        />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/engineering/encroachment-permit"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<EngineeringSidebar />}
        id="engineering-background"
        click={click}
      />
    </>
  );
}

export default EncroachmentPermit;

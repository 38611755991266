import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import JusticeSideBar from "./JusticeSidebar";
import Title from "../../../Components/Text/Title";
import Textbox from "../../../Components/Text/Textbox";
import Half from "../../../Components/Sizes/Half";
import Youtube from "../../../Components/Links/Youtube";
import CardAccordion from "../../../Components/Card/CardAccordion";
import Aidaform from "../../../Components/Aidaform/Aidaform";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

function Arraignment() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Left = () => (
    <>
      <Title>Arraginment</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <p>Watch the following Rights video in your preferred language</p>
      </Textbox>
      <Textbox>
        <Half>
          <Youtube
            src="https://www.youtube.com/embed/Y9Rrn-bTfrY"
            title="English"
          ></Youtube>
        </Half>
        <Half>
          <Youtube
            src="https://www.youtube.com/embed/eDaXvwgboxE"
            title="Spanish"
          ></Youtube>
        </Half>
      </Textbox>
      <Textbox title="Fill out the Rights, Instructions and Waiver">
        <CardAccordion title="English Form">
          <Aidaform
            form="https://ironcounty.aidaform.com/rights-instructions-waiver-form"
            title="Rights Instructions Waiver Form English"
          />
        </CardAccordion>
        <CardAccordion title="Spanish Form">
          <Aidaform
            form="https://ironcounty.aidaform.com/rights-instructions-waiver-form-spanish"
            title="Rights Instructions Waiver Form Spansih"
          />
        </CardAccordion>
      </Textbox>
      <br />
      <Textbox title="Fill out the Right to Counsel">
        <CardAccordion title="English Form">
          <Aidaform
            form="https://ironcounty.aidaform.com/right-to-counsel-form"
            title="Right to Counsel Form English"
          />
        </CardAccordion>
        <CardAccordion title="Spanish Form">
          <Aidaform
            form="https://ironcounty.aidaform.com/right-to-counsel-form-spanish"
            title="Right to Counsel Form Spanish"
          />
        </CardAccordion>
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Justice Court: Arraignment</title>
        <meta
          name="description"
          content="Iron County Justice Court arraignment Page"
        />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/justice-court/arraignment"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<JusticeSideBar />}
        id="justice-background"
        click={click}
      />
    </>
  );
}

export default Arraignment;

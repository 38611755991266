import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import AssessorSidebar from "./AssessorSidebar";
import Aidaform from "../../../Components/Aidaform/Aidaform";
import Title from "../../../Components/Text/Title";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

function ChangeBusinessForm() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Left = () => (
    <>
      <Title>Personal Property Change of Business Form</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Aidaform form="https://ironcounty.aidaform.com/change-of-business-form-app-renew" />
    </>
  );

  return (
    <>
      <Helmet>
        <title>Assessor: Change of Business Form</title>
        <meta
          name="description"
          content="Iron County Assessors Change of Business form page"
        />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/assessor/change-business-form"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<AssessorSidebar />}
        id="assessor-background"
        click={click}
      />
    </>
  );
}

export default ChangeBusinessForm;

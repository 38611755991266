import React from "react";
import AccordionSidemenu from "../../../Components/AccordionMenu/AccordionSidemenu";
import TextWebLink from "../../../Components/Links/TextWebLink";
import HoursContact from "../../../Components/Text/HoursContact";
import PhoneLink from "../../../Components/Links/PhoneLink";
import "../../../Components/Sidebar/Sidebar.css";
import EmailLink from "../../../Components/Links/EmailLink";

function EngineeringSideBar() {
  const navItems = [
    {
      title: "About",
      to: "/departments/engineering",
      isLink: false,
    },

    {
      title: "Encroachment Permit Driveways, Culverts, etc",
      to: "/departments/engineering/encroachment-permit",
      isLink: false,
    },
    {
      title: "FAQ",
      to: "/departments/engineering/faq",
      isLink: false,
    },
    {
      title: "FEMA Flood Map Changes Viewer",
      to: "https://fema.maps.arcgis.com/apps/webappviewer/index.html?id=e7a7dc3ebd7f4ad39bb8e485bb64ce44%5C",
      isLink: true,
    },
    {
      title: "Flood Information",
      to: "https://msc.fema.gov/portal/search",
      isLink: true,
    },

    {
      title: "GIS Maps",
      to: "/departments/it/gis",
      isLink: false,
    },

    {
      title: "Requests and Forms",
      to: "/departments/engineering/requests-and-forms",
      isLink: false,
    },

    {
      title: "Resources",
      sub: [
        {
          title: "Subdivision Construction and Road Standards",
          to: "/files/departments/engineering/sub-const-design-standards.pdf",
          isLink: true,
        },
        {
          title: "Road Cross Section Schematics",
          to: "/files/departments/engineering/cross-sections.pdf",
          isLink: true,
        },
      ],
    },
  ];

  return (
    <>
      <div className="nav-menu-side">
        <HoursContact>
          <p>
            Richard Wilson, County Engineer
            <br />
            <TextWebLink link="https://goo.gl/maps/n4UaZjbAxr5HBvz78">
              82 N 100 E #104 Cedar City, Utah 84720
            </TextWebLink>
            <br />
            M - F 8:00 AM – 5:00 PM
            <br />
            Phone: <PhoneLink>435-865-5370</PhoneLink>
            <br />
            Email: <EmailLink>rwilson@ironcounty.net</EmailLink>
          </p>
        </HoursContact>
        <br />
        <AccordionSidemenu nav={navItems} id="department-sidebar" />
      </div>
    </>
  );
}

export default EngineeringSideBar;

import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import JusticeSideBar from "./JusticeSidebar";
import Textbox from "../../../Components/Text/Textbox";
import Title from "../../../Components/Text/Title";
import TextWebLink from "../../../Components/Links/TextWebLink";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

function Expungement() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Left = () => (
    <>
      <Title>Expungement</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <p>
          Most of your questions can be answered by visiting the{" "}
          <TextWebLink link="https://bci.utah.gov/expungements/">
            Utah Bureau of Criminal Identification (BCI) website
          </TextWebLink>
          , or by calling BCI at 801-965-4445.
        </p>
        <p>
          The expungement process can be easy if you follow the steps below:
          <ul>
            <li>
              Please review the{" "}
              <TextWebLink link="https://bci.utah.gov/expungements/">
                State of Utah’s Certificate of Eligibility
              </TextWebLink>{" "}
              and make application to BCI for your Certificate before proceeding
              to step 2.
            </li>
            <li>
              Once you have applied for and received your Certificate of
              Eligibility, please print this{" "}
              <TextWebLink link="/files/departments/justice-court/expungement_packet.pdf">
                Expungement Packet and follow the instructions.
              </TextWebLink>
            </li>
          </ul>
        </p>
        <p>
          <em>
            - The $135.00 filing fee will be required as of July 1, 2023. See
            Expungement Packet link above for more detailed information.
          </em>
          <br />
          <br />
          <em>
            {" "}
            - For information regarding Utah's Clean Slate Law, please visit{" "}
            <TextWebLink link="https://www.cleanslateutah.org/">
              cleanslateutah.org.
            </TextWebLink>{" "}
          </em>
        </p>
        <p>
          <em>
            Additionally, there are instructions on the utcourts.gov website
            that provide instructions on how a party can request access to their
            expunged case. Here is the link{" "}
            <TextWebLink link="https://www.utcourts.gov/en/self-help/case-categories/criminal-justice/accessing-expunged-records.html">
              Accessing Expunged Records
            </TextWebLink>
          </em>
        </p>
        <p>
          <em>
            There is also a form that can be completed,
            <TextWebLink link="/files/departments/justice-court/case_history_and_expungement_order.pdf">
              Request for Expunged Case History Expungement Order or both.
            </TextWebLink>
          </em>
        </p>
        <p>
          <em>
            If you have a{" "}
            <TextWebLink link="https://www.utcourts.gov/en/self-help/services/mycase.html">
              MyCase account
            </TextWebLink>{" "}
            and know your case number, you can request access to the expunged
            record.
          </em>
        </p>
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Justice Court: Expungement</title>
        <meta
          name="description"
          content="Iron County Justice Court expungement Page"
        />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/justice-court/expungement"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<JusticeSideBar />}
        id="justice-background"
        click={click}
      />
    </>
  );
}

export default Expungement;

import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import BuildingSidebar from "./BuildingSidebar";
import Textbox from "../../../Components/Text/Textbox";
import Title from "../../../Components/Text/Title";
import CardAccordion from "../../../Components/Card/CardAccordion";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

function FAQBuilding() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  const Left = () => (
    <>
      <Title>Frequently Asked Questions</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <p>
          <h1>Permit Refund Policy</h1>
          <ol>
            <li>
              No, building permit fees will be refunded in any case after 180
              days.{" "}
            </li>
            <li>Plan review fees will not be refunded.</li>
            <li>
              If no required inspections are completed on a building project
              within 1 year, the permit holder will have to renew the permit
              according to the fee schedule.
            </li>
          </ol>
        </p>
      </Textbox>
      <Textbox>
        <CardAccordion title="When is a soil report and testing required?">
          <li>Any new dwelling</li>
          <li>
            Any addition where soils testing was required on the existing
            dwelling.{" "}
          </li>
        </CardAccordion>
        <CardAccordion title="When are plans required to be stamped by a structural engineer?">
          <li>All dwellings require structural engineered plans.</li>
          <li>
            All commercial plans require architectural, structural, plumbing,
            mechanical and electrical.
          </li>
        </CardAccordion>
        <CardAccordion title="Where can I build my fence and do I need a permit?">
          <li>
            You can build a fence on your property line. It is recommended that
            you have your property surveyed if you cannot find property corners.{" "}
          </li>
          <li>
            Fence’s over 6 feet tall require building permit and engineering.
          </li>
        </CardAccordion>
        <CardAccordion title="What are the requirements for a retaining wall and do I need a permit?">
          <li>
            A retaining wall that is over 4 feet tall requires engineering and a
            building permit.
          </li>
        </CardAccordion>
        <CardAccordion title="What information is needed to request an inspection?">
          <li>Name on the building permit, permit number, and address.</li>
          <li>You can also schedule an inspection on the permit portal.</li>
        </CardAccordion>
        <CardAccordion title="When is a building permit required?">
          <li>
            A building permit is required for any structural, electrical,
            plumbing or mechanical work that is done.
          </li>
        </CardAccordion>
        <CardAccordion title="How can I find out what my property zoned?">
          <li>
            Look on the Iron County Zoning Map. Have your assessor parcel number
            or property account number.{" "}
          </li>
        </CardAccordion>
        <CardAccordion title="Is a building permit required to remodel my house?">
          <li>
            Yes. Most insurance companies could deny coverage if any building
            takes place without a permit and was not built to code, or
            inspected.
          </li>
        </CardAccordion>
        <CardAccordion title="Is a permit required to finish my basement?">
          <li>Yes</li>
        </CardAccordion>
        <CardAccordion title="Do I need a permit for an accessory structure?">
          <li>
            You can have MAXIMUM of 200 square feet of accessory structure
            without any electrical, plumbing or mechanical in the building
            without a building permit. Any square footage over 200 will require
            a building permit.{" "}
          </li>
        </CardAccordion>
        <CardAccordion title="Is hauling water allowed to my property?">
          <li>
            There are certain circumstances that hauling water is allowed. Call
            the building department for more information.
          </li>
        </CardAccordion>
        <CardAccordion title="How do I pay for a building permit?">
          <li>
            Credit Card (American Express you will need to call the office to
            use). Service charge{" "}
          </li>
          <li>Cash</li>
          <li>Check, Money Order (Made out to Iron County)</li>
        </CardAccordion>
        <CardAccordion title="Can I pay for my building permit online?">
          <li>Yes. It is done through the building permit portal.</li>
        </CardAccordion>
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Building: FAQ</title>
        <meta name="description" content="Iron County Building Page" />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/building/faq"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<BuildingSidebar />}
        id="building-background"
        click={click}
      />
    </>
  );
}

export default FAQBuilding;

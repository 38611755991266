import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";

import SeniorSidebar from "./SeniorSidebar";
import Half from "../../../Components/Sizes/Half";
import Textbox from "../../../Components/Text/Textbox";
import Title from "../../../Components/Text/Title";
import ButtonSingle from "../../../Components/Buttons/Button_Single";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

function SeniorCenter() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Left = () => (
    <>
      <Title>Senior Center</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <p>
          Iron County has two Senior Activity Centers for people over 60 years
          old. They are located in Cedar City and Parowan.
        </p>
        <p>
          The Centers host fresh home-made lunches three days a week, as well as
          various activities like Yoga and Tai Chi. Other popular activities
          enjoyed are Ceramics, Water Color Class, Pool, Bingo, Bridge, and
          Pinochle, to name a few.
        </p>
        <p>
          During lunch you may be treated with presentations about nutrition and
          other health topics, home ideas, a relaxing message from Zion Way, or
          having your blood pressure checked.
        </p>
        <p>
          For detailed information call the centers or click on the pages for
          each below.
        </p>
        <Half>
          <ButtonSingle href="/departments/senior-center/cedar">
            Cedar City Senior Center
          </ButtonSingle>
        </Half>
        <Half>
          <ButtonSingle href="/departments/senior-center/parowan">
            Parowan Senior Center
          </ButtonSingle>
        </Half>
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Senior Center</title>
        <meta name="description" content="Iron County Senior Center Page" />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/senior-center"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<SeniorSidebar />}
        id="Senior-background"
        click={click}
      />
    </>
  );
}

export default SeniorCenter;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";

const Dropdown = (props) => {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);

  return (
    <>
      <ul
        onClick={handleClick}
        className={click ? "dropdown-menu clicked" : "dropdown-menu"}
      >
        {props.list.map((item, index) => {
          if (item.isLink === true) {
            return (
              <li key={index}>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className={item.cName}
                  href={item.url}
                  onClick={() => setClick(false)}
                >
                  {item.title}
                </a>
              </li>
            );
          } else {
            return (
              <li key={index}>
                <Link
                  className={item.cName}
                  to={item.url}
                  onClick={() => setClick(false)}
                >
                  {item.title}
                </Link>
              </li>
            );
          }
        })}
      </ul>
    </>
  );
};

export default Dropdown;

/* <ul className="dropdown">
{submenus.map((submenu, index) => (
  <li key={index} className="menu-items">
    <a href={submenu.url}>{submenu.title}</a>
  </li>
))}
</ul> */

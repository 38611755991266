import React, { useState } from "react";
import "./Text.css";

function Title(props) {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  return (
    <div className="hero-container title">
      <div className="title2">
        <h1>{props.children}</h1>
      </div>
    </div>
  );
}

export default Title;

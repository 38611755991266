import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import Subtitle from "../../../Components/Text/Subtitle";
import Textbox from "../../../Components/Text/Textbox";
import Title from "../../../Components/Text/Title";
import ClerkSidebar from "./ClerkSidebar";
import CardAccordion from "../../../Components/Card/CardAccordion";
import TextWebLink from "../../../Components/Links/TextWebLink";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

function MarriageLicense() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Left = () => (
    <>
      <Title>Marriage License</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <h1 className="m-lic ">
          We will not be issuing marriage licenses on September 5th due to
          system changes. Please plan accordingly. Thank you
        </h1>
        <p>
          Marriage licenses can be obtained from the Iron County Clerk’s Office,
          located at 68 S 100 E, Parowan, Utah.
        </p>
        <p>
          Marriage licenses are issued Monday through Friday 8:00 A.M. to 5:00
          P.M.
        </p>
        <p>
          <b>
            Both parties need to be present with required documents listed
            below.
          </b>{" "}
          A marriage license is then issued and becomes effective immediately
          but must be used within 32 days from the date of purchase, at which
          time the license expires. Refunds and extensions cannot be given. A
          license issued in Iron County may be used in any county in the State
          of Utah.
        </p>
      </Textbox>
      <Textbox>
        <p>
          <Subtitle>Requirements</Subtitle>
          Applicants must bring valid ID, proof of age, and pay a license fee of
          $30.00 by check, cash, money order, or credit/debit card. The license
          fee is non-refundable.
        </p>
        <p>
          The full list of information the Clerk requires in order to issue a
          marriage license:
          <li>Full names, including the maiden name if applicable</li>
          <li>
            Social security numbers, unless the applicant has not been assigned
            a number
          </li>
          <li>Current addresses</li>
          <li>
            Date and place of birth (town or city, county, state or country, if
            possible)
          </li>
          <li>
            Names of respective parents, including the maiden name of the mother
          </li>
          <li>Birthplaces of fathers and mothers (state or country)</li>
          <li>Race of applicants</li>
          <li>
            One form of ID such as a driver’s license or passport. If using a
            birth certificate, a photo ID is also required
          </li>
          <li>
            Divorce decree signed by the Judge if your divorce was final in the
            last 60 days
          </li>
        </p>

        <CardAccordion title="What About Minors?">
          <p>
            Applicants under the age of 18 years of age are required to have the
            following:
            <ul>
              <li>
                If the applicant is 16 or 17 years of age, the minor and the
                parent or legal guardian of the minor shall obtain a written
                authorization to marry from: a judge of the court exercising
                juvenile jurisdiction in the county where either party to the
                marriage resides; or a court commissioner as permitted by rule
                of the Judicial Council. Please contact our office for a form{" "}
                <TextWebLink link="https://le.utah.gov/xcode/Title30/Chapter1/30-1-S9.html?v=C30-1-S9_2018032220180322">
                  (Section 30-1-9 Marriage by Minors).
                </TextWebLink>
              </li>
              <li>
                Applicants 15 years of age and younger are prohibited from
                obtaining a marriage license under Utah law.
              </li>
            </ul>
          </p>
        </CardAccordion>
        <CardAccordion title="Additional Information">
          <p>
            <b>
              Licenses are valid for 32 days from date of issue and may be used
              anywhere within the State of Utah.
            </b>
            The license remains the property of Iron County and must be returned
            to the County Clerk after the marriage is or is not solemnized.
          </p>
          <p>
            The person performing the ceremony will be responsible to complete
            the Marriage Certificate and the Marriage License.
          </p>
          <p>
            After the ceremony you will receive the Marriage Certificate. The
            person performing the marriage is responsible for returning the
            Marriage License to the Clerk’s Office within 30 days of the
            marriage. After the Marriage License is returned to the office, a
            certified copy will be mailed to you in the envelope you address in
            our office. The certified copy of your Marriage License is your
            official proof of marriage.
          </p>
          <p>
            If the one of the participants plans on changing their last name,
            the Social Security office and the Drivers License Division will
            want to see the certified copy of the Marriage License. The Social
            Security office and the Drivers License Division are the two main
            places but you may need to go to banks, insurance companies, etc.
            Most people will just need to see the certified copy, but they
            generally do not need to keep the copy.
          </p>
          <p>
            If you do need a certified copy in the future, we will always file
            the original marriage license in our office. Certified copies are
            $6.00 for each copy.
          </p>
          <p>
            Any ordained minister who is at least 18 years of age, Native
            American spiritual advisors, the Governor, Mayors, Justices of the
            Peace, Judges, County Clerks, President of the Senate, Speaker of
            the House of Representatives, retired Judges or Magistrates, U.S.
            Judges or Magistrates may perform weddings.
          </p>
        </CardAccordion>
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Clerk: Marriage License</title>
        <meta
          name="description"
          content="Iron County Clerk Marriage License Page"
        />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/clerk/marriage-license"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<ClerkSidebar />}
        id="clerk-background"
        click={click}
      />
    </>
  );
}

export default MarriageLicense;

import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import ParksSidebar from "./ParksSidebar";
import Title from "../../../Components/Text/Title";
import Textbox from "../../../Components/Text/Textbox";
import Half from "../../../Components/Sizes/Half";
import Map from "../../../Components/Maps/Map";
import ButtonSingle from "../../../Components/Buttons/Button_Single";
import TextWebLink from "../../../Components/Links/TextWebLink";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

function WoodRanch() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Left = () => (
    <>
      <Title>Wood Ranch Recreational Area</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <ButtonSingle
          href="/files/departments/parks/wood-ranch-reservation.pdf"
          isLink={true}
        >
          Make a Reservation
        </ButtonSingle>
        <p>
          Please email form to:{" "}
          <a href="mailto:sorton@ironcounty.net">sorton@ironcounty.net</a>{" "}
        </p>
      </Textbox>
      <Textbox>
        <Half>
          <h1>About</h1>
          <p>
            Just 12 miles east of Cedar City along scenic highway SR-14, is the
            recreation area known as Wood Ranch. Wood Ranch is a great place to
            picnic, hike, and fish. Kid’s Pond, a popular fishing spot for the
            Southern Utah’s youth is located on the upper section of the
            recreation area. The trailhead for the 32-mile Virgin River Rim
            trail is located at Wood Ranch.
          </p>
          <p>
            Overnight camping is not permitted at Wood Ranch, however, the Cedar
            Canyon Campground is located nearby. Contact the Dixie National
            Forest for camping information at (435) 865-3200 or visit
            recreation.gov.
          </p>
          <p>
            This area is open seasonally from May 1st - September 30th (weather
            permitting)
          </p>
          <h1>
            <b>
              <u>Rules</u>
            </b>
          </h1>
          <ul>
            <li>
              <b>NO OVERNIGHT CAMPING</b>
            </li>
            <li>No pallet burning allowed</li>
            <li>No fireworks</li>
            <li>Fires in provided metal fire rings only</li>
            <li>Pets must be on a leash at all times</li>
            <li>No discharging firearms</li>
            <li>Follow posted speed limits</li>
            <li>Follow state fire restrictions when posted</li>
            <li>No smoking or vaping</li>
            <li>Alcohol permit requirement</li>
          </ul>
        </Half>
        <Half>
          <h1>
            <b>
              <u>Amenities</u>
            </b>
          </h1>
          <ul>
            <li>
              Large group pavilion
              <ul>
                <li>10 picnic tables</li>
                <li>Large charcoal grill</li>
                <li>Fire pit and fireplace</li>
                <li>Volleyball court</li>
                <li>Horseshoe pits</li>
                <li>Playground</li>
              </ul>
            </li>
            <li>
              Small group pavilion{" "}
              <i>Must be reserved and a reservation fee is required</i>
              <ul>
                <li>6 picnic tables</li>
                <li>Large charcoal grill</li>
                <li>Fire pit and fireplace</li>
                <li>Playground</li>
              </ul>
            </li>
            <li>
              Kid’s Pond
              <ul>
                <li>
                  Children 12 and under fish for free. (Must be accompanied by
                  an adult with a valid Utah fishing license.)
                </li>
              </ul>
            </li>
            <li>Trash cans</li>
            <li>Seasonal water</li>
            <li>Electricity</li>
            <li>Emergency satellite phone</li>
            <li>Two double vault toilets</li>
            <li>Large grassy meadow</li>
            <li>
              Several picnic tables and charcoal grills located throughout the
              area. First-come-first-served basis
            </li>
          </ul>
        </Half>
      </Textbox>
      <Textbox>
        <Half>
          {" "}
          <Map src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3161.3324116890103!2d-112.917385!3d37.594335!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80caa3fb4e0dd993%3A0xf0f2b56f26a85531!2sWood%20Ranch%20Park!5e0!3m2!1sen!2sus!4v1694034230309!5m2!1sen!2sus"></Map>
        </Half>
        <Half>
          {" "}
          <Map src="https://calendar.google.com/calendar/embed?height=600&wkst=1&ctz=America%2FDenver&bgcolor=%23ffffff&src=bDZxOTA3bTNyZmlwM2F2OWxtc2dvdWs3NmtAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&color=%237CB342"></Map>
        </Half>
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Parks and Recreation</title>
        <meta
          name="description"
          content="Iron County  Parks and Recreation Page"
        />
        <link rel="canonical" href="https://ironcounty.net/departments/parks" />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<ParksSidebar />}
        id="parks-background"
        click={click}
      />
    </>
  );
}

export default WoodRanch;

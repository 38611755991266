import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import Title from "../../../Components/Text/Title";
import Textbox from "../../../Components/Text/Textbox";
import FireSidebar from "./FireSidebar";
import { Helmet } from "react-helmet-async";
import ButtonSingle from "../../../Components/Buttons/Button_Single";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import TableDynamic from "../../../Components/Table/TableDynamic";

function FireResources() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const FireStations = [
    {
      Agency: "Beryl Fire Department",
      Address: "650 E. Center Street Beryl, UT 84714",
      Non_Emergency_Number: "(435) 439-5600",
    },
    {
      Agency: "Brian Head Public Safety",
      Address: "535 Brian Head Blvd Brian Head, UT 84719",
      Non_Emergency_Number: "(435) 677-2029",
    },
    {
      Agency: "Cedar City Fire Department Station 1 (staffed full-time)",
      Address: "285 N. 800 W. Cedar City, UT 84720",
      Non_Emergency_Number: "(435) 586-2964",
    },
    {
      Agency: "Cedar City Fire Department Station 2",
      Address: "2580 N. Commerce Center Drive Cedar City, UT 84721",
      Non_Emergency_Number: "",
    },
    {
      Agency: "Cedar City Fire Department Station 3",
      Address: "3013 W. 1600 N. Cedar City, UT 84721",
      Non_Emergency_Number: "",
    },
    {
      Agency: "Kanarraville Fire Department",
      Address: "50 E. Center Street Kanarraville, UT 84742",
      Non_Emergency_Number: "(435) 865-1444",
    },
    {
      Agency: "Newcastle Fire Department",
      Address: "72 W. Highway 56 New Castle, UT 84756",
      Non_Emergency_Number: "(435) 439-5300",
    },
    {
      Agency: "Paragonah Fire Department",
      Address: "80 W. Center Street Paragonah, UT 84760",
      Non_Emergency_Number: "(435) 477-3426",
    },
    {
      Agency: "Parowan Fire Department",
      Address: "190 West 200 South Parowan, UT 84761",
      Non_Emergency_Number: "(435) 477-3470",
    },
  ];

  const Left = () => (
    <>
      <Title>Resources</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <h1>Fire Agency Contacts</h1>
        <TableDynamic tableData={FireStations}></TableDynamic>
      </Textbox>
      <Textbox>
        <h1>Protect Your Home</h1>
        <p>
          For more information on how to protect your property follow the links
          below:
        </p>
        <ButtonSingle
          href="https://www.nfpa.org/Public-Education/Fire-causes-and-risks/Wildfire/Firewise-USA"
          isLink={true}
        >
          Fire Wise
        </ButtonSingle>
        <ButtonSingle
          href="https://utah-fire-info-utahdnr.hub.arcgis.com/"
          isLink={true}
        >
          Utah Fire Info
        </ButtonSingle>
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Fire: Resources</title>
        <meta name="description" content="Iron County Fire resources Page" />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/fire/resources"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<FireSidebar />}
        id="fire-background"
        click={click}
      />
    </>
  );
}

export default FireResources;

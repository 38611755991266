import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import CJCSidebar from "./CJCSidebar";
import Title from "../../../Components/Text/Title";
import Textbox from "../../../Components/Text/Textbox";
import Half from "../../../Components/Sizes/Half";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import Image from "../../../Components/Images/Image";
import adelyn from "../../../Images/Departments/CJC/adelyn-abbott.jpg";
import hayden from "../../../Images/Departments/CJC/hayden-morton.jpg";
import wendy from "../../../Images/Departments/CJC/Wendy.jpeg";
import stephanie from "../../../Images/Departments/CJC/stephanie-rainey.jpg";
import jody from "../../../Images/Departments/CJC/Jody-Miller.png";
import Youtube from "../../../Components/Links/Youtube";
import modelInfo from "../../../Images/Departments/CJC/CJC-Model-Informational.jpg";
import { Helmet } from "react-helmet-async";

function AboutUs() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  const Left = () => (
    <>
      <Title>About Us</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>

      <Textbox>
        <h3>
          <b>
            The mission of the Iron County Children’s Justice Center is to
            provide a comfortable, neutral, child-friendly atmosphere for
            children to receive coordinated services during the child abuse
            investigative process
          </b>
        </h3>

        <p>
          The Iron County Children’s Justice Center is dedicated to helping our
          local community respond to allegations of child abuse in ways that are
          effective, efficient, and that minimize trauma for the child. A
          Children’s Justice Center (known as children’s advocacy centers in
          other states) is a child-focused, facility-based program in which
          representatives from law enforcement, child protection, prosecution,
          mental health, medical services, and victim advocacy work together to
          conduct interviews and make team decisions about investigation,
          prosecution, and treatment of child abuse cases.
        </p>
        <p>
          The Iron County Children’s Justice Center believes that the combined
          professional wisdom and skill of the multidisciplinary team approach
          results in a more complete understanding of case issues and the most
          effective child and family focused system response possible.
        </p>
        <p>
          <Image width="100%" height="auto" src={modelInfo} alt="CJC Model" />
        </p>
      </Textbox>
      <Youtube src="https://www.youtube.com/embed/MgQj5auRqRM?si=w5qmcQ3EOZJwRqm-" />

      <Textbox title="Our Team">
        <Half>
          <Image
            width="100%"
            height="500px"
            src={wendy}
            alt="Picture of wendy the director of the Children's Justice Center"
          />
        </Half>
        <Half>
          <h1>Wendy Jessen, Director</h1>
          <p>
            Wendy Jessen is a survivor of childhood abuse and through her own
            healing process, she discovered her life’s passion in advocating for
            others who have similarly suffered. Wendy authored a book about
            recovering from sexual abuse, and is a recipient of the Sexual
            Assault Awareness Month Award from the Utah Coalition Against Sexual
            Assault. Wendy is a graduate of Southern Utah University with a
            Bachelor of Science in Criminal Justice, and will soon complete her
            Master of Arts in Professional Communication.
          </p>
          <p>
            As a mother of six children, Wendy understands the need for
            protecting and standing up for children. Prior to becoming the
            director of the Iron County Children's Justice Center, Wendy worked
            with local law enforcement as a victim advocate, providing support
            and advocacy for victims of crime, specializing in child abuse
            cases. She strongly believes that providing a safe space for
            children and teens to tell their story while giving them access to
            advocacy, support, and resources is the best way to help kids find
            hope and healing.
          </p>
          <p>
            Wendy loves writing, reading, teaching, yoga, toe socks, double
            dates with her husband and friends, and spending time with her
            family.
          </p>
        </Half>

        <h1> Brenda Valle: Victim Services Coordinator</h1>

        <Half>
          <Image
            width="100%"
            height="500px"
            src={adelyn}
            alt="Picture of Adelyn Abbott the forensic interviewer at the CJC"
          />
        </Half>
        <Half>
          <h1>Adelyn Abbott, Forensic Interviewer</h1>
          <p>
            Adelyn “Addy” Abbott is a Forensic Interviewer who has been working
            with kids in some capacity since 2009. Addy has worked in child
            psychiatric facilities, residential treatment centers and group
            homes in Utah, Arizona, and North Carolina. Addy worked as a Child
            Protective Services Investigator for Davis County, Utah as well as a
            supervisor with the Division of Child and Family Services, for a
            total of 6 years. Addy also worked as an Investigator in Fort Worth,
            Texas on the sexual abuse, physical abuse, and fatality team for
            CPS. Addy started her career of forensically interviewing victims
            and witnesses of abuse and crimes as a CPS investigator and in 2022
            began as a dedicated forensic interviewer for Salt Lake County CJC.
            In November of 2023 she made the leap to Iron County where she began
            as a forensic interviewer at the Iron County Children’s Justice
            Center. Addy has received advanced training in forensic interviewing
            such as interviewing children with disabilities, nonverbal children,
            victims of human trafficking, and more.
          </p>
          <p>
            Addy grew up in West Valley, Utah where she enjoyed being a
            ballerina and playing the flute. Addy attended Hunter High school
            where she was a cheerleader and particularly enjoyed competing at
            nationals. Addy graduated with a Bachelors of Science in Behavioral
            Science, with an emphasis in Psychology, from Utah Valley
            University.
          </p>
          <p>
            Addy enjoys being outdoors and going on adventures with her family.
            She also won’t ever turn down a movie night with popcorn, candy, and
            a cozy blanket.
          </p>
        </Half>

        <h1> Jessica Brindley: Victim Services Specialist</h1>

        <Half>
          <Image width="100%" height="100%" src={jody} alt="Picture of Jody" />
        </Half>
        <Half>
          <h1>Jody Miller, FNP-C, APRN</h1>
          <p>
            Jody was born and raised in Cedar City, UT, attended Cedar High
            School and completed her undergraduate studies at Southern Utah
            University where she was a member of the SUU gymnastics team from
            1995 to 1998. She received her associate degree in nursing from
            Weber State University in 2000 and began her nursing career at
            Valley View Medical Center which is now known as Cedar City
            Hospital.
          </p>
          <p>
            Over her 20-year career with Cedar City Hospital she has worked in a
            variety of settings including the Medical Surgical Unit, Intensive
            Care Unit, Same Day Surgery, Recovery Room, Endoscopy, IV Therapy
            and the Imaging Department. Jody was graciously awarded the 2006,
            2016, and 2018 Nurse Excellence Award, and was named the 2007
            Employee of the Year as well as recognized with the Southern Utah
            Nursing Award for Outstanding Small Hospitalist Nursing Practice in
            2011. Jody has achieved several certifications including Pediatric
            Advanced Life Support, Advanced Life Support, PICC (IV)
            certification, and pediatric sedation provider certification.
          </p>
          <p>
            Jody also helped to create the first Imaging Nurse position at Cedar
            City Hospital and implemented the use of Nitrous Oxide for both
            pediatric and adult patients to aid in reducing procedural anxiety
            for patients. Jody obtained both her Bachelor of Nursing degree and
            Master of Nursing degree in Advanced Practice Nursing and Post
            Graduate Nurse Educator Certificate from Graceland University in
            Iowa. She was certified by the American Academy of Nurse
            Practitioner’s in 2018 and is currently pursuing her Doctorate
            degree in Advance Practice Nursing with anticipation to graduate in
            2021.
          </p>
          <p>
            Prior to joining the Iron County CJC Jody worked at a private
            pediatric practice providing primary care to pediatric patients.
            Jody is also concurrently working with Four Points Health Community
            Center providing primary care to patients in the Southern Utah area.
          </p>
          <p>
            She currently lives in Cedar City with her husband and is a mother
            to four boys and a very proud grandma to an adorable little girl.
            Jody also enjoys spending time with her family and their five dogs.
          </p>
        </Half>
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Children's Justice Center: About Us</title>
        <meta
          name="description"
          content="Iron County Children's Justice Center About Page"
        />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/cjc/about-us"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<CJCSidebar />}
        id="cjc-background"
        click={click}
      />
    </>
  );
}

export default AboutUs;

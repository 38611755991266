import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import AssessorSidebar from "./AssessorSidebar";
import Aidaform from "../../../Components/Aidaform/Aidaform";
import EmailLink from "../../../Components/Links/EmailLink";
import Textbox from "../../../Components/Text/Textbox";
import Title from "../../../Components/Text/Title";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

function PropertyExemptionForm() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Left = () => (
    <>
      <Title>Application for Residential Property Exemption</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <p>
          To apply for Residential Property Exemption, please email John
          Williams at <EmailLink>jwilliams@ironcounty.net</EmailLink>
        </p>
        {/* <Aidaform form="https://ironcounty.aidaform.com/application-for-residential-property-exemption" /> */}
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Assessor: Property Exemption Form</title>
        <meta
          name="description"
          content="Iron County Assessors Property Exemption Form Page"
        />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/assessor/property-exemption-form"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<AssessorSidebar />}
        id="assessor-background"
        click={click}
      />
    </>
  );
}

export default PropertyExemptionForm;

import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import ParksSidebar from "./ParksSidebar";
import Title from "../../../Components/Text/Title";
import Textbox from "../../../Components/Text/Textbox";
import Half from "../../../Components/Sizes/Half";
import Map from "../../../Components/Maps/Map";
import ButtonSingle from "../../../Components/Buttons/Button_Single";
import TextWebLink from "../../../Components/Links/TextWebLink";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

function ParksAndRecs() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Left = () => (
    <>
      <Title>Parks and Recreation</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>

      <Textbox>
        <p>
          Discover Cedar City, Utah – where adventure meets culture! Immerse
          yourself in the stunning landscapes of Southern Utah with hikes in
          nearby national parks.
        </p>
        <p>
          Dive into history at the Utah Shakespeare Festival, a renowned
          celebration of the arts. Indulge in local cuisine and unwind in
          charming accommodations.
        </p>
        <p>
          Whether you seek outdoor thrills or cultural enrichment, Cedar City
          offers it all. Plan your escape to Cedar City today and experience the
          perfect blend of natural beauty and artistic flair.
        </p>
      </Textbox>
      <Textbox>
        <ButtonSingle href="/departments/parks/five-mile">
          Five Mile
        </ButtonSingle>
        <ButtonSingle href="/departments/parks/shooting-range">
          Shooting Range
        </ButtonSingle>
        <ButtonSingle href="/departments/parks/three-peaks">
          Three Peaks
        </ButtonSingle>
        <ButtonSingle href="/departments/parks/wood-ranch">
          Wood Ranch
        </ButtonSingle>
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Parks and Recreation</title>
        <meta
          name="description"
          content="Iron County  Parks and Recreation Page"
        />
        <link rel="canonical" href="https://ironcounty.net/departments/parks" />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<ParksSidebar />}
        id="parks-background"
        click={click}
      />
    </>
  );
}

export default ParksAndRecs;

import React from "react";
import "./Text.css";
import Subtitle from "./Subtitle";

function HoursContact(props) {
  return (
    <div className="hoursContact">
      <Subtitle>Hours & Contact </Subtitle>

      <div>{props.children}</div>
    </div>
  );
}

export default HoursContact;

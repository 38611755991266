import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import Title from "../../../Components/Text/Title";
import Textbox from "../../../Components/Text/Textbox";
import EMSidebar from "./EMSidebar";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import Aidaform from "../../../Components/Aidaform/Aidaform";
import { Helmet } from "react-helmet-async";

function StormDamage() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Left = () => (
    <>
      <Title>Storm Reporting</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <p>
          To help facilitate an assessment for potential State & Federal
          emergency assistance, please fill out the form below (this is not an
          application or a guarantee of financial assistance.)
        </p>
      </Textbox>
      <Aidaform form="https://ironcounty.aidaform.com/storm-damage-reporting" />
    </>
  );

  return (
    <>
      <Helmet>
        <title>Emergency Management: Storm Damage</title>
        <meta
          name="description"
          content="Iron County emergency Management storm damage Page"
        />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/emergency-management/storm-damage"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<EMSidebar />}
        id="em-background"
        click={click}
      />
    </>
  );
}

export default StormDamage;

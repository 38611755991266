import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import Title from "../../../Components/Text/Title";
import Textbox from "../../../Components/Text/Textbox";
import EMSidebar from "./EMSidebar";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

function EmergencyManagement() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Left = () => (
    <>
      <Title>Emergency Management</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <h1>What We Do</h1>
        <p>
          Iron County Emergency Management is responsible for planning and
          coordinating actions to mitigate, prepare, respond, and recover from
          natural or man-made disasters in Iron County.
        </p>
        <p>
          The goal of emergency management is to reduce the loss of life and
          property; minimize suffering and the disruption caused by disasters;
          prepare the County to address the possible consequences of terrorism;
          and serve as the County’s portal for emergency management information
          and expertise.
        </p>
        <p>
          In times of emergency or disaster, Iron County Emergency Management
          coordinates, through the Emergency Operations Center, the response of
          county agencies ensuring the most appropriate resources are dispatched
          to the impacted area.
        </p>
        <p>
          Emergency Management works with local governments, volunteer
          organizations and the private sector across Iron County to develop
          disaster preparedness plans and mitigation projects, develop and
          maintain an ongoing training and exercise program for responders as
          well as provide information and training for citizens through CERT
          (Community Emergency Response Team), schools, businesses, non-profits,
          community events, and faith-based organizations. We work closely with
          the other counties within the southwest region as well as the State of
          Utah to strengthen our capabilities to create a resilient community
          that can quickly recover from the impacts of any emergency or disaster
          that may come our way.
        </p>
        <p>
          The Emergency Operations Plan and the attending Emergency Support
          Functions describe the basic strategies, assumptions and mechanisms
          through which the departments/agencies within Iron County will
          mobilize resources and conduct activities to guide and support local
          emergency management efforts through response and recovery. No perfect
          response plan or system is implied by this document, and this plan is
          not intended to limit or restrict initiative, judgment, or independent
          action(s) required to provide appropriate and effective emergency and
          disaster mitigation, preparation, response and recovery. Please follow
          the link to the Emergency Operations Plan to view the full document.
        </p>
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Emergency Management</title>
        <meta
          name="description"
          content="Iron County emergency Management Page"
        />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/emergency-management"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<EMSidebar />}
        id="em-background"
        click={click}
      />
    </>
  );
}

export default EmergencyManagement;

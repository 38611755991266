import React from "react";
import "./Card.css";
import ButtonSingle from "../Buttons/Button_Single";
import Image from "../Images/Image";
import Third from "../Sizes/Third";

function CardSmall(props) {
  return (
    <Third>
      <div className="card-container-text">
        <div className="card-text-fill">
          <section>{props.children}</section>
        </div>
      </div>
    </Third>
  );
}

export default CardSmall;

import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import BuildingSidebar from "./BuildingSidebar";
import Textbox from "../../../Components/Text/Textbox";
import Title from "../../../Components/Text/Title";
import Aidaform from "../../../Components/Aidaform/Aidaform";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

function DuctTest() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  const Left = () => (
    <>
      <Title>Duct Test Form</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <Aidaform form="https://ironcounty.aidaform.com/duct-test-form"></Aidaform>
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Building: Duct Form</title>
        <meta name="description" content="Iron County Duct Form Page" />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/building/duct-form"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<BuildingSidebar />}
        id="building-background"
        click={click}
      />
    </>
  );
}

export default DuctTest;

import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import PlanningSidebar from "./PlanningSidebar";
import Textbox from "../../../Components/Text/Textbox";
import Title from "../../../Components/Text/Title";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import PublicNoticesTable from "../../../Components/Card/PublicNoticesTable";
import ButtonSingle from "../../../Components/Buttons/Button_Single";
import { Helmet } from "react-helmet-async";

function PlanningItems() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const notices = [
    {
      title: "September 5th",
      desc: "The hearings are to receive public comment on an application for a zone change from Agriculture 20 Acre (A-20) to Rural Agriculture 20 Acre (RA-20) – Located near 7000 W 4000 S, Cedar City, UT.  Applicant: JA Watson Family Limited Partnership, c/o Kirt Watson",
      links: [
        {
          title: "PDF",
          to: "https://www.utah.gov/pmn/files/1160309.docx",
          isLink: true,
        },
      ],
    },
    {
      title: "September 5th",
      desc: "The hearing is to receive public comment on an application for a Conditional Use Permit – “Recreation, Commercial” – located near 1280 W Antelope Springs Rd, Newcastle, UT.  Applicant: Safe Gun Range, c/o Jim Harmer (208-477-9332), emailharmer@gmail.com",
      links: [
        {
          title: "PDF",
          to: "https://www.utah.gov/pmn/files/1160305.docx",
          isLink: true,
        },
      ],
    },
    {
      title: "September 5th",
      desc: "The hearing is to receive public comment on an application for a Conditional Use Permit – “Surface Mining & Agriculture Products Processing & Storage” – located near 251 N Cross Hollow Rd, Cedar City, UT.  Applicant: PCI, c/o Randy Clark (702-412-6897) rclark7081@gmail.com",
      links: [
        {
          title: "PDF",
          to: "https://www.utah.gov/pmn/files/1160299.docx",
          isLink: true,
        },
      ],
    },
    {
      title: "September 5th",
      desc: "The hearings are to receive public comment on a proposed “Development Agreement between Iron County and BZI Innovation Park, LLC. – regarding the planned development of approximately 772 acres of undeveloped real property.”",
      links: [
        {
          title: "PDF",
          to: "https://www.utah.gov/pmn/files/1160285.docx",
          isLink: true,
        },
      ],
    },
    {
      title: "July 2nd",
      desc: "The hearing is to receive public comment on an application for a Conditional Use Permit – “Recreation, Commercial” (Iron County Jail) – located near 2160 S Old Highway 91, Kanarraville, UT. Applicant: Ryan & Christie FRISBY (Frisby Farms)",
      links: [
        {
          title: "PDF",
          to: "/files/departments/planning/temp/FRISBY CUP Recr Comm PC 2jul24.pdf",
          isLink: true,
        },
      ],
    },
    {
      title: "July 2nd",
      desc: "The hearing is to receive public comment on an application for a Conditional Use Permit – “Vehicles Sales/Rental Lot” (Impound Yard) – located near 2975 W 200 S, Parowan, UT. Applicant: Spencer Woodbury (SG3 Enterprises LLC)",
      links: [
        {
          title: "PDF",
          to: "/files/departments/planning/temp/GS3 LLC CUP Veh Sales Rent lot (Impound Yard) PC 2jul24.pdf",
          isLink: true,
        },
      ],
    },
    {
      title: "July 2nd",
      desc: "The hearing is to receive public comment on an application for a Conditional Use Permit – “Correctional Institution” (Iron County Jail) – located near 631 N 2550 W, Cedar City, UT. Applicant: Iron County, c/o Iron County Sherr",
      links: [
        {
          title: "PDF",
          to: "/files/departments/planning/temp/Iron County Jail CUP Correctional Inst PC 2jul24.pdf",
          isLink: true,
        },
      ],
    },
    {
      title: "June 6th",
      desc: "The hearing is to receive public comment on an application for a Conditional Use Permit – “Solar Power Plant” (200 MW utility scale photovoltaic solar and battery energy storage system – BESS) – located near 9450 N Lund Hwy, Cedar City, UT Applicant: Appaloosa Solar II LLC, c/o rPlus Energies, LLC",
      links: [
        {
          title: "PDF",
          to: "https://www.utah.gov/pmn/files/1128035.pdf",
          isLink: true,
        },
      ],
    },
    {
      title: "June 6th",
      desc: "The hearings are to receive public comment on proposed revisions and amendments to the Iron County Land Use Code – Ordinance 2024-5 “Adding Sections 17.30.090 – Designated Livestock Trails”",
      links: [
        {
          title: "PDF",
          to: "https://www.utah.gov/pmn/files/1128019.pdf",
          isLink: true,
        },
      ],
    },
    {
      title: "June 6th",
      desc: "Iron County Land Use Code – Ordinance 2024-4 “Adding Sections 17.36.135 – Landscaping Requirements for waterwise practices and adding Chapter 16.40 – Landscaping for water conservation”",
      links: [
        {
          title: "PDF",
          to: "https://www.utah.gov/pmn/files/1128017.pdf",
          isLink: true,
        },
      ],
    },
    {
      title: "June 6th",
      desc: "The hearing is to receive public comment on an application for a Conditional Use Permit – “Outside Storage of Flammable or Hazardous Materials” (Propane Storage and Distribution) – located near the intersection of Iron Springs Rd and N Comstock Rd (3425 N Iron Springs Rd) Cedar City, UT. Applicant: Utah Propane, c/o Dean Herger",
      links: [
        {
          title: "PDF",
          to: "https://www.utah.gov/pmn/files/1128013.pdf",
          isLink: true,
        },
      ],
    },
    {
      title: "June 6th",
      desc: "The hearing is to receive public comment on an application for a Conditional Use Permit – “Resort Lodge & Recreation, Commercial” (Outdoor recreation activities including ATVs, motorbikes, horseback riding, winter sports, etc.) – located near the west end of 825 N (1475 W) and approximately 975 N, Brian Head, UT Applicant: Iron Crest LLC,represented by David Farley",
      links: [
        {
          title: "PDF",
          to: "https://www.utah.gov/pmn/files/1128009.pdf",
          isLink: true,
        },
      ],
    },
    {
      title: "June 6th",
      desc: "The hearings are to receive public comment on an application for a zone change from Agriculture 20 Acres (A-20) to Light Industrial (LI) – located near 500 E SR 56, Beryl, Utah. Applicant: Nathan Harker",
      links: [
        {
          title: "PDF",
          to: "https://www.utah.gov/pmn/files/1128005.pdf",
          isLink: true,
        },
      ],
    },
  ];
  const Left = () => (
    <>
      <Title>Planning Commission Meeting Items</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <ButtonSingle href="/departments/planning/agenda">
          Planning Commission Meeting Agendas
        </ButtonSingle>
        <p>
          Meetings held the first Thursday of each month at 5:30 PM Festival
          Hall, 105 N Main St. Room #1 (Check public notices for change of time
          or place.)
        </p>
        <PublicNoticesTable data={notices} />
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Planning: Items</title>
        <meta name="description" content="Iron County Planning items Page" />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/planning/items"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<PlanningSidebar />}
        id="engineering-background"
        click={click}
      />
    </>
  );
}

export default PlanningItems;

import React from "react";
import ButtonIcons from "../../../../Components/Buttons/Button_Icons";
import "../../Home.css";

function Popular() {
  const PopularButtons = [
    {
      title: "Agenda & Minutes",
      to: "/departments/commission/commission-archive",
    },
    {
      title: "Clerk",
      to: "/departments/clerk",
    },
    {
      title: "Election Results",
      to: "/departments/clerk/election-results",
    },
    {
      title: "GIS Maps",
      to: "/departments/it/gis",
    },
    {
      title: "Justice Court",
      to: "/departments/justice-court",
    },
    {
      title: "Pay your Property Tax",
      to: "https://ironcounty.billtrax.com/biller/quick-pay",
      isLink: true,
    },
    {
      title: "Pay Mobile Home Tax",
      to: "https://secureinstantpayments.com/sip/reports/account_selector.php?FromPopup=1&Merchant=25470&AddrReq=x&Account=",
      isLink: true,
    },
    {
      title: "Pay Business Tax",
      to: "https://secureinstantpayments.com/sip/reports/account_selector.php?FromPopup=1&Merchant=25470&AddrReq=x&Account=",
      isLink: true,
    },

    {
      title: "Recorder / Surveyor",
      to: "/departments/recorders",
    },

    {
      title: "Treasurer",
      to: "/departments/treasurer",
    },
  ];
  return (
    <div id="apply-container">
      <ButtonIcons
        buttons={PopularButtons}
        id="home-buttons"
        className="buttons-container-home"
      />
    </div>
  );
}

export default Popular;

import React from "react";
import { Link } from "react-router-dom";
import "./Buttons.css";

function ButtonIcons(props) {
  return (
    <div className="buttons-container">
      {props.buttons.map((butt) => {
        if (butt.isLink === true) {
          return (
            <a target="_blank" href={butt.to}>
              <button className="icon-button" id={props.id}>
                {butt.icon} {butt.title}
              </button>
            </a>
          );
        } else {
          return (
            <Link to={butt.to}>
              <button className="icon-button" id={props.id}>
                {butt.icon} {butt.title}
              </button>
            </Link>
          );
        }
      })}
    </div>
  );
}

export default ButtonIcons;

// <div className={props.className}>
//       {props.buttons.length === 0 ? (
//         <p>No Buttons</p>
//       ) : (
//         <>
//           {console.log("I reached ture")}
//           {props.buttons.link === true
//             ? props.buttons.map((butt) => (
//                 <a target="_blank" href={butt.to}>
//                   <button className="icon-button" id={props.id}>
//                     {butt.icon} {butt.title}
//                   </button>
//                 </a>
//               ))
//             : props.buttons.map((butt) => (
//                 // <Link to={butt.to}>
//                 <button className="icon-button" id={props.id}>
//                   {butt.icon} {butt.title}
//                 </button>
//                 // </Link>
//               ))}
//         </>
//       )}
//     </div>

import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import JusticeSideBar from "./JusticeSidebar";
import Title from "../../../Components/Text/Title";
import Textbox from "../../../Components/Text/Textbox";
import { Helmet } from "react-helmet-async";
import ButtonSingle from "../../../Components/Buttons/Button_Single";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";

function CourtCalendar() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Left = () => (
    <>
      <Title>Court Calendar</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <p>
          <ul>
            <li>
              If you need to attend a Virtual Jail Hearing, please contact the
              Court at 435-865-5335 and the Court will email you the necessary
              link and information.
            </li>
            <li>Video will start at scheduled time.</li>
            <li>Please Refer to Justice Court Calendar to check your time.</li>
            <li>
              (You will need to download the WEBEX APP if you do not already
              have it)
            </li>
          </ul>
        </p>

        {/* <ButtonSingle
          href="https://utcourts.webex.com/webappng/sites/utcourts/dashboard/pmr/judgebdunlap"
          isLink={true}
        >
          Join Video Meeting
        </ButtonSingle> */}
        <ButtonSingle
          href="https://legacy.utcourts.gov/cal/data/Just_Cedar_City_1103_Calendar.pdf"
          isLink={true}
        >
          Justice Court Calendar
        </ButtonSingle>
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Justice Court: Court Calendar</title>
        <meta
          name="description"
          content="Iron County Justice Court court calendar Page"
        />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/justice-court/court-calendar"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<JusticeSideBar />}
        id="justice-background"
        click={click}
      />
    </>
  );
}

export default CourtCalendar;

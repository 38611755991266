import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import JusticeSideBar from "./JusticeSidebar";
import Title from "../../../Components/Text/Title";
import Textbox from "../../../Components/Text/Textbox";
import Half from "../../../Components/Sizes/Half";
import Youtube from "../../../Components/Links/Youtube";
import Aidaform from "../../../Components/Aidaform/Aidaform";
import CardAccordion from "../../../Components/Card/CardAccordion";
import TextWebLink from "../../../Components/Links/TextWebLink";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

function EntryOfPlea() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Left = () => (
    <>
      <Title>Entry of Plea</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>

      <Textbox>
        <p>
          If you intend to enter a plea of guilty or no contest, and you live
          out of the area, a “Sentence by Mail/Email,” is available. Please
          enter your plea using the form below (Plea by mail is not available
          for DUI’s and Domestic Violence charges).
        </p>
        <p>Watch the following Rights video in your preferred language</p>
      </Textbox>
      <Textbox>
        <Half>
          <Youtube
            src="https://www.youtube.com/embed/Y9Rrn-bTfrY"
            title="English"
          ></Youtube>
        </Half>
        <Half>
          <Youtube
            src="https://www.youtube.com/embed/eDaXvwgboxE"
            title="Spanish"
          ></Youtube>
        </Half>
      </Textbox>
      <Textbox title="Fill out the Entry of Plea Form">
        <p>
          The Entry of Plea Form includes the Rights, Instructions, and Waiver
          form, as well as the Right to Counsel form (Screening for a
          court-appointed Attorney is available if you are pleading not guilty
          to misdemeanor C and B charges). Both forms are available in English
          or Spanish, and all three forms will be submitted together and sent
          automatically to the Justice Court upon completion.
        </p>
        <CardAccordion title="Entry of Plea Form English">
          <Aidaform
            form="https://ironcounty.aidaform.com/entry-of-plea-form"
            title=""
          />
        </CardAccordion>
        <CardAccordion title="Entry of Plea Form Spanish">
          <Aidaform
            form="https://ironcounty.aidaform.com/entry-of-plea-form-spanish"
            title=""
          />
        </CardAccordion>
        <h2>Declaration and Order of Financial Status</h2>
        <p>
          Please submit your Rights Form and Right to Counsel Form (both
          included in the Entry of Plea form above) at least 48 hours prior to
          your Arraignment. If you are pleading Not Guilty your Arraignment date
          will be continued to a Pre-Trial Conference date.
          <TextWebLink link="/files/departments/justice-court/financial-status-declaration-and-order.pdf">
            Click here to download Declaration and Order of Financial Status..
          </TextWebLink>
        </p>
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Justice Court: Entry of Plea</title>
        <meta
          name="description"
          content="Iron County Justice Court entry of plea Page"
        />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/justice-court/entry-of-plea"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<JusticeSideBar />}
        id="justice-background"
        click={click}
      />
    </>
  );
}

export default EntryOfPlea;

import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import JusticeSideBar from "./JusticeSidebar";
import Textbox from "../../../Components/Text/Textbox";
import Aidaform from "../../../Components/Aidaform/Aidaform";
import Title from "../../../Components/Text/Title";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

function TrafficSchoolEnrollment() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Left = () => (
    <>
      <Title>Online Traffic School Enrollment</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>

      <Textbox>
        <h2>
          <b>
            DO NOT PAY FINE UNTIL AFTER YOU'VE RECEIVED TRAFFIC SCHOOL
            ENROLLMENT EMAIL CONFIRMATION
          </b>
        </h2>
        <h3>
          You will receive a confirmation that your request for enrollment was
          received. This is NOT confirmation of actual enrollment. You will
          receive another email directly form the Court confirming you qualify
          to enroll, after your case has been reviewed.
        </h3>
        <p>
          You have 30 days from the date of your citation to initiate your
          interest in a Traffic School Plea in Abeyance. To enroll in a Traffic
          School Plea in Abeyance, please fill out the enrollment form below.
        </p>
        <p>
          <em>
            Please allow one business day for confirmation of your registration.
            Wait to pay your fine until AFTER you have confirmation of
            enrollment in Traffic School.
          </em>
        </p>
      </Textbox>
      <Aidaform
        form="https://ironcounty.aidaform.com/traffic-school-enrollment-form"
        title=""
      />
    </>
  );

  return (
    <>
      <Helmet>
        <title>Justice Court: Traffic School Enrollment</title>
        <meta name="description" content="Iron County Justice Court Page" />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/justice-court/traffic-school-enrollment"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<JusticeSideBar />}
        id="justice-background"
        click={click}
      />
    </>
  );
}

export default TrafficSchoolEnrollment;

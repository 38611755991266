import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import CJCSidebar from "./CJCSidebar";
import Title from "../../../Components/Text/Title";
import Textbox from "../../../Components/Text/Textbox";
import TextWebLink from "../../../Components/Links/TextWebLink";
import PhoneLink from "../../../Components/Links/PhoneLink";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

function ResourcesCJC() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Left = () => (
    <>
      <Title>Resources</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <h1>Information about CJCs and Victims’ Rights</h1>
        <p>
          <li>
            Utah Attorney General’s CJC Program
            <TextWebLink link="https://attorneygeneral.utah.gov/childrens-justice-centers/   ">
              More Info
            </TextWebLink>
          </li>
          <li>
            The Children’s Justice Center Program
            <TextWebLink link="http://www.utahcjc.org/">More Info</TextWebLink>
          </li>
          <li>
            Victims’ Rights
            <TextWebLink link="https://attorneygeneral.utah.gov/resources/victim-rights/">
              More Info
            </TextWebLink>
          </li>
          <li>
            Sex Offender Registry and Offender Search tool
            <TextWebLink link="https://corrections.utah.gov/">
              More Info
            </TextWebLink>
          </li>

          <li>
            The Force Behind Children’s Advocacy Centers – National Children’s
            Alliance
            <TextWebLink link="https://www.nationalchildrensalliance.org/  ">
              More Info
            </TextWebLink>
          </li>
        </p>
        <h1>Iron County Resources:</h1>

        <p>
          <li>
            Canyon Creek Services - sexual assault and domestic violence service
            organization
            <TextWebLink link="https://www.canyoncreekservices.org/">
              More Info
            </TextWebLink>
          </li>

          <li>
            Iron County Victim Services (law enforcement-based victim advocacy)
            <PhoneLink>435-867-7544</PhoneLink>
          </li>
          <li>
            Family Support Center - youth mentoring, nursery, home visitation
            and exchange, and post adopt services
            <TextWebLink link="https://familysupportutah.org/">
              More Info
            </TextWebLink>
          </li>
          <li>
            Cherish Families - Connecting individual and families from plural
            family cultures with tools and resources
            <TextWebLink link="https://cherishfamilies.org/  ">
              More Info
            </TextWebLink>
          </li>
          <li>
            Care and Share - food pantry and emergency shelter
            <TextWebLink link="https://www.careandshare-ut.org/">
              More Info
            </TextWebLink>
          </li>
          <li>
            Youth Futures - safe shelter, resources, and guidance for homeless
            and at-risk youth
            <TextWebLink link="https://www.youthfuturesutah.org/  ">
              More Info
            </TextWebLink>
          </li>
          <li>
            Mental Health providers in Iron County
            <TextWebLink link="https://cedarcitymh.com/">More Info</TextWebLink>
          </li>
          <li>
            Self-help court assistance program (child protective orders, etc.)
            <TextWebLink link="https://www.utcourts.gov/en/self-help/categories/protect-order.html">
              More Info
            </TextWebLink>
          </li>
        </p>
        <h1>Nationwide Resources and Crisis Hotlines:</h1>
        <p>
          <ul className="no-dots">
            <li>
              <b>Call 911 if you have an emergency or are in danger</b>
            </li>
            <li>
              Domestic Violence Crisis Line 1-800-897-LINK (5465) Española
            </li>
            <li>Elder Abuse Reporting Hotline 1-800-271-7897</li>
            <li>Rape Recovery Center Hotline 1-801-467-7273</li>
            <li>
              Saprea - Liberating individuals and society from child sexual
              abuse and its lasting impacts.
              <TextWebLink link="https://saprea.org/  ">Click Here</TextWebLink>
            </li>
            <li>
              The Trevor Project - crisis intervention and suicide prevention
              for LGBTQ youth{" "}
              <TextWebLink link="https://www.thetrevorproject.org/  ">
                Click Here
              </TextWebLink>
            </li>
            <li>
              Child help hotline -{" "}
              <TextWebLink link="https://www.childhelphotline.org/  ">
                Click Here
              </TextWebLink>
            </li>
            <li>
              Healing the Invisible Wounds - helping children who have been
              exposed to violence{" "}
              <TextWebLink link="https://ojjdp.ojp.gov/sites/g/files/xyckuh176/files/programs/safestart/HealingTheInvisibleWounds.pdf">
                Click Here
              </TextWebLink>
            </li>
            <li>
              Tips for talking to children about child abuse -{" "}
              <TextWebLink link="https://ovc.ojp.gov/sites/g/files/xyckuh226/files/media/document/catipsheet.pdf  ">
                Click Here
              </TextWebLink>
            </li>
            <li>
              Tips for caregivers -{" "}
              <TextWebLink link="https://www.justice.gov/sites/default/files/defendingchildhood/legacy/2011/09/19/tips-caregivers.pdf  ">
                Click Here
              </TextWebLink>
            </li>
            <li>
              Tips for teachers -{" "}
              <TextWebLink link="https://www.justice.gov/sites/default/files/defendingchildhood/legacy/2011/09/19/tips-teachers.pdf">
                Click Here
              </TextWebLink>
            </li>
          </ul>
        </p>
        <h1>Cell phone / electronic device safety resources:</h1>
        <p>
          <ul className="no-dots">
            <li>
              Resources and robust courses to help ensure kids build resilience
              and resistance to hypersexualized media.{" "}
              <TextWebLink link="https://culturereframed.org/ ">
                Click Here
              </TextWebLink>
            </li>
            <li>
              Social Media & Mobile Phone Contract.{" "}
              <TextWebLink link="https://parents.culturereframed.org/contract/">
                Click Here
              </TextWebLink>
            </li>
            <li>
              Empowering caregivers to protect their family online with a safer
              way to explore the internet.{" "}
              <TextWebLink link="https://canopy.us/">Click Here</TextWebLink>
            </li>
            <li>
              Online protection with parental controls that build trust.{" "}
              <TextWebLink link="https://www.bark.us/learn/home/">
                Click Here
              </TextWebLink>
            </li>
            <li>
              Phone monitoring and restrictions to help keep your child safe.{" "}
              <TextWebLink link="https://www.mmguardian.com/  ">
                Click Here
              </TextWebLink>
            </li>
            <li>
              Phones and products to keep kids safe while still connecting them
              to friends and family.{" "}
              <TextWebLink link="https://gabb.com/">Click Here</TextWebLink>
            </li>
          </ul>
        </p>
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Children's Justice Center: Resources</title>
        <meta
          name="description"
          content="Iron County Children's Justice Center Resources Page"
        />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/cjc/resources"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<CJCSidebar />}
        id="cjc-background"
        click={click}
      />
    </>
  );
}

export default ResourcesCJC;

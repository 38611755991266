import React from "react";
import "./Map.css";
import Subtitle from "../Text/Subtitle";

function Map(props) {
  return (
    <section>
      {props.title ? <Subtitle>{props.title}</Subtitle> : null}
      <hr />
      <iframe
        class="basic-map"
        src={props.src}
        frameborder="0"
        allowfullscreen
        title={props.title}
      ></iframe>
    </section>
  );
}

export default Map;

import React from "react";

function DepartmentTemplate(props) {
  return (
    <div className="hero-container" {...props}>
      <div className="department-container">
        <div className="department-left">{props.left}</div>

        <div
          className={
            props.click ? "department-right active" : "department-right"
          }
        >
          {props.right}
        </div>
      </div>
    </div>
  );
}

export default DepartmentTemplate;

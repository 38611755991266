export const BusinessData = [
  {
    title: "Business License",
    to: "/departments/assessor",
    isLink: false,
  },
  {
    title: "Building Permit",
    to: "https://ironcounty.mycityinspector.com/register?registration_id=15ccc64ec1d01c",
    isLink: true,
  },
  {
    title: "Change of Business Form",
    to: "/departments/assessor/change-business-form",
    isLink: false,
  },
  {
    title: "Codes and Ordinances",
    to: "https://library.municode.com/ut/iron_county/codes/code_of_ordinances",
    isLink: true,
  },
  {
    title: "Greenbelt Act Information",
    to: "/departments/assessor/greenbelt-act",
    isLink: false,
  },
  {
    title: "Personal Property Information",
    to: "/departments/assessor/personal-property",
    isLink: false,
  },
  {
    title: "Tax Areas",
    to: "https://irongis.maps.arcgis.com/apps/View/index.html?appid=161f9748fa7340009b42ed4a36ae1abd",
    isLink: true,
  },
];

export const submenuItems = [
  //Department Dropdown
  [
    {
      title: "Assessor",
      url: "/departments/assessor",
      cName: "dropdown-link",
    },
    {
      title: "Attorney",
      url: "/departments/attorney",
      cName: "dropdown-link",
    },
    {
      title: "Auditor",
      url: "/departments/auditor",
      cName: "dropdown-link",
    },
    {
      title: "Building and Zoning",
      url: "/departments/building",
      cName: "dropdown-link",
    },
    {
      title: "Childrens Justice Center",
      url: "/departments/cjc",
      cName: "dropdown-link",
    },
    {
      title: "Clerk",
      url: "/departments/clerk",
      cName: "dropdown-link",
    },
    {
      title: "Commission",
      url: "/departments/commission",
      cName: "dropdown-link",
    },
    {
      title: "Emergency Management",
      url: "/departments/emergency-management",
      cName: "dropdown-link",
    },
    {
      title: "Engineering ",
      url: "/departments/engineering",
      cName: "dropdown-link",
    },
    {
      title: "Extension Office",
      url: "https://extension.usu.edu/iron/",
      cName: "dropdown-link",
      isLink: true,
    },
    {
      title: "Event Center / Fair",
      url: "https://ironcountyfair.net/",
      cName: "dropdown-link",
      isLink: true,
    },
    {
      title: "Fire",
      url: "/departments/fire",
      cName: "dropdown-link",
    },
    {
      title: "Fleet",
      url: "/departments/fleet",
      cName: "dropdown-link",
    },

    {
      title: "Human Resources",
      url: "/departments/hr",
      cName: "dropdown-link",
    },
    {
      title: "Information Technology",
      url: "/departments/it",
      cName: "dropdown-link",
    },

    {
      title: "Justice Court",
      url: "/departments/justice-court",
      cName: "dropdown-link",
    },
    {
      title: "Natural Resources",
      url: "/departments/natural-resources",
      cName: "dropdown-link",
    },
    {
      title: "Parks & Recreation",
      url: "/departments/parks",
      cName: "dropdown-link",
    },
    {
      title: "Planning",
      url: "/departments/planning",
      cName: "dropdown-link",
    },
    {
      title: "Recorder & Surveyor",
      url: "/departments/recorders",
      cName: "dropdown-link",
    },
    {
      title: "Roads",
      url: "/departments/roads",
      cName: "dropdown-link",
    },
    {
      title: "Senior Center",
      url: "/departments/senior-center",
      cName: "dropdown-link",
    },
    {
      title: "Solid Waste",
      url: "/departments/solid-waste",
      cName: "dropdown-link",
    },
    {
      title: "Treasurer",
      url: "/departments/treasurer",
      cName: "dropdown-link",
    },
    {
      title: "Weeds",
      url: "/departments/weeds",
      cName: "dropdown-link",
    },
  ],

  //Business Links
  [
    {
      title: "Business License",
      url: "/departments/clerk/business-license",
      cName: "dropdown-link",
    },
    {
      title: "Building Permit",
      url: "https://ironcounty.mycityinspector.com/register?registration_id=15ccc64ec1d01c",
      cName: "dropdown-link",
      isLink: true,
    },
    {
      title: "Change of Business Form",
      url: "/departments/assessor/change-business-form",
      cName: "dropdown-link",
    },
    {
      title: "Codes and Ordinances",
      url: "https://library.municode.com/ut/iron_county/codes/code_of_ordinances",
      cName: "dropdown-link",
      isLink: true,
    },
    {
      title: "Greenbelt Act Information",
      url: "/departments/assessor/greenbelt-act",
      cName: "dropdown-link",
    },
    {
      title: "Personal Property ",
      url: "/departments/assessor/personal-property",
      cName: "dropdown-link",
    },
    {
      title: "Tax Areas",
      url: "https://irongis.maps.arcgis.com/apps/View/index.html?appid=161f9748fa7340009b42ed4a36ae1abd",
      cName: "dropdown-link",
      isLink: true,
    },
  ],
];

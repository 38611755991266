import React from "react";
import AccordionSidemenu from "../../../Components/AccordionMenu/AccordionSidemenu";
import "../../../Components/Sidebar/Sidebar.css";
import HoursContact from "../../../Components/Text/HoursContact";
import TextWebLink from "../../../Components/Links/TextWebLink";
import PhoneLink from "../../../Components/Links/PhoneLink";

function CJCSideBar() {
  const navItems = [
    {
      title: "CJC Home",
      to: "/departments/cjc",
      isLink: false,
    },
    {
      title: "About Us",
      to: "/departments/cjc/about-us",
      isLink: false,
    },
    {
      title: "Contact Us",
      to: "/departments/cjc/contact-us",
      isLink: false,
    },
    {
      title: "Donate",
      to: "/departments/cjc/donate",
      isLink: false,
    },

    {
      title: "Friends Board/Volunteer",
      to: "/departments/cjc/volunteer",
      isLink: false,
    },
    {
      title: "Medical Exams",
      to: "/departments/cjc/medical-exams",
      isLink: false,
    },
    {
      title: "Mental Health Services",
      to: "/departments/cjc/mental-health-services",
      isLink: false,
    },

    {
      title: "Report Abuse",
      to: "/departments/cjc/report-abuse",
      isLink: false,
    },
    {
      title: "Resources",
      to: "/departments/cjc/resources",
      isLink: false,
    },
    {
      title: "Facebook",
      to: "https://www.facebook.com/people/Iron-County-Childrens-Justice-Center/100068952378259/",
      isLink: true,
    },
    {
      title: "Instagram",
      to: "https://www.instagram.com/iron_county_cjc/",
      isLink: true,
    },
  ];

  return (
    <>
      <div className="nav-menu-side">
        <HoursContact>
          <p>
            Iron County Children’s Justice Center
            <br />
            Wendy Jessen, DirectorCJC
            <br />
            1622 E. 5250 N. Enoch, UT 84721
            <br />
            Monday - Friday 8:00 A.M. - 5:00 P.M.
            <br />
            <PhoneLink>435-867-4275</PhoneLink>
          </p>
        </HoursContact>
        <br />
        <AccordionSidemenu nav={navItems} id="department-sidebar" />
      </div>
    </>
  );
}

export default CJCSideBar;

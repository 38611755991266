import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import Title from "../../../Components/Text/Title";
import Textbox from "../../../Components/Text/Textbox";
import EMSidebar from "./EMSidebar";
import CardAccordion from "../../../Components/Card/CardAccordion";
import Half from "../../../Components/Sizes/Half";
import Subtitle from "../../../Components/Text/Subtitle";
import Button_Single from "../../../Components/Buttons/Button_Single";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

function CitizenAlert() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Left = () => (
    <>
      <Title>Citizen Alert</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <p>
          Receive alerts for emergencies and other important community messages
          by signing up for the Citizen Alert System for Iron County and
          surrounding cities.
        </p>
        <p>
          This notification system enables Iron County and the cities within the
          county to provide you with critical information quickly in a variety
          of situations, such as unexpected road closures, missing persons, and
          evacuation of buildings or neighborhoods.
        </p>
        <p>
          You will receive time-sensitive messages using whichever method you
          specify, such as your home, cell, or business phone, email, text
          message, hearing impaired receiving devices, and more. You pick where,
          you pick how.
        </p>
        <p>
          Please take note that most phone voice alerts caller id will be from
          <b>(435) 867-7610</b>, especially in an emergency. It may be a good
          idea to add this phone number in your contacts lists as “Citizen
          Alert” or something similar so that you know who it is calling you.
          Also, please understand this is not a phone number that you may call
          with questions. You must contact the agency that sent you an alert
          with any questions you may have.
        </p>
      </Textbox>
      <Textbox>
        <Half>
          <Subtitle>How to Register</Subtitle>
          <p>
            If you would like to participate in the public notification systems
            offered by the communities in Iron County, you must register below.
            You do not need to be a resident within Iron County, but you must
            have at least one address in Iron County to link to your account.
            This alert system is location based. That means you will only get
            alerts for incidents in your immediate area.
          </p>
          <Button_Single
            isLink={true}
            href="https://member.everbridge.net/index/453003085611405#/signup"
          >
            Register for Alerts
          </Button_Single>
        </Half>
        <Half>
          <Subtitle>Contact Information Change</Subtitle>
          <p>
            The system is only as good as the information you provide. If your
            cell phone, work phone or email address changes, you must go to your
            profile and update the information. The one exception is traditional
            listed landlines. If a traditional listed landline changes to
            another listed landline number, that information will be
            automatically updated annually. You may also add or remove the
            address you attached to your account at anytime.
          </p>
          <Button_Single
            isLink={true}
            href="https://member.everbridge.net/453003085611405/login"
          >
            Manage My Profile
          </Button_Single>
        </Half>
      </Textbox>
      <Textbox title="Frequently Asked Questions">
        <CardAccordion title="Citizen Alert Overview">
          <p>
            We have launched a new notification service that allows us to alert
            you to an emergency or an important community alert in your area.
            The system uses listed phone numbers. You may also enter additional
            ways of contacting you, such as cell phones, business phones, and
            email. The information you enter is protected and will not be used
            for any other purpose.
          </p>
        </CardAccordion>
        <CardAccordion title="How Does the Notification System Work?">
          <p>
            When we issue a message about a potential safety hazard or concern,
            messages will be sent to all standard voice and text communication
            devices that you have registered, including landline phones, cell
            phones, e-mail, text message and more. If you don’t confirm receipt
            of the message, the system will try to reach your second contact
            number or email. The system will continue trying to contact you
            until it receives a confirmation from you.
          </p>
        </CardAccordion>
        <CardAccordion title="How do I Sign Up?">
          <p>
            Residents and businesses with listed telephone numbers have already
            been included in the system. You may use the registration link to
            include additional ways to contact you. All information you provide
            will be kept strictly confidential.
          </p>
          <Button_Single
            href="https://member.everbridge.net/index/453003085611405#/signup"
            isLink={true}
          >
            Register for Alerts
          </Button_Single>
        </CardAccordion>
        <CardAccordion title="How do I Update My Contact Information or Preferences?">
          <p>
            Already signed up for alerts? Log in to update your contact
            information or preferences.
          </p>
          <Button_Single
            href="https://member.everbridge.net/453003085611405/login"
            isLink={true}
          >
            Login
          </Button_Single>
        </CardAccordion>
        <CardAccordion title="When Will the System Be Used?">
          <p>
            The system will be used to notify residents about imminent threats
            to health, safety or for important community alerts.
          </p>
        </CardAccordion>
        <CardAccordion title="How do I Stop Receiving Alerts?">
          <p>
            To opt out of Important Community Alerts, login to your profile and
            opt out. By opting out of Important Community Alerts, you will no
            longer receive community alerts.
          </p>
        </CardAccordion>
        <CardAccordion title="What If My Phone Number or Email Address Change?">
          <p>
            The system is only as good as the information you provide. If your
            cell phone, work phone or email address changes, you must go to your
            profile and update the information. The one exception is traditional
            listed landlines. If a traditional listed landline changes to
            another listed landline number, that information will be
            automatically updated annually.
          </p>
        </CardAccordion>
        <CardAccordion title="Will I Still Get Emergency Notifications if I Don't Sign Up?">
          <p>
            If you don’t sign up, you might still receive notifications on your
            traditional listed home or business phone, but nowhere else. You{" "}
            <b> MUST </b>sign up to ensure that you will receive alerts.
          </p>
        </CardAccordion>
        <CardAccordion title="Will My Contact Information Be Shared with Others?">
          <p>
            No, the information that you provide will be used for notification
            purposes only. We will not give or sell your telephone numbers or
            email addresses to any vendor or other organization.
          </p>
        </CardAccordion>
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Emergency Management: Citizen Alert</title>
        <meta
          name="description"
          content="Iron County emergency Management Page"
        />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/emergency-management/citizen-alert"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<EMSidebar />}
        id="em-background"
        click={click}
      />
    </>
  );
}

export default CitizenAlert;

import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import JusticeSideBar from "./JusticeSidebar";
import Title from "../../../Components/Text/Title";
import Textbox from "../../../Components/Text/Textbox";
import TextWebLink from "../../../Components/Links/TextWebLink";
import TextWebLinkList from "../../../Components/Links/TextWebLinkList";
import EmailLink from "../../../Components/Links/EmailLink";
import Half from "../../../Components/Sizes/Half";
import Subtitle from "../../../Components/Text/Subtitle";
import { Link } from "react-router-dom";
import ButtonIcons from "../../../Components/Buttons/Button_Icons";
import CardAccordion from "../../../Components/Card/CardAccordion";
import CardFAQ from "../../../Components/Card/CardFAQ";
import PhoneLink from "../../../Components/Links/PhoneLink";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

function TrafficSchool() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  const trafficButton = [
    {
      title: "Enroll in Traffic School ",
      to: "/departments/justice-court/traffic-school-enrollment",
    },
    {
      title: "Contact Traffic School",
      to: "/departments/justice-court/traffic-school-contact",
    },
  ];

  const FAQ = [
    {
      question:
        "I Received a Traffic Citation and Paid it. Can I Still Take Traffic School?",
      answer: `No. Once you’ve paid the fine, your charge has gone "of record."`,
    },
    {
      question: "If I Take Traffic School, Do I Still Have to Pay My Fine?",
      answer:
        "Yes. Traffic school costs are not in lieu of the above mentioned fine and Plea in Abeyance fee.",
    },
    {
      question:
        "I Paid My Fine and Plea in Abeyance Fee, But I Never Completed Traffic School. What Will Happen to My Case?",
      answer:
        "After your 3-month probation period ends you will receive a notice via email with a sentencing date for your case. This is your chance to appear before our judge and provide proof of compliance with the Plea in Abeyance agreement. If you do not provide sufficient proof of compliance for the judge to dismiss your charge, or if you do not show up for your court date, your charge will go of record and your case will be closed. No warrant will be issued unless payment of your fine has not been received by the court.",
    },
    {
      question:
        "I Have Signed and Returned All of the Paperwork, Paid My Fine and Fees and Completed Traffic School. What Happens Now?",
      answer:
        "You must abide by any other requirements in the Plea in Abeyance, which includes maintaining a clean driving record and not receiving any more citations on a state or local level for 3-months from the day you return your Plea in Abeyance agreement signed. Your citation will be dismissed at the end of your 3-month probation period, once compliance to these terms has been established.",
    },
  ];

  const Left = () => (
    <>
      <Title>Online Traffic School</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <p>
          <h2 className="text-red">
            DO NOT PAY FINE UNTIL AFTER YOU'VE RECEIVED TRAFFIC SCHOOL
            ENROLLMENT EMAIL CONFIRMATION
          </h2>
          <h3>
            You will receive a confirmation that your request for enrollment was
            received. This is NOT confirmation of actual enrollment. You will
            receive another email directly from the Court confirming you qualify
            to enroll, after your case has been reviewed.
          </h3>
        </p>
      </Textbox>
      <Textbox>
        <Half>
          <p>
            Iron County offers an online traffic school course for moving
            violations issued by:
            <li>Utah Highway Patrol</li>
            <li>Iron County Sheriff’s Office</li>
            <li>City of Enoch Police Department</li>
            <li>Brian Head Marshal’s Office</li>
            <li>Cedar City Police Department</li>
          </p>
          <Subtitle>
            For ALL citations issued in Iron County, please perform the
            following steps:
          </Subtitle>
          <p>
            <ol>
              <li>
                You must click the “Enroll in Traffic School Plea in Abeyance”
                button to enroll in a Plea in Abeyance for Traffic School.
              </li>
              <li>
                You must pay your fine amount, plus a $25.00 Plea in Abeyance
                fee at signup.
              </li>
              <li>
                You must sign and return the Plea in Abeyance form and the
                Rights/Instructions form within 10 days.
              </li>
              <li>
                Scanned copies or cell phones pictures acceptable if emailed or
                faxed.
              </li>
              <li>
                You must sign up for online traffic school, pay the required fee
                ($45.00) and complete the course within 30 days.
                <ol type="A">
                  <li>
                    For SUU: You must sign up directly with the SUU Police
                    Department and pay the required fee ($50.00).
                  </li>
                </ol>
              </li>
              <li>
                We will check your driving record after 90 days from date you
                return your signed Plea in Abeyance Agreement. If there are no
                more citations and you’ve complied with all other conditions of
                your Plea in Abeyance Agreement, your charge will be dismissed.
              </li>
            </ol>
          </p>
        </Half>
        <Half>
          <p>
            SUU Police Department provides their own “in-person” Traffic School.
            You still sign up with the Justice Court, following the steps below.
            However, one additional step is required, in which you contact them
            directly at SUU Police at <PhoneLink>435-586-7793</PhoneLink>.
            Enrollment cost = $50.00, class must be attended in person. More
            information click{" "}
            <TextWebLink link="https://www.suu.edu/police/fingerprinting-traffic-school.html">
              HERE
            </TextWebLink>
            <li>
              <b>
                If you are charged with speed 100 mph or over, charged with 26
                MPH or above the posted limit, or a class B misdemeanor moving
                violation, you are not eligible for traffic school.
              </b>
            </li>
            <li>
              <b>
                If you possess a commercial driver’s license (CDL), you are not
                eligible for traffic school.
              </b>
            </li>
            <li>
              <b>
                If you have an accident on your case, you are not eligible for
                traffic school.
              </b>
            </li>
          </p>
          <Subtitle>Fee Information:</Subtitle>
          <p>
            <li>
              1-10 MPH OVER LIMIT – $130.00 fine + $25.00 Plea in Abeyance fee =
              $155.00*
            </li>
            <li>
              11-15 MPH OVER LIMIT – $160.00 fine + $25.00 Plea in Abeyance fee
              = $185.00*
            </li>
            <li>
              16-20 MPH OVER LIMIT – $210.00 fine + $25.00 Plea in Abeyance fee
              = $235.00*
            </li>
            <li>
              21-25 MPH OVER LIMIT – $280.00 fine + $25.00 Plea in Abeyance fee
              = $305.00*
            </li>
            <br />
            *Speeding fines in construction zones and school zones may be
            higher. Check{" "}
            <TextWebLink link="https://legacy.utcourts.gov/rules/view.php?type=ucja&rule=10C">
              Utah bail schedule
            </TextWebLink>{" "}
            for exact amounts.
            <br />
            <br />
            <em>
              In addition, as of May 4, 2022, any speeding violation in excess
              of 100 mph and fine will be increased by 50%.
            </em>
          </p>
        </Half>
      </Textbox>
      <Textbox title="Court Payment Information">
        <p>
          Once you’ve enrolled in traffic school you may pay the fine by:
          <ul>
            <li>
              Credit or debit card by calling (435) 261-7571,{" "}
              <TextWebLink link="https://secureinstantpayments.com/sip/reports/CORIS.php?m=3016&submit=Run">
                or pay online at Court Payments.
              </TextWebLink>{" "}
              There is a 2.5% fee to pay by credit and debit cards. Please have
              your case number before calling.
            </li>
            <li>
              Cash payments can be made at the Iron County Justice Court from
              8:00 AM to 4:00 PM.
            </li>
            <li>
              Make cashier’s check or money order payable to the Iron County
              Justice Court at 82 N 100 E #101, Cedar City, UT 84720
            </li>
          </ul>
        </p>
        <p>
          For any additional questions or help registering, contact the Justice
          Court:
          <br />
          <br />
          Iron County Justice Court <br />
          82 N 100 E #101 <br />
          Cedar City, UT 84720 <br />
          Email:{" "}
          <Link to="/departments/justice-court/traffic-school-contact">
            Contact Form
          </Link>
        </p>
        <ButtonIcons
          buttons={trafficButton}
          id="department-button"
          className="buttons-container"
        ></ButtonIcons>
      </Textbox>
      <Textbox title="Frequently Asked Questions">
        <CardFAQ questions={FAQ}></CardFAQ>
        <CardAccordion title="Will I be Notified by the Court when my charge has been dismissed?">
          <p>
            The Iron County Justice Court WILL NOT send you confirmation that
            your case has been dismissed. If you would like proof of your case
            being dismissed at the end of the three month period (from date you
            return your signed Agreement), set up a MyCase Account by going to:{" "}
            <TextWebLink link="https://www.utcourts.gov/en/self-help/services/mycase.html">
              MyCase
            </TextWebLink>{" "}
          </p>
        </CardAccordion>
        <CardAccordion title="How Do I Pay for My Traffic School Class?">
          <p>
            You will pay{" "}
            <TextWebLink link="https://www.utahroadsafety.com/">
              utahroadsafety.com
            </TextWebLink>{" "}
            directly for the online traffic school.{" "}
            <b>
              UtahRoadSafety is the ONLY accepted provider for online traffic
              school in Iron County, so please do not register for traffic
              school elsewhere.
            </b>{" "}
            For SUU citations, contact SUU Police Department at{" "}
            <PhoneLink>435-586-7793</PhoneLink> .
          </p>
        </CardAccordion>
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Justice Court: Traffic School</title>
        <meta
          name="description"
          content="Iron County Justice Court traffic school Page"
        />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/justice-court/traffic-school"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<JusticeSideBar />}
        id="justice-background"
        click={click}
      />
    </>
  );
}

export default TrafficSchool;

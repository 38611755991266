import React from "react";
import ButtonIcons from "../../../../Components/Buttons/Button_Icons";
import "../../Home.css";

function Pay() {
  const payButtons = [
    {
      title: "Beer, Landfill, Business License",
      to: "https://secureinstantpayments.com/sip/cart/event.php?EID=2879",
      isLink: true,
    },
    {
      title: " Business Tax",
      to: "https://secureinstantpayments.com/sip/reports/account_selector.php?FromPopup=1&Merchant=25470&AddrReq=x&Account=",
      isLink: true,
    },
    {
      title: "Court Fees",
      to: "https://secureinstantpayments.com/sip/reports/CORIS.php?m=3016&submit=Run",
      isLink: true,
    },

    {
      title: "Mobile Home Tax",
      to: "https://secureinstantpayments.com/sip/reports/account_selector.php?FromPopup=1&Merchant=25470&AddrReq=x&Account=",
      isLink: true,
    },

    {
      title: "Property Tax",
      to: "https://ironcounty.billtrax.com/biller/quick-pay",
      isLink: true,
    },

    {
      title: "Solid Waste (Utility Bills)",
      to: "https://secureinstantpayments.com/sip/reports/account_selector.php?FromPopup=1&Merchant=27740&AddrReq=e&Account=",
      isLink: true,
    },
  ];

  return (
    <div id="apply-container">
      <ButtonIcons
        buttons={payButtons}
        id="home-buttons"
        className="buttons-container-home"
      />
    </div>
  );
}

export default Pay;

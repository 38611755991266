import React from "react";
import ButtonIcons from "../../../../Components/Buttons/Button_Icons";
import "../../Home.css";

function View() {
  const viewButtons = [
    {
      title: "Fee Schedule",
      to: "/files/government/2022-fee-schedule.pdf",
      isLink: true,
    },
    {
      title: "Plat Map",
      to: "https://irongis.ironcounty.net/portal/apps/webappviewer/index.html?id=7a0b58d7d4f44aa8a58bc046cf7d91a3",
      isLink: true,
    },
    {
      title: "Property Accounts",
      to: "https://eagleweb.ironcounty.net/treasurer/web/",
      isLink: true,
    },
    {
      title: "Property Records",
      to: "https://eagleweb.ironcounty.net/eaglesoftware/web/",
      isLink: true,
    },

    {
      title: "Tax Areas",
      to: "https://irongis.maps.arcgis.com/apps/View/index.html?appid=161f9748fa7340009b42ed4a36ae1abd",
      isLink: true,
    },

    {
      title: "Zoning Map",
      to: "https://irongis.maps.arcgis.com/apps/webappviewer/index.html?id=2eecc94438c04796a65b4d3036e49972",
      isLink: true,
    },
  ];
  return (
    <div id="apply-container">
      <ButtonIcons
        buttons={viewButtons}
        id="home-buttons"
        className="buttons-container-home"
      />
    </div>
  );
}

export default View;

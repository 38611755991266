import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import TreasurerSidebar from "./TreasurerSidebar";
import Textbox from "../../../Components/Text/Textbox";
import Title from "../../../Components/Text/Title";
import Half from "../../../Components/Sizes/Half";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import Youtube from "../../../Components/Links/Youtube";
import TextWebLink from "../../../Components/Links/TextWebLink";
import { Helmet } from "react-helmet-async";

function Treasurer() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  const Left = () => (
    <>
      <Title>Treasurer's Office</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>

      <Textbox>
        <Half>
          <h1>What We Do</h1>
          <li>Bill and collect real property, business, mobile taxes</li>
          <li>Distribute taxes to taxing entities</li>
          <li>Process refunds of overpaid property taxes</li>
          <li>Publish a listing of delinquent taxes on an annual basis</li>
          <li>Invest County Funds</li>
          <li>Receipting and cash management of all County Funds</li>
        </Half>
        <Half>
          <h1>What We Don't Do</h1>
          <li>DETERMINE YOUR PROPERTY VALUE</li>
          <li>Determine the Tax Rate</li>
          <li>Abatements, Deferral or Exemption Programs</li>
          <li>Administer Appeals for your Property Value</li>
        </Half>
        <p>
          1 in 3 Utah homes have high levels of radon. Protect your family’s
          health and test your home today. Visit{" "}
          <TextWebLink link="https://deq.utah.gov/waste-management-and-radiation-control/radon-program">
            Radon.utah.gov
          </TextWebLink>
        </p>
      </Textbox>
      <Youtube src="https://www.youtube.com/embed/usFmTlaC11M?si=ldUKgnxz-YAMdizf" />
    </>
  );

  return (
    <>
      <Helmet>
        <title>Treasurer</title>
        <meta name="description" content="Iron County Treasurer Page" />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/treasurer"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<TreasurerSidebar />}
        id="treasurer-background"
        click={click}
      />
    </>
  );
}

export default Treasurer;

import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import CommissionSideBar from "./CommissionSidebar";
import Textbox from "../../../Components/Text/Textbox";
import Title from "../../../Components/Text/Title";
import Aidaform from "../../../Components/Aidaform/Aidaform";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

function AgendaApplication() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  const Left = () => (
    <>
      <Title>Agenda Application</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <p>
          All items involving legal issues and contracts or agreements binding
          the county must be approved by County Attorney before application is
          submitted. Planning & Zoning items must be submitted by the Planning &
          Zoning Office. Each Application request is for 15 minutes-one item per
          application.
        </p>
        <p>
          <b>
            DEADLINE FOR ITEMS TO BE CONSIDERED IS THE MONDAY PRIOR TO THE
            MEETING BY 5:00 P.M.
          </b>
        </p>
      </Textbox>
      <Textbox>
        <Aidaform form="https://ironcounty.aidaform.com/agenda-application" />
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Commission: Agenda Application</title>
        <meta
          name="description"
          content="Iron County Commission agenda application Page"
        />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/commission/agenda-application"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<CommissionSideBar />}
        id="commission-background"
        click={click}
      />
    </>
  );
}

export default AgendaApplication;

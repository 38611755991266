import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import ITSidebar from "./ITSidebar";
import Title from "../../../Components/Text/Title";
import Textbox from "../../../Components/Text/Textbox";
import Map from "../../../Components/Maps/Map";
import TextWebLinkList from "../../../Components/Links/TextWebLinkList";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

function GIS() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  const links = [
    {
      link: "/files/departments/it/drink_cities.pdf",
      name: "Iron County Drinking Water Protection Zones (By City)",
    },
    {
      link: "/files/departments/it/drink_zones.pdf",
      name: "Iron County Drinking Water Protection Zones",
    },
    {
      link: "/files/departments/it/ic_liquefaction-areas.pdf",
      name: "Iron County Liquefaction Areas",
    },
    {
      link: "/files/departments/it/ic_fault-lines.pdf",
      name: "Iron County Fault Lines",
    },
    {
      link: "/files/departments/it/ic_fissure-areas.pdf",
      name: "Iron County Fissure Areas",
    },
    {
      link: "/files/departments/it/geologic-map.pdf",
      name: "Iron County Geological Map",
    },
    {
      link: "/files/departments/it/landslides_cedar-city.pdf",
      name: "Landslide Maps of Cedar City",
    },
    {
      link: "/files/departments/it/landslides.pdf",
      name: "Landslide Maps of Utah",
    },
    {
      link: "/files/departments/it/quichapa-flood-area-management.pdf",
      name: "Quichapa Flood Area Management",
    },
  ];

  const Left = () => (
    <>
      <Title>GIS Maps</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        A geographic information system (GIS) lets us visualize, question,
        analyze, and interpret data to understand relationships, patterns, and
        trends. Our GIS division supports all county departments and the public
        alike.
        <Map
          title="Featured Maps"
          src="https://irongis.ironcounty.net/portal/home/webmap/embedGallery.html?displayapps=true&displayinline=true&group=8ba3cb5729334dcd8027699baf85f9ba"
        />
        <Map
          title="Interactive Maps"
          src="https://irongis.maps.arcgis.com/home/webmap/embedGallery.html?displayapps=true&displayinline=true&group=f0be547a58c348efb1b585ddbd09ff06"
        />
        <TextWebLinkList title="PDF Maps" links={links} />
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>IT: GIS</title>
        <meta name="description" content="Iron County IT GIS Page" />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/it/gis"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<ITSidebar />}
        id="it-background"
        click={click}
      />
    </>
  );
}

export default GIS;

import React from "react";
import AccordionSidemenu from "../../../Components/AccordionMenu/AccordionSidemenu";
import TextWebLink from "../../../Components/Links/TextWebLink";
import HoursContact from "../../../Components/Text/HoursContact";
import PhoneLink from "../../../Components/Links/PhoneLink";
import "../../../Components/Sidebar/Sidebar.css";
import EmailLink from "../../../Components/Links/EmailLink";

function PlanningSidebar() {
  const navItems = [
    {
      title: "About",
      to: "/departments/planning",
      isLink: false,
    },

    {
      title: "Planning Agendas",
      to: "/departments/planning/agenda",
      isLink: false,
    },
    {
      title: "Planning Items",
      to: "/departments/planning/items",
      isLink: false,
    },

    {
      title: "Permits",
      sub: [
        {
          title: "Land Use Permit",
          to: "/files/departments/building/alup.pdf",
          isLink: true,
        },
        {
          title: "Conditional Use Permit",
          to: "/files/departments/building/cup-application.pdf",
          isLink: true,
        },
      ],
    },

    {
      title: "Resources",
      sub: [
        {
          title: "Codes & Ordinances",
          to: "https://library.municode.com/ut/iron_county/codes/code_of_ordinances",
          isLink: true,
        },
        {
          title: "Table of Uses",
          to: "https://library.municode.com/ut/iron_county/codes/code_of_ordinances?nodeId=TIT17ZO_CH17.16ESZODI_17.16.030TAUS",
          isLink: true,
        },
        {
          title: "General Plan",
          to: "/files/departments/planning/iron-county-general-plan.pdf",
          isLink: true,
        },
        {
          title: "General Plan Ordinance 205",
          to: "/files/departments/planning/ordinance-205-general-plan-update.pdf",
          isLink: true,
        },
      ],
    },
    // {
    //
    //   title: "Request a Sketch Meeting",
    //   to: "/departments/planning/request-sketch",
    //   isLink: false,
    // },
  ];

  return (
    <>
      <div className="nav-menu-side">
        <HoursContact>
          <p>
            Reed Erickson, Planner / Services Coordinator
            <br />
            <TextWebLink link="https://goo.gl/maps/n4UaZjbAxr5HBvz78">
              82 N 100 E #102 Cedar City, Utah 84720
            </TextWebLink>
            <br />
            M - F 8:00 AM – 5:00 PM
            <br />
            Phone: <PhoneLink>435-865-5381</PhoneLink>
            <br />
            Email: <EmailLink>rerickson@ironcounty.net</EmailLink>
          </p>
        </HoursContact>
        <br />
        <AccordionSidemenu nav={navItems} id="department-sidebar" />
      </div>
    </>
  );
}

export default PlanningSidebar;

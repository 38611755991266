import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import CJCSidebar from "./CJCSidebar";
import Title from "../../../Components/Text/Title";
import Textbox from "../../../Components/Text/Textbox";
import Image from "../../../Components/Images/Image";
import Half from "../../../Components/Sizes/Half";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import interview from "../../../Images/Departments/CJC/interview-room.jpg";
import logo from "../../../Images/Departments/CJC/CJC-Iron-logo.jpg";
import examRoom from "../../../Images/Departments/CJC/exam-room.png";
import heart from "../../../Images/Departments/CJC/heart-in-hand.jpg";
import referral from "../../../Images/Departments/CJC/referrals-resources.jpg";
import victim from "../../../Images/Departments/CJC/victim-services.jpg";
import wendy from "../../../Images/Departments/CJC/Wendy.jpeg";
import stephanie from "../../../Images/Departments/CJC/stephanie-rainey.jpg";
import CardSmall from "../../../Components/Card/Card-Small";
import ButtonSingle from "../../../Components/Buttons/Button_Single";
import Youtube from "../../../Components/Links/Youtube";
import { Helmet } from "react-helmet-async";

function CJC() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  const Left = () => (
    <>
      <Title>Children's Justice Center</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <Half>
          <p>
            <h1>Who we are</h1>

            <em>” Where Small Voices are Heard ”</em>
          </p>
          <p>
            The Iron County Children’s Justice Center (CJC) is a facility that
            looks and feels like a home so children and families feel safe and
            comfortable during the investigative process of alleged child
            physical or sexual abuse. Child interviews performed by highly
            skilled forensic interviewers are preserved through audio and visual
            recordings, which eliminate the need for multiple interviews by
            various child protection and criminal justice agencies.
          </p>
          <p>
            The CJC is designed for children to help them feel safe, protected,
            and comfortable during an otherwise difficult and potentially
            frightening situation. While abuse is traumatic, our goal is to
            reduce any further trauma during the child abuse investigation by
            providing a space that helps the child feel safe to tell what
            happened to them. Not only are children interviewed in a
            research-based, non-leading manner, but children and families are
            provided with resources and referrals for mental health treatment,
            onsite medical exams, victim advocacy, and other community
            resources.
          </p>
          <p>
            The CJC operational funding is provided by appropriation from the
            Utah State Legislature administered by the Utah Attorney General’s
            Office, the Victims of Crime Act (VOCA) federal grant, as well as
            local private and public donations.
          </p>
        </Half>
        <Half>
          <Image
            width="100%"
            height="auto"
            src={logo}
            alt="Picture of the interview room"
          />
        </Half>
      </Textbox>
      <Youtube src="https://www.youtube.com/embed/JkacxF2wyMo?si=bHYM43ACDlwG1p87"></Youtube>

      <Textbox title="CJC Services">
        <CardSmall>
          <div>
            <Image
              width="100%"
              height="auto"
              src={interview}
              alt="Picture of the interview room"
            />
          </div>

          <h1>Forensic Interviews</h1>
          <p>Forensic Interviews performed by specially-trained interviewers</p>
          <ButtonSingle href="/departments/cjc/forensic-interviews">
            Details
          </ButtonSingle>
        </CardSmall>

        <CardSmall>
          <div>
            <Image
              width="100%"
              height="auto"
              src={heart}
              alt="Picture of hands holding a red heart"
            />
          </div>

          <h1>Mental Health Services</h1>
          <p>
            Therapy provided by qualified practitioners through Victims of Crime
            Act (VOCA) funding to child victims of abuse
          </p>
          <ButtonSingle href="/departments/cjc/mental-health-services">
            Details
          </ButtonSingle>
        </CardSmall>

        <CardSmall>
          <div>
            <Image
              width="100%"
              height="auto"
              src={examRoom}
              alt="Picture of the exam room"
            />
          </div>

          <h1>Medical Exams</h1>
          <p>
            Medical exams performed by Primary Children’s Safe and Healthy
            Families
          </p>
          <ButtonSingle href="/departments/cjc/medicalexams">
            Details
          </ButtonSingle>
        </CardSmall>

        <CardSmall>
          <div>
            <Image
              width="100%"
              height="auto"
              src={victim}
              alt="Picture of a kid sitting on a bench"
            />
          </div>

          <h1>Court Victim Services</h1>
          <p>
            CJC victim advocates can provide emotional support during court
            hearings and other criminal justice proceedings as needed
          </p>
          {/* <ButtonSingle href="">Details</ButtonSingle> */}
        </CardSmall>

        <CardSmall>
          <div>
            <Image
              width="100%"
              height="auto"
              src={referral}
              alt="Picture of two people talking across from each other on a desk"
            />
          </div>

          <h1>Referrals and Resources</h1>
          <p>
            Resources are available for primary and secondary victims to assist
            in healing from abuse and forming healthy behaviors/habits.
          </p>
          <ButtonSingle href="/departments/cjc/resources">Details</ButtonSingle>
        </CardSmall>
      </Textbox>
      <Textbox title="Our Team">
        <CardSmall>
          <div>
            <Image
              width="100%"
              height="auto"
              src={wendy}
              alt="Picture of wendy the director of the Childrens Justice center"
            />
          </div>

          <h1>Wendy Jessen</h1>
          <h1>Director</h1>
          <p>
            Wendy Jessen was recently selected as director for the Iron County
            CJC
          </p>
          <ButtonSingle href="/departments/cjc/about-us">Details</ButtonSingle>
        </CardSmall>

        <CardSmall>
          <div>
            <Image
              width="100%"
              height="auto"
              src={stephanie}
              alt="Picture of stephanie"
            />
          </div>

          <h1>Stephanie Rainey</h1>
          <h1>Victim Services Coordinator</h1>
          <p>
            Born and raised in Cedar City and can’t seem to get enough of this
            great community and the beauty of Southern Utah – which is why she
            moved back
          </p>
          <ButtonSingle href="/departments/cjc/about-us">Details</ButtonSingle>
        </CardSmall>
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Children's Justice Center</title>
        <meta
          name="description"
          content="Iron County Children's Justice Center Page"
        />
        <link rel="canonical" href="https://ironcounty.net/departments/cjc" />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<CJCSidebar />}
        id="cjc-background"
        click={click}
      />
    </>
  );
}

export default CJC;

import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import ParksSidebar from "./ParksSidebar";
import Title from "../../../Components/Text/Title";
import Textbox from "../../../Components/Text/Textbox";
import Half from "../../../Components/Sizes/Half";
import Map from "../../../Components/Maps/Map";
import ButtonSingle from "../../../Components/Buttons/Button_Single";
import TextWebLink from "../../../Components/Links/TextWebLink";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

function ThreePeaks() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Left = () => (
    <>
      <Title>Three Peaks</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <ButtonSingle
          href="/files/departments/parks/three-peaks-reservation.pdf"
          isLink={true}
        >
          Make a Reservation
        </ButtonSingle>
        <p>
          Please email form to:{" "}
          <a href="mailto:sorton@ironcounty.net">sorton@ironcounty.net</a>{" "}
        </p>
      </Textbox>
      <Textbox>
        <Half>
          <h1>
            <b>
              <u>Amenities</u>
            </b>
          </h1>
          <ul>
            <li>
              Bulloch Pavilion
              <ul>
                <li>10 picnic tables</li>
                <li>Large charcoal grill</li>
                <li>Fire pit</li>
                <li>Playground</li>
              </ul>
            </li>
            <li>
              Group Campsite and Pavilion
              <ul>
                <li>6 picnic tables</li>
                <li>Large charcoal grill</li>
                <li>Fire pit and fireplace</li>
                <li>
                  1.25 acres of camping area for tents and RV’s (no hookups)
                </li>
              </ul>
            </li>
            <li>
              17 first-come-first-serve campsites
              <ul>
                <li>Covered picnic table, fire pit with a cooking grate</li>
                <li>
                  Room for several vehicles, tents and RV’s in most sites (no
                  hookups)
                </li>
              </ul>
            </li>
            <li>Trash cans</li>
            <li>Seasonal water (no RV hookups)</li>
            <li>No Electricity</li>
          </ul>
          <h1>
            <b>
              <u>Rules</u>
            </b>
          </h1>
          <ul>
            <li>
              Camp in designated first-come-first-serve sites only. Pay the
              campsite fee at the pay station or pay online by using the QR
              code.
            </li>
            <li>
              The Bulloch Pavilion and Group Campsite must be reserved in
              advance and a fee must be paid.
            </li>
            <li>No pallet burning allowed</li>
            <li>No fireworks</li>
            <li>Follow state fire restrictions when posted</li>
            <li>No discharging firearms</li>
            <li>Follow posted speed limits</li>
            <li>Fires in provided metal fire rings only</li>
            <li>Pets must be on a leash at all times</li>
            <li>No smoking or vaping</li>
            <li>Alcohol permit required</li>
          </ul>
        </Half>
        <Half>
          <h1>About</h1>
          <p>
            The Iron County Three Peaks Campground consists of a 155-acre
            portion of the overall 6,000-acre Three Peaks Recreation Area. Three
            Peaks Campground is great for group gatherings, mountain biking,
            rock crawling, picnicking, and camping for large groups as well as
            individuals. Discover our inviting campground featuring 17
            first-come-first-serve sites. Additionally, we offer a day-use group
            pavilion known as Bulloch Pavilion, perfect for gatherings. For
            large overnight groups we have a group campsite that includes a
            group pavilion. The Bulloch pavilion and the group campsite require
            advanced reservation. To make a reservation call (435) 586-5124.
          </p>
          <p>
            For more information about the greater Three Peaks Recreation Area
            call the Bureau of Land Management at (435) 865-3000 or visit
            blm.gov.
          </p>
        </Half>
      </Textbox>
      <Textbox>
        <Half>
          {" "}
          <Map src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3154.047474747841!2d-113.169398!3d37.765485!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80b5653e0d3c7891%3A0x8eaae2dd7ea7a5b5!2sThree%20Peaks%20Recreation%20Area!5e0!3m2!1sen!2sus!4v1694034261311!5m2!1sen!2sus"></Map>
        </Half>
        <Half>
          {" "}
          <Map src="https://calendar.google.com/calendar/embed?height=600&wkst=1&ctz=America%2FDenver&bgcolor=%23ffffff&src=MW1iMHN0b2IycHY0MzBnY2xkbGZmcTQ4YmtAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&color=%23F09300"></Map>
        </Half>
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Three Peaks</title>
        <meta
          name="description"
          content="Iron County Three Peaks  Parks and Recreation Page"
        />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/parks/three-peaks"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<ParksSidebar />}
        id="parks-background"
        click={click}
      />
    </>
  );
}

export default ThreePeaks;

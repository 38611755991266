import React from "react";
import AccordionSidemenu from "../../../Components/AccordionMenu/AccordionSidemenu";
import "../../../Components/Sidebar/Sidebar.css";
import TextWebLink from "../../../Components/Links/TextWebLink";
import HoursContact from "../../../Components/Text/HoursContact";
import PhoneLink from "../../../Components/Links/PhoneLink";

function FireSideBar() {
  const navItems = [
    {
      title: "About",
      to: "/departments/fire",
      isLink: false,
    },
    {
      title: "Apply for Burn Permit",
      to: "https://air.utah.gov/OpenBurning/form/index.php",
      isLink: true,
    },
    {
      title: "Burn Permits",
      to: "/departments/fire/burn-permit",
      isLink: false,
    },

    {
      title: "Resources",
      sub: [
        {
          title: "Resource Homepage",
          to: "/departments/fire/resources",
        },
        {
          title: "Fire Code & Ordinance",
          to: "/files/departments/fire/wildland-fire-code-2023.pdf",
          isLink: true,
        },
        {
          title: "Firepit Standard",
          to: "/files/departments/fire/firepit-standard.pdf",
          isLink: true,
        },
        {
          title: "Utah Fire Info",
          to: "http://utahfireinfobox.com/",
          isLink: true,
        },
        {
          title: "Firewise USA",
          to: "https://www.nfpa.org/Public-Education/Fire-causes-and-risks/Wildfire/Firewise-USA",
          isLink: true,
        },
        {
          title: "Firework Flyer",
          to: "/files/departments/fire/firework-flyer.pdf",
          isLink: true,
        },
        {
          title: "Fire Restrictions",
          to: "/files/departments/fire/fire-restrictions.pdf",
          isLink: true,
        },
        {
          title: "Fire Map",
          to: "/files/departments/fire/fire-map.pdf",
          isLink: true,
        },
      ],
    },
    {
      title: "Wildfire Wednesday",
      to: "https://www.youtube.com/playlist?list=PL852FZz3WnIOAbGI6igoKXUueDs8CFkfr",
      isLink: true,
    },
  ];

  return (
    <>
      <div className="nav-menu-side">
        <HoursContact>
          <p>
            Ryan Riddle, Iron County Fire Warden
            <br />
            <TextWebLink link="https://goo.gl/maps/VR1N594suRuVHhk26">
              646 N Main, Cedar City, UT 84721
            </TextWebLink>
            <br />
            <PhoneLink>435-590-4714</PhoneLink>
          </p>
          <p>
            Lucas Twitchell, Assistant Iron County Fire Warden
            <br />
            <TextWebLink link="https://goo.gl/maps/p6cxGVmZT8PSUZzb9">
              1302 W Kittyhawk Dr., Cedar City Utah, UT 84721
            </TextWebLink>
            <br />
            <PhoneLink>435-704-4117</PhoneLink>
          </p>
        </HoursContact>
        <br />
        <AccordionSidemenu nav={navItems} id="department-sidebar" />
      </div>
    </>
  );
}

export default FireSideBar;

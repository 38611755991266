import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import RecordersSidebar from "./RecordersSidebar";
import Title from "../../../Components/Text/Title";
import Aidaform from "../../../Components/Aidaform/Aidaform";
import Textbox from "../../../Components/Text/Textbox";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import ButtonSingle from "../../../Components/Buttons/Button_Single";
import { Helmet } from "react-helmet-async";

function FileSurvey() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Left = () => (
    <>
      <Title>File a Survey</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox className="center">
        <ButtonSingle
          href="https://secureinstantpayments.com/sip/cart/event.php?EID=2880"
          isLink={true}
        >
          Submit Payment
        </ButtonSingle>
      </Textbox>

      <Aidaform form="https://ironcounty.aidaform.com/plot-surveys" title="" />
    </>
  );

  return (
    <>
      <Helmet>
        <title>Recorders</title>
        <meta
          name="description"
          content="Iron County Recorders file survey Page"
        />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/recorders/file-survey"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<RecordersSidebar />}
        id="justice-background"
        click={click}
      />
    </>
  );
}

export default FileSurvey;

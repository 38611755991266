import React from "react";
import "./Links.css";
import Subtitle from "../Text/Subtitle";

function Youtube(props) {
  return (
    <div>
      {props.title ? <Subtitle>{props.title}</Subtitle> : null}
      <iframe
        className="youtube"
        src={props.src}
        frameborder="0"
        allowFullScreen
        title={props.title}
      ></iframe>
    </div>
  );
}

export default Youtube;

import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import CommissionSidebar from "./CommissionSidebar";
import Textbox from "../../../Components/Text/Textbox";
import Title from "../../../Components/Text/Title";
import Archive from "../../../Components/Card/Archive";
import { Commission2023 } from "./Commission2023";
import { CommissionHistory } from "./CommissionHistory";
import CardAccordion from "../../../Components/Card/CardAccordion";
import TextWebLink from "../../../Components/Links/TextWebLink";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

function CommissionArchive() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  const Left = () => (
    <>
      <Title>Commission Meetings</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <p>
          Listed below are all of the agenda and minutes for the Iron County
          Commission in PDF format. These records can also be found on the Utah
          Public Notice website at{" "}
          <TextWebLink link="https://www.utah.gov/pmn/">
            utah.gov/pmn/
          </TextWebLink>
        </p>
        <Archive meetings={Commission2023} />
      </Textbox>
      <Textbox>
        <CardAccordion title="Past Commission Meetings">
          <Archive meetings={CommissionHistory} />
        </CardAccordion>
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Commission: Archive</title>
        <meta
          name="description"
          content="Iron County Commission archive Page"
        />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/commission/commission-archive"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<CommissionSidebar />}
        id="commission-background"
        click={click}
      />
    </>
  );
}

export default CommissionArchive;

import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import Title from "../../../Components/Text/Title";
import Textbox from "../../../Components/Text/Textbox";
import FireSidebar from "./FireSidebar";
import Half from "../../../Components/Sizes/Half";
import ButtonSingle from "../../../Components/Buttons/Button_Single";
import Map from "../../../Components/Maps/Map";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

function Fire() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Left = () => (
    <>
      <Title>Fire</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <Half>
          <h1>What We Do</h1>
          <p>
            Iron County provides a wide variety of services to the citizens and
            visitors through the fire program. In addition to municipal calls
            such as vehicle and structural fires firefighters also have advanced
            training in Wildland Fire Management, Hazardous Materials Response,
            Cold Water Rescue, Aircraft Rescue Firefighting and High Angle Rope
            Rescue. Firefighters are trained and certified through the Utah Fire
            and Rescue Academy and exceed the minimum requirements for the state
            of Utah.
          </p>
          <p>
            By agreement Iron County contracts fire management services with the
            Utah Division of Forestry, Fire & State Lands and local community
            fire departments.
          </p>
          <p>
            For more information about the fire program, including tours or
            public presentations, or to become involved with your community fire
            department, contact the Fire Warden or your local fire department.
          </p>
        </Half>

        <Half>
          <h1>Fire Restrictions</h1>
          <p>
            <b>Fire Restrictions are in Effect</b>
          </p>
          <p>
            Due to current and forecasted weather conditions coupled with the
            extremely dry vegetation conditions in Southwest Utah. There are
            Stage 1 fire restrictions enacted by Closure Orders #SWCLO2402 are
            hereby active within the areas described below beginning June 21,
            2024 at 0001 hours.
          </p>
          <ButtonSingle
            href="/files/departments/fire/fire-restrictions.pdf"
            isLink={true}
          >
            More Information
          </ButtonSingle>
        </Half>
      </Textbox>
      <Textbox>
        <Map src="https://bit.ly/CCFirework"></Map>
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Fire</title>
        <meta name="description" content="Iron County Fire Page" />
        <link rel="canonical" href="https://ironcounty.net/departments/fire" />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<FireSidebar />}
        id="fire-background"
        click={click}
      />
    </>
  );
}

export default Fire;

import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import JusticeSideBar from "./JusticeSidebar";
import Title from "../../../Components/Text/Title";
import Aidaform from "../../../Components/Aidaform/Aidaform";
import Textbox from "../../../Components/Text/Textbox";
import TextWebLink from "../../../Components/Links/TextWebLink";
import Half from "../../../Components/Sizes/Half";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

function RequestRecords() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Left = () => (
    <>
      <Title>GRAMA Record Request</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox title="Read Before Proceeding">
        <p>
          <li>
            Private or protected records will only be released to the person
            listed as the Subject of the record. Subject of the Record is the
            victim, suspect, or other defined in Utah GRAMA section{" "}
            <TextWebLink link="https://le.utah.gov/xcode/Title63G/Chapter2/63G-2-S202.html">
              63G-2-202.
            </TextWebLink>{" "}
          </li>
          <li>Controlled records are not subject to release</li>
          <li>
            The Iron County Justice Court will have up to 10 business days from
            the submission of this form to release the record to you.
          </li>
        </p>
      </Textbox>
      <Textbox title="Fees and Payments">
        <Half>
          <li>
            <b>Emailed documents</b> - no cost
          </li>
          <li>
            <b>Photocopies</b> - .25 cents per page
          </li>

          <li>
            <b>Certified Copies</b> - $4.00 per document (plus .25 cents per
            page)
          </li>
          <li>
            <b>Audio CD</b> - $15.00 per recording
          </li>
          <li>
            <b>Postage</b> - 1st Class USPS rates apply
          </li>
          <li>
            <b>Research Time</b> - $16.28 per hour (first 15 minutes is no
            charge)
          </li>
          <p>
            Please Note: Depending on the scope or amount of information
            requested, there could be an additional charge for the cost of staff
            time for search, retrieval, and other direct administrative costs
            for complying with a request.
          </p>
        </Half>
        <Half>
          <p>
            Full payment of the estimated fee is required before any records
            will be processed and provided. Payment can be made in cash, money
            order, or cashier’s check (Payable to the Iron County Justice
            Court). Debit and credit card payments are available upon request.
            Differences in the final cost from the estimated cost will be
            collected prior to receipt of the recording and refunds will be
            mailed to the requester.
          </p>
        </Half>
      </Textbox>
      <Aidaform
        form="https://ironcounty.aidaform.com/court-record-request"
        title=""
      />
    </>
  );

  return (
    <>
      <Helmet>
        <title>Justice Court: Request Records</title>
        <meta
          name="description"
          content="Iron County Justice Court request records Page"
        />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/justice-court/request-records"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<JusticeSideBar />}
        id="justice-background"
        click={click}
      />
    </>
  );
}

export default RequestRecords;

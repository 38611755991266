import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import WeedSidebar from "./WeedSidebar";
import Title from "../../../Components/Text/Title";
import Textbox from "../../../Components/Text/Textbox";
import TextWebLink from "../../../Components/Links/TextWebLink";
import { Helmet } from "react-helmet-async";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import weed1 from "../../../Images/Departments/weeds/weed1.png";
import weed2 from "../../../Images/Departments/weeds/weed2.png";
import Image from "../../../Components/Images/Image";
import Half from "../../../Components/Sizes/Half";
import Subtitle from "../../../Components/Text/Subtitle";

function Weeds() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  const links = [{}];
  const Left = () => (
    <>
      <Title>Weed Control</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <p>
          The Iron County Weed department is tasked to combat the noxious weeds
          within the county borders.
        </p>
      </Textbox>
      <Textbox title="Noxious Weeds">
        <p>
          The county offers a program to help assist the combat of noxious weeds
          on private property.{" "}
          <b> It is the landowners responsibility to control noxious weeds.</b>
        </p>

        <p>
          The cost is as follows:
          <ul>
            <li>$40.00 per hour + Chemical Cost</li>
            <li>
              County assistance will depend on the scope of the project and
              staffing available by the county.
            </li>
            {/* <li>75% of the chemical cost</li> */}
          </ul>
        </p>
      </Textbox>
      <Textbox title="Noxious Weed Information">
        <p>
          These resources are hosted by the{" "}
          <TextWebLink link="https://utahweed.org/">
            Utah Weed Control Association website.
          </TextWebLink>{" "}
          <ul>
            <li>
              <TextWebLink link="https://utahweed.org/noxious-weed-field-guide/">
                {" "}
                Noxious Weed Identification{" "}
              </TextWebLink>
            </li>
            <li>
              <TextWebLink link="https://le.utah.gov/xcode/Title4/Chapter17/4-17.html?v=C4-17_2017050920170701">
                Utah Weed Laws
              </TextWebLink>
            </li>
            <li>
              <TextWebLink link="https://utahweed.org/noxious-weeds/">
                Noxious Weed Alert
              </TextWebLink>
            </li>
          </ul>
        </p>
      </Textbox>

      <Textbox>
        <Half>
          <Subtitle>Whitetop</Subtitle>
        </Half>
        <Half>
          <Subtitle>Scotch Thistle</Subtitle>
        </Half>
        <Half>
          <Image
            width="100%"
            height="500px"
            src={weed1}
            alt="Picture of whitetop"
          />
        </Half>
        <Half>
          <Image
            width="100%"
            height="500px"
            src={weed2}
            alt="Picture of Hoary Cress"
          />
        </Half>
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Weeds</title>
        <meta name="description" content="Iron County Weeds Page" />
        <link rel="canonical" href="https://ironcounty.net/departments/weeds" />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<WeedSidebar />}
        id="weeds-background"
        click={click}
      />
    </>
  );
}

export default Weeds;

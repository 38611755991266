import "./App.css";
import React, { useEffect } from "react";

import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
// import ScrollToTop from "./Components/Layout/ScrollToTop";
import Layout from "./Components/Layout/Layout";
import Home from "./Pages/Home/Home";
import Departments from "./Pages/Departments/Departments";
import Business from "./Pages/Departments/Business";

//Assessor
import Assessor from "./Pages/Departments/Assessor/Assessor";
import GreenbeltAct from "./Pages/Departments/Assessor/GrennbeltAct";
import PersonalProperty from "./Pages/Departments/Assessor/PersonalProperty";
import YourAssessment from "./Pages/Departments/Assessor/YourAssessment";
import ChangeBusinessForm from "./Pages/Departments/Assessor/ChangeBusinessForm";
import PropertyExemptionForm from "./Pages/Departments/Assessor/PropertyExemptionForm";

//Attorney
import Attorney from "./Pages/Departments/Attorney/Attorney";
import ProsecutionPolicy from "./Pages/Departments/Attorney/ProsecutionPolicy";

// Auditor
import TaxSale from "./Pages/Departments/Auditor/Tax-Sale";
import FAQ from "./Pages/Departments/Auditor/FAQ";
import Auditor from "./Pages/Departments/Auditor/Auditor";
import TaxSaleResults from "./Pages/Departments/Auditor/TaxSaleResults";

import Fraud from "./Pages/Departments/Auditor/Fraud";
import PropertyExemptions from "./Pages/Departments/Auditor/PropertyExemptions";
import IndividualAbatements from "./Pages/Departments/Auditor/IndividualAbatements";
import BOA from "./Pages/Departments/Auditor/BOA";
import Budget from "./Pages/Departments/Auditor/Budget";

//BuildingZoning
import Building from "./Pages/Departments/BuildingZoning/Building";
import FAQBuilding from "./Pages/Departments/BuildingZoning/FAQBuilding";
import BuildingForms from "./Pages/Departments/BuildingZoning/BuildingForms";
import ZoningViolation from "./Pages/Departments/BuildingZoning/ZoningViolation";
import GasForm from "./Pages/Departments/BuildingZoning/GasForm";
import DuctTest from "./Pages/Departments/BuildingZoning/DuctForm";

//Childrens Justice Center
import CJC from "./Pages/Departments/ChildrensJusticeCenter/CJC";

import VolunteerCJC from "./Pages/Departments/ChildrensJusticeCenter/Volunteer";
import ResourcesCJC from "./Pages/Departments/ChildrensJusticeCenter/ResourcesCJC";
import DonateCJC from "./Pages/Departments/ChildrensJusticeCenter/Donate";
import ReportAbuse from "./Pages/Departments/ChildrensJusticeCenter/ReportAbuse";
import MentalHealthServices from "./Pages/Departments/ChildrensJusticeCenter/MentalHealthServices";
import ContactUsCJC from "./Pages/Departments/ChildrensJusticeCenter/ContactUs";
import ForensicInterviews from "./Pages/Departments/ChildrensJusticeCenter/ForensicInterviews";
import AboutUs from "./Pages/Departments/ChildrensJusticeCenter/AboutUs";

//Clerk
import Clerk from "./Pages/Departments/Clerk/Clerk";
import UtilityBilling from "./Pages/Departments/Clerk/UtilityBilling";
import PublicNotices from "./Pages/Departments/Clerk/PublicNotices";
import Passport from "./Pages/Departments/Clerk/Passport";
import MarriageLicense from "./Pages/Departments/Clerk/MarriageLicense";
import BusinessLicense from "./Pages/Departments/Clerk/BusinessLicense";
import Elections from "./Pages/Departments/Clerk/Elections";
import ElectionResults from "./Pages/Departments/Clerk/ElectionResults";
import CandidateInformation from "./Pages/Departments/Clerk/CandidateInformation";
import FinancialReports from "./Pages/Departments/Clerk/FinancialReports";

//Commission
import Commission from "./Pages/Departments/Commission/Commission";
import CommissionArchive from "./Pages/Departments/Commission/CommissionArchive";
import CountyBoards from "./Pages/Departments/Commission/CountyBoards";
import AgendaApplication from "./Pages/Departments/Commission/AgendaApplication";
import Jail from "./Pages/Departments/Commission/Jail";
import JailOld from "./Pages/Departments/Commission/JailOld";

//Emergency Management
import EmergencyManagment from "./Pages/Departments/EmergencyManagement/EmergencyManagement";
import CitizenAlert from "./Pages/Departments/EmergencyManagement/CitizenAlert";
import LEPC from "./Pages/Departments/EmergencyManagement/LEPC";
import CERT from "./Pages/Departments/EmergencyManagement/CERT";
import StormDamage from "./Pages/Departments/EmergencyManagement/StormDamage";
import VolunteerHours from "./Pages/Departments/EmergencyManagement/VolunteerHours";

//Engineering
import Engineering from "./Pages/Departments/EngineeringSurveying/Engineering";
import FAQEngineering from "./Pages/Departments/EngineeringSurveying/FAQEngineering";
import EncroachmentPermit from "./Pages/Departments/EngineeringSurveying/EncroachmentPermit";
import Addressing from "./Pages/Departments/EngineeringSurveying/Addressing";
import RequestsAndForms from "./Pages/Departments/EngineeringSurveying/RequestsAndForms";

//Fleet
import Fleet from "./Pages/Departments/Fleet/Fleet";

//Fire
import Fire from "./Pages/Departments/Fire/Fire";
import FireResources from "./Pages/Departments/Fire/FireResources";
import BurnPermit from "./Pages/Departments/Fire/BurnPermit";

//Governement Pages

//Human Resources
import HumanResources from "./Pages/Departments/HR/HumanResources";

//IT
import IT from "./Pages/Departments/IT/IT";
import GIS from "./Pages/Departments/IT/GIS";
import ContactUs from "./Pages/Departments/IT/ContactUs";

//Justice Center
import JusticeCourt from "./Pages/Departments/JusticeCourt/JusticeCourt";
import Arraignment from "./Pages/Departments/JusticeCourt/Arraignment";
import EntryOfPlea from "./Pages/Departments/JusticeCourt/EntryOfPlea";
import EntryOfPleaForm from "./Pages/Departments/JusticeCourt/EntryOfPleaForm";
import Expungement from "./Pages/Departments/JusticeCourt/Expungement";
import FAQJC from "./Pages/Departments/JusticeCourt/FAQ-JC";
import RequestCourt from "./Pages/Departments/JusticeCourt/RequestCourt";
import RequestRecords from "./Pages/Departments/JusticeCourt/RequestRecords";
import SmallClaims from "./Pages/Departments/JusticeCourt/SmallClaims";
import TrafficSchool from "./Pages/Departments/JusticeCourt/TrafficSchool";
import TrafficSchoolContact from "./Pages/Departments/JusticeCourt/TrafficSchoolContact";
import TrafficSchoolEnrollment from "./Pages/Departments/JusticeCourt/TrafficSchoolEnrollment";
import JailCommitment from "./Pages/Departments/JusticeCourt/JailCommitment";
import CourtCalendar from "./Pages/Departments/JusticeCourt/CourtCalendar";
import Seatbelt from "./Pages/Departments/JusticeCourt/Seatbelt";

//Natural Resources
import NaturalResources from "./Pages/Departments/NaturalResources/NaturalResources";
import Projects from "./Pages/Departments/NaturalResources/Projects";

//Parks and Rec
import ParksAndRecs from "./Pages/Departments/ParksAndRecs/ParksAndRecs";
import ShootingRange from "./Pages/Departments/ParksAndRecs/ShootingRange";
import Reservations from "./Pages/Departments/ParksAndRecs/Reservations";
import Trails from "./Pages/Departments/ParksAndRecs/Trails";
import FiveMile from "./Pages/Departments/ParksAndRecs/fiveMile";
import ThreePeaks from "./Pages/Departments/ParksAndRecs/ThreePeaks";
import WoodRanch from "./Pages/Departments/ParksAndRecs/WoodRanch";

//Planning
import Planning from "./Pages/Departments/Planning/Planning";
import PlanningAgendas from "./Pages/Departments/Planning/PlanningAgendas";
import RequestSketch from "./Pages/Departments/Planning/RequestSketch";
import PlanningItems from "./Pages/Departments/Planning/PlanningItems";

//Recorder
import Recorder from "./Pages/Departments/Recorders/Recorders";
import RecorderFees from "./Pages/Departments/Recorders/Fees";
import FileSurvey from "./Pages/Departments/Recorders/FileASurvey";

//Roads
import Roads from "./Pages/Departments/Roads/Roads";

//Senior Center
import SeniorCenter from "./Pages/Departments/SeniorCenter/SeniorCenter";
import CedarCenter from "./Pages/Departments/SeniorCenter/CedarCenter";
import ParowanCenter from "./Pages/Departments/SeniorCenter/ParowanCenter";

//Solid Waste
import SolidWaste from "./Pages/Departments/SolidWaste/SolidWaste";

//Treasurer
import Treasurer from "./Pages/Departments/Treasurer/Treasurer";
import ChangeOfAddress from "./Pages/Departments/Treasurer/ChangeOfAddress";

//Weeds
import Weeds from "./Pages/Departments/Weeds/Weeds";
import MedicalExams from "./Pages/Departments/ChildrensJusticeCenter/MedicalExams";

const ScrollToTop = ({ children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return children || null;
};

function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="/departments" element={<Departments />} />
              <Route path="/business" element={<Business />} />

              {/* Assessor Pages */}
              <Route path="departments/assessor" element={<Assessor />} />
              <Route
                path="departments/assessor/greenbelt-act"
                element={<GreenbeltAct />}
              />
              <Route
                path="departments/assessor/personal-property"
                element={<PersonalProperty />}
              />
              <Route
                path="departments/assessor/your-assessment"
                element={<YourAssessment />}
              />
              <Route
                path="departments/assessor/change-business-form"
                element={<ChangeBusinessForm />}
              />
              <Route
                path="departments/assessor/property-exemption-form"
                element={<PropertyExemptionForm />}
              />

              {/* Attorney Pages */}
              <Route path="departments/attorney" element={<Attorney />} />
              <Route
                path="departments/attorney/prosecution-policy"
                element={<ProsecutionPolicy />}
              />
              {/* Auditor Pages */}
              <Route path="departments/auditor" element={<Auditor />} />
              <Route
                path="departments/auditor/tax-sale"
                element={<TaxSale />}
              />
              <Route path="departments/auditor/faq" element={<FAQ />} />
              <Route path="departments/auditor/fraud" element={<Fraud />} />

              <Route path="departments/auditor/boa" element={<BOA />} />
              <Route
                path="departments/auditor/individual-abatements"
                element={<IndividualAbatements />}
              />
              <Route
                path="departments/auditor/property-exemptions"
                element={<PropertyExemptions />}
              />
              <Route
                path="departments/auditor/tax-sale-results"
                element={<TaxSaleResults />}
              />
              <Route path="departments/auditor/budget" element={<Budget />} />

              {/* Building and Zoning */}
              <Route path="departments/building" element={<Building />} />
              <Route
                path="departments/building/faq"
                element={<FAQBuilding />}
              />
              <Route
                path="departments/building/zoning-violation"
                element={<ZoningViolation />}
              />
              <Route
                path="departments/building/forms"
                element={<BuildingForms />}
              />
              <Route
                path="departments/building/gas-form"
                element={<GasForm />}
              />
              <Route
                path="departments/building/duct-form"
                element={<DuctTest />}
              />

              {/* Childrens Justice Center */}
              <Route path="departments/cjc" element={<CJC />} />
              <Route path="departments/cjc/donate" element={<DonateCJC />} />
              <Route
                path="departments/cjc/report-abuse"
                element={<ReportAbuse />}
              />
              <Route
                path="departments/cjc/mental-health-services"
                element={<MentalHealthServices />}
              />
              <Route
                path="departments/cjc/medical-exams"
                element={<MedicalExams />}
              />
              <Route
                path="departments/cjc/resources"
                element={<ResourcesCJC />}
              />
              <Route
                path="departments/cjc/volunteer"
                element={<VolunteerCJC />}
              />
              <Route
                path="departments/cjc/contact-us"
                element={<ContactUsCJC />}
              />
              <Route
                path="departments/cjc/forensic-interviews"
                element={<ForensicInterviews />}
              />

              <Route path="departments/cjc/about-us" element={<AboutUs />} />

              {/* Clerk */}
              <Route path="departments/clerk" element={<Clerk />} />
              <Route
                path="departments/clerk/business-license"
                element={<BusinessLicense />}
              />
              <Route
                path="departments/clerk/elections"
                element={<Elections />}
              />
              <Route
                path="departments/clerk/marriage-license"
                element={<MarriageLicense />}
              />
              <Route path="departments/clerk/passport" element={<Passport />} />
              <Route
                path="departments/clerk/public-notices"
                element={<PublicNotices />}
              />
              <Route
                path="departments/clerk/utility-billing"
                element={<UtilityBilling />}
              />
              <Route
                path="departments/clerk/election-results"
                element={<ElectionResults />}
              />
              <Route
                path="departments/clerk/candidate-information"
                element={<CandidateInformation />}
              />
              <Route
                path="departments/clerk/financial-reports"
                element={<FinancialReports />}
              />

              {/* Commission */}
              <Route path="departments/commission" element={<Commission />} />
              <Route
                path="departments/commission/commission-archive"
                element={<CommissionArchive />}
              />
              <Route
                path="departments/commission/county-boards"
                element={<CountyBoards />}
              />
              <Route
                path="departments/commission/agenda-application"
                element={<AgendaApplication />}
              />
              <Route path="departments/commission/jail" element={<Jail />} />
              <Route
                path="departments/commission/jail-old"
                element={<JailOld />}
              />

              {/* Emergency Management */}
              <Route
                path="departments/emergency-management"
                element={<EmergencyManagment />}
              />
              <Route
                path="departments/emergency-management/citizen-alert"
                element={<CitizenAlert />}
              />
              <Route
                path="departments/emergency-management/cert"
                element={<CERT />}
              />
              <Route
                path="departments/emergency-management/storm-damage"
                element={<StormDamage />}
              />
              <Route
                path="departments/emergency-management/volunteer-hours"
                element={<VolunteerHours />}
              />
              <Route
                path="departments/emergency-management/lepc"
                element={<LEPC />}
              />

              {/* Engineering */}
              <Route path="departments/engineering" element={<Engineering />} />
              <Route
                path="departments/engineering/faq"
                element={<FAQEngineering />}
              />
              <Route
                path="departments/engineering/encroachment-permit"
                element={<EncroachmentPermit />}
              />
              <Route
                path="departments/engineering/addressing"
                element={<Addressing />}
              />
              <Route
                path="departments/engineering/requests-and-forms"
                element={<RequestsAndForms />}
              />

              {/* Fleet */}
              <Route path="departments/fleet" element={<Fleet />} />
              {/* Fire */}
              <Route path="departments/fire" element={<Fire />} />
              <Route
                path="departments/fire/burn-permit"
                element={<BurnPermit />}
              />
              <Route
                path="departments/fire/resources"
                element={<FireResources />}
              />

              {/* Human Resources */}
              <Route path="departments/hr" element={<HumanResources />} />

              {/* IT */}
              <Route path="departments/it" element={<IT />} />
              <Route path="departments/it/gis" element={<GIS />} />
              <Route path="departments/contact-us" element={<ContactUs />} />

              {/* Justice Center */}
              <Route
                path="departments/justice-court"
                element={<JusticeCourt />}
              />
              <Route
                path="departments/justice-court/arraignment"
                element={<Arraignment />}
              />
              <Route
                path="departments/justice-court/entry-of-plea"
                element={<EntryOfPlea />}
              />
              <Route
                path="departments/justice-court/entry-of-plea-form"
                element={<EntryOfPleaForm />}
              />
              <Route
                path="departments/justice-court/expungement"
                element={<Expungement />}
              />
              <Route path="departments/justice-court/faq" element={<FAQJC />} />
              <Route
                path="departments/justice-court/request-court"
                element={<RequestCourt />}
              />
              <Route
                path="departments/justice-court/request-records"
                element={<RequestRecords />}
              />
              <Route
                path="departments/justice-court/small-claims"
                element={<SmallClaims />}
              />
              <Route
                path="departments/justice-court/traffic-school"
                element={<TrafficSchool />}
              />
              <Route
                path="departments/justice-court/traffic-school-contact"
                element={<TrafficSchoolContact />}
              />
              <Route
                path="departments/justice-court/traffic-school-enrollment"
                element={<TrafficSchoolEnrollment />}
              />
              <Route
                path="departments/justice-court/jail-commitment"
                element={<JailCommitment />}
              />
              <Route
                path="departments/justice-court/court-calendar"
                element={<CourtCalendar />}
              />
              {/* <Route
                path="departments/justice-court/seatbelt"
                element={<Seatbelt />}
              /> */}

              {/* Natural Resouces */}
              <Route
                path="departments/natural-resources"
                element={<NaturalResources />}
              />
              <Route
                path="departments/natural-resources/projects"
                element={<Projects />}
              />

              {/* Parks and Rec */}
              <Route path="departments/parks" element={<ParksAndRecs />} />
              <Route
                path="departments/parks/shooting-range"
                element={<ShootingRange />}
              />
              <Route
                path="departments/parks/reservations"
                element={<Reservations />}
              />
              <Route path="departments/parks/trails" element={<Trails />} />
              <Route
                path="departments/parks/five-mile"
                element={<FiveMile />}
              />
              <Route
                path="departments/parks/wood-ranch"
                element={<WoodRanch />}
              />
              <Route
                path="departments/parks/three-peaks"
                element={<ThreePeaks />}
              />

              {/* Planning */}
              <Route path="departments/planning" element={<Planning />} />

              <Route
                path="departments/planning/agenda"
                element={<PlanningAgendas />}
              />
              <Route
                path="departments/planning/request-sketch"
                element={<RequestSketch />}
              />

              <Route
                path="departments/planning/items"
                element={<PlanningItems />}
              />

              {/* Recorders */}
              <Route path="departments/recorders" element={<Recorder />} />
              <Route
                path="departments/recorders/fees"
                element={<RecorderFees />}
              />
              <Route
                path="departments/recorders/file-survey"
                element={<FileSurvey />}
              />

              {/* Roads */}
              <Route path="departments/roads" element={<Roads />} />

              {/* Senior Center */}
              <Route
                path="departments/senior-center"
                element={<SeniorCenter />}
              />
              <Route
                path="departments/senior-center/cedar"
                element={<CedarCenter />}
              />
              <Route
                path="departments/senior-center/parowan"
                element={<ParowanCenter />}
              />

              {/* Solid Waste */}
              <Route path="departments/solid-waste" element={<SolidWaste />} />

              {/* Treasurer */}
              <Route path="departments/treasurer" element={<Treasurer />} />
              <Route
                path="departments/treasurer/change-of-address"
                element={<ChangeOfAddress />}
              />

              {/* Weeds */}
              <Route path="departments/weeds" element={<Weeds />} />
            </Route>
          </Routes>
        </ScrollToTop>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
// (function () {
//   var cx = "9474916a429ab476d";
//   var gcse = document.createElement("script");
//   gcse.type = "text/javascript";
//   gcse.async = true;
//   gcse.src = "https://cse.google.com/cse.js?cx=" + cx;
//   var s = document.getElementsByTagName("script")[0];
//   s.parentNode.insertBefore(gcse, s);
// })();

import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import Title from "../../../Components/Text/Title";
import Textbox from "../../../Components/Text/Textbox";
import FireSidebar from "./FireSidebar";
import Half from "../../../Components/Sizes/Half";
import ButtonSingle from "../../../Components/Buttons/Button_Single";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import TextWebLink from "../../../Components/Links/TextWebLink";
import PhoneLink from "../../../Components/Links/PhoneLink";
import { Helmet } from "react-helmet-async";

function BurnPermit() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Left = () => (
    <>
      <Title>Burn Permits</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <ButtonSingle
          href="https://air.utah.gov/OpenBurning/form/index.php"
          isLink={true}
        >
          Apply for Burn Permit
        </ButtonSingle>
      </Textbox>
      <Textbox>
        <Half>
          <h1>Rules</h1>
          <p>
            <b>
              IRON COUNTY OUTDOOR BURNING DATES MARCH 1st – NOVEMBER 15th. WITH
              A PERMIT. OUTDOOR BURNING IS CLOSED NOVEMBER 15th – THE LAST DAY
              OF FEBRUARY.
            </b>
          </p>
          <p>
            Iron County desires to allow residents, visitors, commercial and
            agricultural industries to burn materials allowed by state law when
            environmental, safety, and regulatory conditions are appropriate.
            Statutorily Closed Fire Season: Burn Permit Required from Fire
            Warden June 1st – October 31st
          </p>
          <p>
            <ol type="A">
              <li>
                Burning Prohibited; Exemptions: Subject to exemptions in this
                chapter, burning is prohibited during the statutorily closed
                fire season from June 1 through October 31 and the regulatory
                closed fire season from November 16 through the last day of
                February. Where state statue permits, from June 1 to October 31,
                and the person obtains a state issued closed season burning
                permit prior to burning, persons may burn pursuant to the terms
                of the state issued permit.
              </li>
              <li>
                Notification Requirement: When a person has obtained a closed
                season burning permit, the person shall notify the local fire
                department of the approximate time the burn will occur before
                the burn takes place.
              </li>
              <li>
                Exemptions: Any local, state, or federal fire official or
                firefighting forces in the performance of official duty.
              </li>
              <li>
                Misdemeanor: Any violation of this section shall be punishable
                as a class C misdemeanor and, upon conviction, subject to
                penalty as provided in section 1.08 of this code.
              </li>
            </ol>
          </p>
          <h1>Permited Burn Season</h1>
          <p>
            Burn Permit Required from Fire Department or Fire Warden March 1st
            –May 30 & November 1 – November 15.
          </p>
          <p>
            <ol type="A">
              <li>
                Burning Permit Required: Subject to exceptions in this chapter,
                persons shall obtain a written county burning permit which shall
                be filled out completely before starting a fire in the
                unincorporated areas of the county during the open fire season
                which is March 1 through May 30 and November 1 through November
                15.
              </li>
              <li>
                Burning permits will be issued only when in compliance with the
                Utah Air Conservation Regulations. The following requirements
                must be met with each permit issued:
                <ol>
                  <li>
                    The permit is not valid and operative unless the clearing
                    index is 500 or above. The clearing index is determined
                    daily by the U.S. Weather Bureau and available at the{" "}
                    <TextWebLink link="https://www.weather.gov/slc/ClearingIndex">
                      National Weather Service Salt Lake City Weather
                    </TextWebLink>{" "}
                    . It can also be obtained by calling the NWS Salt Lake City,
                    (801) 524-5133 or by contacting your local fire department
                    or county Fire Warden
                  </li>
                  <li>
                    A permit may be extended one day at a time, without
                    inspection upon request to the issuing officer. The request
                    must be made before expiration of the permit
                  </li>
                </ol>
              </li>
              <li>
                Burning permits shall not be issued when red flag conditions
                exist or are forecasted by the National Weather Service. Every
                permittee is required to contact the National Weather Service to
                assure that a red flag condition does not exist or is not
                forecasted. Permits are not valid or operative during declared
                red flag conditions.
              </li>
              <li>
                During the permitted burn season, the state Fire Warden, chief
                fire officer or official of each fire protection entity that is
                responsible for providing fire protection services in the
                unincorporated areas of the county may postpone, revoke or deny
                burning permits due to environmental conditions, public
                nuisance, incompetency by the petitioner, or risk to public
                safety.
              </li>
              <li>
                Misdemeanor: Any violation of this section shall be punishable
                as a class C misdemeanor and, upon conviction, subject to
                penalty as provided in section 1.08 of this code.
              </li>
            </ol>
          </p>
        </Half>
        <Half>
          <h1>Application</h1>
          <p>
            During the permitted burn season, the state fire warden, chief fire
            officer or official of each fire protection entity that is
            responsible for providing fire protection services in the
            unincorporated areas of the county shall be responsible for signing
            and issuing a burn permit, on forms provided by the county.
          </p>
          <p>
            There are three ways to complete the open burn permit application:
            <ol>
              <li>
                Online – Follow the link to the{" "}
                <TextWebLink link="https://air.utah.gov/OpenBurning/form/index.php">
                  Burn Permit Request Form.
                </TextWebLink>{" "}
                Completing this form online is the easiest and fastest way to
                complete the open burn permit application. An electronic copy of
                the application is automatically submitted to the county or
                municipal fire authority upon completion.
              </li>
              <li>
                Phone – An applicant can call the DAQ at{" "}
                <PhoneLink>(801) 536-4000</PhoneLink> and complete the open burn
                permit application process over the phone. A DAQ inspector will
                ask the applicant for the required information and complete the
                application in just a few minutes.
              </li>
              <li>Contact your local Fire Department or State Fire Warden</li>
            </ol>
          </p>
          <h1>Exceptions</h1>
          <p>
            The provisions of this chapter are not applicable to:
            <ol type="A">
              <li>
                Any local, state, or federal fire official or firefighting
                forces in the performance of official duty.
              </li>
              <li>
                Devices for the primary purpose of preparing food such as
                outdoor grills and fireplaces.
              </li>
              <li>
                Campfires and fires used solely for recreational purposes
                pending the following guidelines are followed:
                <ol>
                  <li>
                    Where such fires are under control of a responsible person
                    the fire is contained in a pit 18 inches deep into mineral
                    soil absent of roots or any other organic materials or solid
                    ring made of non-combustible material that is at least 18
                    inches in height that will contain the fuel wood or coals
                    while shielding the ashes from being blown by the wind.
                  </li>
                  <li>
                    Maintain 18 inch depth of the pit by removing build up ash
                    and other material; assure disposed materials are completely
                    extinguished.
                  </li>
                  <li>
                    Campfire must be at least 15 feet away from any combustible
                    vegetation or structures, vertically or horizontally. Fire
                    resistant material as part of the landscaping is excluded.
                  </li>
                  <li>
                    At least 1 standard size shovel must be dedicated and
                    immediately available on-site for suppression.
                  </li>
                  <li>
                    5 gallons of water or a 5 pound “A” rated or above fire
                    extinguisher dedicated and immediately available on-site for
                    suppression
                  </li>
                  <li>
                    Campfires must be completely extinguished, cold to the
                    touch, when not attended.
                  </li>
                  <li>
                    Combustible material used in the campfire is clean dry wood
                    or charcoal.
                  </li>
                </ol>
              </li>
            </ol>
          </p>
        </Half>
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Fire: Burn Permit</title>
        <meta name="description" content="Iron County Fire burn permit Page" />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/fire/burn-permit"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<FireSidebar />}
        id="fire-background"
        click={click}
      />
    </>
  );
}

export default BurnPermit;

import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import JailSideBar from "./JailSidebar";
import Textbox from "../../../Components/Text/Textbox";
import Title from "../../../Components/Text/Title";
import PhoneLink from "../../../Components/Links/PhoneLink";
import EmailLink from "../../../Components/Links/EmailLink";
import CardAccordion from "../../../Components/Card/CardAccordion";
import Youtube from "../../../Components/Links/Youtube";
import ButtonSingle from "../../../Components/Buttons/Button_Single";
import Image from "../../../Components/Images/Image";
import Jail1 from "../../../Images/Departments/Commission/Jail/Jail-1.jpg";
import Jail2 from "../../../Images/Departments/Commission/Jail/Jail-2.jpg";
import Jail3 from "../../../Images/Departments/Commission/Jail/Jail-3.jpg";
import Jail4 from "../../../Images/Departments/Commission/Jail/Jail-4.jpg";
import Jail5 from "../../../Images/Departments/Commission/Jail/Jail-5.jpg";
import Half from "../../../Components/Sizes/Half";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

function JailOld() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  const data1 = [
    { id: "Parowan", name: "9.25%", column1: "17.66%", column2: "10.22%" },
    { id: "Paragonah", name: "11.43%", column1: "21.36%", column2: "12.63%" },
    {
      id: "Kanarraville",
      name: "10.15%",
      column1: "19.21%",
      column2: "11.21%",
    },
    { id: "Enoch", name: "10.17%", column1: "19.24%", column2: "11.24%" },
    { id: "Brian Head", name: "8.90%", column1: "17.05%", column2: "9.83%" },
    { id: "Cedar City", name: "9.46%", column1: "18.03%", column2: "10.45%" },
    { id: "County #7", name: "10.05%", column1: "19.04%", column2: "11.11%" },
    { id: "County #10", name: "9.57%", column1: "18.22%", column2: "10.58%" },

    // Add more rows as needed
  ];

  const Left = () => (
    <>
      <Title>Notice of Proposed Tax Increase</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <h1>Iron County is considering an increase in its General Fund Levy</h1>
        <p>
          Due to inflation and the urgent need to construct a new Sheriff's
          Complex, including a jail, Iron County is considering an increase to
          its General Fund. Please select the documents, links, and FAQ's on
          this page for more information.
        </p>
        <p>
          For more information, Call:
          <li>
            General Information <PhoneLink>435-477-8349</PhoneLink>
          </li>
          <li>
            Lucas Little County Auditor <PhoneLink>435-477-8332</PhoneLink>
          </li>
          <li>
            Jon Whittaker County Clerk <PhoneLink>435-477-8341</PhoneLink>
          </li>
          <li>
            Ken Carpenter County Sheriff<PhoneLink>435-867-7550</PhoneLink>
          </li>
          <li>
            Shalon Shaver Jail Commander <PhoneLink>435-867-7594</PhoneLink>
          </li>
          <li>
            Paul Cozzens Commissioner <PhoneLink>435-590-7618</PhoneLink>
          </li>
          <li>
            Mike Bleak Commissioner <PhoneLink>435-590-9666</PhoneLink>
          </li>
          <li>
            Marilyn Wood Commissioner <PhoneLink>435-590-4943</PhoneLink>
          </li>
          <li>
            Email <EmailLink>clerk.group@ironcounty.net</EmailLink>
          </li>
        </p>
      </Textbox>
      <Textbox title="The Iron County General fund is proposing to increase its property tax revenue">
        <li>
          The Iron County General Fund tax on a $405,000 residence would
          increase from $186.44 to $371.93, which is $185.49 per year.
        </li>
        <li>
          The Iron County General Fund tax on a $405,000 business would increase
          from $338.99 to $676.25, which is $337.26 per year.
        </li>
        <li>
          If the proposed budget is approved, Iron County General Fund would
          increase its property tax budgeted revenue by 99.49% above last year’s
          property tax budgeted revenue excluding eligible new growth.
        </li>
      </Textbox>
      <Textbox title="FAQ">
        <>
          <CardAccordion title="An increase of 99.49%?!? Are my taxes going to double?">
            The short answer is <b>No.</b> While the Iron County General Fund
            has a proposed increase of 99.49%, it represents between 9% and 11%
            of your tax bill, depending on where your property is located. There
            are as many as 11 different levies on Iron County tax bills. Because
            the Iron County General Fund is a small part of the overall bill,
            the total increase will be between 10% and 12% (See Chart Below).
            Not all levies are on every tax bill. Some are specific to a city or
            town, some are countywide, and some, like the Central Iron County
            Water Conservancy District, cover certain geographical areas of Iron
            County.
            <br />
            <br />
            <table width="100%">
              <thead>
                <tr>
                  <th>Area</th>
                  <th>County % of Tax Rate Now</th>
                  <th>County % of Tax Rate if Increase</th>
                  <th>Overall Tax Rate Increase</th>
                </tr>
              </thead>
              <tbody>
                {data1.map((item) => (
                  <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>{item.name}</td>
                    <td>{item.column1}</td>
                    <td>{item.column2}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </CardAccordion>

          <CardAccordion title="How does Iron County’s Tax Rate Compare? How Does Utah’s Property Tax Compare?">
            Iron County currently has the 5th lowest General Fund tax rate among
            the 29 counties in Utah. If the proposed tax increase is approved,
            our General Fund tax would be 18th lowest of 29 counties. Property
            taxes in Utah are 12th lowest among the 50 states.
          </CardAccordion>
          <CardAccordion title="Why is this tax increase needed?">
            There are two main reasons for the tax increase, also known as
            “Truth in Taxation.”
            <br />
            <li>
              First, is the need to build a new Sheriff’s Complex, including a
              jail. The bond and staffing expenses will be greater than Iron
              County can currently afford.{" "}
            </li>
            <li>
              Second, is inflation. Iron County has avoided increasing taxes for
              over 20 years and over the past 5 years has been saving up money
              to help pay for a portion of the sheriff’s complex. Due to
              inflation and rising construction costs, the County will need to
              increase taxes in order to maintain its current level of services
              and pay for the Sheriff’s Complex.
            </li>
          </CardAccordion>

          <CardAccordion title="Why does Iron County need to build a new Sheriff’s Complex?">
            The current Sheriff’s Complex and Jail were built in 1987. It is the
            oldest operating correctional facility in Utah. The equipment is
            obsolete and outdated. Many replacement parts are no longer in
            production and must be acquired through websites such as eBay. The
            facility was designed for only four female prisoners and now
            averages 25. Many prisoners are housed not in cells, but in common
            areas that have been converted to dorm-style housing. The current
            jail is near capacity, and there are times when booked prisoners
            must be turned away due to overcrowding. To quote Michael Gibbs the
            Iron County Jail Maintenance Supervisor:
            <br />
            <br />
            <em>
              “Each time we turn away a lawfully arrested individual, we lower
              the standard of acceptable crime in our community.”
            </em>
          </CardAccordion>

          <CardAccordion title="Why does Iron County Even Need a Jail?">
            The Iron County Sheriff is responsible for protecting the citizens
            of Iron County by providing a secure holding facility for persons
            legally confined in the county. The primary mission of the Iron
            County Jail is public safety. The secondary mission of the Iron
            County Jail is to instill the concept of personal responsibility to
            its prisoners.
          </CardAccordion>

          <CardAccordion title="This Seems Like a Very Large Project. Why is it So Big?">
            Deciding the proper size of the facility to build has been carefully
            thought out and researched for years. Ideally, a new Sheriff’s
            Complex will be big enough for current needs and growth, both of
            incarcerated persons as well as sheriff staff. Also, by housing
            contract inmates, the facility will have the ability to mitigate the
            expense of building and staffing it. The existing facility can house
            about 180 inmates. With an expected capacity of over 600 inmates,
            Iron County will be able to meet our local needs, as well as house
            state and federal inmates, helping to reduce the burden on
            taxpayers. Contracting to hold inmates for the generation of revenue
            is a common practice in all of our neighboring counties, including
            Washington, Garfield, Kane, and Beaver.
          </CardAccordion>

          <CardAccordion title="Couldn’t we simply remodel the existing facility?">
            Three factors make this an impossible approach for Iron County
            <li>
              First, the security demands of a jail make it cumbersome and
              expensive to remodel an operating jail.{" "}
            </li>
            <li>
              Second, the existing building and limited land make it impossible.{" "}
            </li>
            <li>
              Third, the design of the building is outdated, and any updates
              would require many more deputies to operate compared to modern
              designs.
            </li>
          </CardAccordion>

          <CardAccordion title="Why Doesn’t Iron County Build the jail away from population centers?">
            Jails are very complex buildings that need significant connections
            to data, water, power, sewer, etc. Every mile that those services
            must extend adds millions of dollars of expense. Also, every law
            enforcement agency in Iron County uses the county jail for detaining
            arrestees, so a remote location would add miles and time away from
            their jurisdiction. Finally, community and officer safety, as well
            as the security of inmates, is lower with every additional mile
            needed to transport prisoners.
          </CardAccordion>

          <CardAccordion title="Where Will the Sheriff’s Complex Be Located?">
            The new facility will be located on a 33-acre parcel of land north
            and west of “Horse Alley” and southeast of the airport in Cedar
            City. For reference, please see this map:
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="/assets/commission-files/jail-site/2023-02-21-Iron County_Presentation_Concept_Page_2.jpg"
            >
              <img
                class=""
                src="/assets/commission-files/jail-site/2023-02-21-Iron County_Presentation_Concept_Page_2.jpg"
                alt="Other misc jail site images"
              />
            </a>
          </CardAccordion>

          <CardAccordion title="What is the Difference Between a Jail, a Prison, and a Penitentiary?">
            All three are considered correctional facilities. Simply put: <br />
            <li>
              <b>
                <u>Counties operate jails </u>
              </b>
              jails and are the first place an accused criminal is detained.
              Jails hold accused persons going through the court process and
              inmates convicted of lesser crimes serving sentences under a year.{" "}
            </li>
            <li>
              <b>
                <u>States operate prisons</u>
              </b>
              with higher security and hold inmates convicted of more severe
              crimes serving sentences over a year.
            </li>
            <li>
              <b>
                <u>The Federal Government operates Penitentiaries</u>
              </b>
              with the highest security, holding inmates convicted of federal
              crimes.
            </li>
          </CardAccordion>

          <CardAccordion title="Who is Released into Our Community When They are Discharged?">
            Conditions of inmate release vary, but typically State and Federal
            inmates are returned to the county they were arrested for release.
            Following sentencing, Federal Inmates are relocated to a Federal
            Penitentiary. County Inmates are released to return to their homes
            and families.
          </CardAccordion>

          <CardAccordion title="Could We Operate the Jail Without Contract Inmates?">
            Yes, we can. However, in that case, Iron County would be required to
            cover the entire burden of operating the facility. This would
            require a tax increase just to operate the new facility, let alone
            to build it. Our goal is to build a responsibly sized facility and
            fill the excess capacity with contract inmates to avoid as much
            impact to the taxpayer as possible.
          </CardAccordion>

          <CardAccordion title="How Many Visitors Come to the Jail Daily?">
            Today, visitors seldom come to the physical jail site for
            visitation. This is because all visitation is required to be via
            video conferencing and no in-person visitation is allowed. Most
            families of inmates choose to stay in their own homes and conference
            from their living room. A very limited number actually come to the
            jail to use our video conferencing room.
          </CardAccordion>

          <CardAccordion title="How Much Traffic Does a Jail Bring?">
            Traffic around a jail is typically limited to Sheriff and other law
            enforcement agencies interacting with the jail. Public traffic is
            very minimal due to digital visitation previously discussed. Unlike
            a Fire-Station or Ambulance speeding or running sirens when exiting
            the facility is rare. Deputies on active patrol primarily respond to
            calls from locations within the community.
          </CardAccordion>

          <CardAccordion title="Will the New County Jail Lower Property Values or Increase Crime Rates Nearby? ">
            Studies have shown that Jail facilities have no negative impact on
            the surrounding property values or on crime rates. This has
            certainly been our experience with the current facility built in
            1987. A thriving community including residential, retail business,
            churches and schools have been built after the current facility,
            without negative effect. Most County Jails are built in very close
            proximity to the populations they serve and interact with.
          </CardAccordion>

          <CardAccordion title="How will this affect my property taxes?">
            As mentioned before, the County has done its best to utilize every
            possible resource before raising taxes. In the past 25 years the
            County has NOT done a property tax increase to generate additional
            revenue for the County. In order to finance this new Sheriff’s
            Complex and jail the County will need to increase revenues by
            approximately $5,900,000 to pay for the future financing and
            operating costs. This additional revenue will be generated by
            increasing the property tax levy across the County. The amount of
            additional taxes will depend on the value of your home, a primary
            residential home with a market value of $400,000 would see
            approximately $15.27 monthly increase in their property tax whereas
            a $700,000 market value home would see a $26.73 monthly increase in
            property tax.
          </CardAccordion>

          <CardAccordion title="What will the County do with this additional revenue?">
            It is critical the County has this additional tax revenue so that it
            can pay for the financing and operating expenses of the Sheriff’s
            Complex and jail. The County is planning to borrow money by issuing
            bonds to pay for the portions of the jail it can not afford with
            cash. The County is in a position that it can borrow money at
            cheaper interest rates, this special kind of debt is called
            Tax-Exempt Bonds. If the County does not borrow money to pay for the
            remaining portion of the jail, then it will never be able to save up
            enough money for the project due to the negative effects of
            inflation. The additional revenue will go towards bond payments,
            jail operating expenses and general operating expenses of the
            County.
          </CardAccordion>

          <CardAccordion title="Will my taxes ever go back down?">
            The County’s main goal is to keep taxes as low as possible while
            still providing the high level of service it needs to function. The
            goal of the County will be for the jail project to eventually pay
            for its operating and financing expenses, at which time it will use
            additional money to start paying down the bonds. Once the debt is
            paid off it can start to look at current needs and make the decision
            to decrease taxes.
          </CardAccordion>
        </>
      </Textbox>
      <Textbox title="Jail Information">
        <section>
          <Youtube src="https://www.youtube.com/embed/-3hhI5z9y9A?si=poi04ZFih6Oe_5hc"></Youtube>
        </section>
        <h1 className="center">Links to Previous Meetings</h1>

        <section className="center">
          <ButtonSingle
            href="https://www.youtube.com/embed/dqYiYJtn_BQ?si=h6e0BNjGaFHZD9rX"
            isLink={true}
          >
            Town Hall Meeting
          </ButtonSingle>
          <ButtonSingle
            href="/files/commission/jail/town-hall-pp-2.pdf"
            isLink={true}
          >
            Town Hall PowerPoint
          </ButtonSingle>
          <ButtonSingle
            href="https://www.youtube.com/embed/1rY5ETOAm50?si=zsQdTUgP4R7TzNzL"
            isLink={true}
          >
            February 22, 2022 Special Meeting
          </ButtonSingle>
          <ButtonSingle
            href="https://www.youtube.com/embed/H1wob5P4M1M?si=wcGNz5WC-Q_2EKRu"
            isLink={true}
          >
            March 14, 2022 Special Meeting
          </ButtonSingle>
          <ButtonSingle
            href="https://www.youtube.com/embed/jeqH0krRwqM?si=urCELK-mwlq2wQn5"
            isLink={true}
          >
            March 31, 2022 Special Meeting
          </ButtonSingle>
        </section>
      </Textbox>
      <Textbox title="March 2023 Concept Designs">
        <Half>
          <section className="center">
            <Image
              width="100%"
              height="auto"
              src={Jail1}
              alt="Concept Designs for the new jail"
            />
            <ButtonSingle
              isLink={true}
              href="/files/commission/jail/jail-1.jpg"
            >
              View
            </ButtonSingle>
          </section>
        </Half>
        <Half>
          <section className="center">
            <Image
              width="100%"
              height="auto"
              src={Jail2}
              alt="Concept Designs for the new jail"
            />
            <ButtonSingle
              isLink={true}
              href="/files/commission/jail/jail-2.jpg"
            >
              View
            </ButtonSingle>
          </section>
        </Half>
        <Half>
          <section className="center">
            <Image
              width="100%"
              height="auto"
              src={Jail3}
              alt="Concept Designs for the new jail"
            />
            <ButtonSingle
              isLink={true}
              href="/files/commission/jail/jail-3.jpg"
            >
              View
            </ButtonSingle>
          </section>
        </Half>
        <Half>
          <section className="center">
            <Image
              width="100%"
              height="auto"
              src={Jail4}
              alt="Concept Designs for the new jail"
            />
            <ButtonSingle
              isLink={true}
              href="/files/commission/jail/jail-4.jpg"
            >
              View
            </ButtonSingle>
          </section>
        </Half>
        <Half>
          <section className="center">
            <Image
              width="100%"
              height="auto"
              src={Jail5}
              alt="Concept Designs for the new jail"
            />
            <ButtonSingle
              isLink={true}
              href="/files/commission/jail/jail-5.jpg"
            >
              View
            </ButtonSingle>
          </section>
        </Half>
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Commission: Jail Old</title>
        <meta
          name="description"
          content="Iron County Commission Jail Old Page"
        />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/commission/jail-old"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<JailSideBar />}
        id="commission-background"
        click={click}
      />
    </>
  );
}

export default JailOld;

import React, { useState } from "react";
import "./TabView.css";

function TabView(props) {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const activateTab = (index) => {
    setActiveTabIndex(index);
  };

  return (
    <div className="TabView" id={props.size}>
      <div>
        {props.tabs.length === 0 ? (
          <div>No Tabs</div>
        ) : (
          <div>
            <div id="tabs">
              {props.tabs.map((tab, index) => (
                <label
                  key={index}
                  id={index === activeTabIndex ? "active-tab" : "tab"}
                  onClick={() => activateTab(index)}
                >
                  {tab.name}
                </label>
              ))}
            </div>
            <div id="content">{props.tabs[activeTabIndex].content} </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default TabView;

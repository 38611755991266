import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import CardAccordion from "../../../Components/Card/CardAccordion";
import TextWebLink from "../../../Components/Links/TextWebLink";
import Textbox from "../../../Components/Text/Textbox";
import Title from "../../../Components/Text/Title";
import { Helmet } from "react-helmet-async";
import ButtonSingle from "../../../Components/Buttons/Button_Single";
import ElectionSidebar from "./ElectionSidebar";

import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";

function ElectionResults() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Left = () => (
    <>
      <Title>Election Results</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <ButtonSingle
        href="/files/departments/clerk/elections/recount/canvass-results-8-5.pdf"
        isLink={true}
      >
        Recount - August 5th
      </ButtonSingle>
      <ButtonSingle
        href="/files/departments/clerk/elections/precinct-july-8th-canvass.pdf"
        isLink={true}
      >
        July 8th Canvass - Precinct
      </ButtonSingle>
      <ButtonSingle
        href="/files/departments/clerk/elections/summary-july-8th-canvass.pdf"
        isLink={true}
      >
        July 8th Canvass - Summary
      </ButtonSingle>
      <Textbox>
        {/* <h1 className="center">November 21st Election Results</h1> */}
        <section className="center"></section>
      </Textbox>
      <Textbox>
        <h1 className="center">Past Results</h1>
        <CardAccordion title="2023 Election Results">
          <section className="center">
            <ButtonSingle
              width="500px"
              isLink={true}
              href="/files/departments/clerk/elections/03-05-2024-summary-results.pdf"
            >
              March 5th, Democratic Election Results
            </ButtonSingle>
          </section>
        </CardAccordion>
        <CardAccordion title="2023 Election Results">
          <section className="center">
            <ButtonSingle
              width="500px"
              isLink={true}
              href="/files/departments/clerk/elections/cd2-summary-results-(canvass).pdf"
            >
              CD2 Summary Results
            </ButtonSingle>
            <ButtonSingle
              width="500px"
              isLink={true}
              href="/files/departments/clerk/elections/cd2-precinct-results-(canvass).pdf"
            >
              CD2 Precinct Summary Results
            </ButtonSingle>
            <ButtonSingle
              isLink={true}
              href="/files/departments/clerk/elections/cedar-precinct-results-(canvass).pdf"
            >
              Cedar City Precinct Summary Results
            </ButtonSingle>
            <ButtonSingle
              isLink={true}
              href="/files/departments/clerk/elections/cedar-summary-results-(canvass).pdf"
            >
              Cedar City Summary Results
            </ButtonSingle>
            <ButtonSingle
              isLink={true}
              href="/files/departments/clerk/elections/parowan-precinct-results-(canvass).pdf"
            >
              Parowan Precinct Summary Results
            </ButtonSingle>
            <ButtonSingle
              isLink={true}
              href="/files/departments/clerk/elections/parowan-summary-results-(canvass).pdf"
            >
              Parowan Summary Results
            </ButtonSingle>
            <ButtonSingle
              href="https://electionresults.utah.gov/results/public/ironcountyutah/elections/2023-Nov-General"
              isLink={true}
            >
              November 21st, 2023
            </ButtonSingle>
            <ButtonSingle
              href="/files/departments/clerk/elections/icsd-recount-12-12.pdf"
              isLink={true}
            >
              ICSD Recount 12-12
            </ButtonSingle>
          </section>
        </CardAccordion>
        <CardAccordion title="2022 Election Results">
          <p>
            Below are links to final primary election results for Iron County
            election that took place in 2022. The results have been combined
            into one document for convenience.
          </p>
          <li>
            <TextWebLink link="/files/departments/clerk/iron-summary-canvass.pdf">
              Final Iron County Election Results (11/21)
            </TextWebLink>
          </li>
          <li>
            <TextWebLink link="/files/departments/clerk/iron-precinct-canvass.pdf">
              Final Iron County Election Precicnt Results (11/21)
            </TextWebLink>
          </li>
          <li>
            <TextWebLink link="/files/departments/clerk/06-28-canvass-results.pdf">
              Primary Summary Results (7/11)
            </TextWebLink>
          </li>
          <li>
            <TextWebLink link="/files/departments/clerk/06-28-precinct-summary.pdf">
              Primary Summary Precinct Results (7/11)
            </TextWebLink>
          </li>
        </CardAccordion>
        <CardAccordion title="2021 Election Results">
          <p>
            Below are links to final primary election results for Iron County
            election that took place in 2021. The results have been combined
            into one document for convenience.
          </p>
          <li>
            <TextWebLink link="/files/departments/clerk/2021-election-summary.pdf">
              Final Iron County Election Results Summary
            </TextWebLink>
          </li>
        </CardAccordion>
        <CardAccordion title="2020 Primary Election Results">
          <p>
            Below are links to final primary election results for Iron County
            after the Canvass took place on July 13th in the Iron County
            Commission Meeting.
          </p>
          <li>
            <TextWebLink link="/files/departments/clerk/iron-7-13-sanvass-precinct.pdf">
              Final Iron County Election Night Summary
            </TextWebLink>
          </li>
          <li>
            <TextWebLink link="/files/departments/clerk/iron-7-13-canvass-summary.pdf">
              Final Iron County Precinct Election Results
            </TextWebLink>
          </li>
        </CardAccordion>
        <CardAccordion title="2020 General Election Results">
          <p>
            Below are links to final primary election results for Iron County
            after the Canvass took place on November 17th during a special Iron
            County Commission Meeting.
          </p>
          <li>
            <TextWebLink link="/files/departments/clerk/2020-final-general-summary.pdf">
              Final Iron County Election Night Summary
            </TextWebLink>
          </li>
          <li>
            <TextWebLink link="/files/departments/clerk/2020-final-general-precinct.pdf">
              Final Iron County Precinct Election Results
            </TextWebLink>
          </li>
        </CardAccordion>
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Clerk: Election Results</title>
        <meta
          name="description"
          content="Iron County Clerk election results Page"
        />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/clerk/election-results"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<ElectionSidebar />}
        id="clerk-background"
        click={click}
      />
    </>
  );
}

export default ElectionResults;

import React from "react";

function TextWebLink(props) {
  return (
    <a target="_blank" rel="noreferrer" href={props.link}>
      {" "}
      {props.children}
    </a>
  );
}
export default TextWebLink;

import React from "react";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import "../../../Components/Sidebar/Sidebar.css";
import HoursContact from "../../../Components/Text/HoursContact";
import TextWebLink from "../../../Components/Links/TextWebLink";
import PhoneLink from "../../../Components/Links/PhoneLink";
import EmailLink from "../../../Components/Links/EmailLink";
import AccordionSidemenu from "../../../Components/AccordionMenu/AccordionSidemenu";

function JusticeSideBar() {
  const navItems = [
    {
      title: "About",
      to: "/departments/justice-court",
      isLink: false,
    },
    {
      title: "Access MyCase",
      to: "https://www.utcourts.gov/en/self-help/services/mycase.html",
      isLink: true,
    },
    {
      title: "Access Warrant Search",
      to: "https://secure.utah.gov/warrants/index.html",
      isLink: true,
    },
    {
      title: "Arraignment",
      to: "/departments/justice-court/arraignment",
      isLink: false,
    },
    {
      title: "Deferred Prosecution",
      to: "https://www.utcourts.gov/en/self-help/case-categories/criminal-justice/deferred.html",
      isLink: true,
    },
    {
      title: "Entry of Plea",
      to: "/departments/justice-court/entry-of-plea",
      isLink: false,
    },
    {
      title: "Expunge Court Records",
      to: "/departments/justice-court/expungement",
      isLink: false,
    },
    {
      title: "FAQ",
      to: "/departments/justice-court/faq",
      isLink: false,
    },
    {
      title: " Forms",
      sub: [
        {
          title: "Community Service Worksheet ",
          to: "/files/departments/justice-court/community-service-worksheet2.pdf",
          isLink: true,
        },
        {
          title: "Jail Commitment",
          to: "/departments/justice-court/jail-commitment",
          isLink: false,
        },
        {
          title: "Notice of Appeal from Justice Court",
          to: "/files/departments/justice-court/notice-of-appeal-from-justice-court.pdf",
          isLink: true,
        },
        {
          title: "Order on Petition to Expunge",
          to: "/files/departments/justice-court/order-on-petition-to-expunge.pdf",
          isLink: true,
        },
        {
          title: "Petition to Expunge",
          to: "/files/departments/justice-court/petition-to-expunge.pdf",
          isLink: true,
        },
      ],
    },
    {
      title: "Make Online Payments Here",
      to: "https://secureinstantpayments.com/sip/reports/CORIS.php?m=3016&submit=Run",
      isLink: true,
    },
    {
      title: "Make a Request to Court",
      to: "/departments/justice-court/request-court",
      isLink: false,
    },
    {
      title: "Request Court Records",
      to: "/departments/justice-court/request-records",
      isLink: false,
    },
    // {
    //   title: " Seat Belt Course",

    //   to: "/departments/justice-court/seatbelt",
    //   isLink: false,
    // },
    {
      title: "Small Claims",
      to: "/departments/justice-court/small-claims",
      isLink: false,
    },
    {
      title: "Traffic School",
      to: "/departments/justice-court/traffic-school",
      isLink: false,
    },
    {
      title: " View Justice Court Calendar",
      to: "/departments/justice-court/court-calendar",
      isLink: false,
    },
    // sub: [
    //   {
    //     title: "Justice Court Calendar",
    //     to: "https://legacy.utcourts.gov/cal/data/Just_Cedar_City_1103_Calendar.pdf",
    //     isLink: true,
    //   },
    //   {
    //     title:
    //       "Use this link to join a Jail Hearing.  Video will start at scheduled time.  Refer to Court Calendar.",
    //     to: "https://utcourts.webex.com/webappng/sites/utcourts/dashboard/pmr/judgebdunlap",
    //     isLink: true,
    //   },
    // ],
  ];

  return (
    <>
      <div className="nav-menu-side">
        <HoursContact>
          <p>
            Justice Court
            <br />
            Brent Dunlap – Judge
            <br />
            Lori Eichelberger – Court Administrator
            <br />
            <TextWebLink link="https://goo.gl/maps/EfPipV79R6hqFJy56">
              82 N 100 E #101, Cedar City, UT 84720
            </TextWebLink>
            <br />
            Monday - Friday 8:00 A.M. - 5:00 P.M.
            <br />
            <PhoneLink>435-865-5335</PhoneLink>
            <br />
            <EmailLink>jcironcounty@utcourts.gov</EmailLink>
          </p>
        </HoursContact>
        <br />
        <AccordionSidemenu nav={navItems} id="department-sidebar" />
      </div>
    </>
  );
}

export default JusticeSideBar;

import React, { useState } from "react";
import "../Departments.css";
import RecordersSidebar from "./RecordersSidebar";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import Textbox from "../../../Components/Text/Textbox";
import Title from "../../../Components/Text/Title";
import { Helmet } from "react-helmet-async";
import TextWebLink from "../../../Components/Links/TextWebLink";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";

function RecorderFees() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Left = () => (
    <>
      <Title>Fees</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>

      <Textbox>
        <div className="hero-child">
          <p>
            <h3>
              <u>
                <b>Fee Schedule</b>
              </u>
            </h3>
            <li>
              Effective May 14th, 2019.{" "}
              <TextWebLink link="https://le.utah.gov/xcode/Title17/Chapter21/17-21-S18.5.html?v=C17-21-S18.5_2014040320150701">
                Utah Code 17-21-18.5
              </TextWebLink>
            </li>
            <br />

            <table style={{ width: "100%", border: "solid" }}>
              <tr>
                <th colSpan={2}>Document Recording Fees</th>
              </tr>

              <tr>
                <td style={{ width: "80%" }}>Standard Document Fee</td>
                <td>$40.00</td>
              </tr>
              <tr>
                <td>Each Lot/Description over 10</td>
                <td>$2.00</td>
              </tr>
              <tr>
                <td>Subdivision Plat each page</td>
                <td>$50.00</td>
              </tr>
              <tr>
                <td>Each Lot/Unit on a Subdivision Plat</td>
                <td>$2.00</td>
              </tr>
            </table>

            <table style={{ width: "100%", border: "solid" }}>
              <tr>
                <th colSpan={2}>Copy Fees</th>
              </tr>

              <tr>
                <td style={{ width: "80%" }}>Copy of recorded document</td>
                <td>$1.00</td>
              </tr>
              <tr>
                <td>Xerox copies (per copy)</td>
                <td>10¢</td>
              </tr>
              <tr>
                <td>Certificate under seal</td>
                <td> $5.00</td>
              </tr>
            </table>
            <table style={{ width: "100%", border: "solid" }}>
              <tr>
                <th colSpan={2}>Document Recording Fees</th>
              </tr>

              <tr>
                <td style={{ width: "80%" }}>Ownership Plat, 18″x18″</td>
                <td>$3.00</td>
              </tr>
              <tr>
                <td>Official Plat, 24″x36″</td>
                <td>$5.00</td>
              </tr>
              <tr>
                <td>County Map</td>
                <td>$5.00</td>
              </tr>
            </table>
          </p>
        </div>
      </Textbox>
      <Textbox title="Checklist to Assist Individuals Recording a Document">
        <p>
          <ul>
            <h3>Each Document must:</h3>
            <li>Contain original signatures and notary</li>
            <li>
              Contain a legible legal description of the property and the serial
              number
            </li>
            <li>
              If the document is conveying property, the grantee’s mailing
              address must be included
            </li>
            <br />
            <b>
              NOTE: The recording stamp requires a space 2.5 inches down and 4.5
              inches across on the upper right corner of the first page, and a
              margin of 1 inch at the bottom of each successive page.
            </b>
          </ul>
        </p>
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Recorders: Fees</title>
        <meta name="description" content="Iron County Recorders fees Page" />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/recorders/fees"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<RecordersSidebar />}
        id="recorder-background"
        click={click}
      />
    </>
  );
}

export default RecorderFees;

import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import ResourcesSideBar from "./ResourcesSidebar";
import Title from "../../../Components/Text/Title";
import TextWebLink from "../../../Components/Links/TextWebLink";
import Textbox from "../../../Components/Text/Textbox";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import ButtonSingle from "../../../Components/Buttons/Button_Single";
import CardAccordion from "../../../Components/Card/CardAccordion";
import { Helmet } from "react-helmet-async";

function Projects() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Left = () => (
    <>
      <Title>Local & Regional Projects</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <br />
      <Textbox>
        <CardAccordion title="Greater Sage Grouse Planning">
          <p>
            <h1>Greater Sage Grouse Planning</h1>
            <p>
              The greater sage-grouse was considered for listing under the
              Endangered Species Act by the federal government a few years ago.
              However, with cooperation from the US Fish and Wildlife, state and
              local governments developed conservation strategies to avoid
              listing and put in place conservation measures to ensure adequate
              habitat. BLM has attempted to incorporate these conservation plans
              into their land use planning process, but each time the federal
              administration changes, new direction changes the course of
              action. Currently, federal agencies are working under a 2015 plan
              and all land use plans are being revised.
            </p>
            <ul>
              <li>
                Federal Agencies: The federal agencies are preparing a
                region-wide Environmental Assessment that will provide direction
                to BLM and Forest Service offices on conservation measures to
                take in management of sage-grouse habitat.{" "}
                <TextWebLink link="https://www.blm.gov/programs/fish-and-wildlife/sage-grouse">
                  www.blm.gov/programs/fish-and-wildlife/sage-grouse
                </TextWebLink>{" "}
              </li>
              <li>
                Utah: The state of Utah has prepared a Conservation Plan for
                Greater Sage-grouse in Utah, February 14, 2013. The identified
                conservation measures will become an alternative in the federal
                EIS mentioned above:{" "}
                <TextWebLink link="https://wildlife.utah.gov/greater-sage-grouse.html">
                  wildlife.utah.gov/greater-sage-grouse.html
                </TextWebLink>{" "}
              </li>
              <li>
                Iron County (This is where the link from the “News and Events”
                page should take the reader): In response to the proposed
                listing of the sage-grouse under the Endangered Species Act,
                Iron County has prepared a draft Greater Sage-grouse Resource
                Management Plan that is currently open for public comment. This
                plan will be considered for amended into the Iron County General
                Plan by the County Commissioners after review by the public and
                by the Iron County Planning Commission.
              </li>
            </ul>
            <p>
              To review and make comments on the document click here. Send
              comments either by mail or email to Mike Worthen, IC Natural
              Resource Specialist. Contact information is listed at the top of
              this page.
            </p>
          </p>
        </CardAccordion>
        <CardAccordion title="BLM Resource Management Plan">
          <p>
            The BLM is currently revising their Field Office-wide planning
            document that will provide guidance on local projects for the next
            15 to 20 years. The public can follow the progress of this planning
            process and provide timely comments by visiting{" "}
            <TextWebLink link="https://www.blm.gov/office/cedar-city-field-office">
              Cedar City Field Office
            </TextWebLink>
          </p>
        </CardAccordion>
        <CardAccordion title="5-Mile Picnic Area Project (AKA Vermillion Castle Picnic Area)">
          <p>
            The 5-Mile Picnic Area has been popular picnic site for local
            residences for over 90 years. Located four miles south of Parowan on
            SR 143 and one mile east up Bowery creek, it is nestled in the
            vermillion and red rock formations. In 2017, floods created by the
            Brian Head fire scar that took out most of the picnic sites and
            caused extensive damage to the area. Iron County secured a permit
            from the Dixie National Forest to reconstruct the site and should be
            open to the public in the summer of 2024.
          </p>
        </CardAccordion>
        <CardAccordion title="Recreation Trails">
          <p>See Iron County Parks and Recreation</p>
        </CardAccordion>
        <CardAccordion title="Pine Valley Water Supply Project">
          <p>
            This project is a resilient water solution for Cedar Valley in Iron
            County. It will sustainably supply the valley with 15,000 acre-feet
            (AF) of court decreed water rights owned by the District. This new
            source will help restore aquifer levels that have been declining,
            and provide safe and reliable water for the future
            <ButtonSingle
              width="500px"
              isLink={true}
              href="https://cicwcd.org/pvwsproject/"
            >
              Water Conservnacy District
            </ButtonSingle>
          </p>
        </CardAccordion>
        <CardAccordion title="Recreation Trails">
          <ButtonSingle
            width="500px"
            isLink={false}
            href="/departments/parks/trails"
          >
            Parks and Trails
          </ButtonSingle>
        </CardAccordion>
        <CardAccordion title="Cedar Mountain Winter Recreation Parking Project">
          <p>
            The project will enhance winter recreation opportunities for both
            motorized and non-motorized uses on Cedar Mountain located off
            Scenic Highway 14. Parking areas will be constructed to allow safe
            winter parking and high quality winter recreational experiences,
            while promoting economic benefits for local businesses in rentals,
            guides, and sales of snowmobiles, sled, and cross-country skis. The
            parking areas are planned to be complete by the 2024/2025 winter
            season.
          </p>
        </CardAccordion>
        <CardAccordion title="Vision Iron County Project">
          <p>
            The mission of the Vision Iron County is to bring stakeholders
            together and create an atmosphere of collaboration for recreation
            planning.
          </p>
          <ButtonSingle
            width="500px"
            isLink={true}
            href="https://visionironcounty.org/"
          >
            Learn More
          </ButtonSingle>
        </CardAccordion>
        <CardAccordion title="Three-Peaks Recreation Area - Equestrian Challenge Course Project">
          <p>
            Iron County and the BLM are planning an International Mountain Trail
            Challenge Association (IMTCA) course dedicated to the sport of
            equine mountain trail. The course will be constructed on Bureau of
            Land Management lands in the Three Peaks Recreation area in Iron
            County. The course will provide numerous obstacles and challenges
            including bridges, teeter-totters, a trench, water obstacles, a
            gate, logs and balance beams. The course will provide a fun and
            family-oriented atmosphere to broaden opportunities for both the
            rider and the horse. These courses are very popular around the world
            and would be professionally designed, constructed and aesthetically
            pleasing to fit in with the natural landscape. The course should be
            finished by 2025.
          </p>
        </CardAccordion>
        <CardAccordion title="Wild Horses">
          <p>
            The western portion of Iron County has eight Horse Management Areas
            designated for wild horse management. The federal government has
            jurisdiction over wild horses as detailed in the 1971 Wild Horse and
            Burro Act. The BLM is the lead agency on wild horse management. For
            more management information, visit the sites below:
          </p>
          <ButtonSingle
            width="500px"
            isLink={true}
            href="https://wildhorsesonline.blm.gov/"
          >
            BLM
          </ButtonSingle>
          <ButtonSingle
            width="500px"
            isLink={true}
            href="https://extension.usu.edu/freesnetwork/"
          >
            Utah State
          </ButtonSingle>
        </CardAccordion>
        <CardAccordion title="Utah Prairie Dog Planning">
          <p>
            In 2018, The Range-wide General Conservation Plan for the Utah
            prairie dog in Residential and Commercial Development Areas (GCP)
            went into effect. This plan allows landowners or developers to get
            authorization to build on private property that has Utah prairie
            dogs or their habitat. The clearance area is the area where Utah
            prairie dog development surveys are required to determine if there
            are prairie dogs present or not on the property. The GCP requires
            that Utah prairie dog development surveys before any
            ground-disturbing activities take place. The GCP also has provisions
            that help conserve Utah prairie dogs while managing or preventing
            conflicts with humans.
          </p>
          <ButtonSingle
            width="500px"
            isLink={true}
            href="https://ironcounty.mycityinspector.com/register?registration_id=15ccc64ec1d01c"
          >
            Building Permit Requirements
          </ButtonSingle>
        </CardAccordion>
        <CardAccordion title="Water">
          <p>
            Water in Utah is managed by the State. For questions relating to
            water (well drilling, water rights, stream alterations,
            adjudication, etc.) contact the Utah Department of Natural
            Resources, Water Rights Division.
          </p>
          <ButtonSingle
            width="500px"
            isLink={true}
            href="https://waterrights.utah.gov/"
          >
            Water Rights Division
          </ButtonSingle>
        </CardAccordion>
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Natural Resources: Projects</title>
        <meta
          name="description"
          content="Iron County Justice Court Natural Resources projects Page"
        />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/natural-resources/projects"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<ResourcesSideBar />}
        id="resources-background"
        click={click}
      />
    </>
  );
}

export default Projects;

export const CommissionHistory = [
  {
    date: "2023",
    agenda: "/files/commission/archive/2023-agendas.pdf",
    minutes: "/files/commission/archive/2023-minutes.pdf",
    youtube: "",
  },
  {
    date: "2022",
    agenda: "/files/commission/archive/2022-agendas.pdf",
    minutes: "/files/commission/archive/2022-minutes.pdf",
    youtube: "",
  },
  {
    date: "2021",
    agenda: "/files/commission/archive/2021-agendas.pdf",
    minutes: "/files/commission/archive/2021-minuteslarge.pdf",
    youtube: "",
  },
  {
    date: "2020",
    agenda: "/files/commission/archive/2020-commission-agendas.pdf",
    minutes: "/files/commission/archive/2020-commission-minutes.pdf",
    youtube: "",
  },
  {
    date: "2019",
    agenda: "/files/commission/archive/2019-commission-agendas.pdf",
    minutes: "/files/commission/archive/2019-commission-minutes.pdf",
    youtube: "",
  },
  {
    date: "2018",
    agenda: "/files/commission/archive/2018-commission-agendas.pdf",
    minutes: "/files/commission/archive/2018-commission-minutes.pdf",
    youtube: "",
  },
  {
    date: "2017",
    agenda: "/files/commission/archive/2017-commission-agendas.pdf",
    minutes: "/files/commission/archive/2017-commission-minutes.pdf",
    youtube: "",
  },
  {
    date: "2016",
    agenda: "/files/commission/archive/2016-commission-agendas.pdf",
    minutes: "/files/commission/archive/2016-commission-minutes.pdf",
    youtube: "",
  },
  {
    date: "2015",
    agenda: "/files/commission/archive/2015-commission-agendas.pdf",
    minutes: "/files/commission/archive/2015-commission-minutes.pdf",
    youtube: "",
  },
  {
    date: "2014",
    agenda: "/files/commission/archive/2014-commission-agendas.pdf",
    minutes: "/files/commission/archive/2014-commission-minutes.pdf",
    youtube: "",
  },
  {
    date: "2013",
    agenda: "/files/commission/archive/2013-commission-agendas.pdf",
    minutes: "/files/commission/archive/2013-commission-minutes.pdf",
    youtube: "",
  },
  {
    date: "2012",
    agenda: "/files/commission/archive/2012-commission-agendas.pdf",
    minutes: "/files/commission/archive/2012-commission-minutes.pdf",
    youtube: "",
  },
  {
    date: "2011",
    agenda: "/files/commission/archive/2011-commission-agendas.pdf",
    minutes: "/files/commission/archive/2011-commission-minutes.pdf",
    youtube: "",
  },
  {
    date: "2010",
    agenda: "",
    minutes: "/files/commission/archive/2010-commission-minutes.pdf",
    youtube: "",
  },
  {
    date: "2009",
    agenda: "",
    minutes: "/files/commission/archive/2009-commission-minutes.pdf",
    youtube: "",
  },
  {
    date: "2008",
    agenda: "",
    minutes: "/files/commission/archive/2008-commission-minutes.pdf",
    youtube: "",
  },
  {
    date: "2007",
    agenda: "",
    minutes: "/files/commission/archive/2007-commission-minutes.pdf",
    youtube: "",
  },
  {
    date: "2006",
    agenda: "",
    minutes: "/files/commission/archive/2006-commission-minutes.pdf",
    youtube: "",
  },
  {
    date: "2005",
    agenda: "",
    minutes: "/files/commission/archive/2005-commission-minutes.pdf",
    youtube: "",
  },
  {
    date: "2004",
    agenda: "",
    minutes: "/files/commission/archive/2004-commission-minutes.pdf",
    youtube: "",
  },
  {
    date: "2003",
    agenda: "",
    minutes: "/files/commission/archive/2003-commission-minutes.pdf",
    youtube: "",
  },
  {
    date: "2002",
    agenda: "",
    minutes: "/files/commission/archive/2002-commission-minutes.pdf",
    youtube: "",
  },
  {
    date: "2001",
    agenda: "",
    minutes: "/files/commission/archive/2001-commission-minutes.pdf",
    youtube: "",
  },
  {
    date: "2000",
    agenda: "",
    minutes: "/files/commission/archive/2000-commission-minutes.pdf",
    youtube: "",
  },
  {
    date: "1999",
    agenda: "",
    minutes: "/files/commission/archive/1999-commission-minutes.pdf",
    youtube: "",
  },
  {
    date: "1998",
    agenda: "",
    minutes: "/files/commission/archive/1998-commission-minutes.pdf",
    youtube: "",
  },
  {
    date: "1997",
    agenda: "",
    minutes: "/files/commission/archive/1997-commission-minutes.pdf",
    youtube: "",
  },
  {
    date: "1996",
    agenda: "",
    minutes: "/files/commission/archive/1996-commission-minutes.pdf",
    youtube: "",
  },
  {
    date: "1995",
    agenda: "",
    minutes: "/files/commission/archive/1995-commission-minutes.pdf",
    youtube: "",
  },
  {
    date: "1994",
    agenda: "",
    minutes: "/files/commission/archive/1994-commission-minutes.pdf",
    youtube: "",
  },
  {
    date: "1993",
    agenda: "",
    minutes: "/files/commission/archive/1993-commission-minutes.pdf",
    youtube: "",
  },
  {
    date: "1992",
    agenda: "",
    minutes: "/files/commission/archive/1992-commission-minutes.pdf",
    youtube: "",
  },
  {
    date: "1991",
    agenda: "",
    minutes: "/files/commission/archive/1991-commission-minutes.pdf",
    youtube: "",
  },
  {
    date: "1990",
    agenda: "",
    minutes: "/files/commission/archive/1990-commission-minutes.pdf",
    youtube: "",
  },
  {
    date: "1989",
    agenda: "",
    minutes: "/files/commission/archive/1989-commission-minutes.pdf",
    youtube: "",
  },
  {
    date: "1988",
    agenda: "",
    minutes: "/files/commission/archive/1988-commission-minutes.pdf",
    youtube: "",
  },
  {
    date: "1987",
    agenda: "",
    minutes: "/files/commission/archive/1987-commission-minutes.pdf",
    youtube: "",
  },
  {
    date: "1986",
    agenda: "",
    minutes: "/files/commission/archive/1985-commission-minutes.pdf",
    youtube: "",
  },
  {
    date: "1985",
    agenda: "",
    minutes: "/files/commission/archive/1985-commission-minutes.pdf",
    youtube: "",
  },
  {
    date: "1984",
    agenda: "",
    minutes: "/files/commission/archive/1984-commission-minutes.pdf",
    youtube: "",
  },
  {
    date: "1983",
    agenda: "",
    minutes: "/files/commission/archive/1983-commission-minutes.pdf",
    youtube: "",
  },
  {
    date: "1982",
    agenda: "",
    minutes: "/files/commission/archive/1982-commission-minutes.pdf",
    youtube: "",
  },
  {
    date: "1981",
    agenda: "",
    minutes: "/files/commission/archive/1981-commission-minutes.pdf",
    youtube: "",
  },
  {
    date: "1980",
    agenda: "",
    minutes: "/files/commission/archive/1980-commission-minutes.pdf",
    youtube: "",
  },
  {
    date: "1979",
    agenda: "",
    minutes: "/files/commission/archive/1979-commission-minutes.pdf",
    youtube: "",
  },
  {
    date: "1978",
    agenda: "",
    minutes: "/files/commission/archive/1978-commission-minutes.pdf",
    youtube: "",
  },
  {
    date: "1977",
    agenda: "",
    minutes: "/files/commission/archive/1977-commission-minutes.pdf",
    youtube: "",
  },
  {
    date: "1976",
    agenda: "",
    minutes: "/files/commission/archive/1976-commission-minutes.pdf",
    youtube: "",
  },
  {
    date: "1975",
    agenda: "",
    minutes: "/files/commission/archive/1975-commission-minutes.pdf",
    youtube: "",
  },
  {
    date: "1974",
    agenda: "",
    minutes: "/files/commission/archive/1974-commission-minutes.pdf",
    youtube: "",
  },
  {
    date: "1973",
    agenda: "",
    minutes: "/files/commission/archive/1973-commission-minutes.pdf",
    youtube: "",
  },
  {
    date: "1972",
    agenda: "",
    minutes: "/files/commission/archive/1972-commission-minutes.pdf",
    youtube: "",
  },
  {
    date: "1971",
    agenda: "",
    minutes: "/files/commission/archive/1971-commission-minutes.pdf",
    youtube: "",
  },
  {
    date: "1970",
    agenda: "",
    minutes: "/files/commission/archive/1970-commission-minutes.pdf",
    youtube: "",
  },
  {
    date: "1969",
    agenda: "",
    minutes: "/files/commission/archive/1969-commission-minutes.pdf",
    youtube: "",
  },
  {
    date: "1968",
    agenda: "",
    minutes: "/files/commission/archive/1968-commission-minutes.pdf",
    youtube: "",
  },
  {
    date: "1967",
    agenda: "",
    minutes: "/files/commission/archive/1967-commission-minutes.pdf",
    youtube: "",
  },
  {
    date: "1966",
    agenda: "",
    minutes: "/files/commission/archive/1966-commission-minutes.pdf",
    youtube: "",
  },
  {
    date: "1965",
    agenda: "",
    minutes: "/files/commission/archive/1965-commission-minutes.pdf",
    youtube: "",
  },
];

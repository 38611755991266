import React from "react";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import "../../../Components/Sidebar/Sidebar.css";
import HoursContact from "../../../Components/Text/HoursContact";
import TextWebLink from "../../../Components/Links/TextWebLink";
import PhoneLink from "../../../Components/Links/PhoneLink";
import AccordionSidemenu from "../../../Components/AccordionMenu/AccordionSidemenu";

function WeedSideBar() {
  const navItems = [
    // {
    //
    //   title: "Test",
    //   to: "/",
    //   isLink: true,
    // },
  ];

  return (
    <>
      <div className="nav-menu-side">
        <HoursContact>
          <p>
            Weed Control
            <br />
            Amber Hatch
            <br />
            PO Box 1030
            <br />
            <TextWebLink link="https://maps.app.goo.gl/KQsawqbNjTnGSZV8A">
              1302 W Kittyhawk Drive Ste. 3 Cedar City, UT 84721
            </TextWebLink>
            <br />
            Monday - Friday 8:00 A.M. - 5:00 P.M.
            <br />
            <PhoneLink>435-865-5406</PhoneLink>
          </p>
        </HoursContact>

        <br />
        <AccordionSidemenu nav={navItems} id="department-sidebar" />
      </div>
    </>
  );
}

export default WeedSideBar;

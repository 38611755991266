import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import { Helmet } from "react-helmet-async";
import TextWebLink from "../../../Components/Links/TextWebLink";
import Textbox from "../../../Components/Text/Textbox";
import Title from "../../../Components/Text/Title";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import ElectionSidebar from "./ElectionSidebar";
import CardSmallMultiple from "../../../Components/Card/Card-Small-Multiple";

function CandidateInformation() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  const cardItems = [
    {
      title: "Ben A. Johnson",
      desc: "School District 2",

      links: [
        {
          title: "Download PDF",
          to: "/files/departments/clerk/elections/candidate/ben-a-johnson.pdf",
          isLink: true,
        },
      ],
    },
    {
      title: "Carri R. Jeffries",
      desc: "Iron County Recorder/Surveyor",

      links: [
        {
          title: "Download PDF",
          to: "/files/departments/clerk/elections/candidate/carrie-r-jeffries.pdf",
          isLink: true,
        },
      ],
    },
    {
      title: "Dallas J. Guymon (Withdrawn)",
      desc: `Commission "C"`,

      links: [
        {
          title: "Download PDF",
          to: "/files/departments/clerk/elections/candidate/dallas-j-guymon.pdf",
          isLink: true,
        },
      ],
    },
    {
      title: "James S. Johnson",
      desc: "School District 3",

      links: [
        {
          title: "Download PDF",
          isLink: true,
          to: "/files/departments/clerk/elections/candidate/james-s-johnson.pdf",
        },
      ],
    },
    {
      title: "John R. Taylor",
      desc: "School District 1",

      links: [
        {
          title: "Download PDF",
          to: "/files/departments/clerk/elections/candidate/john-r-taylor.pdf",
          isLink: true,
        },
      ],
    },
    {
      title: "Karsten M. Reed",
      desc: `Iron County Assessor`,

      links: [
        {
          title: "Download PDF",
          to: "/files/departments/clerk/elections/candidate/karsten-m-reed.pdf",
          isLink: true,
        },
      ],
    },
    {
      title: "Kenneth L. Robinson",
      desc: `Commission "C"`,

      links: [
        {
          title: "Download PDF",
          to: "/files/departments/clerk/elections/candidate/kenneth-l-robinson.pdf",
          isLink: true,
        },
      ],
    },
    {
      title: "Lucas A. Little",
      desc: "Iron County Auditor",

      links: [
        {
          title: "Download PDF",
          to: "/files/departments/clerk/elections/candidate/lucas-a-little.pdf",
          isLink: true,
        },
      ],
    },
    {
      title: "Maile Wilson Edwards",
      desc: `Commission "C"`,

      links: [
        {
          title: "Download PDF",
          to: "/files/departments/clerk/elections/candidate/maile-wilson-edwards.pdf",
          isLink: true,
        },
      ],
    },

    {
      title: "Nicole Rosenberg",
      desc: "Iron County Treasurer",

      links: [
        {
          title: "Download PDF",
          to: "/files/departments/clerk/elections/candidate/nicole-rosenberg.pdf",
          isLink: true,
        },
      ],
    },
    {
      title: "Patrick L. Larson (Withdrawn)",
      desc: `Commission "C"`,

      links: [
        {
          title: "Download PDF",
          to: "/files/departments/clerk/elections/candidate/patrick-l-larson.pdf",
          isLink: true,
        },
      ],
    },

    {
      title: "Rex P. Shipp",
      desc: "House of Representatives 71",

      links: [
        {
          title: "Download PDF",
          to: "/files/departments/clerk/elections/candidate/rex-p-shipp.pdf",
          isLink: true,
        },
      ],
    },
    {
      title: "Steve Merrill",
      desc: `House 71`,

      links: [
        {
          title: "Download PDF",
          to: "/files/departments/clerk/elections/candidate/steve-merrill.pdf",
          isLink: true,
        },
      ],
    },
    {
      title: "Steven M. Dodds (Withdrawn)",
      desc: `Commission "C"`,

      links: [
        {
          title: "Download PDF",
          to: "/files/departments/clerk/elections/candidate/steven-m-dodds-withdraw.pdf",
          isLink: true,
        },
      ],
    },
    {
      title: "Tyrel W. Eddy",
      desc: "School District 3",

      links: [
        {
          title: "Download PDF",
          to: "/files/departments/clerk/elections/candidate/tyrel-w-eddy.pdf",
          isLink: true,
        },
      ],
    },
    {
      title: "Zeno B. Parry",
      desc: "House of Representatives 70",

      links: [
        {
          title: "Download PDF",
          to: "/files/departments/clerk/elections/candidate/zeno-b-parry.pdf",
          isLink: true,
        },
      ],
    },
  ];

  const Left = () => (
    <>
      <Helmet>
        <title>Clerk: Candidate Information</title>
        <meta
          name="description"
          content="Iron County Clerk candidate information Page"
        />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/clerk/candidate-information"
        />
      </Helmet>
      <Title>Candidate Information</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <p>
          Please note: This candidate information is provided as a courtesy to
          citizens within Iron County. Contact information provided for each
          candidate was supplied by the candidates themselves when filing for
          office, either with the Lieutenant Governor's office or the Iron
          County Clerk, depending on the office. Iron County does not endorse
          any candidate and is not responsible for verifying the accuracy of any
          statement provided by any candidate.
        </p>
        <p>
          To learn more information about all of the candidates and items on
          your ballot this year, click here:{" "}
          <TextWebLink link="https://votesearch.utah.gov/voter-search/search/search-by-address/candidates-and-issues">
            votesearch.utah.gov/voter-search/search/search-by-address/candidates-and-issues
          </TextWebLink>
        </p>
      </Textbox>
      <Textbox>
        <CardSmallMultiple data={cardItems}></CardSmallMultiple>
      </Textbox>
    </>
  );

  return (
    <>
      <DepartmentTemplate
        left={<Left />}
        right={<ElectionSidebar />}
        id="clerk-background"
        click={click}
      />
    </>
  );
}

export default CandidateInformation;

import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import Title from "../../../Components/Text/Title";
import Textbox from "../../../Components/Text/Textbox";
import FleetSidebar from "./FleetSidebar";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import Map from "../../../Components/Maps/Map";
import { Helmet } from "react-helmet-async";
import PhoneLink from "../../../Components/Links/PhoneLink";

function Fleet() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Left = () => (
    <>
      <Title>Fleet</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <h1>Motor Pool Reservation, Check out and Return Instructions</h1>
        {/* <p>
          The calendar below displays which vehicle are checked out and for how
          long.
        </p> */}
        <p>
          If you would like to reserve a vehicle please contact the fleet
          department at <PhoneLink>435-865-5407</PhoneLink>
        </p>
        {/* <ol>
          <li>
            <u>Motor Pool Vehicles need to be reserved in advance.</u>
          </li>
          <ul>
            <li>
              Please fill out the RTA Reservation form to request a vehicle. You
              will receive a conformation email with a reservation number. (
              Contact Fleet to receive the RTA Reservation Form)
            </li>
          </ul>

          <li>
            <u>Before your trip</u>
          </li>
          <ul>
            <li>
              {" "}
              Please fill out the check out form located in the vehicle at the
              start of you trip ( Scan the QR code from your mobile device to
              access the Check out / Return Form)
            </li>
          </ul>

          <li>
            <u>Upon Return</u>
          </li>
          <ul>
            <li>
              {" "}
              Please fill out the (Check out / Return Form) by scanning the same
              QR Code located in the vehicle. and return key to key box.
            </li>
          </ul>
        </ol> */}
        {/* <p>
          For further assistance or clarification, please contact the Fleet
          Dept.
        </p> */}
      </Textbox>
      {/* <Textbox>
        <Map src="https://outlook.office365.com/owa/calendar/ef80150c98e44e96a8b180c9b9ba9efc@ironcounty.net/85fd3c2b939e4d8696d28eb6e59c458711043383112737653473/calendar.html" />
      </Textbox> */}
      {/* <Textbox>
        <Map src="https://forms.office.com/pages/responsepage.aspx?id=Kw5RoFlVfkq8_LRykMDbvwFcf86vMvJKule7x7VUl5FUMVBKS0FCVlREUTZZOFFFVkhOQlYzN09HTS4u&origin=lprLink" />
      </Textbox> */}
    </>
  );

  return (
    <>
      <Helmet>
        <title>Fleet</title>
        <meta name="description" content="Iron County Fleet Page" />
        <link rel="canonical" href="https://ironcounty.net/departments/fleet" />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<FleetSidebar />}
        id="fleet-background"
        click={click}
      />
    </>
  );
}

export default Fleet;

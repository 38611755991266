import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import EngineeringSidebar from "./EngineeringSidebar";
import Textbox from "../../../Components/Text/Textbox";
import Title from "../../../Components/Text/Title";
import CardAccordion from "../../../Components/Card/CardAccordion";
import PhoneLink from "../../../Components/Links/PhoneLink";
import EmailLink from "../../../Components/Links/EmailLink";
import ButtonSingle from "../../../Components/Buttons/Button_Single";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

function FAQEngineering() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  const Left = () => (
    <>
      <Title>Frequently Asked Questions</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <br />
      <Textbox>
        <CardAccordion title="Do I need an encroachment permit to add a driveway or culvert to my property?">
          <p>
            Yes, if you are going to access your property from a public road or
            county right of way. If you are building a home, the first access is
            approved with your building permit. Any additional accesses will
            need an encroachment permit.{" "}
          </p>
        </CardAccordion>
        <CardAccordion title="Did you get a notice (sticker) on your “Private Property” or “No Trespassing” sign saying it is not in compliance?">
          <p>
            It is likely that the road is a public road and cannot be blocked.
            We have many public roads that go through private property. Please
            contact the Engineer’s office at <PhoneLink>435-865-5370</PhoneLink>{" "}
            to review and resolve the situation. Each instance will be evaluated
            by the Engineer and/or Road Review Committee. The county has
            suggestions and options for you to come into compliance. Click
            Resources to the right and then click Sign Requirements and
            Restrictions.
          </p>
        </CardAccordion>
        <CardAccordion title="Do you need your property surveyed?">
          <p>
            Please contact a private engineering or surveying firm to do your
            survey. The county does not provide this service.{" "}
          </p>
          <ButtonSingle href="/departments/engineering/requests-and-forms">
            Requests and Forms
          </ButtonSingle>
        </CardAccordion>
        <CardAccordion title="How do I get a copy of a filed plat or survey?">
          <p>
            Contact the Recorder’s office or go to their webpage on this
            website.{" "}
          </p>
          <ButtonSingle href="/departments/recorders">
            Recorders Page
          </ButtonSingle>
        </CardAccordion>
        <CardAccordion title="How do I get an address for my new home or property? ">
          <p>
            Contact the Engineer’s office at <PhoneLink>435-865-5370</PhoneLink>{" "}
            or <EmailLink>mwilson@ironcounty.net</EmailLink>. Please provide an
            account # or parcel #
          </p>
          <ButtonSingle href="/departments/engineering/requests-and-forms">
            Requests and Forms
          </ButtonSingle>
        </CardAccordion>
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Engineering: FAQ</title>
        <meta name="description" content="Iron County engineering faq Page" />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/engineering/faq"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<EngineeringSidebar />}
        id="engineering-background"
        click={click}
      />
    </>
  );
}

export default FAQEngineering;

import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import CJCSidebar from "./CJCSidebar";
import Title from "../../../Components/Text/Title";
import Textbox from "../../../Components/Text/Textbox";
import EmailLink from "../../../Components/Links/EmailLink";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

function VolunteerCJC() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Left = () => (
    <>
      <Title>Volunteer</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <p>
          The Friends of the Iron County Children’s Justice Center is a
          501(c)(3) nonprofit, charitable organization established to support
          the Children’s Justice Center. The Board is made up of volunteers from
          the community who work to raise operational funds for the Center as
          well as spread awareness of child abuse within our community.
        </p>
        <p>
          Members of the Friends Board meet together for a monthly lunch-hour
          meeting to discuss and plan fundraising activities, events, and
          community awareness projects that take place throughout the year. All
          positions are volunteer-based only.
        </p>
        <p>
          The Children’s Justice Center also utilizes volunteers for tasks such
          as organizing, cleaning, or creating materials for the center.{" "}
        </p>
        <p>
          If you are interested in becoming a Friends Board member,
          volunteering, or would like more information, you may email us at{" "}
          <EmailLink>cjc.group@ironcounty.net</EmailLink> .
        </p>
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Children's Justice Center: Volunteer</title>
        <meta
          name="description"
          content="Iron County Children's Justice Center volunteer Page"
        />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/cjc/volunteer"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<CJCSidebar />}
        id="cjc-background"
        click={click}
      />
    </>
  );
}

export default VolunteerCJC;

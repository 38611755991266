import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import AuditorSideBar from "./AuditorSideBar";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

function TaxSaleResults() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Left = () => (
    <>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <h1>Replace me</h1>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Auditor: Tax Sale Results</title>
        <meta
          name="description"
          content="Iron County Auditor tax sale results Page"
        />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/auditor/tax-sale-results"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<AuditorSideBar />}
        id="Auditor-background"
      />
    </>
  );
}

export default TaxSaleResults;

import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import CardAccordion from "../../../Components/Card/CardAccordion";
import TextWebLink from "../../../Components/Links/TextWebLink";
import Textbox from "../../../Components/Text/Textbox";
import Title from "../../../Components/Text/Title";
import ElectionSidebar from "./ElectionSidebar";
import Map from "../../../Components/Maps/Map";
import ButtonSingle from "../../../Components/Buttons/Button_Single";
import TableDynamic from "../../../Components/Table/TableDynamic";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import Half from "../../../Components/Sizes/Half";
import { Helmet } from "react-helmet-async";

const ballots = [
  {
    Ballot_Information: "Total Ballots Mailed",
    Total_Number: "20,227",
  },
  {
    Ballot_Information: "Ballots Received",
    Total_Number: "9887",
  },
  {
    Ballot_Information: "Ballots Not Yet Processed",
    Total_Number: "7",
  },
  {
    Ballot_Information: "Ballots Processed",
    Total_Number: "9813",
  },
  {
    Ballot_Information: "In-person",
    Total_Number: "404",
  },
  {
    Ballot_Information: "Provisional Ballot un-Processed",
    Total_Number: "1",
  },
  {
    Ballot_Information: "Ballots Replicated",
    Total_Number: "40",
  },
  {
    Ballot_Information: "Ballots Challenged",
    Total_Number: "500",
  },
];

function Elections() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Left = () => (
    <>
      <Title>Elections</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <Map src="https://irongis.maps.arcgis.com/apps/instant/lookup/index.html?appid=e83307d2257c412c86028711a2b9fab0"></Map>
        <ButtonSingle
          href="https://irongis.maps.arcgis.com/apps/instant/lookup/index.html?appid=e83307d2257c412c86028711a2b9fab0"
          isLink={true}
        >
          Open in New Tab
        </ButtonSingle>
        <p>
          RECOUNT FOR REPUBLICAN U.S. HOUSE 2
          <ul>
            <li>
              July 31, 2024, 8:00 a.m., Recount of House District 2 Republican
              Primary
            </li>
            <li>August 1, 2024, 8:00 a.m., Recount (continued, if needed)</li>
            <li>August 1, 2024, 4:00 p.m., Post-Election Audit</li>
            <li>
              August 1, 2024, 5:00 p.m., Canvass of Recount of U.S. House
              District 2 Primary
            </li>
          </ul>
        </p>
      </Textbox>
      <Textbox title="Important Announcments">
        {/* <h1>2024 Republican Primary Ballot Statistics</h1>

        <TableDynamic tableData={ballots}></TableDynamic>
        <p>
          <i>
            Ballot processing in Clerk’s office: June 10, 2024 – July 8, 2024{" "}
            <br />
            Canvass of the election July 8, 2024 10:00
          </i>
        </p> */}

        {/* <ButtonSingle
          href="https://electionresults.utah.gov/results/public/ironcountyutah/elections/2023-Nov-General"
          isLink={true}
        >
          Election Results
        </ButtonSingle> */}
      </Textbox>
      <Textbox title="Voter Registration">
        <p>
          Don’t wait until it’s too late. There are several ways to register to
          vote, but there are also a few eligibility requirements you will need
          to know. The following will assist you with the information you need
          to be ready.
        </p>
        <p>
          <h2>You may register to vote in the State of Utah if you are:</h2>

          <li>At least 18 years-old on or before the next election.</li>
          <li>A citizen of the United States.</li>
          <li>
            A resident of Utah for at least 30 days before the election - An
            applicant is considered a resident of Utah if he or she has his
            principal place of residence within Utah and intends to continue to
            reside within Utah indefinitely.
          </li>
        </p>
        <p>
          <h2>Voter registration is valid for life, unless you:</h2>

          <li>Move</li>
          <li>Change your name</li>
          <li>Wish to affiliate with a different party</li>
        </p>
        <p>
          To obtain a copy of your voter registration card, please contact the
          Iron County Clerk’s Office.
        </p>
        <CardAccordion title="Register Online">
          <p>
            You can obtain the form for mail-in voter registration at the
            following link:
            <TextWebLink link="https://secure.utah.gov/voterreg/index.html">
              Online Voter Registration
            </TextWebLink>
          </p>
          <p>
            These are the requirements to be able to register to vote online:
            <li>Requires a valid Utah Driver License</li>
            <li>Update voter registration or party affiliation</li>
            <li>Mark a ballot preference for partisan primary elections</li>
            <li>
              First, update your UT Driver's License address online at{" "}
              <TextWebLink link="https://secure.utah.gov/dlr/index.html">
                www.secure.utah.gov
              </TextWebLink>{" "}
              - there's no need to stand in line and it's free. (The address
              printed on your UT Driver's License/ID card does not need to match
              your voter registration address). It may take 24-48 hours before
              the new address is active.
            </li>
          </p>
        </CardAccordion>
        <CardAccordion title="Register By Mail">
          <p>
            <ButtonSingle
              href="/files/departments/clerk/voter-registration-form.pdf"
              isLink={true}
            >
              Voter Registration Form
            </ButtonSingle>
          </p>
          <p>
            You may pick up a mail-in registration form at the following places:
            <li>Iron County Clerk’s Office</li>
            <li>Cedar City Library</li>
            <li>Cedar City Offices</li>
            <li>Enoch City Offices</li>
            <li>Driver’s License</li>
          </p>
          <p>
            Once completed, mail the form to: <br />
            Iron County Clerk’s Office <br />
            PO Box 429 <br />
            Parowan, UT 84761
          </p>
        </CardAccordion>
        <CardAccordion title="Register In Person">
          <p>
            Fifteen days or more before an election, a person may register to
            vote in person at the Iron County Clerk’s office. You will not be
            eligible to vote during early voting, but you will be able to vote
            on Election Day at your polling location.
          </p>
          <p>
            <b>
              You can also register to vote at the following agencies at least
              30 days before an election:
            </b>
            <li>The Drivers License Division</li>
            <li>Division of Workforce Services</li>
            <li>Utah State Department of Health</li>
            <li>Women, Infant, & Children (WIC) Offices</li>
            <li>The Division of Services for People with Disabilities</li>
            <li>The Department of Rehabilitation</li>
            <li>Public Colleges & Universities</li>
          </p>
        </CardAccordion>
      </Textbox>
      <Textbox title="Indentification Requirements:">
        <Half>
          <p>
            ONE: This is either a form of identification that bears the name and
            photograph of the voter or two forms of identification that bear the
            name of the voter and provide evidence of the voter’s residence.
          </p>
          <p>
            <b>In Utah, these forms of identification include:</b>
            <li>A currently valid Utah driver license;</li>
            <li>
              A currently valid ID card issued by the state or a branch,
              department, or agency of the United States;
            </li>
            <li>A currently valid Utah permit to carry a concealed weapon;</li>
            <li>A currently valid United States passport; or</li>
            <li>
              A valid tribal ID card, whether or not the card includes a
              photograph of the voter.
            </li>
          </p>
        </Half>
        <Half>
          <p>
            Or TWO forms of identification that bear the name of the voter and
            provide evidence that the voter resides in the voting precinct,
            which may include:
            <li>
              A current utility bill or copy thereof dated within 90 days before
              the election;
            </li>
            <li>
              A bank or other financial account statement, or legible copy
              thereof;
            </li>
            <li>A certified birth certificate;</li>
            <li>A valid Social Security card;</li>
            <li>
              A check issued by the state or federal government or legible copy
              thereof;
            </li>
            <li>
              A paycheck from the voter’s employer, or legible copy thereof
            </li>
            <li>A currently valid Utah hunting or fishing license;</li>
            <li>A currently valid U.S. military ID card;</li>
            <li>Certified naturalization documents (NOT a green card);</li>
            <li>
              A certified copy of court records showing the voter’s adoption or
              name change;
            </li>
            <li>A Bureau of Indian Affairs card;</li>
            <li>A tribal treaty card;</li>
            <li>
              A valid Medicaid or Medicare or Electronic Benefits Transfer card;
            </li>
            <li>
              A currently valid ID card issued by a local government within the
              state;
            </li>
            <li>A currently valid ID card issued by an employer;</li>
            <li>
              A currently valid ID card issued by a college, university,
              technical school or professional school within the state; or
            </li>
            <li>A current Utah vehicle registration.</li>
          </p>
        </Half>
      </Textbox>
      <Textbox title="Absentee Voting">
        <h2>
          **Any person who is registered to vote may vote by absentee ballot.**
        </h2>
        <Half>
          <p>
            <b>Obtaining an absentee ballot:</b>
          </p>
          <p>
            You may vote absentee by mail or in-person in the county clerk’s
            office. All completed forms should be mailed or submitted to the
            following offices:
            <li>
              Regular general and primary elections (held in even-numbered
              years). The application must be filed by mail or in-person in the
              office of the voter’s county clerk.
            </li>
            <li>
              Municipal elections (held in odd-numbered years). This application
              should be filed by mail or in-person in the office of the voter’s
              city clerk or recorder.
            </li>
          </p>
        </Half>
        <Half>
          <p>
            <b>Deadline for applying for an absentee ballot:</b>
            <li>
              For regular absentee voting, the ballot application must be
              received no later than the Friday before the election.
            </li>
            <li>
              For overseas voters, the clerk should receive the application no
              later than 20 days before the election.
            </li>
          </p>
          <p>
            Individuals using an absentee ballot in the office of their clerk or
            recorder must apply for the ballot and vote no later than the Friday
            before the election.
          </p>
        </Half>
        <p>
          <h2>Track the status of your absentee ballot:</h2>
          To track the status of your absentee ballot, log in to{" "}
          <TextWebLink link="https://vote.utah.gov/">
            vote.utah.gov
          </TextWebLink>{" "}
          by entering in your voter information. The current status will appear
          under your name on the right side of the screen if you have applied to
          receive an absentee ballot.
        </p>
        <p>
          <em>
            NOTE: The ballot is only valid if it is clearly postmarked before
            (not on) election day, and received in the county clerk’s office
            before noon on the day of the official canvass
          </em>
        </p>

        <p>
          <h2>Military/Oversea voters:</h2>
          Military/Oversea voters will be mailed out a ballot 45 day prior to
          the Election date. Ballots must be postmarked or emailed to
          clerk.group@ironcounty.net no later than 8PM (Utah local time) on
          Election Day.
        </p>
        <p>
          <em>
            For more information on the use of the Federal Post Card
            Applications (FPCA), the Federal Voting Assistance Program (FVAP),
            and related deadlines, or the use of a Federal Write-In Absentee
            Ballot (FWAB) if you do not receive your ballot in time, please
            contact Iron County Elections office at 435-477-8340 or email
            clerk.group@ironcounty.net
          </em>
        </p>
        <p>
          <ButtonSingle
            href="https://www.fvap.gov/uploads/FVAP/Forms/fwab2013.pdf"
            isLink={true}
          >
            Federal Write-In Absentee Ballot
          </ButtonSingle>
          <ButtonSingle href="https://www.fvap.gov/" isLink={true}>
            www.fvap.gov/
          </ButtonSingle>
          <ButtonSingle
            href="https://www.overseasvotefoundation.org/vote/home.htm?phoneNumberTypes=Home&phoneNumberTypes=Work&phoneNumberTypes=Mobile&phoneNumberTypes=Other"
            isLink={true}
          >
            Overseas Vote
          </ButtonSingle>
        </p>
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Clerk: Elections</title>
        <meta name="description" content="Iron County Clerk elections Page" />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/clerk/elections"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<ElectionSidebar />}
        id="clerk-background"
        click={click}
      />
    </>
  );
}

export default Elections;

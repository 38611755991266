import React from "react";
import AccordionSidemenu from "../../../Components/AccordionMenu/AccordionSidemenu";
import "../../../Components/Sidebar/Sidebar.css";

function CommissionSideBar() {
  const navItems = [
    {
      title: "About",
      to: "/departments/commission",
      isLink: false,
    },
    {
      title: "Commission Assignments",
      to: "/files/commission/commission-assignments.pdf",
      isLink: true,
    },

    {
      title: "Commission Meetings",
      to: "/departments/commission/commission-archive",
      isLink: false,
    },
    {
      title: "County Boards",
      to: "/departments/commission/county-boards",
      isLink: false,
    },
    {
      title: "Meeting Agenda Application",
      to: "/departments/commission/agenda-application",
      isLink: false,
    },
    {
      title: "New Jail Information",
      to: "/departments/commission/jail",
      isLink: false,
    },

    {
      title: "Watch Commission Meetings",
      to: "https://www.youtube.com/playlist?list=PLXd72c-bFFxnA9yqWhV8qYhlbqzbhCBNm",
      isLink: true,
    },
  ];

  return (
    <>
      <div className="nav-menu-side">
        <AccordionSidemenu nav={navItems} id="department-sidebar" />
      </div>
    </>
  );
}

export default CommissionSideBar;

import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import ParksSidebar from "./ParksSidebar";
import Title from "../../../Components/Text/Title";
import Textbox from "../../../Components/Text/Textbox";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import ButtonSingle from "../../../Components/Buttons/Button_Single";
import { Helmet } from "react-helmet-async";
import Image from "../../../Components/Images/Image";
import CardSmall from "../../../Components/Card/Card-Small";

import Markagunt from "../../../Images/Departments/Parks/Markagunt-Trail-Map.jpg";
import NorthHills from "../../../Images/Departments/Parks/North-Hills-Trail-Map.jpg";
import OldWest from "../../../Images/Departments/Parks/Old-West-Trail-Map.jpg";
import Parowan from "../../../Images/Departments/Parks/Parowan-Gap-Trail-Map.jpg";

function Trails() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Left = () => (
    <>
      <Title>Trails</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <p>
          The Color Country OHV Trail System is a network of trails that ATV
          enthusiasts can take throughout Iron County. These trails connect to
          other trail systems in adjoining counties, and offer the chance to
          enjoy magnificent scenery found throughout Iron County. The system
          takes in trail segments of the high desert regions in the western part
          of the county, and traverses the forested areas to the east. Parts of
          the trail in the west desert area can be traveled year-round and offer
          a glimpse of this area’s unique desert ecosystem. The Color Country
          Trail System is broken into segments, each segment with its own core
          trails and loops. Each loop is identified by name and marked with
          identifiable signage, so the riders will know where they are during
          their trips. Maps located at both kiosks and online will help riders
          identify loops, parking areas, and gather local information. Much of
          the Color Country OHV Trail segments are still under development and
          will be posted as they become available.
        </p>
        <ButtonSingle
          href="https://irongis.maps.arcgis.com/apps/webappviewer/index.html?id=68233488463a442f8c6ee305c374b132"
          isLink={true}
        >
          Interactive Trail Map
        </ButtonSingle>
      </Textbox>
      <Textbox title="PDF Maps">
        <CardSmall>
          <div>
            <Image
              width="100%"
              height="auto"
              src={Parowan}
              alt="Picture of the parowan gap trail in pdf form"
            />
          </div>

          <h2>Parowan Gap Trail Map</h2>
          <ButtonSingle
            href="/files/departments/parks/parowan-gap-trail.pdf"
            isLink={true}
          >
            View Map
          </ButtonSingle>
        </CardSmall>
        <CardSmall>
          <div>
            <Image
              width="100%"
              height="auto"
              src={Markagunt}
              alt="Picture of the Markagunt trail in pdf form"
            />
          </div>
          <h2>Markagunt Trail Map</h2>
          <ButtonSingle
            href="/files/departments/parks/markagunt-trail.pdf"
            isLink={true}
          >
            View Map
          </ButtonSingle>
        </CardSmall>
        <CardSmall>
          <div>
            <Image
              width="100%"
              height="auto"
              src={OldWest}
              alt="Picture of the oldwest trail in pdf form"
            />
          </div>
          <h2>Old West Trail Map</h2>
          <ButtonSingle
            href="/files/departments/parks/old-west-trail.pdf"
            isLink={true}
          >
            View Map
          </ButtonSingle>
        </CardSmall>
        <CardSmall>
          <div>
            <Image
              width="100%"
              height="300px"
              src={NorthHills}
              alt="Picture of the north hills trail in pdf form"
            />
          </div>
          <h2>North Hills Trail Map</h2>
          <ButtonSingle
            href="/files/departments/parks/north-hills-trail.pdf"
            isLink={true}
          >
            View Map
          </ButtonSingle>
        </CardSmall>
      </Textbox>
      <Textbox title="Additional Information">
        <ButtonSingle
          href="https://stateparks.utah.gov/activities/off-highway-vehicles/ohv-publications/"
          isLink={true}
        >
          Utah State Parks
        </ButtonSingle>
        <ButtonSingle
          href="https://www.fs.usda.gov/activity/dixie/recreation/ohv"
          isLink={true}
        >
          Dixie National Forest
        </ButtonSingle>
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Parks and Recreation: Trails</title>
        <meta
          name="description"
          content="Iron County  Parks and Recreation trails Page"
        />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/parks/trails"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<ParksSidebar />}
        id="parks-background"
        click={click}
      />
    </>
  );
}

export default Trails;

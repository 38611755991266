import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import { Helmet } from "react-helmet-async";
import Textbox from "../../../Components/Text/Textbox";
import Title from "../../../Components/Text/Title";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import ElectionSidebar from "./ElectionSidebar";
import PublicNoticesTable from "../../../Components/Card/PublicNoticesTable";
// import CardAccordion from "../../../Components/Card/CardAccordion";

function FinancialReports() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  const cardItems = [
    {
      title: "Kenneth Robinson",
      desc: "Date Filed: June 18th",

      links: [
        {
          title: "Download PDF",
          to: "/files/departments/clerk/elections/financial-reports/2024-kenneth-robinson2.pdf",
          isLink: true,
        },
      ],
    },
    {
      title: "Maile Wilson ",
      desc: "Date Filed: July 2nd",

      links: [
        {
          title: "Download PDF",
          to: "/files/departments/clerk/elections/financial-reports/2024-maile-wilson3.pdf",
          isLink: true,
        },
      ],
    },
  ];

  const Left = () => (
    <>
      <Helmet>
        <title>Clerk: Financial Reports</title>
        <meta
          name="description"
          content="Iron County Clerk Financial Reports Page"
        />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/clerk/financial-reports"
        />
      </Helmet>
      <Title>Candidate Financial Reports</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <p>Financial Reports for 2024 candidates.</p>
      </Textbox>
      <Textbox>
        <PublicNoticesTable data={cardItems} />
      </Textbox>
      <br />
      {/* <CardAccordion title="2023 Financial Reports"></CardAccordion> */}
    </>
  );

  return (
    <>
      <DepartmentTemplate
        left={<Left />}
        right={<ElectionSidebar />}
        id="clerk-background"
        click={click}
      />
    </>
  );
}

export default FinancialReports;

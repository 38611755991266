import React from "react";
import "./Text.css";
import SplitText from "./SplitText";
import Subtitle from "./Subtitle";

function Textbox(props) {
  return (
    <>
      {props.title ? <Subtitle>{props.title}</Subtitle> : null}
      <div className="hero-container" {...props}>
        {props.children}
        {/* <SplitText texts={props.text} /> */}
      </div>
    </>
  );
}

export default Textbox;

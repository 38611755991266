import React from "react";

import "../../../Components/Sidebar/Sidebar.css";
import AccordionSidemenu from "../../../Components/AccordionMenu/AccordionSidemenu";
import TextWebLink from "../../../Components/Links/TextWebLink";
import HoursContact from "../../../Components/Text/HoursContact";
import PhoneLink from "../../../Components/Links/PhoneLink";

function WasteSideBar() {
  const navItems = [
    {
      title: "Utility Billing",
      to: "/departments/clerk/utility-billing",
      isLink: false,
    },
  ];

  return (
    <>
      <div className="nav-menu-side">
        <HoursContact>
          <p>
            Bruce Anderson, Supervisor
            <br />
            PO Box 743
            <br />
            Cedar City, UT 84721
            <br />
            Main Phone: <PhoneLink>435-865-7015</PhoneLink>
            <br />
            Parowan Phone: <PhoneLink>435-477-3377</PhoneLink>
          </p>
          <p>
            <b> Main Landfill </b> <br />
            <TextWebLink link="https://goo.gl/maps/7K3581KiWwFKMJ7z7">
              3127 N Iron Springs Rd
            </TextWebLink>
            <br />
            Cedar City, UT 84720{" "}
          </p>
          <p>
            <b> Secondary Landfill </b> <br />
            <TextWebLink link="https://goo.gl/maps/YALoqmAaGSqfVRYg8">
              {" "}
              588 S 1200 W
            </TextWebLink>
            <br />
            Parowan, UT 84761{" "}
          </p>
        </HoursContact>
        <br />

        <AccordionSidemenu nav={navItems} />
      </div>
    </>
  );
}

export default WasteSideBar;

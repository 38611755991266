import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import CJCSidebar from "./CJCSidebar";
import Title from "../../../Components/Text/Title";
import Textbox from "../../../Components/Text/Textbox";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

function ReportAbuse() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Left = () => (
    <>
      <Title>Report Abuse</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <h2>
          Utah 24-hour child abuse reporting hotline: <br />
          <font size="10">
            <b>1-855-323-3237</b>
          </font>{" "}
        </h2>
        <p>
          Utah law requires any person who has reason to believe that a child
          has been subjected to abuse, neglect, or dependency to immediately
          notify the nearest office of Child and Family Services, a peace
          officer, or a law enforcement agency. Abuse, neglect, or dependency of
          a child can be physical, emotional, or sexual.
        </p>
        <h2>Local Law Enforcement</h2>
        <p>
          If you suspect child abuse or neglect, please call your local law
          enforcement or the DCFS reporting hotline.
        </p>
        <ul className="no-dots">
          <li>Cedar City Police Department (435) 586-2956</li>
          <li>Enoch City Police Department (435) 586-1119</li>
          <li>Parowan City Police Department (435) 477-3383</li>
          <li>Iron County Sheriff’s Office (435) 867-7500</li>
          <li>Department of Child and Family Services 1-855-323-3237</li>
        </ul>
        <h2>Other Resources</h2>
        <ul className="no-dots">
          <li>
            <b>Canyon Creek Services (Business office), 501(c)3 nonprofit</b>
          </li>
          <li>297 N Cove Dr. </li>
          <li>Cedar City, UT 84720</li>
          <li>(435) 867-9411</li>
        </ul>
        <ul className="no-dots">
          <li>
            <b>Family Support Center</b>
          </li>
          <li>102 N 200 E</li>
          <li>Cedar City, UT 84720</li>
          <li>435-586-0791</li>
          <li>24-hour Emergency Service (435) 233-5732</li>
        </ul>

        <ul className="no-dots">
          <li>
            <b>Southwest Behavioral Health Center, Iron County</b>
          </li>
          <li>245 E. 680 S.</li>
          <li>Cedar City, UT 84720</li>
          <li>(435) 867-7654</li>
          <li>24-hour Emergency Service (800) 574-6763</li>
        </ul>
        <ul className="no-dots">
          <li>International Helpline</li>
          <li>1-866-71-ABUSE</li>
        </ul>
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Children's Justice Center: Report Abuse</title>
        <meta
          name="description"
          content="Iron County Children's Justice Center report abuse Page"
        />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/cjc/report-abuse"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<CJCSidebar />}
        id="cjc-background"
        click={click}
      />
    </>
  );
}

export default ReportAbuse;

import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import CommissionSideBar from "./CommissionSidebar";
import Textbox from "../../../Components/Text/Textbox";
import Title from "../../../Components/Text/Title";
import CardSmallCountyBoards from "../../../Components/Card/Card-Small-CountyBoards";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

function CountyBoards() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  const allData = [
    {
      title: "Accident & Safety Review Board",
      desc: "The Accident & Safety Review Board will meet as needed. Members serve until further notice",
      names:
        "Jon Whittaker, Jennifer Bradbury, Justin Goodwin, Dave Mitchell, Luke Little, Shalon Shavor, Sam Woodall, Karsten Reed, Bruce Anderson, Lori Eichelberger",
    },
    {
      title:
        "AG Protection Area Board / Enterprise & Iron (E&I) Conservation District",
      desc: "Iron County does not make appointments. (Elected By Local Farm Svc. Agcy. List). Send notes to: P.O. Box 806, Panguitch UT, 84759",
      names:
        "Nancy Clark, Brent Hunter, Brandon Hunter, Mark Halterman, Cody Staheli, Glen Halterman",
    },
    {
      title: "Bookmobile Library Board",
      desc: "",
      names:
        "Pat Tompkins, Megan Bailey, Ashley Christensen, Paula Hein, Kay Dawn Mar, Laurie Rigby, Steve Decker, Cori Adams, Paul Cozzens",
    },

    {
      title: "Boundary Commission",
      desc: "2 municipalities appoint – within 30 days of filing protest UT Code 10-2-409",
      names: "Karsten Reed, Paul Cozzens",
    },
    {
      title: "Council on Aging",
      desc: "3 year terms staggered so 3 terms expire each year",
      names:
        "Lois Bulloch, Earl Paddock, Marlee Prince, Mark Jennings, Kathy Jennings, Robert Rasmussen, Arlen Grimshaw, Steve Davis, Curtis Crawford, Paul Cozzens",
    },
    {
      title: "Health Insurance Committee",
      desc: "Assigned by Department Head",
      names:
        "Jennifer Bradbury, Exilda Serrano, Shane Klenk, Carrie Christiansen, Stephanie Rainey, Taylor Lorance, Julie Sanders, Josh Meredith, Jeremy Truman, Marcia Smith, Ben Harris, Mickelle Croft, Melanie Hall",
    },
    {
      title: "IC Special Service Dist#3 Administrative Control Board",
      desc: "4 year terms",
      names:
        "Kim Evans, Jared Christensen, Brandon Hunter, Jon Whittaker, Marilyn Wood",
    },
    {
      title:
        "IC Econ. Opportunity Advisory Board/Industrial Protection Area Advisory Board",
      desc: "",
      names:
        "Scott Leavitt, Joni Anderson, Mollie Halterman, Garth Green, Ben Johnson, Spencer Jones, Bret Howser, Stephen Lisonbee, Terry Hartley, Brent Drew, Craig Isom, Geoffrey Chestnut, Danny Stewart, Mike Bleak",
    },
    {
      title: "LEPC/Emergency Preparedness Committee",
      desc: "",
      names: "George Colson, Rob Dotson, Rick Brown, Mike Bleak",
    },
    {
      title: "Mountain View Spec Service District Admin Control Board",
      desc: "",
      names: "Chuck Milby, George Kirkman, Jon Whittaker",
    },
    {
      title: "Natural Resource Advisory Council",
      desc: "Serve as available-replace as needed",
      names:
        "Rick Bonzo, Donnie Hunter, Jeff Hunter, Daneen McCarthy, Tom Stratton, Mike Worthen, Mike Bleak",
    },
    {
      title: "Planning Commission",
      desc: "Maximum nine consecutive years plus remainder of another",
      names:
        "Roger Thomas, Mark Halterman, Laine Sutherland, Mike Platt, Jared Christiansen, Michelle Tullis, Erick Cox, Merilee Wilson, Terry Palmer, Mike Bleak",
    },
    {
      title: "Restaurant Tax Board",
      desc: "",
      names:
        "Daniel Akin, Gary Dallman, Julie Padilla, Maria Twitchell, Mike Bleak",
    },
    {
      title: "Southwest Behavioral Health Board",
      desc: "",
      names:
        "Gil Almquist, Andy Gant, Wade Hollingshead, Kent Peterson, Jerry Taylor, Michael Deal, Dr. David Blodgett, Paul Cozzens",
    },
    {
      title: "Summitt Special Service Dist. Admin Control Board",
      desc: "4 Year Terms",
      names: "Danny Hulet, Val Williams, Adrian Anderson, Deo Jones, Wade Gale",
    },
    {
      title: "Tourism Advisory Board",
      desc: "3 year terms",
      names:
        "Dian Bixman, Kyle Grambley, Kade Maxedon, Rex McCarther, Jet Smith, Eddy Wittwer, Maria Twitchell, Mike Bleak",
    },
    {
      title: "Water Conservancy District (CICWCD)",
      desc: "4 year terms-(Kanarraville 1, Agricultural Interest 1, Enoch 2, Cedar 3)",
      names:
        "Terry Hartley, Andrew McCrea, Tyler Allred, Tyler Melling, David Harris, Brent Hunter, Spencer Jones, Paul Monroe, Paul Cozzens",
    },
    {
      title: "Weed Control Board",
      desc: "4 year term",
      names:
        "Paul Nelson, Shawn Reber, Kim Evans, Glen Halterman, Lee Hulet, Kacey Adams, Lance Halterman, Marilyn Wood",
    },
  ];

  const Left = () => (
    <>
      <Title>County Boards</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <CardSmallCountyBoards data={allData} />
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Commission: County Boards</title>
        <meta name="description" content="Iron County Commission Page" />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/commission/county-boards"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<CommissionSideBar />}
        id="commission-background"
        click={click}
      />
    </>
  );
}

export default CountyBoards;

import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import JusticeSideBar from "./JusticeSidebar";
import Aidaform from "../../../Components/Aidaform/Aidaform";
import CardAccordion from "../../../Components/Card/CardAccordion";
import Textbox from "../../../Components/Text/Textbox";
import Title from "../../../Components/Text/Title";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

function EntryOfPleaForm() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Left = () => (
    <>
      <Title>Entry of Plea Forms</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <br />
      <Textbox>
        <CardAccordion title="Entry of Plea Form English">
          <Aidaform
            form="https://ironcounty.aidaform.com/entry-of-plea-form"
            title=""
          />
        </CardAccordion>
        <CardAccordion title="Entry of Plea Form Spanish">
          <Aidaform
            form="https://ironcounty.aidaform.com/entry-of-plea-form-spanish"
            title=""
          />
        </CardAccordion>
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Justice Court: Entry of Plea Form</title>
        <meta
          name="description"
          content="Iron County Justice Court Entry of Plea Form Page"
        />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/justice-court/entry-of-plea-form"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<JusticeSideBar />}
        id="justice-background"
        click={click}
      />
    </>
  );
}

export default EntryOfPleaForm;

import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import TreasurerSidebar from "./TreasurerSidebar";
import Textbox from "../../../Components/Text/Textbox";
import Title from "../../../Components/Text/Title";
import Aidaform from "../../../Components/Aidaform/Aidaform";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

function ChangeOfAddress() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  const Left = () => (
    <>
      <Title>Change of Address</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <Aidaform form="https://ironcounty.aidaform.com/treasurer-change-of-address-form" />
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Treasurer: Change of Address</title>
        <meta name="description" content="Iron County Treasurer Page" />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/treasurer/change-of-address"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<TreasurerSidebar />}
        id="treasurer-background"
        click={click}
      />
    </>
  );
}

export default ChangeOfAddress;

import React, { useState } from "react";
import "../Departments.css";
import AssessorSidebar from "./AssessorSidebar";
import Textbox from "../../../Components/Text/Textbox";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import TextWebLink from "../../../Components/Links/TextWebLink";
import Title from "../../../Components/Text/Title";
import Youtube from "../../../Components/Links/Youtube";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

function Assessor() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  const Left = () => (
    <>
      <Title>Assessor of Iron County</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>

      <Textbox>
        <section>
          <p>
            The Assessor’s office is responsible for classifying all property in
            the county; in cooperation with the Iron County Recorder’s office,
            associate ownership with the property; establishing a taxable value
            for all property subject to property taxation; and compiling this
            information into reports that are utilized by the Auditor’s office
            to calculate tax rates and the Iron County Treasurer’s office for
            collections.
            <br />
            <br />
            <b>Real Property</b> - Residential, commercial buildings,
            agricultural and vacant land.
            <br />
            <br />
            <b>Personal Property</b> - Equipment, including leased and rented,
            that is used to operate a business. This includes furniture,
            shelves, light fixtures, supplies, etc. More info
          </p>
        </section>
      </Textbox>
      <Textbox title="Motor Vehicles">
        <section>
          <p>
            In addition to classifying all property in the county, the Iron
            County Assessor is your local motor vehicles office. The primary
            duty of motor vehicles is to issue title and registration for Utah
            vehicles, including passenger vehicles, trucks, trailers,
            motorcycles, motor homes, off-highway vehicles, boats, and
            snowmobiles. We have two motor vehicle office locations, one in
            Parowan and another in Cedar City. For more information about motor
            vehicles, see{" "}
            <TextWebLink link="https://dmv.utah.gov/">dmv.utah.gov</TextWebLink>
            .
          </p>
        </section>
      </Textbox>
      <Youtube
        src="https://www.youtube.com/embed/JSVT-5st2Wk"
        title="Assessor Video"
      />
      <Youtube
        src="https://www.youtube.com/embed/nkMzAXd9oj0"
        title="Property Tax"
      />
    </>
  );

  return (
    <>
      <Helmet>
        <title>Assessor</title>
        <meta name="description" content="Iron County Assessors Home Page" />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/assessor"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<AssessorSidebar />}
        id="assessor-background"
        click={click}
      />
    </>
  );
}

export default Assessor;

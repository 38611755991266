import React, { useState } from "react";
import "../Departments.css";
import AuditorSideBar from "./AuditorSideBar";
import Textbox from "../../../Components/Text/Textbox";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import Title from "../../../Components/Text/Title";
import Youtube from "../../../Components/Links/Youtube";
import MobileNav from "../../../Components/Navbar/MobileNav";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

function Auditor() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  const Left = () => (
    <>
      <Title>Auditor of Iron County</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      {/* <MobileNav></MobileNav> */}

      <Textbox>
        <p>
          The Auditor’s Office is the accounting office for Iron County. Paying
          all county bills, collecting funds (exclusive of taxes) owed to the
          county and preparing the payrolls for all Iron County employees. Iron
          County’s annual budget is prepared by the Auditor, who consults with
          and advises other county officials during each year.
        </p>
      </Textbox>
      <Youtube
        src="https://www.youtube.com/embed/UK__4k5r4qU"
        // title="Auditor Video"
      />
    </>
  );

  return (
    <>
      <Helmet>
        <title>Auditor</title>
        <meta name="description" content="Iron County Auditor Page" />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/auditor"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<AuditorSideBar />}
        id="auditor-background"
        click={click}
      />
    </>
  );
}

export default Auditor;

import React, { useState } from "react";
import "../Departments.css";
import AuditorSideBar from "./AuditorSideBar";
import Textbox from "../../../Components/Text/Textbox";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import Title from "../../../Components/Text/Title";
import Half from "../../../Components/Sizes/Half";
import TextWebLinkList from "../../../Components/Links/TextWebLinkList";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

function Budget() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  const BudgetList = [
    {
      name: "2024 Budget",
      link: "/files/departments/auditor/final-budget-2024.pdf",
    },
    {
      name: "2023 Budget",
      link: "/files/departments/auditor/tentative-budget-public.pdf",
    },
  ];
  const FinancialList = [
    {
      name: "2023 Audited Financial Statements",
      link: "/files/departments/auditor/2023-audited-financial.pdf",
    },
    {
      name: "2022 Audited Financial Statements",
      link: "/files/departments/auditor/2022-audited-financial-statements.pdf",
    },
  ];

  const Left = () => (
    <>
      <Title>Budget & Financial Statements</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <Half>
          <TextWebLinkList
            title="Budget List"
            links={BudgetList}
          ></TextWebLinkList>
        </Half>
        <Half>
          <TextWebLinkList
            title="Financial Statements"
            links={FinancialList}
          ></TextWebLinkList>
        </Half>
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Auditor: Budget and Financial Statements</title>
        <meta
          name="description"
          content="Iron County Auditor budget and financial statments Page"
        />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/auditor/budget"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<AuditorSideBar />}
        id="auditor-background"
        click={click}
      />
    </>
  );
}

export default Budget;

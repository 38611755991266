import React from "react";
import { Link } from "react-router-dom";
import "./Card.css";
import ButtonSingle from "../Buttons/Button_Single";
import Image from "../Images/Image";
import Half from "../Sizes/Half";
import Subtitle from "../Text/Subtitle";

function CardSmallCountyBoards(props) {
  return (
    <>
      {props.data.map((card) => {
        return (
          <Half>
            <div className="card-container-text">
              <div className="card-text-fill">
                <Subtitle>{card.title}</Subtitle>
                <p>{card.desc}</p>
                <p>
                  <em>{card.names}</em>
                </p>
              </div>
            </div>
          </Half>
        );
      })}
    </>
  );
}

export default CardSmallCountyBoards;

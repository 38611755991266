import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import AttorneySidebar from "./AttorneySidebar";
import Textbox from "../../../Components/Text/Textbox";
import Title from "../../../Components/Text/Title";
import Half from "../../../Components/Sizes/Half";
import Quarter from "../../../Components/Sizes/Quarter";
import Subtitle from "../../../Components/Text/Subtitle";
import TextWebLink from "../../../Components/Links/TextWebLink";
import PhoneLink from "../../../Components/Links/PhoneLink";
import { Link } from "react-router-dom";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

function Attorney() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Left = () => (
    <>
      <Title>Attorney's Office</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>

      <Textbox>
        <h1>Mission Statement</h1>
        <p>
          The mission of the Iron County Attorney’s Office is to professionally,
          ethically and vigorously promote the safety and security of Iron
          County through a pro-active approach to the criminal justice system.
        </p>
        <p>
          We will effectively and compassionately advocate on behalf of crime
          victims, by demanding accountability from offenders. We will work
          alongside our partners in law enforcement and community stakeholders
          to enhance the quality of life of the citizens of Iron County.
        </p>
        <p>
          We will provide excellent legal advice and services to the governing
          bodies and elected officials of Iron County.
        </p>
        <p>
          We will uphold the principles of the Constitution of the United
          States, the laws of the State of Utah, and the rights guaranteed to
          each individual.
        </p>
      </Textbox>
      <Textbox>
        <Half>
          <Subtitle>Iron County Victim and Witness Services</Subtitle>
          <p>
            Crime Victim and Witness Services, as part of the Iron County
            Attorney's Office, are here to assist victims and witnesses of crime
            through the criminal justice process.
          </p>
          <p>
            <b>Jessica Cramer, C.A.</b>
            <em>
              Victim and Witness Coordinator Certified in Trauma Informed
              Advocacy in Legal Systems
            </em>
          </p>
          <p>
            <b>Support Offered:</b>
            <li>
              Uphold Victim Rights -{" "}
              <TextWebLink link="https://le.utah.gov/xcode/Title77/Chapter37/77-37-S3.html#:~:text=Bill%20of%20rights.,-(1)&text=Victims%20and%20witnesses%2C%20including%20children,provide%20this%20information%20and%20assistance.">
                Bill of Rights UCA § 77-37-3
              </TextWebLink>
            </li>
            <li>Trauma-Informed trial preparation and courtroom advocacy</li>
            <li>Reparation and restitution advocacy</li>
            <li>Criminal case information and hearing notifications</li>
            <li>Liaison between victims of crime and prosecutor</li>
            <li>Referrals to community resources</li>
          </p>
          <p>
            <b>Resources:</b>
            <li>
              <TextWebLink link="https://www.canyoncreekservices.org/">
                Canyon Creek Services
              </TextWebLink>{" "}
              - 24 Hour Hotline: <PhoneLink>435-233-5732</PhoneLink>
            </li>
            <li>
              <Link to="/departments/cjc">
                Iron County Children's Justice Center
              </Link>{" "}
              - <PhoneLink>435-867-4275</PhoneLink>
            </li>
            <li>
              Cedar City Police Victim Advocate -{" "}
              <PhoneLink>435-590-1413</PhoneLink>
            </li>
            <li>
              <TextWebLink link="https://ironsheriff.net/divisions/victim-services">
                Iron County Sheriff’s Victim Advocate
              </TextWebLink>{" "}
              - <PhoneLink>-435-704-6658</PhoneLink>
            </li>
            <li>
              Enoch/Parowan Police Department Victim Advocate -{" "}
              <PhoneLink>435-704-7830</PhoneLink>
            </li>
            <li>
              <TextWebLink link="https://www.crimevictim.utah.gov/">
                Utah Office for Victims of Crime
              </TextWebLink>{" "}
              - <PhoneLink>800-621-7444</PhoneLink>
            </li>

            <li>
              SAKI - Sexual Assault Kit Information Hotline -
              <PhoneLink>801-893-1145</PhoneLink>
            </li>
            <li>
              <TextWebLink link="http://www.utahvictimsclinic.org/">
                Utah Crime Victims Legal Clinic
              </TextWebLink>{" "}
            </li>
            <li>
              <TextWebLink link="https://www.utahlegalservices.org/">
                Utah Legal Services
              </TextWebLink>{" "}
              - <PhoneLink>800-662-4245</PhoneLink>
            </li>
            <li>
              <TextWebLink link="https://www.vinelink.com/#state-selection">
                VINE – Victim Information and Notification Everyday{" "}
              </TextWebLink>{" "}
              - <PhoneLink>877-884-8463</PhoneLink>
            </li>
            <li>
              <TextWebLink link="https://udvc.org/">
                Utah Domestic Violence Coalition 24-Hour LINKline
              </TextWebLink>{" "}
              - <PhoneLink>800-897-5465</PhoneLink>
            </li>
            <li>
              <TextWebLink link="https://www.ucasa.org/">
                Utah Coalition Against Sexual Assault
              </TextWebLink>{" "}
              - <PhoneLink>801-746-0404</PhoneLink>
            </li>
            <li>
              <TextWebLink link="http://www.utahhomicidesurvivors.org/">
                Utah Homicide Survivors
              </TextWebLink>{" "}
              - <PhoneLink>801-500-9077</PhoneLink>
            </li>
          </p>
        </Half>
        <Half>
          <Subtitle>Office Personnel</Subtitle>
          <Quarter>
            <h1>Attorney's</h1>

            <h4>Chad Dotson</h4>
            <h4>David Hill</h4>
            <h4>Trajan Evans</h4>
            <h4>Shane Klenk</h4>
            <h4>Sam Woodall</h4>
            <hr />
          </Quarter>
          <Quarter>
            <h1>Legal Assistant's</h1>

            <h4>Hayden Morton</h4>
            <h4>Susan Carter</h4>
            <h4>Amy Robinson</h4>
            <h4>Sandra Capps</h4>
            <h4>Lacee Mitchell</h4>
            <hr />
          </Quarter>
          <Quarter>
            <h1>Victim Services</h1>

            <h4>Jessica Cramer</h4>
            <hr />
          </Quarter>
          <Quarter>
            <h1>Children's Justice Center</h1>
          </Quarter>
        </Half>
      </Textbox>
      {/* <Textbox title="Office Personnel"></Textbox> */}
    </>
  );

  return (
    <>
      <Helmet>
        <title>Attorney</title>
        <meta name="description" content="Iron County Attorney Page" />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/attorney"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<AttorneySidebar />}
        id="attorney-background"
        click={click}
      />
    </>
  );
}

export default Attorney;

import * as React from "react";
import "./Table.css";
// import fakedata from "./TestData";
import { useTable } from "react-table";
import addSpace from "../Text/addSpace";

function TableDynamic(props) {
  const fakedata = [...props.tableData];
  let keys = Object.keys(fakedata[0]);

  const testing = [];
  let x = 0;
  let y = "a";

  while (x < keys.length) {
    y = addSpace(keys[x]);

    testing.push({
      Header: y,
      accessor: keys[x],
    });
    x++;
  }

  const data = React.useMemo(() => fakedata, []);

  const columns = React.useMemo(() => testing, []);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  return (
    <div className="hero-child">
      <table className="table-test" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default TableDynamic;

import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import AuditorSideBar from "./AuditorSideBar";
import Title from "../../../Components/Text/Title";
import Textbox from "../../../Components/Text/Textbox";
import TextWebLink from "../../../Components/Links/TextWebLink";
import CardAccordion from "../../../Components/Card/CardAccordion";
import Half from "../../../Components/Sizes/Half";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

function IndividualAbatements() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Left = () => (
    <>
      <Title>Individual Abatements</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <br />

      <Textbox>
        <h3>For Individuals who are Blind, Disabled, Low Income or Veterans</h3>
        <p>
          Below are individual abatement opportunities offered by Iron County.
          Each application has eligibility standards that have to be met in
          order to qualify. There is an application and required documentation
          that will need to be{" "}
          <b>
            <u>completed and submitted on a yearly basis</u>
          </b>{" "}
          for most applications. In order for the abatement to take affect for
          the current years taxes all information must be{" "}
          <b>
            <u>in the auditor’s office by 5 p.m. on Sept. 1st.</u>
          </b>{" "}
          There are no refunds available for previous year’s taxes.{" "}
        </p>
        <Half>
          <CardAccordion title="Low Income & Elderly">
            <p>
              Credit amount based on gross annual household income
              <h2>
                <u>Eligibility:</u>
              </h2>
              <li>66+ years of age</li>
              OR
              <li>Widow or widower at any age</li>
              AND
              <li>Provide own financial support</li>
              <li>Gross annual household income is less than $40,840</li>
              <li>Claimants name on title of primary residence</li>
              <li>Own home full calendar year</li>
              <h2>
                <u>Application Requirements:</u>
              </h2>
              <li>
                <TextWebLink link="/files/departments/auditor/low-income.pdf">
                  Application - required yearly
                </TextWebLink>
                Forms TC-90cb and TC-90cy will be released in early July.
              </li>
              <li>Tax Return and/or Statements for all household income</li>
            </p>
          </CardAccordion>
          {/* <Subtitle>Low Income & Elderly</Subtitle> */}
        </Half>
        <Half>
          <CardAccordion title="Under 66 with Low Income and Hardship">
            <p>
              Credit based on taxes owed and gross annual household income
              <h2>
                <u>Eligibility:</u>
              </h2>
              <li>65+ years of age</li>
              OR
              <li>Disabled</li>
              OR
              <li>Under extreme hardship</li>
              AND
              <li>Gross annual household income is less than $40,840</li>
              <li>Claimants name on title of primary residence</li>
              <li>Own home full calendar year</li>
              <h2>
                <u>Application Requirements:</u>
              </h2>
              <li>
                <TextWebLink link="/files/departments/auditor/under66-low-income.pdf">
                  Application - required yearly
                </TextWebLink>
                Forms TC-90cb and TC-90cy will be released in early July.
              </li>
              <li>A signed letter explaining hardship</li>
              <li>
                Tax Return and/or statements for all household income
                <li>
                  If qualifying via Social Security Disability a{" "}
                  <u>
                    <b>
                      Benefit Verification Letter must be provided for proof of
                      disability income.
                    </b>
                  </u>{" "}
                  If this letter in not included your SSDI{" "}
                  <u>
                    <b> will</b>
                  </u>{" "}
                  be included as income
                </li>
              </li>
            </p>
          </CardAccordion>
          {/* <Subtitle>Under 66 with Low Income and Hardship</Subtitle> */}
        </Half>
        <Half>
          <CardAccordion title="Blind/Spouse">
            <p>
              {/* <Subtitle>Blind/Spouse</Subtitle> */}
              <h2>
                <u>Eligibility:</u>
              </h2>
              <li>Legally Blind</li>
              <li>Unmarried surviving spouse or minor child of blind</li>
              <li>Claimants name on title of primary residence</li>
              <h2>
                <u>Application Requirements:</u>
              </h2>
              <li>
                <TextWebLink link="/files/departments/auditor/blind-application.pdf">
                  Application - required yearly
                </TextWebLink>
              </li>
              <li>Letter from doctor stating percentage of blindness</li>
            </p>
          </CardAccordion>
        </Half>
        <Half>
          <CardAccordion title="Disabled Veteran / Spouse">
            <p>
              {/* <Subtitle>Disabled Veteran / Spouse</Subtitle> */}
              <h2>
                <u>Eligibility:</u>
              </h2>

              <li>At least 10% service incurred disability</li>
              <li>
                unmarried surviving spouse or minor child of a disabled veteran
              </li>
              <li>Claimants name on title of primary residence</li>
              <h2>
                <u>Application Requirements:</u>
              </h2>
              <li>
                <TextWebLink link="/files/departments/auditor/veteran.pdf">
                  Application - Required Once
                </TextWebLink>
              </li>
              <li>DD214</li>
              <li>VA Disability Award Letter</li>
            </p>
          </CardAccordion>
        </Half>
        <Half>
          <CardAccordion title="Active Duty Veteran">
            <p>
              {/* <Subtitle>Active Duty Veteran</Subtitle> */}
              <h2>
                <u>Eligibility:</u>
              </h2>
              <li>
                At least 200 days in a continuous 365 day period outside the
                state beginning in prior year
              </li>
              <li>Claimants name on title of primary residence</li>
              <h2>
                <u>Application Requirements:</u>
              </h2>
              <li>
                <TextWebLink link="/files/departments/auditor/active-veteran.pdf">
                  Application - required yearly
                </TextWebLink>
              </li>
              <li>Copy of deployment orders</li>
            </p>
          </CardAccordion>
        </Half>
        <Half>
          <CardAccordion title="Widow or Widower of KIA Veteran">
            <p>
              {/* <Subtitle>Widow or Widower of KIA Veteran</Subtitle> */}
              <h2>
                <u>Eligibility:</u>
              </h2>
              <li>
                Unmarried surviving spouse or minor child of a veteran killed in
                the line of duty.*
              </li>
              <li>
                Widow(er) or minor child name on title of primary residence
              </li>
              <h2>
                <u>Application Requirements:</u>
              </h2>
              <li>
                <TextWebLink link="/files/departments/auditor/kia-veteran.pdf">
                  Application - One Time
                </TextWebLink>
              </li>
              <li>Copy of deployment orders</li>
              <li>
                *If applying as an unmarried surviving spouse or minor child of
                a veteran killed in the line of duty{" "}
              </li>
              <li>Letter indicating casualty information from VA</li>
            </p>
          </CardAccordion>
        </Half>

        <section>
          <p>
            <b>
              <u>
                All forms and documentation must be completely filled out,
                signed and sent to the Iron County Auditor’s office via mail,
                email or in person by 5 p.m. on Sept. 1st to be considered for
                tax adjustments for that year.{" "}
              </u>
            </b>
          </p>
          <p
            style={{
              border: "solid",
              textAlign: "center",
              width: "300px",
              margin: "auto",
            }}
          >
            Iron County's Auditor's Office
            <br />
            PO Box 457
            <br />
            68 South 100 East
            <br />
            Parowan, UT, 84760
            <br />
            {/* <EmailLink>clister@ironcounty.net</EmailLink> */}
          </p>
          <h3>
            <u>Appeal Rights</u>
          </h3>
          <p>
            If you feel you have been wrongfully denied exemption or abatement
            you may appeal to the Utah State Tax Commission. You must file the
            appeal within 30 day of the denial notification. Contact the
            Auditor’s office for the necessary appeal forms.
          </p>
        </section>
      </Textbox>
    </>
  );
  return (
    <>
      <Helmet>
        <title>Auditor: Individual Abatements</title>
        <meta
          name="description"
          content="Iron County Auditor individual abatements Page"
        />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/auditor/individual-abatements"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<AuditorSideBar />}
        id="auditor-background"
      />
    </>
  );
}

export default IndividualAbatements;
